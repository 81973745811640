import { AxiosInstance, AxiosPromise } from "axios"

export const archiveEndorsement = (
  client: AxiosInstance,
  endorsement_id: string,
): AxiosPromise => client.post(`/api/endorsements/${endorsement_id}/archive`)

export const unarchiveEndorsement = (
  client: AxiosInstance,
  endorsement_id: string,
): AxiosPromise => client.post(`/api/endorsements/${endorsement_id}/unarchive`)
