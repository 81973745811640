import { FC, ReactNode, useId } from "react"

import { Logo as OttoLogo } from "@appia/ui-components"

import AcceptedRadioButtons from "../AcceptedRadioButtons"
import BoundingBoxCTA from "../components/BoundingBoxCTA"
import Group from "../components/Group"
import KiLogo from "./KiLogo"
import QuestionInput from "../components/QuestionInput"
import ReadOnlyKiData from "../components/ReadOnlyKiData"

import { KiAnswer, PBQAAnswer, PBQAQuestion } from "@appia/api"

import { answersMatch } from "ReviewPBQA/answerUtils"
import {
  QuestionAnswerState,
  QuestionAnswerStateBoolean,
  QuestionAnswerStateEEA,
  QuestionAnswerStateOverlining,
  copyKiAnswerToAnswer,
} from "ReviewPBQA/state"

import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"
import { logPBQAValueCopy } from "src/amplitude"

import classNames from "classnames"

// Exported for testing only
export const ComparisonQuestion: FC<{
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  questionAnswerState: Exclude<
    QuestionAnswerState,
    | QuestionAnswerStateBoolean
    | QuestionAnswerStateOverlining
    | QuestionAnswerStateEEA
  >
  showFieldErrors: boolean
}> = ({
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  questionAnswerState,
  showFieldErrors,
}) => {
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  const { question, answer } = questionAnswerState

  const answerDiffersFromKiAnswer = !answersMatch(
    questionAnswerState.answer,
    questionAnswerState.kiAnswer,
  )

  const onCopyValue = answerDiffersFromKiAnswer
    ? () => {
        // We want the same shape as a `KiAnswer`
        const extractedValue: KiAnswer =
          questionAnswerState.type === "decimal" ||
          questionAnswerState.type === "integer"
            ? {
                answer: questionAnswerState.answer.answer,
                unit: questionAnswerState.answer.unit,
              }
            : questionAnswerState.answer.answer

        logPBQAValueCopy({
          pbqaId,
          surveyId,
          questionId: questionAnswerState.question.id,
          extractedValue,
          kiValue: questionAnswerState.kiAnswer ?? null,
        })

        const updatedAnswer = copyKiAnswerToAnswer(questionAnswerState)

        // The values must now match by definition, so we auto-accept with
        // `true` and remove the note and reasons
        onChangeAnswer({
          ...updatedAnswer,
          accepted: true,
          note: null,
          reasons: [],
        })
      }
    : undefined

  const inputId = useId()
  const inputLabelId = useId()
  const legendId = useId()

  return (
    <Group aria-labelledby={legendId} className="border-b border-otto-grey-300">
      <legend
        id={legendId}
        className="w-full border-b border-otto-grey-300 p-2 font-bold"
      >
        {question.text}
      </legend>

      <div
        className={classNames(
          "grid w-full grid-cols-1 items-stretch justify-between",
          isActive ? "bg-otto-pop-200" : "bg-otto-pop-50",
          "lg:grid-cols-[minmax(0,1fr),minmax(0,1fr),auto]",
        )}
      >
        <div className="w-full border-b border-otto-grey-300 bg-ki-light p-2 lg:border-b-0 lg:border-r">
          <ReadOnlyKiData
            questionAnswerState={questionAnswerState}
            onCopyValue={onCopyValue}
          />
        </div>

        <div className="w-full p-2">
          <div className="flex min-h-[1.75rem] items-end gap-2 pb-1">
            {answer.boundingBox && (
              <BoundingBoxCTA
                questionLabel={question.label}
                onClick={onJumpToBoundingBox}
              />
            )}

            <label htmlFor={inputId} id={inputLabelId}>
              {question.label}
            </label>
          </div>

          <QuestionInput
            autoAcceptOnBlur
            inputId={inputId}
            inputLabelId={inputLabelId}
            onChangeAnswer={onChangeAnswer}
            questionAnswerState={questionAnswerState}
            showChangedValuesWarning={answerDiffersFromKiAnswer}
            showFieldErrors={showFieldErrors}
          />
        </div>

        <AcceptedRadioButtons
          questionId={question.id}
          questionText={question.text}
          accepted={answer.accepted}
          onChangeAcceptance={accepted =>
            onChangeAnswer({ ...answer, accepted })
          }
          noteAndReasons={{ note: answer.note, reasons: answer.reasons }}
          onChangeNoteAndReasons={({ note, reasons }) =>
            onChangeAnswer({ ...answer, note, reasons })
          }
          showFieldErrors={showFieldErrors}
          paddingTop="lg:pt-8"
        />
      </div>
    </Group>
  )
}

const columnHeaders: { logo: ReactNode; bgColor: string }[] = [
  { logo: <KiLogo />, bgColor: "bg-ki-light" },
  { logo: <OttoLogo />, bgColor: "bg-otto-pop-50" },
]

const ComparisonGroup: FC<{
  onChangeAnswer: (id: PBQAQuestion["id"], a: PBQAAnswer) => void
  onJumpToBoundingBox: (id: PBQAQuestion["id"]) => void
  questionStates: QuestionAnswerState[]
  showFieldErrors: boolean
}> = ({
  onChangeAnswer,
  onJumpToBoundingBox,
  questionStates,
  showFieldErrors,
}) => {
  const {
    activeQuestionId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  return (
    <>
      <div className="grid grid-cols-[1fr,1fr,theme('width.32')] border-b border-otto-grey-300 text-center">
        {columnHeaders.map(({ logo, bgColor }, i) => (
          <div
            key={i}
            className={classNames(
              "flex items-center justify-center border-r border-otto-grey-300 p-4",
              bgColor,
            )}
          >
            {logo}
          </div>
        ))}

        <div className="p-4 text-lg font-bold">Status</div>
      </div>

      {questionStates.map(state => {
        // Although we don't enforce it in the types or API, there is a soft
        // agreement with the product and design teams that boolean and
        // overlining questions should never be rendered as comparison layouts
        if (
          state.type === "boolean" ||
          state.type === "overlining" ||
          state.type === "eea"
        ) {
          throw new Error(
            `Unexpected ${state.type} question in comparison group (survey ID ${surveyId})`,
          )
        }

        const questionId = state.question.id

        return (
          <ComparisonQuestion
            key={questionId}
            isActive={activeQuestionId === questionId}
            onChangeAnswer={a => onChangeAnswer(questionId, a)}
            onJumpToBoundingBox={() => onJumpToBoundingBox(questionId)}
            questionAnswerState={state}
            showFieldErrors={showFieldErrors}
          />
        )
      })}
    </>
  )
}

export default ComparisonGroup
