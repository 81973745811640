import { AxiosInstance, AxiosPromise } from "axios"
import type { User } from "../Users"

export const assignPBQA = (
  client: AxiosInstance,
  pbqa_id: string,
  user_id: User["id"],
): AxiosPromise =>
  client.post(`/api/pbqa/${pbqa_id}/assign`, {
    owner_id: user_id,
  })

export const unassignPBQA = (
  client: AxiosInstance,
  pbqa_id: string,
): AxiosPromise => client.delete(`/api/pbqa/${pbqa_id}/unassign`, {})
