import { FC } from "react"

export interface InitialsProps {
  initials: string
  label: string
}

const Initials: FC<InitialsProps> = ({ initials, label }) => {
  return (
    <div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-otto-grey-700">
      <span
        className="text-sm font-medium leading-none text-white"
        title={label}
        aria-hidden
      >
        {initials}
      </span>

      <span className="sr-only">{label}</span>
    </div>
  )
}

export default Initials
