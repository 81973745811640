import axios, { AxiosInstance, AxiosPromise } from "axios"

export interface PBQAUploadFileLimits {
  acceptedDocumentTypes: string[]
  sizeLimitMb: number
}

export const getPBQAUploadFileLimitsUrl = "/api/pbqa/limits"

interface PBQANewDocumentRequest {
  name: string
  mimetype: string
  extension: string
}

interface PBQAUploadUrlResponse {
  id: string // Document ID
  pbqa_id: string
  url: string
  fields: Record<string, string>
}

export const generatePBQAUploadUrl = (
  client: AxiosInstance,
  body: PBQANewDocumentRequest,
): AxiosPromise<PBQAUploadUrlResponse> =>
  client.post(`/api/pbqa/upload`, body, {
    // Don't camelCase the response or the subsequent S3 upload won't work
    transformResponse: axios.defaults.transformResponse,
  })
