export const createObjectURL = async (
  url: string,
  mimeType: string,
): Promise<string> => {
  const res = await fetch(url)
  const blob = await res.blob()
  const blob_ = new Blob([blob], { type: mimeType })
  return URL.createObjectURL(blob_)
}

export const downloadFile = async (
  url: string,
  filename: string,
  mimetype: string,
): Promise<void> => {
  const objectURL = await createObjectURL(url, mimetype)
  const link = document.createElement("a")
  link.href = objectURL
  link.download = filename
  document.body.append(link)
  link.click()
  link.remove()

  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}
