import { FC } from "react"
import DefinitionList from "src/components/DefinitionList"
import { formatAttachmentPoint } from "src/utils/formatAttachmentPoint"
import { formatCurrency } from "src/utils/prettyPrinters"
import {
  ContractSectionItem,
  MAJOR_CLASS_CODE_DESCRIPTION_MAPPING,
  MajorClassCode,
} from "@appia/api"
import {
  ControlledDisclosure,
  InformationCircleIcon,
} from "@appia/ui-components"
import { orderedSyndicateNames } from "src/utils/orderedSyndicateNames"

const LayerTitle: FC<{
  layer: ContractSectionItem
  showError: boolean
}> = ({ layer, showError }) => {
  const showRiskCodes = layer.lines
    .map(line => line.syndicateData.syndicateData.majorClass)
    .some(
      majorClass =>
        majorClass === MajorClassCode.FI || majorClass === MajorClassCode.FI_US,
    )

  return (
    <div className="flex w-full items-center justify-between">
      <div>
        <span className="pr-2 font-bold">{formatAttachmentPoint(layer)}</span>
        {showRiskCodes && (
          <span className="border-l-2 border-otto-grey-400 pl-2 text-sm">
            <span className="text-otto-grey-600">Risk Code: </span>
            <span className="font-bold">{layer.riskCodes.join(", ")}</span>
          </span>
        )}
      </div>
      {showError ? (
        <InformationCircleIcon className="w-6 text-otto-red-500" />
      ) : null}
    </div>
  )
}

const LayerDisclosure: FC<{
  layer: ContractSectionItem
  open: boolean
  onClick: () => void
  showError: boolean
}> = ({ layer, open, onClick, showError }) => {
  return (
    <ControlledDisclosure
      key={layer.sectionId}
      title={<LayerTitle layer={layer} showError={showError} />}
      open={open}
      onClick={onClick}
      showError={showError}
    >
      <DefinitionList
        className="grid grid-cols-2 !gap-x-2 !gap-y-4 bg-[#F5F5F5] py-4 px-6"
        items={[
          {
            label: "Limit",
            value:
              layer.limitAmount === 0
                ? "---"
                : formatCurrency(
                    layer.limitAmount,
                    layer.lines[0].writtenPremiumCurrency
                      ? layer.lines[0].writtenPremiumCurrency
                      : "---",
                  ),
          },
          {
            label: "Excess",
            value:
              layer.excess === 0
                ? "---"
                : formatCurrency(
                    layer.excess,
                    layer.lines[0].writtenPremiumCurrency
                      ? layer.lines[0].writtenPremiumCurrency
                      : "---",
                  ),
          },
          {
            label: "Deductible",
            value:
              layer.deductible !== null
                ? formatCurrency(
                    layer.deductible,
                    layer.lines[0].writtenPremiumCurrency
                      ? layer.lines[0].writtenPremiumCurrency
                      : "---",
                  )
                : "---",
          },
          {
            label: "Written premium",
            value:
              layer.deductible !== null
                ? formatCurrency(
                    layer.lines[0].writtenPremium
                      ? layer.lines[0].writtenPremium
                      : 0,
                    layer.lines[0].writtenPremiumCurrency
                      ? layer.lines[0].writtenPremiumCurrency
                      : "---",
                  )
                : "---",
          },
          {
            label: "Major class",
            value:
              MAJOR_CLASS_CODE_DESCRIPTION_MAPPING[
                layer.lines[0].syndicateData.syndicateData.majorClass
              ],
          },
          {
            label: "Risk codes",
            value: layer.riskCodes.join(", "),
          },
          {
            label: "Syndicates",
            value: orderedSyndicateNames(layer),
          },
        ]}
      />
    </ControlledDisclosure>
  )
}

export default LayerDisclosure
