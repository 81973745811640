import { FC, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  Input,
  InputLabel,
  Logo,
  LogoBackgroundPattern,
} from "@appia/ui-components"
import ErrorMessage, { printErrorDetails } from "src/components/ErrorMessage"

import * as Sentry from "@sentry/react"
import { logButtonClick } from "src/amplitude"
import * as RD from "@appia/remote-data"

import useApiClient from "src/contexts/ApiClientContext"
import { isApiError, login } from "@appia/api"
import { useGetCurrentUser } from "src/swr"

import laptopPng from "./laptop.png"
import laptopWebp from "./laptop.webp"

type LoginFailure =
  | { type: "401"; message: string }
  | { type: "unknown"; message: string }

const LoginScreen: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const apiClient = useApiClient()

  const { update: updateCurrentUser } = useGetCurrentUser()

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const invalidData: boolean = email.length === 0 || password.length === 0

  const [formError, setFormError] = useState<string | null>(null)
  const [loginRequest, setLoginRequest] = useState<
    RD.RemoteData<LoginFailure, null>
  >(RD.NotAsked)

  return (
    <main className="grid min-h-screen grid-cols-1 items-center bg-white lg:grid-cols-2">
      <section className="p-4">
        <div className="mx-auto mb-6 w-max lg:hidden">
          <Logo />
        </div>

        <div className="mb-4 text-center">
          <h1 className="text-2xl font-bold">Welcome back!</h1>
          <p className="text-otto-grey-700">Please enter your details</p>
        </div>

        <form
          aria-label="Login details"
          className="mx-auto grid max-w-sm gap-4"
          onSubmit={async e => {
            e.preventDefault()

            if (RD.isLoading(loginRequest)) {
              return
            }

            logButtonClick({
              buttonName: "Log in",
              containerName: "Main",
              pageName: "Login",
            })

            if (invalidData) {
              setFormError("Please enter a valid email and password")
              return
            }

            setFormError(null)
            setLoginRequest(RD.Loading)

            try {
              await login(apiClient, email, password)
              await updateCurrentUser()
              setLoginRequest(RD.Success(null))

              if (typeof location.state === "string") {
                navigate(location.state)
              } else {
                navigate("/")
              }
            } catch (e) {
              if (isApiError(e)) {
                // If the login request fails because the user needs to do
                // something in the Cognito hosted UI, such as reset their
                // password, the API will return a 300 with a `location` header
                // telling us where to go.
                //
                // We'll be able to ditch this in favour of a regular redirect
                // that the browser can follow automatically, like a 303 or 307,
                // once all the pages are part of our app and we don't use the
                // Cognito hosted UI any more.
                if (e.response?.status === 300) {
                  window.location.href = e.response.headers.location
                }
                // A 401 indicates the user's credentials were wrong somehow
                else if (e.response?.status === 401) {
                  setLoginRequest(
                    RD.Failure({ type: "401", message: printErrorDetails(e) }),
                  )
                } else {
                  setLoginRequest(
                    RD.Failure({
                      type: "unknown",
                      message: printErrorDetails(e),
                    }),
                  )
                  Sentry.captureException(e)
                }
              }
              // Something other than the API call went wrong
              else if (e instanceof Error) {
                setLoginRequest(
                  RD.Failure({ type: "unknown", message: e.message }),
                )
                Sentry.captureException(e)
              }
            }
          }}
        >
          {RD.isFailure(loginRequest) && (
            <ErrorMessage message={loginRequest.error.message} />
          )}

          {formError && <ErrorMessage message={formError} />}

          <InputLabel label="Email">
            <Input
              type="email"
              inputMode="email"
              autoComplete="email"
              required
              hasError={
                RD.isFailure(loginRequest) && loginRequest.error.type === "401"
              }
              onChange={val => setEmail(val)}
              value={email}
            />
          </InputLabel>

          <InputLabel label="Password">
            <Input
              type="password"
              autoComplete="current-password"
              required
              hasError={
                RD.isFailure(loginRequest) && loginRequest.error.type === "401"
              }
              onChange={val => setPassword(val)}
              value={password}
            />
          </InputLabel>

          <Button
            className="mt-2"
            label="Sign in"
            theme="pop"
            style="filled"
            stretch="space-around"
            disabled={invalidData}
            isLoading={RD.isLoading(loginRequest)}
          />

          <div className="text-center">
            <a
              href={`${window.HOST}/auth/forgot_password`}
              className="otto-focus rounded-sm underline"
            >
              Forgot your password?
            </a>
          </div>
        </form>
      </section>

      <section className="relative hidden self-stretch bg-otto-night text-otto-pop lg:block">
        <div className="relative z-10 p-8">
          <Logo />
        </div>

        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute z-0 w-[max(500px,80vw)] translate-x-[-25%] translate-y-[-10%] rotate-[105deg]">
            <LogoBackgroundPattern />
          </div>
        </div>

        <picture>
          <source srcSet={laptopWebp} type="image/webp" />

          <img
            src={laptopPng}
            alt="A laptop computer displaying the Otto user interface on its screen. The Otto interface is open to the P.B.Q.A. page."
            className="absolute top-1/2 left-[-10%] z-10 w-full max-w-[90vh] translate-y-[-50%]"
          />
        </picture>
      </section>
    </main>
  )
}

export default LoginScreen
