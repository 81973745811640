import { FC } from "react"
import { ValidatedHeaderData } from "./validateHeaderData"

export interface HeaderFields {
  key: keyof ValidatedHeaderData
  label: string
  properties: string
}

const SuccessModalHeader: FC<{
  headerData: ValidatedHeaderData
}> = ({ headerData }) => {
  const headerFields: HeaderFields[] = [
    { key: "assuredName", label: "Assured name", properties: "col-span-3" },
    {
      key: "inceptionDate",
      label: "Inception date",
      properties: "col-span-2",
    },
    { key: "riskCodes", label: "Risk codes", properties: "col-span-2" },
  ]

  return (
    <div className="grid grid-cols-8 gap-0 rounded-md bg-otto-grey-100 pl-4 pt-3 pb-4 pr-4">
      {headerFields.map(({ key, label, properties }) => (
        <div key={key} className={properties}>
          <div className="basis-[20%] text-otto-grey-700">{label}</div>
          <div>{headerData[key]}</div>
        </div>
      ))}
    </div>
  )
}

export default SuccessModalHeader
