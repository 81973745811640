import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from "react"

import {
  Broker,
  ContractInsuredName,
  ContractStatus,
  ContractsPipelineSearchResult,
  Umr,
  User,
  archiveContract,
  assignContract,
  searchBrokers,
  searchPBQAAssuredNames,
  searchUmr,
  unassignContract,
} from "@appia/api"
import { useGetUsers, useSearchContracts } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import {
  ModalOverlay,
  SelectOption,
  SpinnerIcon,
  Toast,
} from "@appia/ui-components"
import StatusPill, { prettyPrintStatus } from "src/components/StatusPill"

import BaseTable, {
  TableControls as BaseTableControls,
  TableSettings as BaseTableSettings,
  Column,
  configureFilter,
} from "src/components/DashboardTable"
import OwnerSelection from "src/components/OwnerSelection"
import EmptyData from "src/components/EmptyData"

import {
  prettyPrintBroker,
  prettyPrintDateString,
  prettyPrintSnakeCase,
} from "src/utils/prettyPrinters"
import { compareByName, fullNameOrEmail } from "src/utils/users"
import ContractActionCTA from "./ContractActionCTA"
import ContractActionMenu from "./ContractActionMenu"
import useApiClient from "../../contexts/ApiClientContext"

export const localStorageFiltersKey = "@otto/contract-table-filters"
export const localStorageSettingsKey = "@otto/contract-table-settings"

/*
 * ====================
 * Table config
 */
type ColumnKeys =
  | "insured"
  | "broking_house"
  | "inception_date"
  | "assigned_to"
  | "aggregate_classes_of_business"
  | "aggregate_total_premium"
  | "umr"
  | "status"
  | "version_status"
  | "action"

// Configure the columns to render
const tableColumns: Column<ColumnKeys>[] = [
  {
    columnKey: "insured",
    label: "Insured",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "broking_house",
    label: "Broking House",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "umr",
    label: "UMR",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "assigned_to",
    label: "Assigned to",
    sortable: true,
  },
  {
    columnKey: "inception_date",
    label: "Inception date",
    sortable: true,
  },
  {
    columnKey: "aggregate_total_premium",
    label: "Total premium",
    sortable: true,
  },
  {
    columnKey: "aggregate_classes_of_business",
    label: "Class of business",
    sortable: true,
  },
  {
    columnKey: "version_status",
    label: "Status",
  },
  {
    columnKey: "action",
    label: "Action",
    visuallyHideLabel: true,
  },
]

// Configure the table's pagination, sorting, etc
export type TableSettings = BaseTableSettings<ColumnKeys>

export const defaultTableSettings: TableSettings = {
  pageNumber: 1,
  pageSize: 20,
  sortDirection: "desc",
  sortKey: "inception_date",
}

// The possible filters that may be active for the table
export interface TableFilters {
  insured: ContractInsuredName[]
  brokingHouse: Broker[]
  umr: Umr[]
  assignedTo: string[]
  versionStatus: ContractStatus[]
}

export const defaultTableFilters: TableFilters = {
  insured: [],
  brokingHouse: [],
  umr: [],
  assignedTo: [],
  versionStatus: [],
}

export const ContractStatuses: ContractStatus[] = [
  "created",
  "completed",
  "in_progress",
]

/*
 * ====================
 * Table rendering
 */

// Render an individual table cell for the given column key and Contract
const RenderCell: FC<{
  colKey: ColumnKeys
  contract: ContractsPipelineSearchResult
  setLoadingOverlay: (text: string | null) => void
  handleOpenModal: (id: string) => void
  onArchive: (id: string) => void
  updateContracts: () => void
  onAssign: (
    userId: User["id"],
    userName: string,
    contractId: string,
    versionId: number,
  ) => void
  onUnassign: (contractId: string, versionId: number) => void
}> = ({
  onUnassign,
  onAssign,
  onArchive,
  handleOpenModal,
  colKey,
  contract,
}): ReactElement => {
  switch (colKey) {
    case "insured":
      return contract.insured ? (
        <span title={contract.insured}>{contract.insured}</span>
      ) : (
        <EmptyData />
      )
    case "broking_house":
      return contract.brokingHouse ? (
        <span title={contract.brokingHouse}>{contract.brokingHouse}</span>
      ) : (
        <EmptyData />
      )
    case "umr":
      return contract.uniqueMarketReference ? (
        <span title={contract.uniqueMarketReference}>
          {contract.uniqueMarketReference}
        </span>
      ) : (
        <EmptyData />
      )
    case "assigned_to": {
      const buttonLabel = contract.insured
        ? `Change contract assigned to ${contract.insured}`
        : `Change contract assigned to`
      return (
        <OwnerSelection
          buttonLabel={buttonLabel}
          ownerId={contract.assignedTo}
          onAssign={async (userId, userName) => {
            const contractId = contract.id
            const versionId = contract.latestVersionId
            onAssign(userId, userName, contractId, versionId)
          }}
          onUnassign={async () => {
            const contractId = contract.id
            const versionId = contract.latestVersionId
            onUnassign(contractId, versionId)
          }}
        />
      )
    }
    case "inception_date":
      return contract.inceptionDate ? (
        <span>{prettyPrintDateString(contract.inceptionDate)}</span>
      ) : (
        <EmptyData />
      )
    case "version_status":
      return <StatusPill status={contract.versionStatus} />
    case "aggregate_classes_of_business":
      return (
        <span title={contract.aggregateClassesOfBusiness.join(", ")}>
          {contract.aggregateClassesOfBusiness
            .map(prettyPrintSnakeCase)
            .join(", ")}
        </span>
      )
    case "aggregate_total_premium":
      return (
        <span title={contract.aggregateTotalPremium.toString()}>
          {contract.aggregateTotalPremium}
        </span>
      )
    case "action": {
      return (
        <div className="flex items-center justify-between gap-1">
          <div className="w-full">
            <ContractActionCTA contract={contract} tableLabel={TABLE_LABEL} />
          </div>
          <ContractActionMenu
            contract={contract}
            onRedo={handleOpenModal}
            onArchive={onArchive}
          />
        </div>
      )
    }
    default:
      return <></>
  }
}

/*
 * ====================
 * Constants
 */
const TABLE_LABEL = "Contract processing table"
const TABLE_ID = "contract-table-active"

export const Table = forwardRef<
  HTMLTableElement,
  {
    tableFilters: TableFilters
    tableSettings: TableSettings
    setTableSettings: Dispatch<SetStateAction<TableSettings>>
    triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
    handleOpenModal: (id: string) => void
  }
>(
  (
    {
      handleOpenModal,
      tableSettings,
      setTableSettings,
      triggerToast,
      tableFilters,
    },
    ref,
  ) => {
    const apiClient = useApiClient()
    const { request: contractsRequest, update: updateContracts } =
      useSearchContracts({
        insured: tableFilters.insured.map(({ name }) => name),
        brokingHouse: tableFilters.brokingHouse.map(({ name }) => name),
        uniqueMarketReference: tableFilters.umr.map(({ value }) => value),
        versionStatus: tableFilters.versionStatus,
        assignedTo: tableFilters.assignedTo.includes("unassigned")
          ? tableFilters.assignedTo.filter(item => item !== "unassigned")
          : tableFilters.assignedTo,
        archived: false,
        pageSize: tableSettings.pageSize,
        pageNumber: tableSettings.pageNumber,
        unassigned: tableFilters.assignedTo.includes("unassigned")
          ? [true]
          : tableFilters.assignedTo.length > 0
          ? [false]
          : undefined,
      })

    const handleArchive = async (id: string): Promise<void> => {
      try {
        await archiveContract(apiClient, id)
        triggerToast("success", <>Successfully archived contract</>)
        updateContracts()
      } catch (error) {
        Sentry.captureException(error)
        triggerToast("error", <>Failed to archive contract</>)
      }
    }

    const handleAssign = async (
      userId: User["id"],
      userName: string,
      contractId: string,
      versionId: number,
    ): Promise<void> => {
      try {
        await assignContract(apiClient, contractId, versionId, userId)
        triggerToast(
          "success",
          <>Successfully assigned contract to {userName}</>,
        )
        updateContracts()
      } catch (error) {
        Sentry.captureException(error)
        triggerToast("error", <>Failed to assign contract owner</>)
      }
    }

    const handleUnassign = async (
      contractId: string,
      versionId: number,
    ): Promise<void> => {
      try {
        await unassignContract(apiClient, contractId, versionId)
        triggerToast("success", <>Successfully removed contract owner</>)
        updateContracts()
      } catch (error) {
        Sentry.captureException(error)
        triggerToast("error", <>Failed to remove contract owner</>)
      }
    }

    const [loadingOverlay, setLoadingOverlay] = useState<string | null>(null)

    return (
      <>
        <BaseTable
          id={TABLE_ID}
          label={TABLE_LABEL}
          dataRequest={RD.map(
            ({ items, hits }) => ({ rows: items, totalRows: hits }),
            contractsRequest,
          )}
          settings={tableSettings}
          onSettingsChange={setTableSettings}
          columns={tableColumns}
          renderCell={(colKey, contract) => (
            <RenderCell
              handleOpenModal={handleOpenModal}
              colKey={colKey}
              contract={contract}
              setLoadingOverlay={setLoadingOverlay}
              onArchive={handleArchive}
              updateContracts={updateContracts}
              onAssign={handleAssign}
              onUnassign={handleUnassign}
            />
          )}
          tableRef={ref}
        />

        {loadingOverlay && (
          <ModalOverlay
            bgColor="bg-white"
            isOpen
            onClose={() => {
              setLoadingOverlay(null)
            }}
          >
            <div className="isolate inline-block text-center">
              <p className="mb-4 text-5xl font-bold">{loadingOverlay}</p>
              <SpinnerIcon className="mx-auto w-12 text-otto-pop" />
            </div>
          </ModalOverlay>
        )}
      </>
    )
  },
)

export const TableControls: FC<{
  handleOpenModal: (id: string) => void
  tableFilters: TableFilters
  setTableFilters: Dispatch<SetStateAction<TableFilters>>
  setTableSettings: Dispatch<SetStateAction<TableSettings>>
}> = ({ tableFilters, setTableFilters, setTableSettings }) => {
  const { request: usersRequest } = useGetUsers()

  const onFilterChange = <K extends keyof TableFilters>(
    key: K,
    val: TableFilters[K],
  ): void => {
    setTableSettings(ts => ({ ...ts, pageNumber: 1 }))
    setTableFilters(tf => ({ ...tf, [key]: val }))
  }

  const ownerOptions = useMemo<SelectOption[]>(() => {
    const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

    const unassignedOpt = { value: "unassigned", label: "Unassigned" }

    const usersOpts = users
      .sort(compareByName)
      .map(u => ({ value: u.id, label: fullNameOrEmail(u) }))

    return [unassignedOpt, ...usersOpts]
  }, [usersRequest])

  return (
    <BaseTableControls
      filters={[
        configureFilter<ContractInsuredName>({
          type: "async",
          uniqueKey: "insured",
          label: "Insured",
          values: tableFilters.insured,
          onChange: vals => onFilterChange("insured", vals),
          loader: searchPBQAAssuredNames,
          mapResultToLabel: ({ name }) => name,
          mapResultToValue: ({ name }) => name,
        }),

        configureFilter<Broker>({
          type: "async",
          uniqueKey: "brokingHouse",
          label: "Broking House",
          values: tableFilters.brokingHouse,
          onChange: vals => onFilterChange("brokingHouse", vals),
          loader: searchBrokers,
          mapResultToLabel: prettyPrintBroker,
          mapResultToValue: ({ id }) => id,
        }),

        configureFilter<Umr>({
          type: "async",
          uniqueKey: "umr",
          label: "UMR",
          values: tableFilters.umr,
          onChange: vals => onFilterChange("umr", vals),
          loader: searchUmr,
          mapResultToLabel: ({ value }) => value,
          mapResultToValue: ({ value }) => value,
        }),

        configureFilter<string>({
          type: "sync",
          uniqueKey: "assignedTo",
          label: "Assigned to",
          values: tableFilters.assignedTo,
          onChange: vals => onFilterChange("assignedTo", vals),
          mapResultToLabel: v =>
            ownerOptions.find(opt => opt.value === v)?.label ?? "",
          options: ownerOptions,
        }),

        configureFilter<ContractStatus>({
          type: "sync",
          uniqueKey: "versionStatus",
          label: "Status",
          values: tableFilters.versionStatus,
          onChange: vals => onFilterChange("versionStatus", vals),
          mapResultToLabel: prettyPrintStatus,
          options: ContractStatuses.map(status => ({
            value: status,
            label: prettyPrintStatus(status),
          })),
        }),
      ]}
      onClear={() => {
        setTableSettings(defaultTableSettings)
        setTableFilters(defaultTableFilters)
      }}
      tableId={TABLE_ID}
      tableLabel={TABLE_LABEL}
    />
  )
}
