import axios, { AxiosInstance, AxiosPromise } from "axios"

import { Document, DocumentType } from "../Documents"
import { PBQAInfo } from "../PBQA"

export interface PolicyCreationData {
  umr: string
  insuredName: string | null
  policyReference: string | null
  inceptionDate: string | null
  documentId: Document["id"]
}

export const createPolicy = (
  client: AxiosInstance,
  data: PolicyCreationData,
): AxiosPromise<PBQAInfo> => client.post(`/api/pbqa/stub/create`, data)

export interface PolicyCreationUploadFileLimits {
  allowedMimetype: string[]
  sizeLimitMb: number
}

export const getPolicyCreationUploadFileLimitsUrl = "/api/endorsements/limits"

export interface PolicyCreationUrlResponse {
  id: Document["id"]
  url: string
  fields: Record<string, string>
}

interface PolicyCreationUrlDetails {
  fileName: Document["name"]
  mimetype: Document["mimetype"]
  type: DocumentType
}

export const generatePolicyCreationUploadUrl = (
  client: AxiosInstance,
  uploadDetails: PolicyCreationUrlDetails,
): AxiosPromise<PolicyCreationUrlResponse> =>
  client.post(`/api/documents/upload`, uploadDetails, {
    // Don't camelCase the response or the subsequent S3 upload won't work
    transformResponse: axios.defaults.transformResponse,
  })

export const confirmUpload = (
  client: AxiosInstance,
  documentId: Document["id"],
): AxiosPromise => client.post(`/api/documents/${documentId}/confirm`)

export const failUpload = (
  client: AxiosInstance,
  documentId: Document["id"],
): AxiosPromise => client.post(`/api/documents/${documentId}/failed`)
