import { FC, useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import SectionNav from "./SectionNav"
import OverliningSection from "./OverliningSection"
import FormSection from "./FormSection"
import DocumentsSection from "./DocumentsSection"
import ConfirmModal from "./ConfirmModal"

import {
  ApiClient,
  Document,
  KiQuote,
  PBQAAnswer,
  PBQAInfo,
  PBQAQuestion,
  PBQASurvey,
  Syndicate,
  confirmPBQASurvey,
  recordKiQuote,
  savePBQASurvey,
  searchKiQuotes,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"
import { PBQASurveyContext } from "ReviewPBQA/PBQASurveyContext"

import {
  PBQAReviewState,
  initialiseState,
  setAnswer,
  storeKiQuoteAndAutoAcceptAnswers,
  updateAnswersForSyndicates,
} from "ReviewPBQA/state"
import { PBQASurveyWithDetails } from "ReviewPBQA/useGetSurveys"
import { surveyComplete, surveySectionToUrl } from "ReviewPBQA/surveyUtils"

import { logButtonClick, logPBQAConfirm, logPBQASave } from "src/amplitude"
import { getQuoteForAssuredName, orderQuotesByQuoteLineID } from "./helpers"
import { KiQuoteSearchFilters } from "../kiQuoteSearch"

type PutOrPostRequest = RD.RemoteData<Error, null>

const savePBQAAnswers = async (
  apiClient: ApiClient,
  pbqaId: string,
  state: PBQAReviewState,
  survey: PBQASurvey,
): Promise<void> => {
  const answerList: PBQAAnswer[] = Object.values(state)
    .flatMap(({ answer }) => answer)
    // Only include answers for this survey, not for any others
    .filter(a => survey.questions.find(q => q.id === a.questionId))
    // Don't include questions that haven't been answered or accepted
    .filter(a => !(a.answer === null && a.accepted === null))

  await savePBQASurvey(apiClient, pbqaId, survey.id, answerList)
}

const confirmPBQA = async (
  apiClient: ApiClient,
  saveAnswers: () => Promise<void>,
  setConfirmRequest: (r: PutOrPostRequest) => void,
  pbqaId: string,
  pageName: string,
  survey: PBQASurvey,
): Promise<void> => {
  logButtonClick({
    buttonName: "Complete PBQA",
    containerName: "Main",
    pageName,
  })

  logPBQAConfirm({ pbqaId, surveyId: survey.id, surveyName: survey.slug })

  setConfirmRequest(RD.Loading)

  try {
    await saveAnswers()
    await confirmPBQASurvey(apiClient, pbqaId, survey.id)
    setConfirmRequest(RD.Success(null))
  } catch (e) {
    if (e instanceof Error) {
      setConfirmRequest(RD.Failure(e))
      Sentry.captureException(e)
    }
  }
}

const Survey: FC<{
  activeDocumentId: Document["id"] | null
  activeSectionUrl: string | null
  activeSheetName: string | null
  documents: Document[]
  pbqaId: PBQAInfo["id"]
  quote: KiQuote | null
  selectedSyndicates: Syndicate[]
  setActiveDocumentId: (id: Document["id"]) => void
  setActiveSheetName: (sheetName: string) => void
  surveys: PBQASurveyWithDetails[]
}> = ({
  activeDocumentId,
  activeSectionUrl,
  activeSheetName,
  documents,
  pbqaId,
  quote,
  selectedSyndicates,
  setActiveDocumentId,
  setActiveSheetName,
  surveys,
}) => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const navigate = useNavigate()

  /**
   * Active survey
   */
  const [activeSurveyId, setActiveSurveyId] = useState<
    PBQASurveyWithDetails["id"]
  >(surveys.find(s => s.active)?.id ?? surveys[0].id)

  const activeSurvey = surveys.find(s => s.id === activeSurveyId)
  if (!activeSurvey) {
    throw new Error(`Could not find activeSurvey for id: ${activeSurveyId}`)
  }

  /**
   * Question/answer state
   */
  const [state, setState] = useState<PBQAReviewState>(() =>
    initialiseState(
      pbqaId,
      surveys,
      selectedSyndicates.map(s => s.id),
      quote,
    ),
  )

  // Add/remove answers as needed when the list of selected syndicates changes
  useEffect(() => {
    setState(oldState =>
      updateAnswersForSyndicates(
        pbqaId,
        selectedSyndicates.map(s => s.id),
        oldState,
      ),
    )
  }, [pbqaId, selectedSyndicates])

  const onChangeAnswer = (
    questionId: PBQAQuestion["id"],
    answer: PBQAAnswer,
  ): void => {
    setState(s => ({
      ...s,
      [questionId]: setAnswer(s[questionId], answer),
    }))
    setSaveDraftRequest(RD.NotAsked)
  }

  /**
   * Currently selected question
   */
  const [activeQuestionId, setActiveQuestionId] = useState<
    PBQAQuestion["id"] | null
  >(null)

  const onJumpToBoundingBox = (questionId: PBQAQuestion["id"]): void => {
    setActiveQuestionId(questionId)

    const answer = state[questionId].answer

    // If there are multiple answers for this question, they should all have
    // identical bounding boxes, so we can pick the first one
    const documentId = Array.isArray(answer)
      ? answer[0].boundingBox?.documentId
      : answer.boundingBox?.documentId

    if (documentId !== undefined) {
      setActiveDocumentId(documentId)
    }
  }

  /**
   * Currently selected Ki quote
   */
  const [selectedKiQuote, setSelectedKiQuote] = useState<KiQuote | null>(quote)

  /**
   * When a new quote is loaded and selected...
   */
  const onChangeQuote =
    (resetNonComparisonQuestions: boolean) =>
    async (quote: KiQuote): Promise<void> => {
      // If the quote hasn't changed, do nothing
      if (quote.quoteLineId === selectedKiQuote?.quoteLineId) {
        return
      }

      let newActiveSurvey = activeSurvey

      // If the currently active survey doesn't support the journey type for the
      // quote the user selected, switch to one that does
      const { journeyType } = quote
      if (!activeSurvey.kiJourneyTypes.includes(journeyType)) {
        const firstSurveyOfType = surveys.find(s =>
          s.kiJourneyTypes.includes(journeyType),
        )

        if (firstSurveyOfType !== undefined) {
          newActiveSurvey = firstSurveyOfType
        }
      }

      // Apply the auto-answer logic
      setState(s =>
        storeKiQuoteAndAutoAcceptAnswers(
          s,
          quote,
          newActiveSurvey,
          resetNonComparisonQuestions,
        ),
      )
      setSelectedKiQuote(quote)
      setActiveSurveyId(newActiveSurvey.id)
    }

  const onManuallyChangeQuote = onChangeQuote(true)
  const onAutoChangeQuote = onChangeQuote(false)

  /**
   * When a new survey is selected, switch to it and re-apply the auto-answer
   * logic
   */
  const onChangeSurvey = (surveyId: PBQASurveyWithDetails["id"]): void => {
    setActiveSurveyId(surveyId)

    if (selectedKiQuote === null) {
      return
    }

    const newSurvey = surveys.find(s => s.id === surveyId)

    if (newSurvey === undefined) {
      return
    }

    setState(s =>
      storeKiQuoteAndAutoAcceptAnswers(s, selectedKiQuote, newSurvey, true),
    )
  }

  /**
   * Form state and API requests
   */
  const [showFieldErrors, setShowFieldErrors] = useState<boolean>(false)

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const [saveDraftRequest, setSaveDraftRequest] = useState<PutOrPostRequest>(
    RD.NotAsked,
  )

  const [confirmRequest, setConfirmRequest] = useState<PutOrPostRequest>(
    RD.NotAsked,
  )

  const onSaveDraft = async (): Promise<void> => {
    logPBQASave({ pbqaId, surveyId: activeSurvey.id })

    setSaveDraftRequest(RD.Loading)

    // First save the selected quote if there is one
    if (selectedKiQuote) {
      try {
        await recordKiQuote(apiClient, pbqaId, selectedKiQuote.quoteLineId)
      } catch (e) {
        if (e instanceof Error) {
          setSaveDraftRequest(RD.Failure(e))
          Sentry.captureException(e)
        }
      }
    }

    // Then save the survey data
    try {
      await savePBQAAnswers(apiClient, pbqaId, state, activeSurvey)
      setSaveDraftRequest(RD.Success(null))
    } catch (e) {
      if (e instanceof Error) {
        setSaveDraftRequest(RD.Failure(e))
        Sentry.captureException(e)
      }
    }
  }

  const [loadQuoteReq, setLoadQuoteReq] = useState<
    RD.RemoteData<Error, KiQuote>
  >(selectedKiQuote ? RD.Success(selectedKiQuote) : RD.NotAsked)

  // If there is an existing quote we need to populate the search results with
  // it, or the search bar input won't display the quote properly.
  const [searchResults, setSearchResults] = useState<KiQuote[]>(
    quote ? [quote] : [],
  )

  const searchAndSetResults = async (query: string): Promise<void> => {
    try {
      const { data: results } = await searchKiQuotes(apiClient, pbqaId, query)
      const orderedResults = orderQuotesByQuoteLineID(results)
      setSearchResults(orderedResults)
    } catch (e) {
      if (e instanceof Error) {
        Sentry.captureException(e)
      }
    }
  }

  const [searchText, setSearchText] = useState<string>("")
  const [searchFilters, setSearchFilters] = useState<
    Partial<KiQuoteSearchFilters>
  >({})

  /**
   * If there wasn't an existing quote associated with this PBQA, try to
   * automatically load one that matches the extracted assured name
   */
  useEffect(() => {
    const go = async (): Promise<void> => {
      if (!RD.isNotAsked(loadQuoteReq)) {
        return
      }

      setLoadQuoteReq(RD.Loading)

      try {
        const quote = await getQuoteForAssuredName(
          apiClient,
          pbqaId,
          activeSurvey,
        )

        if (quote) {
          setLoadQuoteReq(RD.Success(quote))

          // As above, we need to populate the search results in order for the
          // quote to display properly
          setSearchResults([quote])

          onAutoChangeQuote(quote)

          return
        } else {
          setLoadQuoteReq(
            RD.Failure(new Error("No quote found for assured name")),
          )
        }
      } catch (e) {
        if (e instanceof Error) {
          setLoadQuoteReq(RD.Failure(e))
          Sentry.captureException(e)
        }
      }
    }
    go()
  }, [activeSurvey, apiClient, loadQuoteReq, onAutoChangeQuote, pbqaId, state])

  const quoteSearchBarTestId: string = RD.isSuccess(loadQuoteReq)
    ? "quote-search-loaded"
    : RD.isFailure(loadQuoteReq)
    ? "quote-search-failed"
    : ""

  /**
   * When the form is submitted for any section other than the final one, just
   * save as a draft and proceed to the next section
   */
  const onSubmitPrecedingSection = async (): Promise<void> => {
    if (!RD.isSuccess(saveDraftRequest)) {
      await onSaveDraft()
    }

    const nextSectionUrl = surveySectionToUrl(
      activeSurvey.groupingHeaders[activeSectionIdx + 1],
    )

    navigate(`/pbqa/review/${pbqaId}/${nextSectionUrl}`)
  }

  /**
   * When the final form is submitted, require the validation to succeed before
   * confirming the PBQA
   */
  const onSubmitFinalSection = async (): Promise<void> => {
    if (
      !surveyComplete(
        activeSurvey,
        selectedSyndicates.map(s => s.id),
        state,
      ) ||
      selectedKiQuote === null
    ) {
      setShowFieldErrors(true)
      return
    }

    setShowFieldErrors(false)

    await confirmPBQA(
      apiClient,
      async () => {
        if (selectedKiQuote) {
          await recordKiQuote(apiClient, pbqaId, selectedKiQuote.quoteLineId)
        }

        await savePBQAAnswers(apiClient, pbqaId, state, activeSurvey)
      },
      setConfirmRequest,
      pbqaId,
      pageName,
      activeSurvey,
    )

    setModalOpen(true)
  }

  /**
   * Currently selected survey section
   */
  const activeSectionIdx = activeSurvey.groupingHeaders.findIndex(
    section => surveySectionToUrl(section) === activeSectionUrl,
  )

  if (activeSectionIdx < 0) {
    const firstSectionUrl = surveySectionToUrl(activeSurvey.groupingHeaders[0])
    return <Navigate to={`/pbqa/review/${pbqaId}/${firstSectionUrl}`} />
  }

  // TODO Add the OverliningSection in OE-132
  const isOverliningSection = false

  return (
    <PBQASurveyContext.Provider
      value={{ pbqaId, activeSurvey, activeSectionIdx, activeQuestionId }}
    >
      <div className="flex h-full flex-col">
        <SectionNav
          pageTitle={pageName}
          showFieldErrors={showFieldErrors}
          state={state}
          survey={activeSurvey}
        />

        <div className="mx-auto h-full w-full max-w-screen px-4 py-4 sm:px-6 lg:px-8">
          {isOverliningSection ? (
            <OverliningSection />
          ) : (
            <div className="grid h-full grid-cols-2 items-stretch gap-4 xl:grid-cols-[2fr,3fr]">
              <div className="relative">
                <FormSection
                  confirmRequest={confirmRequest}
                  onChangeAnswer={onChangeAnswer}
                  onChangeSurvey={onChangeSurvey}
                  onJumpToBoundingBox={onJumpToBoundingBox}
                  onManuallyChangeQuote={onManuallyChangeQuote}
                  onSaveDraft={onSaveDraft}
                  onSubmitFinalSection={onSubmitFinalSection}
                  onSubmitPrecedingSection={onSubmitPrecedingSection}
                  quoteSearchBarTestId={quoteSearchBarTestId}
                  saveDraftRequest={saveDraftRequest}
                  searchAndSetResults={searchAndSetResults}
                  searchFilters={searchFilters}
                  searchResults={searchResults}
                  searchText={searchText}
                  selectedKiQuote={selectedKiQuote}
                  selectedSyndicates={selectedSyndicates}
                  setSearchFilters={setSearchFilters}
                  setSearchText={setSearchText}
                  showFieldErrors={showFieldErrors}
                  state={state}
                  surveys={surveys}
                />
              </div>

              <div className="relative">
                <DocumentsSection
                  activeDocumentId={activeDocumentId}
                  activeSheetName={activeSheetName}
                  documents={documents}
                  setActiveDocumentId={setActiveDocumentId}
                  setActiveQuestionId={setActiveQuestionId}
                  setActiveSheetName={setActiveSheetName}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        request={confirmRequest}
        showCopyHeadersheetButton
      />
    </PBQASurveyContext.Provider>
  )
}

export default Survey
