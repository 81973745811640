import { FC, ReactNode } from "react"

import { Policy } from "@appia/api"

import { SrEndOfSentence } from "src/components/A11y"
import EmptyData from "src/components/EmptyData"
import { prettyPrintDateString } from "src/utils/prettyPrinters"

const ASSURED_NAME_LABEL = "Assured name"
const UMR_LABEL = "UMR"
const POLICY_REF_LABEL = "Eclipse reference"
const INCEPTION_DATE_LABEL = "Inception date"

export const ResultsHeader: FC = () => (
  <div className="sticky top-0 z-10 bg-white">
    <p className="border-b border-b-otto-grey-300 px-3 py-1 text-otto-grey-700">
      Search results
    </p>

    <ol
      aria-label="Search result fields"
      className="list-style-none hidden w-full grid-cols-4 gap-4 border-b border-b-otto-grey-300 py-2 pl-3 pr-9 lg:grid"
    >
      {[
        UMR_LABEL,
        ASSURED_NAME_LABEL,
        POLICY_REF_LABEL,
        INCEPTION_DATE_LABEL,
      ].map((label, i) => (
        <li key={i} className="text-otto-grey-700">
          {label}
        </li>
      ))}
    </ol>
  </div>
)

const SearchResultField: FC<{
  className?: string
  label: ReactNode
  value: ReactNode
}> = ({ className, label, value }) => (
  <div className={className}>
    <dt className="pb-1 text-sm text-otto-grey-700 lg:hidden">
      {label}
      <span className="sr-only">:&nbsp;</span>
    </dt>
    <dd className="text-base">
      {value}
      <SrEndOfSentence />
    </dd>
  </div>
)

export const SearchResult: FC<{
  policy: Policy
}> = ({ policy }) => (
  <dl className="grid gap-2 sm:grid-cols-2 lg:grid-cols-4 lg:gap-4">
    <SearchResultField label={UMR_LABEL} value={policy.umr ?? <EmptyData />} />

    <SearchResultField
      label={ASSURED_NAME_LABEL}
      value={policy.assuredName ?? <EmptyData />}
    />

    <SearchResultField
      label={POLICY_REF_LABEL}
      value={policy.policyReference ?? <EmptyData />}
    />

    <SearchResultField
      label={INCEPTION_DATE_LABEL}
      value={
        policy.inceptionDate ? (
          prettyPrintDateString(policy.inceptionDate)
        ) : (
          <EmptyData />
        )
      }
    />
  </dl>
)
