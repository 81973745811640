import { FILETYPE_MSG } from "./acceptedFileTypes"

export const ERROR_MESSAGES = {
  fileTypeUnrecognised: `Unrecognised document type. ${FILETYPE_MSG}`,
  uploadFailed: `We were unable to accept your upload. ${FILETYPE_MSG}`,
  missingUploadUrl: (umr: string, fileName: string) =>
    `Missing upload_url in the file pre-upload response. UMR: ${umr} - File name: ${fileName}.`,
  missingFileId: (umr: string, fileName: string) =>
    `Missing file_id in the file pre-upload response. UMR: ${umr} - File name: ${fileName}.`,
  undefinedFile: `File is undefined.`,
  missingFileIdMetadata: `File ID is missing in metadata.`,
  serverUploadError: (
    status: number,
    statusText: string,
    umr: string,
    fileName: string,
  ) =>
    `Unsuccessful upload for UMR - ${umr}, file name - ${fileName}. The server returned an error with status
    code: ${status} (${statusText}). Please try again or contact support if the problem persists.`,
  serverConfirmError: (
    status: number,
    statusText: string,
    umr: string,
    fileName: string,
  ) =>
    `Unsuccessful upload confirmation for UMR - ${umr}, file name - ${fileName}. The server returned an error with status
    code: ${status} (${statusText}). Please try again or contact support if the problem persists.`,
  genericError: `An unexpected error occurred while processing your file upload. Please try again later or contact 
  support if the problem persists.`,
}
