import { FC, ReactElement, Suspense } from "react"

import { Document } from "@appia/api"

import { Card, Tabs } from "@appia/ui-components"
import Loading from "src/components/Loading"

const DocumentTabs: FC<{
  documents: Document[]
  activeDocumentId: Document["id"] | null
  setActiveDocumentId: (id: Document["id"]) => void
  children: (d: Document) => ReactElement
}> = ({ documents, activeDocumentId, setActiveDocumentId, children }) => (
  <Tabs.Root
    asChild
    value={activeDocumentId || ""}
    onValueChange={setActiveDocumentId}
  >
    <section
      className="absolute flex h-full w-full flex-col"
      aria-label="Document preview"
    >
      <Card padding={0} className="mb-2 shadow">
        <Tabs.List
          className="divide-x divide-otto-grey-300 rounded-md"
          aria-label="Documents"
        >
          {documents.map(({ id, name }) => (
            <Tabs.Trigger
              key={id}
              value={id}
              className="p-2 text-sm font-medium"
            >
              {name}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Card>

      <Suspense fallback={<Loading className="mt-4" />}>
        {documents.map(document => (
          <Tabs.Content asChild value={document.id} key={document.id}>
            <div className="flex h-full flex-grow flex-col">
              {children(document)}
            </div>
          </Tabs.Content>
        ))}
      </Suspense>
    </section>
  </Tabs.Root>
)

export default DocumentTabs
