import Fuse from "fuse.js"

export function scoreExactMatchString(
  quoteString: string,
  pbqaStateString: string,
): number {
  return pbqaStateString.toLowerCase().trim() ===
    quoteString.toLowerCase().trim()
    ? 1.0
    : 0.0
}

export function scoreFuzzyMatchString(
  quoteString: string,
  pbqaStateString: string,
): number {
  quoteString = quoteString.trim()
  pbqaStateString = pbqaStateString.trim()
  if (quoteString === "" && pbqaStateString === "") {
    return 0.0
  }

  const [longerString, shorterString] =
    pbqaStateString.length > quoteString.length
      ? [pbqaStateString, quoteString]
      : [quoteString, pbqaStateString]

  const fuse = new Fuse([longerString], { includeScore: true })
  const result = fuse.search(shorterString)
  if (result.length === 0) return 0.0

  const similarityScore = 1 - (result[0].score || 0) // Convert score into a similarity
  return similarityScore
}

export function scoreRelativeDays(
  quoteDate: Date,
  pbqaStateDate: Date,
  maximumDaysConstant: number,
): number {
  // Calculate the difference in days between two dates
  const timeDifference = Math.abs(quoteDate.getTime() - pbqaStateDate.getTime())
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))

  return (
    1.0 - Math.min(daysDifference, maximumDaysConstant) / maximumDaysConstant
  )
}

export function scoreRelativeMatchStrings(
  quoteSet: Set<string>,
  pbqaStateSet: Set<string>,
): number {
  if (quoteSet.size === 0 || pbqaStateSet.size === 0) {
    return 0.0
  }

  const intersection = new Set([...quoteSet].filter(x => pbqaStateSet.has(x)))
  return intersection.size / Math.min(quoteSet.size, pbqaStateSet.size)
}

export function scoreRelativeNumericalValue(
  quoteNumericalValue: number,
  pbqaStateNumericalValue: number,
): number {
  if (quoteNumericalValue === 0 && pbqaStateNumericalValue === 0) {
    return 1.0
  }

  if (quoteNumericalValue === 0 || pbqaStateNumericalValue === 0) {
    return 0.0
  }

  const difference = Math.abs(quoteNumericalValue - pbqaStateNumericalValue)
  const maxValue = Math.max(quoteNumericalValue, pbqaStateNumericalValue)
  return 1.0 - difference / maxValue
}
