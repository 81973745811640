import { PolicyReferencesForSyndicate } from "@appia/api"

export const sortPolicyReferencesBySyndicateName = (
  policyReference: PolicyReferencesForSyndicate[],
  order: string[],
): PolicyReferencesForSyndicate[] => {
  const syndicateNameOrderMap = new Map(
    order.map((name, index) => [name, index]),
  )
  const maxIndex = order.length

  return [...policyReference].sort((a, b) => {
    const indexA = syndicateNameOrderMap.get(a.syndicate.name) ?? maxIndex
    const indexB = syndicateNameOrderMap.get(b.syndicate.name) ?? maxIndex

    return indexA - indexB
  })
}
