import { FC, ReactNode } from "react"

import { Card, Link } from "@appia/ui-components"
import ScreenTemplate from "src/templates/ScreenTemplate"

import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"

const PAGE_NAME = "Settings"

const Section: FC<{ heading: string; children: ReactNode }> = ({
  heading,
  children,
}) => (
  <section className="mb-4 border-b border-otto-grey-400 pb-4 last:mb-0 last:border-none last:pb-0">
    <h2 className="text-xl font-bold">{heading}</h2>
    <div>{children}</div>
  </section>
)

const SettingsScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  return (
    <ScreenTemplate layout={{ type: "regular" }} pageTitle="Settings">
      <Card>
        <Section heading="Webhooks">
          <Link
            theme="night"
            style="filled"
            href="/settings/webhooks"
            label="Manage webhooks"
            className="mt-4"
            onClick={() => {
              logButtonClick({
                buttonName: "Manage webhooks",
                linkHref: "/settings/webhooks",
                containerName: "Main",
                pageName: PAGE_NAME,
              })
            }}
          />
        </Section>
      </Card>
    </ScreenTemplate>
  )
}

export default SettingsScreen
