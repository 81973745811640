import { AxiosInstance, AxiosPromise } from "axios"

export const login = (
  client: AxiosInstance,
  email: string,
  password: string,
): AxiosPromise =>
  client.post("/auth/log_in", {
    email,
    password,
  })

export const logout = (client: AxiosInstance): AxiosPromise =>
  client.get("/auth/log_out")
