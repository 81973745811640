import { FC } from "react"

import { ContractsPipelineSearchResult } from "@appia/api"

import { ChevronRightIcon, IconLink } from "@appia/ui-components"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"
import { getContractLinkDetails } from "./GetContractLinkDetails"

const ContractActionCTA: FC<{
  contract: ContractsPipelineSearchResult
  tableLabel: string
}> = ({ contract, tableLabel }) => {
  const pageName = usePageName()

  const linkDetails = getContractLinkDetails(contract)
  if (!linkDetails) {
    return null
  }
  const { label, ariaLabel, href } = linkDetails

  return (
    <IconLink
      href={href}
      onClick={() => {
        logButtonClick({
          buttonName: label,
          linkHref: href,
          containerName: tableLabel,
          pageName: pageName,
        })
      }}
      icon={<ChevronRightIcon />}
      label={ariaLabel}
      size={10}
      theme="bordered"
    />
  )
}

export default ContractActionCTA
