import { Dispatch, SetStateAction, useEffect, useState } from "react"

/**
 * Like `useState`, but reads and writes the state from/to localStorage at the
 * given key
 */

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useLocalStorageState = <T extends unknown>(
  key: string,
  defaultState: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const storedState = localStorage.getItem(key)
    if (!storedState) {
      return defaultState
    }

    try {
      return JSON.parse(storedState)
    } catch (e) {
      return defaultState
    }
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}

export default useLocalStorageState
