import { FC, useState } from "react"
import { Navigate } from "react-router-dom"

import { Button, Card, CheckCircleIcon, Link } from "@appia/ui-components"
import Loading from "src/components/Loading"
import ScreenTemplate from "src/templates/ScreenTemplate"

import { Team, User, joinTeam } from "@appia/api"
import { useGetCurrentUser, useGetTeams, useGetUsers } from "src/swr"
import * as RD from "@appia/remote-data"
import { forenameOrEmail } from "src/utils/users"

import useApiClient from "src/contexts/ApiClientContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"

import { logButtonClick, useLogPageView } from "src/amplitude"
import * as Sentry from "@sentry/react"

const PAGE_NAME = "Teams"

const printUsers = (teamId: Team["id"], users: User[]): string => {
  const usersInTeam = users.filter(user => user.teamId === teamId)

  switch (usersInTeam.length) {
    case 0:
      return "Team is empty"
    case 1:
      return forenameOrEmail(usersInTeam[0])
    case 2:
      return `${forenameOrEmail(usersInTeam[0])} and ${forenameOrEmail(
        usersInTeam[1],
      )}`
    default: {
      const remaining = usersInTeam.length - 2
      return `${forenameOrEmail(usersInTeam[0])}, ${forenameOrEmail(
        usersInTeam[1],
      )} and ${remaining} ${remaining === 1 ? "other" : "others"}`
    }
  }
}

const TeamScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const apiClient = useApiClient()

  const { request: userRequest, update: updateCurrentUser } =
    useGetCurrentUser()

  const { request: teamsRequest } = useGetTeams()
  const { request: usersRequest, update: updateUsers } = useGetUsers()

  const [joinTeamRequest, setJoinTeamRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  if (!RD.isSuccess(userRequest)) {
    return <Navigate to="/" />
  }

  const user = userRequest.data.user

  const addUserToTeam = async (teamId: string): Promise<void> => {
    if (!apiClient) {
      return
    }

    setJoinTeamRequest(RD.Loading)

    try {
      await joinTeam(apiClient, teamId, user.id, user.companyId)
      setJoinTeamRequest(RD.Success(null))
      updateUsers()
      updateCurrentUser()
    } catch (e) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        setJoinTeamRequest(RD.Failure(e))
      }
    }
  }

  return (
    <ScreenTemplate pageTitle="Teams" layout={{ type: "regular" }}>
      <section
        className="mb-4 border-b border-otto-grey-500 pb-4"
        data-cy="teams-section"
      >
        {RD.isLoading(usersRequest) || RD.isLoading(teamsRequest) ? (
          <Loading />
        ) : RD.isSuccess(usersRequest) &&
          RD.isSuccess(teamsRequest) &&
          teamsRequest.data.teams.length > 0 ? (
          <ul className="grid grid-cols-1 items-stretch gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {teamsRequest.data.teams.map(team => (
              <li key={team.id} data-cy="team-card">
                <Card className="flex h-full flex-col">
                  <p className="mb-2 block w-full text-2xl">{team.name}</p>
                  <p className="mb-2">
                    {printUsers(team.id, usersRequest.data.users)}
                  </p>

                  {user.teamId === team.id ? (
                    <p className="mb-2 text-success" data-cy="team-member">
                      <span
                        className="mr-1 inline-block w-5 align-middle"
                        aria-hidden
                        role="img"
                      >
                        <CheckCircleIcon />
                      </span>
                      <span className="align-middle">
                        You&lsquo;re a member of this team
                      </span>
                    </p>
                  ) : RD.isFailure(joinTeamRequest) ? (
                    <p className="mb-2 text-otto-deep-red">
                      <span className="align-middle">
                        There was an error while trying to join the team
                      </span>
                    </p>
                  ) : null}

                  <div className="mt-auto">
                    {user.teamId ? (
                      <Link
                        href={`/teams/manage/${team.id}`}
                        label="Manage"
                        theme="pop"
                        style="filled"
                        onClick={() => {
                          logButtonClick({
                            buttonName: "Manage team",
                            linkHref: `/teams/manage/${team.id}`,
                            containerName: `Team card = ${team.id}`,
                            pageName: PAGE_NAME,
                          })
                        }}
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          addUserToTeam(team.id)

                          logButtonClick({
                            buttonName: "Join team",
                            containerName: `Team card = ${team.id}`,
                            pageName: PAGE_NAME,
                          })
                        }}
                        label="Join"
                        theme="pop"
                        style="filled"
                      />
                    )}
                  </div>
                </Card>
              </li>
            ))}
          </ul>
        ) : (
          <div className="p-4 text-center" data-cy="team-none">
            No teams found
          </div>
        )}
      </section>

      <section>
        <div className="mx-auto w-max">
          <Link
            data-cy="create-team"
            href="/teams/create"
            label="Create a new team"
            theme="night"
            style="filled"
            onClick={() => {
              logButtonClick({
                buttonName: "Create team",
                linkHref: "/teams/create",
                containerName: "Main",
                pageName: PAGE_NAME,
              })
            }}
          />
        </div>
      </section>
    </ScreenTemplate>
  )
}

export default TeamScreen
