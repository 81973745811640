import type { SVGComponent } from "./IconWrapper"

const CopyRightSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13H12V15H6V13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9H8V11H6V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5H12V7H6V5Z"
      fill="currentColor"
    />
    <path
      d="M14.51 6L13.09 7.41L14.67 8.99H9.5V10.99H14.67L13.09 12.58L14.51 13.99L18.5 9.99L14.51 6Z"
      fill="currentColor"
    />
    <path
      d="M9 20.5V22C9 22.5523 9.44772 23 10 23H20C20.5523 23 21 22.5523 21 22V7.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1C2.89543 1 2 1.89543 2 3V17C2 18.1046 2.89543 19 4 19H15C16.1046 19 17 18.1046 17 17V15H15V17H4V3H15V5H17V3C17 1.89543 16.1046 1 15 1H4Z"
      fill="currentColor"
    />
  </svg>
)

export default CopyRightSVG
