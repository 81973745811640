import { FC, useState } from "react"

import { KiClassOfBusiness } from "@appia/api"

import { KiQuoteSearchFilters } from "ReviewPBQA/kiQuoteSearch"
import { prettyPrintKiClassOfBusiness } from "../kiQuoteHelpers"

import {
  Button,
  Input,
  InputLabel,
  Select,
  SelectOption,
} from "@appia/ui-components"
import { ID } from "src/components/A11y"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

type PartialFilters = Partial<KiQuoteSearchFilters>

const classes: KiClassOfBusiness[] = [
  "ambridge_cyber_tech",
  "ambridge_excess_casualty",
  "ambridge_general_liability",
  "ambridge_liability_package_sir",
  "ambridge_professional_indemnity_us",
  "ambridge_property_package_sir",
  "ambridge_small_na_liability",
  "ambridge_terrorism",
  "ambridge_transactional",
  "british_cyber_attack_plus",
  "cargo",
  "casualty_treaty_lt_cat",
  "casualty_treaty_lt_risk",
  "casualty_treaty_st_cat",
  "casualty_treaty_st_risk",
  "commercial_na_facilities",
  "commercial_na_open_market",
  "commercial_worldwide",
  "contingency",
  "cyber_privacy_tech",
  "directors_officers_worldwide",
  "energy_construction",
  "energy_gom_wind",
  "energy_liability",
  "energy_midstream",
  "energy_operational",
  "energy_renewable",
  "financial_institutions",
  "flood",
  "healthcare_liability",
  "household_hv",
  "household_na",
  "hull",
  "marine_liability",
  "marine_war",
  "nuclear",
  "personal_accident",
  "professional_indemnity_us",
  "professional_liability_international",
  "property_financial",
  "property_liability_us_scgl",
  "property_uk",
  "scion_casualty",
  "small_na_liability",
  "specie",
  "terrorism",
  "transportation",
]

const isClassOfBusiness = (s: string): s is KiClassOfBusiness =>
  classes.some(c => c === s)

const classOptions: SelectOption[] = classes.map(c => ({
  label: prettyPrintKiClassOfBusiness(c),
  value: c,
}))

const Filters: FC<{
  filters: PartialFilters
  onSubmit: (f: PartialFilters) => void
  onReset: () => void
}> = ({ filters: initialFilters, onSubmit, onReset }) => {
  const pageName = usePageName()

  const [filters, setFilters] = useState<PartialFilters>(initialFilters)

  return (
    <form
      aria-label="Search filters"
      onSubmit={e => {
        // Don't submit the form, and don't allow the submission event to bubble
        // up to higher-level forms
        e.preventDefault()
        e.stopPropagation()

        logButtonClick({
          buttonName: "Submit",
          containerName: "Overlining modal filters",
          pageName,
        })

        onSubmit(filters)
      }}
    >
      <p className="border-b border-b-otto-grey-300 px-4 py-2 text-otto-grey-700">
        I&rsquo;m looking for...
      </p>

      <div className="grid gap-2 border-b border-b-otto-grey-300 px-4 pt-2 pb-4">
        <InputLabel label="Assured name">
          <Input
            id="search-assured-name"
            placeholder="Search on assured name"
            onChange={v => setFilters(f => ({ ...f, assuredName: v }))}
            value={filters.assuredName || ""}
          />
        </InputLabel>

        <InputLabel label="Class of business">
          <Select
            placeholder="Select class of business"
            options={[{ label: "All", value: "all-classes" }, ...classOptions]}
            onSelect={v => {
              if (v === "all-classes") {
                setFilters(f => ({ ...f, groupClass: undefined }))
              } else if (isClassOfBusiness(v)) {
                setFilters(f => ({ ...f, groupClass: v }))
              }
            }}
            selectedValue={filters.groupClass || null}
          />
        </InputLabel>

        <InputLabel
          label={
            <>
              Quote <ID />
            </>
          }
        >
          <Input
            id="search-quote-id"
            placeholder="Search on quote ID"
            onChange={v => setFilters(f => ({ ...f, quoteId: v }))}
            value={filters.quoteId || ""}
          />
        </InputLabel>

        <InputLabel
          label={
            <>
              Quote line <ID />
            </>
          }
        >
          <Input
            id="search-quote-line-id"
            placeholder="Search on quote line ID"
            onChange={v => setFilters(f => ({ ...f, quoteLineId: v }))}
            value={filters.quoteLineId || ""}
          />
        </InputLabel>
      </div>

      <div className="flex flex-col items-center justify-end gap-2 px-4 py-2 sm:flex-row">
        <Button
          label="Reset"
          theme="night"
          style="outlined"
          size="small"
          onClick={() => {
            logButtonClick({
              buttonName: "Reset",
              containerName: "Overlining modal filters",
              pageName,
            })

            onReset()
          }}
        />

        <Button label="Search" theme="pop" style="filled" size="small" />
      </div>
    </form>
  )
}

export default Filters
