import { FC, useEffect, useId, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import {
  SignedDetails,
  acceptReferredEndorsement,
  getSignedReferralDetails,
  rejectReferredEndorsement,
} from "@appia/api"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"
import axios from "axios"

import {
  Button,
  ExclamationCircleIcon,
  ModalDescription,
  StarCircleIcon,
  TextArea,
  Toast,
} from "@appia/ui-components"

import {
  ScreenTemplateWithHeader,
  ScreenTemplateWithoutHeader,
} from "./Templates"

import {
  EndorsementUMR,
  SelectedPolicyUMR,
} from "src/screens/ReviewEndorsement/UMR"
import { PolicyDocumentPanel } from "src/screens/ReviewEndorsement/Documents/PolicyDocuments"
import { EndorsementDocumentPanel } from "src/screens/ReviewEndorsement/Documents/EndorsementDocuments"

import ModalTemplate from "src/components/ModalTemplate"
import Loading from "src/components/Loading"
import ToastViewport from "src/components/ToastViewport"

import useApiClient from "src/contexts/ApiClientContext"
import { PageNameContext } from "src/contexts/PageNameContext"

const Success: FC<{ team: string }> = ({ team }) => (
  <ScreenTemplateWithoutHeader
    icon={<StarCircleIcon className="w-14 text-otto-pop-900" />}
    heading="Success!"
    body={
      <p className="mt-2 w-[10rem] text-center xs:w-[20rem]">
        The endorsements status has been successfully updated and will now be
        actioned by {team}.
      </p>
    }
  />
)

const Invalid: FC = () => (
  <ScreenTemplateWithoutHeader
    icon={<ExclamationCircleIcon className="w-14 text-otto-yellow-600" />}
    heading="Invalid link"
    body={
      <p className="mt-2 w-[10rem] text-center xs:w-[20rem]">
        Unfortunately, the endorsement link is invalid. Please speak to a member
        of the relevant team who will be able to reissue the email.
      </p>
    }
  />
)

const RejectModal: FC<{
  isOpen: boolean
  team: string
  onSuccess: (message: string) => void
  onClose: () => void
}> = ({ isOpen, team, onSuccess, onClose }) => {
  const [message, setMessage] = useState<string | null>(null)

  const formId = useId()

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Reject"
      content={
        <ModalDescription as="div">
          <form
            id={formId}
            noValidate
            aria-label="Rejection reason"
            onSubmit={() => {
              if (message) {
                onSuccess(message)
              }
            }}
          >
            <TextArea
              label={`Please add a note expanding on why this endorsement has been
            rejected, so it can be handled by ${team}.`}
              visuallyHideLabel
              placeholder="Add your message"
              maxLengthChars={300}
              onChange={setMessage}
              value={message}
            />
          </form>
        </ModalDescription>
      }
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => onClose()}
        />,

        <Button
          key="submit"
          disabled={message === null}
          form={formId}
          label="Submit"
          theme="pop"
          style="filled"
        />,
      ]}
    />
  )
}

const PAGE_NAME = "Review endorsement referral"

enum ReferralStage {
  Viewing,
  ChoiceSent,
  RejectModal,
  Completed,
}

const ReferralScreen: FC = () => {
  const apiClient = useApiClient()
  const { id: endorsementId } = useParams<{ id: string }>()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("signing_token")

  if (!token) {
    throw new Error("Missing token in endorsement referral screen")
  } else if (!endorsementId) {
    throw new Error("Missing endorsementId in endorsement referral screen")
  }

  const [stage, setStage] = useState<ReferralStage>(ReferralStage.Viewing)

  const [detailsReq, setDetailsReq] = useState<
    RD.RemoteData<Error, SignedDetails>
  >(RD.NotAsked)

  const fetchDetails = async (): Promise<void> => {
    setDetailsReq(RD.Loading)
    try {
      const details = await getSignedReferralDetails(
        apiClient,
        endorsementId,
        token,
      )
      setDetailsReq(RD.Success(details.data))
    } catch (e) {
      // We'll hit this error case if the token is expired / invalid / already
      // used, so it's an expected case and we don't need to log to Sentry
      if (e instanceof Error) {
        setDetailsReq(RD.Failure(e))
      }
    }
  }

  useEffect(() => {
    if (token) {
      fetchDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<string>("")
  const toastState = Toast.useToastState()

  const endorsementHeadingId = useId()
  const policyHeadingId = useId()

  const acceptReferral = async (): Promise<void> => {
    setStage(ReferralStage.ChoiceSent)
    try {
      await acceptReferredEndorsement(apiClient, endorsementId, token)
      setStage(ReferralStage.Completed)
    } catch (e) {
      if (e instanceof Error) {
        const message = axios.isAxiosError(e)
          ? e.response?.data?.detail?.message ?? e.message
          : e.message
        Sentry.captureException(e)
        setToastType("error")
        setToastMessage(message)
        toastState.triggerToast()
        setStage(ReferralStage.Viewing)
      }
    }
  }

  const rejectReferral = async (reason: string): Promise<void> => {
    setStage(ReferralStage.ChoiceSent)
    try {
      await rejectReferredEndorsement(apiClient, endorsementId, token, reason)
      setStage(ReferralStage.Completed)
    } catch (e) {
      if (e instanceof Error) {
        const message = axios.isAxiosError(e)
          ? e.response?.data?.detail?.message ?? e.message
          : e.message
        Sentry.captureException(e)
        setToastType("error")
        setToastMessage(message)
        toastState.triggerToast()
        setStage(ReferralStage.Viewing)
      }
    }
  }

  return RD.match(
    detailsReq,

    <Loading className="mt-8" />,

    <Loading className="mt-8" />,

    ({ endorsement, endorsementDocuments, policyDocuments, teamName }) => (
      <PageNameContext.Provider value={PAGE_NAME}>
        {stage === ReferralStage.Completed ? (
          <Success team={teamName} />
        ) : (
          <ScreenTemplateWithHeader
            pageTitle={PAGE_NAME}
            actionButton={
              <div className="grid grid-cols-2 items-center gap-2">
                <Button
                  label="Reject"
                  disabled={stage === ReferralStage.ChoiceSent}
                  onClick={() => setStage(ReferralStage.RejectModal)}
                  style="filled"
                  theme="night"
                  size="regular"
                />

                <Button
                  label="Accept"
                  disabled={stage === ReferralStage.ChoiceSent}
                  onClick={acceptReferral}
                  style="filled"
                  theme="pop"
                  size="regular"
                />
              </div>
            }
          >
            {/* TODO [OE-656] This layout needs to be kept in sync with the
                ReviewEndorsement screen. We should consider refactoring this
                into a shared component. */}
            <div className="mx-auto h-full max-w-screen px-4 sm:px-6 lg:px-8">
              <div className="grid h-full grid-flow-col grid-cols-2 grid-rows-[auto,1fr] gap-x-4 gap-y-0">
                <section aria-labelledby={policyHeadingId} className="contents">
                  <h2 className="sr-only" id={policyHeadingId}>
                    Policy
                  </h2>

                  <SelectedPolicyUMR
                    standalone={true}
                    policyIsBritFollow={false}
                    policyIsStub={false}
                    policyUMR={endorsement.umr}
                    onCopySuccess={() => {
                      setToastType("success")
                      setToastMessage("Successfully copied UMR")
                      toastState.triggerToast()
                    }}
                    onCopyFailure={() => {
                      setToastType("error")
                      setToastMessage("Failed to copy UMR")
                      toastState.triggerToast()
                    }}
                  />

                  <PolicyDocumentPanel
                    pbqaDocuments={policyDocuments.pbqaDocs}
                    endorsementDocuments={policyDocuments.endorsementDocs}
                  />
                </section>

                <section
                  aria-labelledby={endorsementHeadingId}
                  className="contents"
                >
                  <h2 className="sr-only" id={endorsementHeadingId}>
                    Endorsement
                  </h2>

                  <EndorsementUMR endorsementUMR={endorsement.umr} />

                  <EndorsementDocumentPanel
                    documents={endorsementDocuments}
                    platformUrl={endorsement.platformUrl}
                    source={endorsement.policyData?.source ?? null}
                  />
                </section>
              </div>
            </div>

            <RejectModal
              isOpen={stage === ReferralStage.RejectModal}
              team={teamName}
              onSuccess={rejectReferral}
              onClose={() => setStage(ReferralStage.Viewing)}
            />

            <Toast.Toast
              type={toastType}
              message={toastMessage}
              open={toastState.open}
              onOpenChange={toastState.onOpenChange}
            />

            <ToastViewport />
          </ScreenTemplateWithHeader>
        )}
      </PageNameContext.Provider>
    ),

    () => <Invalid />,
  )
}

export default ReferralScreen
