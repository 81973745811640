import type { SVGComponent } from "./IconWrapper"

const FilterSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3H5V4H2V6H5V7H9V6H22V4H9V3ZM5 20H2V18H5V17H9V18H22V20H9V21H5V20ZM2 13H15V14H19V13H22V11H19V10H15V11H2V13Z"
      fill="currentColor"
    />
  </svg>
)

export default FilterSVG
