import { AxiosInstance, AxiosPromise } from "axios"
import { Document } from "../Documents"
import { Endorsement } from "./Endorsement"

export const getEndorsementDocumentsUrl = (
  endorsement_id: Endorsement["id"],
): string => `/api/endorsements/${endorsement_id}/documents`

export const getEndorsementDocuments = (
  client: AxiosInstance,
  endorsement_id: Endorsement["id"],
): AxiosPromise<Document[]> =>
  client.get(getEndorsementDocumentsUrl(endorsement_id))
