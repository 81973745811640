import { FC, useRef } from "react"

import { ArrowRightIcon, Button, Link } from "@appia/ui-components"
import { Endorsement, PolicySource } from "@appia/api"

import ModalTemplate from "src/components/ModalTemplate"
import ModalContent from "./ModalContent"

import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"

const CONTAINER_NAME = "Process modal"

const PlatformActionCTA: FC<{
  platformUrl: string | null
  source: PolicySource | null
}> = ({ platformUrl, source }) => {
  const pageName = usePageName()

  // Whitespace is the only supported platform for which we should render a
  // link (provided we have a URL)
  if (platformUrl && source === "whitespace") {
    return (
      <Link
        label="Whitespace action"
        icon={{ position: "right", icon: <ArrowRightIcon /> }}
        theme="night"
        style="outlined"
        href={platformUrl}
        target="_blank"
        onClick={() => {
          logButtonClick({
            pageName,
            buttonName: "Platform action",
            containerName: CONTAINER_NAME,
          })
        }}
      />
    )
  }
  // For other placement platforms, or if there's no URL, we show a disabled
  // button. (It's not semantically valid to disable a link)
  else {
    return (
      <Button
        label="Whitespace action"
        disabled
        icon={{ position: "right", icon: <ArrowRightIcon /> }}
        theme="night"
        style="outlined"
      />
    )
  }
}

const ProcessModal: FC<{
  endorsementStatus: Endorsement["status"]
  isOpen: boolean
  onClickComplete: () => void
  onClickReferPU: () => void
  onClose: () => void
  platformUrl: string | null
  source: PolicySource | null
}> = ({
  endorsementStatus,
  isOpen,
  onClickComplete,
  onClickReferPU,
  onClose,
  platformUrl,
  source,
}) => {
  const pageName = usePageName()

  const logClickAndClose = (): void => {
    logButtonClick({
      pageName,
      buttonName: "Close",
      containerName: CONTAINER_NAME,
    })

    onClose()
  }

  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={logClickAndClose}
      title="Process"
      initialFocus={contentRef}
      content={<ModalContent contentRef={contentRef} />}
      actions={[
        <PlatformActionCTA
          key="platform-cta"
          platformUrl={platformUrl}
          source={source}
        />,

        <Button
          key="refer-pu"
          label="Refer to PU"
          icon={{ position: "right", icon: <ArrowRightIcon /> }}
          theme="night"
          style="outlined"
          disabled={
            endorsementStatus === "accepted_by_decider" ||
            endorsementStatus === "rejected_by_decider" ||
            endorsementStatus === "review_complete"
          }
          onClick={() => {
            logButtonClick({
              pageName,
              buttonName: "Refer to PU",
              containerName: CONTAINER_NAME,
            })

            onClickReferPU()
          }}
        />,

        <Button
          key="complete"
          label="Complete"
          theme="pop"
          style="filled"
          disabled={
            endorsementStatus === "referred_to_decider" ||
            endorsementStatus === "review_complete"
          }
          onClick={() => {
            logButtonClick({
              pageName,
              buttonName: "Complete",
              containerName: CONTAINER_NAME,
            })

            onClickComplete()
          }}
        />,
      ]}
    />
  )
}

export default ProcessModal
