import { FC } from "react"

import { DocumentSearchIcon, IconButton } from "@appia/ui-components"

const BoundingBoxCTA: FC<{ questionLabel: string; onClick: () => void }> = ({
  questionLabel,
  onClick,
}) => (
  <IconButton
    label={`Jump to extraction location for ${questionLabel}`}
    color="text-otto-grey-700"
    size={6}
    icon={<DocumentSearchIcon />}
    onClick={onClick}
  />
)

export default BoundingBoxCTA
