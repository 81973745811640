import { ReactNode } from "react"
import * as Sentry from "@sentry/react"

// Run an action which:
//  - Shows a loading overlay if it doesn't resolve within a certain timeout
//  - Shows a toast on success/failure
//  - Refreshes the PBQAs when it succeeds
const doPBQAAction = async <T,>(
  action: () => Promise<T>,
  loadingMsg: string,
  successMsg: (t: T) => ReactNode,
  failureMsg: string,
  setLoadingOverlay: (message: string | null) => void,
  triggerToast: (type: "success" | "error", message: ReactNode) => void,
  updatePBQAs: () => void,
): Promise<void> => {
  const timeoutId = setTimeout(() => {
    setLoadingOverlay(loadingMsg)
  }, 300)

  try {
    const res = await action()
    updatePBQAs()
    triggerToast("success", successMsg(res))
  } catch (e) {
    if (e instanceof Error) {
      Sentry.captureException(e)
      triggerToast("error", failureMsg)
    }
  } finally {
    clearTimeout(timeoutId)
    setLoadingOverlay(null)
  }
}

export default doPBQAAction
