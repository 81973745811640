import { FC, memo } from "react"
import { Link, Logo, LogoBackgroundPattern } from "@appia/ui-components"

import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"

const PAGE_NAME = "Not found"

const NotFoundScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })

  useDocumentTitle("404 Not Found")

  return (
    <div className="relative h-screen bg-otto-night text-otto-pop">
      <header className="relative z-10 p-8">
        <Logo />
      </header>

      <main className="absolute inset-0 flex items-center justify-center overflow-hidden">
        <div className="absolute z-0 w-[max(1500px,100vw)] rotate-[110deg] scale-[1.2]">
          <LogoBackgroundPattern />
        </div>

        <div className="z-10 max-w-7xl text-center">
          <p className="text-lg font-medium">404 error</p>

          <h1 className="mt-2 text-4xl font-bold sm:text-5xl">
            Uh oh! I think you&#8217;re lost.
          </h1>

          <p className="mt-2 text-lg font-medium">
            It looks like the page you’re looking for doesn&#8217;t exist.
          </p>

          <Link
            href="/"
            label="Go home"
            theme="pop"
            style="filled"
            className="mx-auto mt-6"
            onClick={() => {
              logButtonClick({
                buttonName: "Go home",
                linkHref: "/",
                containerName: "Main",
                pageName: PAGE_NAME,
              })
            }}
          />
        </div>
      </main>
    </div>
  )
}

export default memo(NotFoundScreen)
