import { FC, useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import UserSelection from "./components/UserSelection"

import { Button, Card, Link } from "@appia/ui-components"
import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"
import ScreenTemplate from "src/templates/ScreenTemplate"

import useApiClient from "src/contexts/ApiClientContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"

import { Team, User, joinTeam, leaveTeam } from "@appia/api"
import { useGetCurrentUser, useGetTeam, useGetUsers } from "src/swr"
import * as RD from "@appia/remote-data"

import { logButtonClick, useLogPageView } from "src/amplitude"
import * as Sentry from "@sentry/react"

import classNames from "classnames"

const PAGE_NAME = "Manage team"

const definitionItemStyles =
  "sm:grid sm:grid-cols-[25%,auto] md:grid-cols-[25%,50%] gap-4 w-full"

const dtStyles = "block mb-2 sm:mb-0 font-bold"

const ManageTeamScreen: FC = () => {
  const { id: teamId } = useParams<{ id: string }>()

  if (teamId === undefined) {
    throw new Error("Missing teamId in ManageTeam screen")
  }

  useLogPageView({ pageName: PAGE_NAME })

  const apiClient = useApiClient()

  const { update: updateGetCurrentUser } = useGetCurrentUser()
  const { request: teamRequest } = useGetTeam(teamId)
  const { request: usersRequest, update: updateGetUsers } = useGetUsers()

  const documentTitle =
    RD.isSuccess(teamRequest) && teamRequest.data.name
      ? `${teamRequest.data.name} | Manage team`
      : `Manage team`
  useDocumentTitle(documentTitle)

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  useEffect(() => {
    if (RD.isSuccess(usersRequest)) {
      const usersInTeam = usersRequest.data.users.filter(
        user => user.teamId === teamId,
      )
      setSelectedUserIds(usersInTeam.map(user => user.id))
    }
  }, [usersRequest, teamId])

  const [updateRequest, setUpdateRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const onSubmitForm = async (team: Team, users: User[]): Promise<void> => {
    if (!apiClient) {
      return
    }

    const userIdsCurrentlyInTeam = users
      .filter(u => u.teamId === teamId)
      .map(u => u.id)

    // Add users who are selected but not currently in team
    const userIdsToAdd = selectedUserIds.filter(
      id => !userIdsCurrentlyInTeam.includes(id),
    )

    // Remove users who are currently in team but not selected
    const userIdsToRemove = userIdsCurrentlyInTeam.filter(
      id => !selectedUserIds.includes(id),
    )

    setUpdateRequest(RD.Loading)

    try {
      await userIdsToAdd.reduce<Promise<unknown>>(async (acc, userId) => {
        await acc
        return joinTeam(apiClient, team.id, userId, team.companyId)
      }, Promise.resolve())

      await userIdsToRemove.reduce<Promise<unknown>>(async (acc, userId) => {
        await acc
        return leaveTeam(apiClient, team.id, userId, team.companyId)
      }, Promise.resolve())

      setUpdateRequest(RD.Success(null))

      updateGetCurrentUser()
      updateGetUsers()
    } catch (e) {
      if (e instanceof Error) {
        setUpdateRequest(RD.Failure(e))
        Sentry.captureException(e)
      }
    }
  }

  if (RD.isFailure(teamRequest) || RD.isFailure(usersRequest)) {
    return <Navigate to="/404" />
  }

  if (
    RD.isNotAsked(teamRequest) ||
    RD.isLoading(teamRequest) ||
    RD.isNotAsked(usersRequest) ||
    RD.isLoading(usersRequest)
  ) {
    return (
      <ScreenTemplate
        layout={{ type: "regular", backPath: "/teams" }}
        pageTitle={PAGE_NAME}
      >
        <Loading />
      </ScreenTemplate>
    )
  }

  const team = teamRequest.data
  const { users } = usersRequest.data

  return (
    <ScreenTemplate
      layout={{ type: "regular", backPath: "/teams" }}
      pageTitle={PAGE_NAME}
    >
      <Card>
        <dl className="mb-6">
          <div className={classNames(definitionItemStyles, "mb-6")}>
            <dt className={dtStyles}>Team name</dt>
            <dd>{team.name}</dd>
          </div>

          <div className={definitionItemStyles}>
            <dt className={dtStyles}>Team email address</dt>
            <dd>
              {team.slug}@{team.domain}
            </dd>
          </div>
        </dl>

        <form
          aria-label="Edit team"
          onSubmit={async ev => {
            ev.preventDefault()
            onSubmitForm(team, users)
          }}
        >
          <div
            role="group"
            aria-labelledby="team-members-label"
            className={definitionItemStyles}
          >
            <span id="team-members-label" className={dtStyles}>
              Team members
            </span>
            <UserSelection
              allUsers={users}
              teamId={team.id}
              selectedUserIds={selectedUserIds}
              selectUser={id => setSelectedUserIds(ids => [...ids, id])}
              deselectUser={idToDeselect =>
                setSelectedUserIds(ids => ids.filter(id => id !== idToDeselect))
              }
            />
          </div>

          <div className={definitionItemStyles}>
            <div className="col-start-2">
              <div className="my-6">
                {RD.isSuccess(updateRequest) && (
                  <div className="text-otto-deep-green">
                    Team updated successfully
                  </div>
                )}

                {RD.isFailure(updateRequest) && (
                  <ErrorMessage
                    message="There was an error updating your team:"
                    error={updateRequest.error}
                  />
                )}
              </div>

              <div className="grid-cols-2 gap-4 sm:grid">
                <Link
                  href="/teams"
                  theme="night"
                  style="outlined"
                  label="Go back"
                  stretch="space-around"
                  className="mb-4 sm:mb-0"
                  onClick={() => {
                    logButtonClick({
                      buttonName: "Go back",
                      containerName: "Main",
                      pageName: PAGE_NAME,
                      linkHref: "/teams",
                    })
                  }}
                />

                <Button
                  theme="night"
                  style="filled"
                  stretch="space-around"
                  label="Update team"
                  isLoading={RD.isLoading(updateRequest)}
                />
              </div>
            </div>
          </div>
        </form>
      </Card>
    </ScreenTemplate>
  )
}

export default ManageTeamScreen
