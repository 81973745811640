import { FC, useMemo } from "react"

import { Document, PBQAQuestion, PBQASurvey } from "@appia/api"

import {
  BoundingBoxWithIdentifier,
  answerToBoundingBox,
} from "ReviewPBQA/answerUtils"

import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import DocumentTabs from "src/components/DocumentTabs"
import ExcelViewer from "src/components/ExcelViewer"
import PDFViewer from "src/components/PDFViewer"

const PDFLoader: FC<{
  activeField: PBQAQuestion["id"] | null
  document: Document
  setActiveQuestionId: (id: PBQAQuestion["id"]) => void
  survey: PBQASurvey | null
}> = ({ activeField, document, setActiveQuestionId, survey }) => {
  const boundingBoxes = useMemo<BoundingBoxWithIdentifier[]>(
    () =>
      survey === null
        ? []
        : survey.answers.flatMap(a => answerToBoundingBox(a, document.id)),
    [survey, document],
  )

  return (
    <PDFViewer<PBQAQuestion["id"]>
      activeField={activeField}
      boundingBoxes={boundingBoxes}
      documentMimeType={document.mimetype}
      documentName={document.name}
      documentUrl={document.url}
      onHighlightClick={setActiveQuestionId}
    />
  )
}

const DocumentsSection: FC<{
  activeDocumentId: Document["id"] | null
  activeSheetName: string | null
  documents: Document[]
  setActiveDocumentId: (id: Document["id"]) => void
  setActiveQuestionId: (id: PBQAQuestion["id"]) => void
  setActiveSheetName: (sheetName: string) => void
}> = ({
  activeDocumentId,
  activeSheetName,
  documents,
  setActiveDocumentId,
  setActiveQuestionId,
  setActiveSheetName,
}) => {
  const { activeQuestionId, activeSurvey } = usePBQASurvey()

  return (
    <DocumentTabs
      documents={documents}
      activeDocumentId={activeDocumentId}
      setActiveDocumentId={setActiveDocumentId}
    >
      {document =>
        document.isSpreadsheet ? (
          <ExcelViewer<PBQAQuestion["id"]>
            activeField={activeQuestionId}
            activeSheetName={activeSheetName}
            cellsToHighlight={[]}
            documentMimeType={document.mimetype}
            documentName={document.name}
            documentUrl={document.url}
            documentUuid={document.id}
            setActiveSheetName={setActiveSheetName}
          />
        ) : (
          <PDFLoader
            activeField={activeQuestionId}
            document={document}
            setActiveQuestionId={setActiveQuestionId}
            survey={activeSurvey}
          />
        )
      }
    </DocumentTabs>
  )
}

export default DocumentsSection
