import { AxiosInstance, AxiosPromise } from "axios"
import { Endorsement } from "./Endorsement"

export type EndorsementStatus =
  | "created"
  | "processing"
  | "failed"
  | "review_required"
  | "review_in_progress"
  | "blocked_awaiting_third_party"
  | "referred_to_decider"
  | "accepted_by_decider"
  | "rejected_by_decider"
  | "review_complete"
  | "archived"

export const blockEndorsement = (
  client: AxiosInstance,
  endorsement_id: Endorsement["id"],
): AxiosPromise => client.post(`/api/endorsements/${endorsement_id}/block`)
