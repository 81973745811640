import { FC } from "react"
import ModalTemplate from "src/components/ModalTemplate"
import { Button, Callout, Card } from "@appia/ui-components"
import QuoteMatchingHeader, { HeaderData } from "./QuoteMatchingHeader"
import ErrorMessage from "src/components/ErrorMessage"
import { QuoteComparisonMatchModalData } from "src/screens/ReviewPBQA/state"
import QuoteComparisonMatch from "./QuoteComparisonMatch"
import { KiQuote } from "@appia/api"

export interface ValidatedHeaderData {
  assuredName: string
  quoteId: number
  quoteLineId: number
  fon: string
  match: number
}

const QuoteLineMatchingModal: FC<{
  isOpen: boolean
  onClose: () => void
  onLeave: () => void
  onSubmit: (kiQuote: KiQuote) => void
  quoteData: QuoteComparisonMatchModalData[] | undefined
  headerData: HeaderData | undefined
  kiQuote: KiQuote | undefined
  setOpen: (open: boolean) => void
  setHasConfirmedQuoteDetailsModal: (
    hasConfirmedQuoteDetailsModal: boolean,
  ) => void
}> = ({
  isOpen,
  onClose,
  quoteData,
  headerData,
  onSubmit,
  kiQuote,
  setOpen,
  setHasConfirmedQuoteDetailsModal,
}) => {
  const validateHeaderData = (): ValidatedHeaderData => {
    return {
      assuredName: headerData?.assuredName ?? "",
      quoteId: headerData?.quoteId ?? 0,
      quoteLineId: headerData?.quoteLineId ?? 0,
      fon: headerData?.fon !== null ? "Yes" : "No",
      match: headerData?.match ?? 0,
    }
  }

  const isIndication = kiQuote?.isIndication || false

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Quote details"
      content={
        <div className="h-[576px] w-[600px] gap-[16px] overflow-y-auto opacity-100">
          {headerData ? (
            <QuoteMatchingHeader headerData={validateHeaderData()} />
          ) : (
            <Card>
              <ErrorMessage message="No Header data found." />
            </Card>
          )}

          {isIndication && (
            <Callout
              style="filled"
              type="error"
              rounded={false}
              data-testid="indication-warning-message"
            >
              <p>
                <b>THIS IS AN INDICATION</b>, do not bind this risk unless
                explicitly agreed by an underwriter.
              </p>
            </Callout>
          )}
          {quoteData ? (
            <QuoteComparisonMatch quoteData={quoteData} />
          ) : (
            <Card>
              <ErrorMessage message="No Quote data found." />
            </Card>
          )}
        </div>
      }
      actionsReflowBreakpoint="sm"
      data-testid="quoteline-matching-modal"
      actions={[
        <Button
          key="cancel"
          id="cancel-button"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={onClose}
        />,

        <Button
          key="submit"
          id="submit-and-confirm-button"
          label="Confirm & Select"
          theme="pop"
          style="filled"
          onClick={e => {
            e.stopPropagation()
            if (kiQuote) {
              onSubmit(kiQuote)
              setHasConfirmedQuoteDetailsModal(true)
              setOpen(false)
              onClose()
            }
          }}
        />,
      ]}
    />
  )
}

export default QuoteLineMatchingModal
