import { FC } from "react"
import MatchingFieldsPercentPill from "../KiQuoteSearchBar/MatchingFieldsPercentPill"
import { ValidatedHeaderData } from "./QuoteLineMatchingModal"

export interface HeaderData {
  assuredName: string | null
  quoteId: number | null
  quoteLineId: number | null
  fon: string | null
  match: number | null
}

export interface HeaderFields {
  key: keyof HeaderData
  label: string
  properties: string
}

const QuoteMatchingHeader: FC<{
  headerData: ValidatedHeaderData
}> = ({ headerData }) => {
  const headerFields: HeaderFields[] = [
    { key: "assuredName", label: "Assured name", properties: "col-span-5" },
    { key: "quoteId", label: "Quote ID", properties: "col-span-2 pl-2" },
    { key: "quoteLineId", label: "Quote line ID", properties: "col-span-2" },
    { key: "fon", label: "FON?", properties: "col-span-1 pl-1" },
    { key: "match", label: "Match", properties: "col-span-2 pl-2" },
  ]

  function isMatchKey(
    key: keyof HeaderData,
    _: ValidatedHeaderData[keyof HeaderData],
  ): _ is number {
    return key === "match"
  }

  const renderContent = (
    key: keyof ValidatedHeaderData,
    value: ValidatedHeaderData[keyof ValidatedHeaderData],
  ): JSX.Element | string => {
    if (isMatchKey(key, value)) {
      return <MatchingFieldsPercentPill matchValue={value} />
    } else {
      return value
    }
  }

  return (
    <div className="ml-2 mr-2 grid grid-cols-12 gap-0 rounded-md bg-otto-grey-100 pl-4 pt-3 pb-4 pr-4">
      {headerFields.map(({ key, label, properties }) => (
        <div key={key} className={properties}>
          <div className="basis-[20%] text-otto-grey-700">{label}</div>
          <div>{renderContent(key, headerData[key])}</div>
        </div>
      ))}
    </div>
  )
}

export default QuoteMatchingHeader
