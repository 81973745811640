import { FC } from "react"

import {
  Button,
  Callout,
  InformationCircleIcon,
  ModalDescription,
} from "@appia/ui-components"
import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"
import ModalTemplate from "src/components/ModalTemplate"

const CONTAINER_NAME = "Partner Only Warning Modal"

const PartnerOnlyWarningModal: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const pageName = usePageName()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      content={
        <ModalDescription as="div" className="gap-4 text-center">
          <InformationCircleIcon className="mx-auto w-8 text-otto-pop-800" />
          <h1 className="pb-4 text-2xl font-bold">
            Ki Insurance (1618) is not on contract
          </h1>
          <Callout type="information">
            <p className="mx-1">
              Document could not be generated because Ki Insurance (1618) is not
              on the contract.
            </p>
          </Callout>
        </ModalDescription>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="Close"
          label="Close"
          theme="pop"
          style="filled"
          onClick={() => {
            logButtonClick({
              buttonName: "Close",
              containerName: CONTAINER_NAME,
              pageName,
            })
            onClose()
          }}
        />,
      ]}
    />
  )
}

export default PartnerOnlyWarningModal
