import { ReactElement } from "react"
import { Navigate, Route } from "react-router-dom"

import { RequiresAuth } from "./Authentication"

import LoginScreen from "./screens/Login"
import LogoutScreen from "./screens/LogoutScreen"
import NotFoundScreen from "./screens/NotFoundScreen"
import SettingsScreen from "./screens/SettingsScreen"

import CreateTeamScreen from "./screens/Teams/CreateTeamScreen"
import ManageTeamScreen from "./screens/Teams/ManageTeamScreen"
import TeamsScreen from "./screens/Teams/TeamsScreen"

import PBQAScreen from "./screens/PBQA"
import ReviewPBQAScreen from "./screens/ReviewPBQA"
import UploadPBQAScreen from "./screens/UploadPBQA"
import ViewPBQAScreen from "./screens/ViewPBQA"

import {
  REVIEW_CONTRACT_DOCUMENTS_PATH,
  REVIEW_CONTRACT_GENERAL_PATH,
  REVIEW_CONTRACT_SECTIONS_PATH,
  ReviewContractScreen,
} from "./screens/ReviewContract"
import ViewContractScreen from "./screens/ViewContract"
import GeneralSectionScreen from "./screens/ReviewContract/GeneralScreen/GeneralSectionScreen"
import SectionScreen from "./screens/ReviewContract/SectionScreen"
import EndorsementReferralScreen from "./screens/ReferEndorsement"
import EndorsementsScreen from "./screens/Endorsements"
import ReviewEndorsementScreen from "./screens/ReviewEndorsement"
import UploadEndorsementScreen from "./screens/UploadEndorsement"
import ViewEndorsementScreen from "./screens/ViewEndorsement"
import WebhooksScreen from "./screens/Webhooks/WebhooksScreen"
import CreateWebhookScreen from "./screens/Webhooks/CreateWebhookScreen"
import EditWebhookScreen from "./screens/Webhooks/EditWebhookScreen"
import ContractProcessingScreen from "./screens/Contract"
import DocumentsScreen from "./screens/ReviewContract/DocumentsScreen/DocumentsScreen"
import EndorsementUploadArea from "./screens/UploadEndorsement/EndorsementUploadArea"

const appRoutes: ReactElement = (
  <>
    <Route path="/" element={<Navigate to="/pbqa" />} />

    <Route path="pbqa">
      <Route
        index
        element={
          <RequiresAuth>
            <PBQAScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="upload"
        element={
          <RequiresAuth>
            <UploadPBQAScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="review/:id/:section?"
        element={
          <RequiresAuth>
            <ReviewPBQAScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="view/:id"
        element={
          <RequiresAuth>
            <ViewPBQAScreen />
          </RequiresAuth>
        }
      />
    </Route>

    <Route path="contract">
      <Route
        index
        element={
          <RequiresAuth>
            <ContractProcessingScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="review/:contractId/:version"
        element={
          <RequiresAuth>
            <ReviewContractScreen />
          </RequiresAuth>
        }
      >
        <Route
          path=""
          element={
            <Navigate relative="path" to={REVIEW_CONTRACT_GENERAL_PATH} />
          }
        />
        <Route
          path={REVIEW_CONTRACT_DOCUMENTS_PATH}
          element={<DocumentsScreen />}
        />

        <Route
          path={REVIEW_CONTRACT_GENERAL_PATH}
          element={<GeneralSectionScreen />}
        />
        <Route
          path={REVIEW_CONTRACT_SECTIONS_PATH}
          element={<SectionScreen />}
        />
      </Route>
      <Route
        path="view/:contractId"
        element={
          <RequiresAuth>
            <ViewContractScreen />
          </RequiresAuth>
        }
      />
    </Route>

    <Route path="endorsements">
      <Route
        index
        element={
          <RequiresAuth>
            <EndorsementsScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="upload"
        element={
          <RequiresAuth>
            <UploadEndorsementScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="upload-area"
        element={
          <RequiresAuth>
            <EndorsementUploadArea />
          </RequiresAuth>
        }
      />
      <Route
        path="review/:id"
        element={
          <RequiresAuth>
            <ReviewEndorsementScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="view/:id"
        element={
          <RequiresAuth>
            <ViewEndorsementScreen />
          </RequiresAuth>
        }
      />
      <Route path=":id/referred" element={<EndorsementReferralScreen />} />
    </Route>

    <Route path="settings">
      <Route
        index
        element={
          <RequiresAuth>
            <SettingsScreen />
          </RequiresAuth>
        }
      />
      <Route path="webhooks">
        <Route
          index
          element={
            <RequiresAuth>
              <WebhooksScreen />
            </RequiresAuth>
          }
        />
        <Route
          path="create"
          element={
            <RequiresAuth>
              <CreateWebhookScreen />
            </RequiresAuth>
          }
        />
        <Route
          path=":id"
          element={
            <RequiresAuth>
              <EditWebhookScreen />
            </RequiresAuth>
          }
        />
      </Route>
    </Route>

    <Route path="teams">
      <Route
        index
        element={
          <RequiresAuth>
            <TeamsScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequiresAuth>
            <CreateTeamScreen />
          </RequiresAuth>
        }
      />
      <Route
        path="manage/:id"
        element={
          <RequiresAuth>
            <ManageTeamScreen />
          </RequiresAuth>
        }
      />
    </Route>

    <Route path="login" element={<LoginScreen />} />
    <Route path="logout" element={<LogoutScreen />} />

    <Route path="404" element={<NotFoundScreen />} />
    <Route path="*" element={<Navigate to="/404" />} />
  </>
)

export default appRoutes
