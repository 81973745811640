import { HeaderSectionProps } from "./HeaderSection"
import {
  CONDUCT_RATING_LOW,
  CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE,
  ContractHeaderItem,
  ContractResponse,
  ContractSectionItem,
  Document,
  EnergyReferenceSplit,
  KiSyndicateData,
  MajorClassCode,
  Me,
  MinorClassCode,
  MinorSubClassResponse,
  ReferenceSplit,
  SubClassCode,
  SyndicateData,
  SyndicateLine,
} from "@appia/api"
import { EnergyGroupClassCode } from "@appia/api/src/Contracts/EnergyGroupClass"

export const mockHeaderSectionData: HeaderSectionProps = {
  insured: "Hugo Besley",
  umr: "1234556",
  programmeRef: "12038rhoh3",
}

export const currencyOptions = [
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "AED", value: "AED" },
  { label: "AFN", value: "AFN" },
  { label: "ALL", value: "ALL" },
  { label: "AMD", value: "AMD" },
  { label: "ANG", value: "ANG" },
  { label: "AOA", value: "AOA" },
  { label: "ARS", value: "ARS" },
  { label: "AUD", value: "AUD" },
  { label: "AWG", value: "AWG" },
  { label: "AZN", value: "AZN" },
  { label: "BAM", value: "BAM" },
  { label: "BBD", value: "BBD" },
  { label: "BDT", value: "BDT" },
  { label: "BGN", value: "BGN" },
  { label: "BHD", value: "BHD" },
  { label: "BIF", value: "BIF" },
  { label: "BMD", value: "BMD" },
  { label: "BND", value: "BND" },
  { label: "BOB", value: "BOB" },
  { label: "BOV", value: "BOV" },
  { label: "BRL", value: "BRL" },
  { label: "BSD", value: "BSD" },
  { label: "BTN", value: "BTN" },
  { label: "BWP", value: "BWP" },
  { label: "BYN", value: "BYN" },
  { label: "BZD", value: "BZD" },
  { label: "CAD", value: "CAD" },
  { label: "CDF", value: "CDF" },
  { label: "CHE", value: "CHE" },
  { label: "CHF", value: "CHF" },
  { label: "CHW", value: "CHW" },
  { label: "CLF", value: "CLF" },
  { label: "CLP", value: "CLP" },
  { label: "CNY", value: "CNY" },
  { label: "COP", value: "COP" },
  { label: "COU", value: "COU" },
  { label: "CRC", value: "CRC" },
  { label: "CUC", value: "CUC" },
  { label: "CUP", value: "CUP" },
  { label: "CVE", value: "CVE" },
  { label: "CZK", value: "CZK" },
  { label: "DJF", value: "DJF" },
  { label: "DKK", value: "DKK" },
  { label: "DOP", value: "DOP" },
  { label: "DZD", value: "DZD" },
  { label: "EGP", value: "EGP" },
  { label: "ERN", value: "ERN" },
  { label: "ETB", value: "ETB" },
  { label: "FJD", value: "FJD" },
  { label: "FKP", value: "FKP" },
  { label: "GEL", value: "GEL" },
  { label: "GHS", value: "GHS" },
  { label: "GIP", value: "GIP" },
  { label: "GMD", value: "GMD" },
  { label: "GNF", value: "GNF" },
  { label: "GTQ", value: "GTQ" },
  { label: "GYD", value: "GYD" },
  { label: "HKD", value: "HKD" },
  { label: "HNL", value: "HNL" },
  { label: "HRK", value: "HRK" },
  { label: "HTG", value: "HTG" },
  { label: "HUF", value: "HUF" },
  { label: "IDR", value: "IDR" },
  { label: "ILS", value: "ILS" },
  { label: "INR", value: "INR" },
  { label: "IQD", value: "IQD" },
  { label: "IRR", value: "IRR" },
  { label: "ISK", value: "ISK" },
  { label: "JMD", value: "JMD" },
  { label: "JOD", value: "JOD" },
  { label: "JPY", value: "JPY" },
  { label: "KES", value: "KES" },
  { label: "KGS", value: "KGS" },
  { label: "KHR", value: "KHR" },
  { label: "KMF", value: "KMF" },
  { label: "KPW", value: "KPW" },
  { label: "KRW", value: "KRW" },
  { label: "KWD", value: "KWD" },
  { label: "KYD", value: "KYD" },
  { label: "KZT", value: "KZT" },
  { label: "LAK", value: "LAK" },
  { label: "LBP", value: "LBP" },
  { label: "LKR", value: "LKR" },
  { label: "LRD", value: "LRD" },
  { label: "LSL", value: "LSL" },
  { label: "LYD", value: "LYD" },
  { label: "MAD", value: "MAD" },
  { label: "MDL", value: "MDL" },
  { label: "MGA", value: "MGA" },
  { label: "MKD", value: "MKD" },
  { label: "MMK", value: "MMK" },
  { label: "MNT", value: "MNT" },
  { label: "MOP", value: "MOP" },
  { label: "MRU", value: "MRU" },
  { label: "MUR", value: "MUR" },
  { label: "MVR", value: "MVR" },
  { label: "MWK", value: "MWK" },
  { label: "MXN", value: "MXN" },
  { label: "MXV", value: "MXV" },
  { label: "MYR", value: "MYR" },
  { label: "MZN", value: "MZN" },
  { label: "NAD", value: "NAD" },
  { label: "NGN", value: "NGN" },
  { label: "NIO", value: "NIO" },
  { label: "NOK", value: "NOK" },
  { label: "NPR", value: "NPR" },
  { label: "NZD", value: "NZD" },
  { label: "OMR", value: "OMR" },
  { label: "PAB", value: "PAB" },
  { label: "PEN", value: "PEN" },
  { label: "PGK", value: "PGK" },
  { label: "PHP", value: "PHP" },
  { label: "PKR", value: "PKR" },
  { label: "PLN", value: "PLN" },
  { label: "PYG", value: "PYG" },
  { label: "QAR", value: "QAR" },
  { label: "RON", value: "RON" },
  { label: "RSD", value: "RSD" },
  { label: "RUB", value: "RUB" },
  { label: "RWF", value: "RWF" },
  { label: "SAR", value: "SAR" },
  { label: "SBD", value: "SBD" },
  { label: "SCR", value: "SCR" },
  { label: "SDG", value: "SDG" },
  { label: "SEK", value: "SEK" },
  { label: "SGD", value: "SGD" },
  { label: "SHP", value: "SHP" },
  { label: "SLE", value: "SLE" },
  { label: "SLL", value: "SLL" },
  { label: "SOS", value: "SOS" },
  { label: "SRD", value: "SRD" },
  { label: "SSP", value: "SSP" },
  { label: "STN", value: "STN" },
  { label: "SVC", value: "SVC" },
  { label: "SYP", value: "SYP" },
  { label: "SZL", value: "SZL" },
  { label: "THB", value: "THB" },
  { label: "TJS", value: "TJS" },
  { label: "TMT", value: "TMT" },
  { label: "TND", value: "TND" },
  { label: "TOP", value: "TOP" },
  { label: "TRY", value: "TRY" },
  { label: "TTD", value: "TTD" },
  { label: "TWD", value: "TWD" },
  { label: "TZS", value: "TZS" },
  { label: "UAH", value: "UAH" },
  { label: "UGX", value: "UGX" },
  { label: "USN", value: "USN" },
  { label: "UYI", value: "UYI" },
  { label: "UYU", value: "UYU" },
  { label: "UYW", value: "UYW" },
  { label: "UZS", value: "UZS" },
  { label: "VED", value: "VED" },
  { label: "VES", value: "VES" },
  { label: "VND", value: "VND" },
  { label: "VUV", value: "VUV" },
  { label: "WST", value: "WST" },
  { label: "XAF", value: "XAF" },
  { label: "XAG", value: "XAG" },
  { label: "XAU", value: "XAU" },
  { label: "XBA", value: "XBA" },
  { label: "XBB", value: "XBB" },
  { label: "XBC", value: "XBC" },
  { label: "XBD", value: "XBD" },
  { label: "XCD", value: "XCD" },
  { label: "XDR", value: "XDR" },
  { label: "XOF", value: "XOF" },
  { label: "XPD", value: "XPD" },
  { label: "XPF", value: "XPF" },
  { label: "XPT", value: "XPT" },
  { label: "XSU", value: "XSU" },
  { label: "XTS", value: "XTS" },
  { label: "XUA", value: "XUA" },
  { label: "XXX", value: "XXX" },
  { label: "YER", value: "YER" },
  { label: "ZAR", value: "ZAR" },
  { label: "ZMW", value: "ZMW" },
  { label: "ZWL", value: "ZWL" },
]

export const mockReferenceSplitEnergy: EnergyReferenceSplit = {
  policyReference: "EN123456",
  eea: true,
  premiumAllocation: 10000,
  premiumAllocationCurrency: "USD",
  premiumAllocationPercent: 50,
  limitAllocation: 5000,
  limitAllocationCurrency: "USD",
  limitAllocationPercent: 80,
  groupClass: "energy_operational",
  minorClass: MinorClassCode.ACCIDENT_DIRECT,
  subClass: SubClassCode.FINANCIAL_ALL_RISKS,
  gnModelPlr: 0.13,
  gnTechnicalPlr: 0.13,
  ggTechnicalPrice: 50.0,
  ggModelPrice: 100.0,
  riskCodes: ["RC1", "RC2"],
  britPolicyReference: "XYZ456",
}

export const mockReferenceSplitROW: ReferenceSplit = {
  policyReference: "ABC123",
  premiumAllocation: 0.75,
  riskCodes: ["RC1", "RC2"],
  britPolicyReference: "XYZ456",
  eea: false,
  premiumAllocationCurrency: "USD",
  premiumAllocationPercent: 50.0,
  gnModelPlr: 0.13,
  gnTechnicalPlr: 0.13,
  ggTechnicalPrice: 50.0,
  ggModelPrice: 100.0,
}

export const mockReferenceSplitEEA: ReferenceSplit = {
  policyReference: "DEF789",
  premiumAllocation: 0.75,
  riskCodes: ["TD3", "TD4"],
  britPolicyReference: "BOB333",
  eea: true,
  premiumAllocationCurrency: "USD",
  premiumAllocationPercent: 50.0,
  gnModelPlr: 0.13,
  gnTechnicalPlr: 0.13,
  ggTechnicalPrice: 50.0,
  ggModelPrice: 100.0,
}

export const mockKiSyndicateData: KiSyndicateData = {
  type: "ki",
  groupClass: "ambridge_cyber_tech",
  subClass: SubClassCode.FINANCIAL_ALL_RISKS,
  kiJourney: "brit_new_business",
  britProgrammeReference: "Brit123",
  minorClass: MinorClassCode.OTHER,
  majorClass: MajorClassCode.PERSONAL_ACCIDENT_NT_PROPERTY_INTL_P,
  producingTeam: "pi_us",
  classType: "property",
  referenceSplit: [mockReferenceSplitROW],
  businessType: "existing_business_follow",
}

export const mockEnergyKiSyndicateData: KiSyndicateData = {
  ...mockKiSyndicateData,
  groupClass: "energy_operational",
  minorClass: MinorClassCode.OTHER,
  majorClass: MajorClassCode.PERSONAL_ACCIDENT_NT_PROPERTY_INTL_P,
  referenceSplit: [mockReferenceSplitEnergy],
}

export const createMockSyndicateDataDto = (
  syndicate: string,
): SyndicateData => ({
  syndicateDataId: 1,
  lineId: 123,
  type: syndicate,
  syndicateData: mockKiSyndicateData,
  updatedAt: new Date(),
})

export const mockEnergySyndicateDataDto: SyndicateData = {
  ...createMockSyndicateDataDto("ki"),
  syndicateData: mockEnergyKiSyndicateData,
}

export const mockSyndicateLine: SyndicateLine = {
  lineId: 123,
  contractId: "a3e85fc1-f4b0-476e-aa06-3faae3704405",
  sectionId: 456,
  nominatedSyndicateId: 1618,
  nominatedSyndicateShare: 0.5,
  lineSizeAmount: 100_000,
  lineSizePercentage: 0.1,
  lbs: "lbs",
  writtenPremium: 888_888,
  writtenPremiumCurrency: "USD",
  updatedAt: new Date(),
  eea: true,
  modelPrice: 150_000,
  technicalPrice: 120_000,
  syndicateData: createMockSyndicateDataDto("ki"),
}

export const mockEnergySyndicateLine: SyndicateLine = {
  ...mockSyndicateLine,
  syndicateData: mockEnergySyndicateDataDto,
}

export const mockContractSectionItem: ContractSectionItem = {
  sectionId: 456,
  contractHeaderId: 789,
  journeyConfigId: null,
  limitAmount: 2_000_000,
  limitAmountCurrency: "USD",
  excess: 100_000,
  aggregateRiskData: "AggregateRiskData1",
  cyberExposure: "Exposure1",
  cyberWarExposure: "WarExposure1",
  deductible: 50_000,
  yoa: 2022,
  updatedAt: new Date(),
  riskCodes: ["RC1", "RC2"],
  classOfBusiness: "BusinessClass1",
  quoteLineId: "1",
  pbqaId: "a3e85fc1-f4b0-476e-aa06-3faae3704405",
  lines: [mockSyndicateLine],
  brokerSettledIndicator: "FR",
  brokerSelectedIndustry: "Minerals",
  rarc: null,
}

export const mockContractHeaderItem: ContractHeaderItem = {
  aggregateClassOfBusiness: ["Class1", "Class2"],
  aggregateWrittenPremium: 1_000_000,
  brokerName: "BrokerName",
  brokeragePercentage: 10,
  brokingHouse: "BrokingHouse",
  conductRating: CONDUCT_RATING_LOW,
  contractHeaderId: 123,
  createdAt: new Date(),
  customerType: CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE,
  domicileCountry: "Country",
  expiryDate: new Date(),
  inceptionDate: new Date(),
  insured: "InsuredName",
  otherCommissions: 50_000,
  updatedAt: new Date(),
  versionId: 1,
}

export const mockContractResponse: ContractResponse = {
  contract: {
    contractId: "123",
    archived: false,
    uniqueMarketReference: "ABC",
    version: {
      versionId: 1,
      versionStatus: "created",
      contractId: "123",
      assignedTo: "1",
      createdAt: new Date(),
      completedAt: new Date(),
      quoteIds: [1, 2],
      externalId: "456",
    },
    archivedAt: null,
  },
  header: mockContractHeaderItem,
  sections: [mockContractSectionItem],
}

export const mockContractResponseWithFiles: ContractResponse = {
  ...mockContractResponse,
  files: [
    {
      id: "1",
      name: "schedule.xslx",
      type: "SOV",
      source: "PPL",
      status: "uploaded",
      uploadedToPartnerIngestionDate: true,
      updatedAt: new Date("2023-01-02T15:30:00Z"),
    },
    {
      id: "2",
      name: "slip.pdf",
      type: "PREMIUM_SPLIT_FILE",
      source: "WHITESPACE",
      status: "uploaded",
      uploadedToPartnerIngestionDate: false,
      updatedAt: new Date(),
    },
  ],
}

export const mockMe: Me = {
  user: {
    id: "e28180b4-2c32-4157-b4b4-ff0a488d5a95",
    email: "mat@mat.com",
    companyId: "mat inc",
  },
  supportedFlows: {
    pbqa: "ki",
    endorsements: true,
  },
}

export const mockPBQADocumentsResponse: Document[] = [
  {
    createdAt: "2024-06-23T00:00:00Z",
    id: "032d1360-cfcc-4f41-8140-f2172213d1b3",
    isSpreadsheet: false,
    mimetype: "application/pdf",
    name: "Willis_contract-B080117462L21-3.pdf",
    size: 49,
    updatedAt: "2024-06-23T17:21:59Z",
    url: "idonotexist",
  },
]

export const mkContract = ({
  writtenPremium = 1_000,
  premiumAllocation = 0.75,
  premiumAllocationPercent = 50,
  nominatedSyndicateId = 1618,
}: {
  writtenPremium?: number
  premiumAllocation?: number
  premiumAllocationPercent?: number
  nominatedSyndicateId?: number
} = {}): ContractResponse => ({
  ...mockContractResponse,
  sections: [
    {
      ...mockContractSectionItem,
      lines: [
        {
          ...mockSyndicateLine,
          writtenPremium,
          nominatedSyndicateId,
          syndicateData: {
            ...createMockSyndicateDataDto("ki"),
            syndicateData: {
              ...mockKiSyndicateData,
              referenceSplit: [
                {
                  ...mockReferenceSplitROW,
                  premiumAllocation,
                  premiumAllocationPercent,
                },
                mockReferenceSplitEEA,
              ],
            },
          },
        },
      ],
    },
  ],
})

export const mkEnergyContract = ({
  writtenPremium = 1_000,
  limitAmount = 1_000,
  premiumAllocation = 0.75,
  premiumAllocationPercent = 50,
  limitAllocation = 0.75,
  limitAllocationPercent = 50,
  groupClass = "energy_renewable",
}: {
  writtenPremium?: number
  limitAmount?: number
  premiumAllocation?: number
  premiumAllocationPercent?: number
  limitAllocation?: number
  limitAllocationPercent?: number
  groupClass?: EnergyGroupClassCode
} = {}): ContractResponse => ({
  ...mockContractResponse,
  sections: [
    {
      ...mockContractSectionItem,
      limitAmount,
      lines: [
        {
          ...mockSyndicateLine,
          writtenPremium,
          syndicateData: {
            ...mockEnergySyndicateDataDto,
            syndicateData: {
              ...mockEnergyKiSyndicateData,
              groupClass,
              referenceSplit: [
                {
                  ...mockReferenceSplitEnergy,
                  premiumAllocation,
                  premiumAllocationPercent,
                  limitAllocation,
                  limitAllocationPercent,
                  groupClass,
                },
              ],
            },
          },
        },
      ],
    },
  ],
})

export const mkContractWithSingleSection = ({
  writtenPremium = 1_000,
  premiumAllocation = 1_000,
  premiumAllocationCurrency = "USD",
  premiumAllocationPercent = 100,
}: {
  writtenPremium?: number
  premiumAllocation?: number
  premiumAllocationCurrency?: string
  premiumAllocationPercent?: number
} = {}): ContractResponse => ({
  ...mockContractResponse,
  sections: [
    {
      ...mockContractSectionItem,
      lines: [
        {
          ...mockSyndicateLine,
          writtenPremium,
          syndicateData: {
            ...createMockSyndicateDataDto("ki"),
            syndicateData: {
              ...mockKiSyndicateData,
              referenceSplit: [
                {
                  ...mockReferenceSplitROW,
                  premiumAllocation,
                  premiumAllocationCurrency,
                  premiumAllocationPercent,
                },
              ],
            },
          },
        },
      ],
    },
  ],
})

export const mockContractSectionItemWithMultipleLines: ContractSectionItem = {
  sectionId: 456,
  contractHeaderId: 789,
  journeyConfigId: null,
  limitAmount: 2_000_000,
  limitAmountCurrency: "USD",
  excess: 100_000,
  aggregateRiskData: "AggregateRiskData1",
  cyberExposure: "Exposure1",
  cyberWarExposure: "WarExposure1",
  deductible: 50_000,
  yoa: 2022,
  updatedAt: new Date(),
  riskCodes: ["RC1", "RC2"],
  classOfBusiness: "BusinessClass1",
  quoteLineId: "1",
  pbqaId: "a3e85fc1-f4b0-476e-aa06-3faae3704405",
  lines: [
    {
      ...mockSyndicateLine,
      nominatedSyndicateId: 1618,
      syndicateData: {
        ...createMockSyndicateDataDto("ki"),
        syndicateData: {
          ...mockKiSyndicateData,
          minorClass: MinorClassCode.ABORTED_BID_COSTS,
          subClass: SubClassCode.APARTMENTS_HIGH_RISE,
          referenceSplit: [
            {
              ...mockReferenceSplitROW,
              policyReference: "ABC123",
              premiumAllocation: 0.75,
              premiumAllocationCurrency: "USD",
              premiumAllocationPercent: 50,
            },
          ],
        },
      },
    },
    {
      ...mockSyndicateLine,
      nominatedSyndicateId: 1234,
      syndicateData: {
        ...createMockSyndicateDataDto("ki"),
        syndicateData: {
          ...mockKiSyndicateData,
          minorClass: MinorClassCode.ABORTED_BID_COSTS,
          subClass: SubClassCode.APARTMENTS_HIGH_RISE,
          referenceSplit: [
            {
              ...mockReferenceSplitROW,
              policyReference: "XYZ456",
              premiumAllocation: 0.75,
              premiumAllocationCurrency: "USD",
              premiumAllocationPercent: 50,
            },
          ],
        },
      },
    },
    {
      ...mockSyndicateLine,
      nominatedSyndicateId: 5678,
      syndicateData: {
        ...createMockSyndicateDataDto("ki"),
        syndicateData: {
          ...mockKiSyndicateData,
          minorClass: MinorClassCode.ABORTED_BID_COSTS,
          subClass: SubClassCode.APARTMENTS_HIGH_RISE,
          referenceSplit: [
            {
              ...mockReferenceSplitROW,
              policyReference: "DEF789",
              premiumAllocation: 0.75,
              premiumAllocationCurrency: "USD",
              premiumAllocationPercent: 50,
            },
          ],
        },
      },
    },
    {
      ...mockSyndicateLine,
      nominatedSyndicateId: 9012,
      syndicateData: {
        ...createMockSyndicateDataDto("ki"),
        syndicateData: {
          ...mockKiSyndicateData,
          minorClass: MinorClassCode.ABORTED_BID_COSTS,
          subClass: SubClassCode.APARTMENTS_HIGH_RISE,
          referenceSplit: [
            {
              ...mockReferenceSplitROW,
              policyReference: "GHI012",
              premiumAllocation: 0.75,
              premiumAllocationCurrency: "USD",
              premiumAllocationPercent: 50,
            },
          ],
        },
      },
    },
  ],
  brokerSettledIndicator: "FR",
  brokerSelectedIndustry: "Minerals",
  rarc: null,
}

export const mkMinorSubClasses: MinorSubClassResponse = {
  minor_classes: {
    "AD - ACCIDENT - DIRECT": [
      "60000 - OUTWARDS R/I",
      "90200 - BANKS / FI",
      "99000 - POLITICAL RISK",
      "99001 - CONTINGENCY / K & R",
      "99003 - BOND / CREDIT / SURETY",
      "99004 - MISCELLANEOUS ACCIDENT",
      "99014 - PERSONAL ACCIDENT (RISK)",
    ],
    "AR - ACCIDENT - RETRO": [
      "91143 - PLANT HIRE CONTRACTORS",
      "91146 - QUARRIES",
      "91220 - MISC",
      "95200 - PROPERTY ONLY",
      "96102 - CONSUMER FINANCE / BANKING",
    ],
    "LR - LIABILITY - RETRO": ["91126 - MACHINE TOOLS MANUFACTURER"],
  },
}

export const mkEnergyMinorSubClasses: MinorSubClassResponse = {
  minor_classes: {
    "EC - COST OF CONTROL": [
      "25000 - NETT ACCOUNT",
      "47200 - GENERAL COST OF CONTROL",
      "47201 - Onshore Control of Well",
      "47202 - Offshore Control of Well",
      "47810 - ENERGY WAR",
      "60000 - OUTWARDS R/I",
      "90793 - T.R.I.A.",
    ],
    "EN - Onshore Energy/Entertainers": [
      "25000 - NETT ACCOUNT",
      "47700 - GENERAL ONSHORE ENERGY",
      "47701 - ONSHORE OPERATING",
      "47702 - ONSHORE CONSTRUCTION",
      "60000 - OUTWARDS R/I",
      "90793 - T.R.I.A.",
    ],
    "EO - OFFSHORE ENERGY": [
      "25000 - NETT ACCOUNT",
      "47300 - GENERAL OFFSHORE ENERGY",
      "47301 - OFFSHORE OPERATING",
      "47302 - OFFSHORE CONSTRUCTION",
      "47303 - OFFSHORE CONTRACTORS",
      "47304 - OFFSHORE MOBILS/JACK-UP ETC",
      "47810 - ENERGY WAR",
      "60000 - OUTWARDS R/I",
      "90793 - T.R.I.A.",
    ],
    "ET - ENERGY TREATY": [
      "47500 - GENERAL ENERGY",
      "60000 - OUTWARDS R/I",
      "90793 - T.R.I.A.",
    ],
  },
}
