import { AxiosInstance, AxiosPromise } from "axios"
import type { User } from "../Users"

export const assignEndorsement = (
  client: AxiosInstance,
  endorsement_id: string,
  user_id: User["id"],
): AxiosPromise =>
  client.post(`/api/endorsements/${endorsement_id}/assign`, {
    owner_id: user_id,
  })

export const unassignEndorsement = (
  client: AxiosInstance,
  endorsement_id: string,
): AxiosPromise => client.delete(`/api/endorsements/${endorsement_id}/unassign`)
