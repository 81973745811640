import { AxiosInstance, AxiosPromise } from "axios"

export interface Umr {
  value: string
}

export const searchUmrUrl = (query: string): string =>
  query
    ? `/api/policies/search/umr?scope=pbqas&query=${query}`
    : `/api/policies/search/umr?scope=pbqas`

export const searchUmr = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<Umr[]> => client.get(searchUmrUrl(query))
