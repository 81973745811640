import { FC, ReactNode } from "react"

import { PBQASearchResult } from "@appia/api"

import {
  ArchiveIcon,
  CheckIcon,
  DotsVerticalIcon,
  IconMenu,
  MenuItem,
} from "@appia/ui-components"

const Menu: FC<{ children: ReactNode }> = ({ children }) => (
  <IconMenu
    buttonTextAccessible="Action menu"
    icon={() => <DotsVerticalIcon />}
  >
    {children}
  </IconMenu>
)

const PBQAEntryFormActionMenu: FC<{
  pbqa: PBQASearchResult
  onArchive: () => void
  onReview: () => void
}> = ({ pbqa, onArchive, onReview }) => {
  const archiveMenuItem = (
    <MenuItem
      onClick={onArchive}
      className="flex items-center justify-start gap-2"
    >
      <ArchiveIcon className="w-5 text-otto-grey-700" />
      <span>Archive</span>
    </MenuItem>
  )

  const reviewMenuItem = (
    <MenuItem
      onClick={onReview}
      className="flex items-center justify-start gap-2"
    >
      <CheckIcon className="w-5 text-otto-grey-700" />
      <span>Review</span>
    </MenuItem>
  )

  switch (pbqa.status) {
    case "archived":
    case "created":
    case "processing":
      return null
    case "failed":
    case "review_complete":
      return <Menu>{archiveMenuItem}</Menu>
    case "review_in_progress":
    case "review_required":
      return (
        <Menu>
          {reviewMenuItem}
          {archiveMenuItem}
        </Menu>
      )
    default:
      return null
  }
}

export default PBQAEntryFormActionMenu
