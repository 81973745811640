import { FC, ReactNode, useId } from "react"

import { ErrorMessage } from "@appia/ui-components"

import { FormField } from "@appia/form-data"

const InputWithError: FC<{
  className?: string
  errorTestId?: string
  formField: FormField<unknown, unknown>
  input: (ariaDescribedBy?: string) => ReactNode
  showFormFieldErrors: boolean
}> = ({ className, errorTestId, formField, input, showFormFieldErrors }) => {
  const baseId = useId()

  const errorId: string | undefined =
    formField.validated.valid === false && showFormFieldErrors
      ? baseId
      : undefined

  return (
    <div className={className}>
      {input(errorId)}

      <div role="alert">
        {formField.validated.valid === false && showFormFieldErrors && (
          <ErrorMessage
            id={errorId}
            data-testid={errorTestId}
            className="mt-1"
            message={formField.validated.error}
          />
        )}
      </div>
    </div>
  )
}

export default InputWithError
