import type { SVGComponent } from "./IconWrapper"

const BoltSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.81254 17.5L9.66671 11.625H6.62504C6.47226 11.625 6.36115 11.5556 6.29171 11.4167C6.22226 11.2778 6.22226 11.1458 6.29171 11.0208L11.3959 2.5H12.25L11.3959 8.35417H14.3959C14.5487 8.35417 14.6632 8.42361 14.7396 8.5625C14.816 8.70139 14.8195 8.83333 14.75 8.95833L9.66671 17.5H8.81254Z"
      fill="currentColor"
    />
  </svg>
)

export default BoltSVG
