import { FC, ReactNode } from "react"
import { Menu as HeadlessMenu } from "@headlessui/react"

import { ChevronDownIcon, ChevronUpIcon } from "./Icons"

import classNames from "classnames"

export type MenuProps = {
  buttonTextVisible: string
  buttonTextAccessible?: string
  children: ReactNode
  containerClassName?: string
}

export const Menu: FC<MenuProps> = ({
  buttonTextVisible,
  buttonTextAccessible,
  children,
  containerClassName,
}) => (
  <div className="relative">
    <HeadlessMenu>
      <HeadlessMenu.Button className="otto-focus-inset flex h-8 w-full items-center justify-between rounded border border-otto-grey-400 bg-white px-2 text-base">
        {({ open }) => (
          <>
            <span aria-hidden>{buttonTextVisible}</span>
            <span className="sr-only">
              {buttonTextAccessible || buttonTextVisible}
            </span>
            <span className="inline-block w-5 align-middle">
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </span>
          </>
        )}
      </HeadlessMenu.Button>

      <HeadlessMenu.Items
        className={classNames(
          "otto-focus-inset absolute top-[calc(100%+0.5rem)] left-0 z-10 w-full overflow-hidden rounded-md border border-otto-grey-400 bg-white shadow-lg",
          containerClassName,
        )}
        aria-orientation="vertical"
        as="ul"
      >
        {children}
      </HeadlessMenu.Items>
    </HeadlessMenu>
  </div>
)

export type IconMenuProps = {
  buttonTextAccessible?: string
  children: ReactNode
  containerClassName?: string
  icon: (open: boolean) => ReactNode
}

export const IconMenu: FC<IconMenuProps> = ({
  buttonTextAccessible,
  children,
  containerClassName,
  icon,
}) => (
  <div className="relative">
    <HeadlessMenu>
      <HeadlessMenu.Button
        className="otto-focus rounded"
        title={buttonTextAccessible}
      >
        {({ open }) => (
          <>
            <span className="sr-only">{buttonTextAccessible}</span>
            <span className="inline-block w-6 align-middle">{icon(open)}</span>
          </>
        )}
      </HeadlessMenu.Button>

      <HeadlessMenu.Items
        className={classNames(
          "otto-focus-inset absolute top-[calc(100%+0.5rem)] right-[calc(100%-2rem)] z-10 min-w-[10rem] overflow-hidden rounded-md border border-otto-grey-400 bg-white shadow-lg",
          containerClassName,
        )}
        aria-orientation="vertical"
        as="ul"
      >
        {children}
      </HeadlessMenu.Items>
    </HeadlessMenu>
  </div>
)

export interface MenuItemProps {
  children: ReactNode
  className?: string
  onClick: () => void
  title?: string
}

export const MenuItem: FC<MenuItemProps> = ({
  children,
  className,
  onClick,
  title,
}) => (
  <HeadlessMenu.Item>
    {({ active, disabled }) => (
      // `Menu.Item` adds key events but eslint doesn't know that
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <li
        role="menuitem"
        className={classNames(
          "cursor-pointer p-2 text-base",
          {
            "text-otto-grey-900": !active && !disabled,
            "bg-otto-pop-50 forced-colors:bg-SystemHighlight":
              active && !disabled,
            "cursor-not-allowed text-otto-grey-500": disabled,
          },
          className,
        )}
        title={title}
        onClick={onClick}
      >
        {children}
      </li>
    )}
  </HeadlessMenu.Item>
)
