import { FC } from "react"
import { Card, Checkbox } from "@appia/ui-components"
import { Syndicate } from "@appia/api"

interface SyndicateListProps {
  formId: string
  onCheckboxChange: (syndicateId: Syndicate["id"], isChecked: boolean) => void
  onSubmit: () => void
  selectedSyndicatesIds: Syndicate["id"][]
  syndicates: Syndicate[]
}

const SyndicateForm: FC<SyndicateListProps> = ({
  formId,
  onCheckboxChange,
  onSubmit,
  selectedSyndicatesIds,
  syndicates,
}) => (
  <form
    id={formId}
    onSubmit={e => {
      e.preventDefault()
      e.stopPropagation()
      onSubmit()
    }}
    noValidate
  >
    <fieldset>
      <legend>Select all syndicates on risk:</legend>

      {syndicates.map(syndicate => (
        <Card padding={2} className="mt-2 shadow-none" key={syndicate.id}>
          <label className="flex items-center gap-2">
            <Checkbox
              checked={selectedSyndicatesIds.includes(syndicate.id)}
              onChange={(checked: boolean) => {
                onCheckboxChange(syndicate.id, checked)
              }}
            />

            <span>
              {syndicate.name} ({syndicate.code})
            </span>
          </label>
        </Card>
      ))}
    </fieldset>
  </form>
)

export default SyndicateForm
