import { PBQASearchResult } from "@appia/api"

interface LinkDetails {
  label: string
  ariaLabel: string
  href: string
}

export function getPBQALinkDetails(pbqa: PBQASearchResult): LinkDetails | null {
  const forAssuredName = pbqa.assuredName ? `for ${pbqa.assuredName}` : ""

  switch (pbqa.status) {
    case "archived":
    case "created":
    case "failed":
    case "processing":
      return null
    case "review_required":
    case "review_in_progress":
    case "failed_extraction":
      return {
        label: "Review",
        ariaLabel: `Review PBQA slip ${forAssuredName}`,
        href: `/pbqa/review/${pbqa.id}`,
      }
    case "review_complete":
      return {
        label: "View details",
        ariaLabel: `View details ${forAssuredName}`,
        href: `/pbqa/view/${pbqa.id}`,
      }
    default:
      return null
  }
}
