import { AxiosInstance, AxiosPromise } from "axios"

export interface PBQAAssuredName {
  name: string
}

export const searchPBQAAssuredNamesUrl = (query: string): string =>
  query
    ? `/api/policies/search/assureds?scope=pbqas&query=${query}`
    : `/api/policies/search/assureds?scope=pbqas`

export const searchPBQAAssuredNames = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<PBQAAssuredName[]> =>
  client.get(searchPBQAAssuredNamesUrl(query))
