import { FC, useCallback, useEffect, useId, useState } from "react"

import { KiQuote, searchKiQuotes } from "@appia/api"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"
import usePageName from "src/contexts/PageNameContext"

import Filters from "./Filters"
import {
  KiQuoteSearchFilters,
  buildKiQuoteSearchQuery,
} from "ReviewPBQA/kiQuoteSearch"

import {
  orderedFields,
  prettyPrintKiQuoteField,
  renderKiQuoteField,
} from "../kiQuoteHelpers"
import EmptyTableIcon from "./EmptyTableIcon"
import UISearchBar from "./SearchBar"
import {
  TBody,
  THead,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from "../ResponsiveTable"

import { logButtonClick } from "src/amplitude"
import { useDebouncedCallback } from "use-debounce"

const SearchBar: FC<{
  checkedQuoteLineIds: KiQuote["quoteLineId"][]
  defaultSearchText: string
  mainQuoteLineId: KiQuote["quoteLineId"]
  onChangeChecked: (q: KiQuote, checked: boolean) => void
}> = ({
  checkedQuoteLineIds,
  defaultSearchText,
  mainQuoteLineId,
  onChangeChecked,
}) => {
  const pageName = usePageName()
  const { pbqaId } = usePBQASurvey()
  const apiClient = useApiClient()

  const [searchText, setSearchText] = useState<string>(defaultSearchText)
  const [searchFilters, setSearchFilters] = useState<
    Partial<KiQuoteSearchFilters>
  >({})
  const [searchResults, setSearchResults] = useState<KiQuote[]>([])

  const searchAndSetResults = useCallback(
    async (query: string): Promise<void> => {
      try {
        const { data: results } = await searchKiQuotes(apiClient, pbqaId, query)
        setSearchResults(results.filter(r => r.quoteLineId !== mainQuoteLineId))
      } catch (e) {
        if (e instanceof Error) {
          Sentry.captureException(e)
        }
      }
    },
    [apiClient, pbqaId, mainQuoteLineId],
  )

  const searchAndSetResultsDebounced = useDebouncedCallback(
    searchAndSetResults,
    600,
  )

  // Populate the results once to begin with
  useEffect(() => {
    searchAndSetResults(defaultSearchText)
  }, [searchAndSetResults, defaultSearchText])

  return (
    <UISearchBar
      checkedQuoteLineIds={checkedQuoteLineIds}
      filters={onSubmit => (
        <Filters
          filters={searchFilters}
          onReset={() => {
            logButtonClick({
              buttonName: "Reset",
              containerName: "Search filters",
              pageName,
            })

            setSearchFilters({})

            onSubmit()
          }}
          onSubmit={async updatedFilters => {
            logButtonClick({
              buttonName: "Submit",
              containerName: "Search filters",
              pageName,
            })

            setSearchFilters(updatedFilters)

            const query = buildKiQuoteSearchQuery(updatedFilters)
            setSearchText(query)

            await searchAndSetResults(query)

            onSubmit()
          }}
        />
      )}
      onChangeChecked={onChangeChecked}
      onSearch={async query => {
        setSearchText(query)

        // Reset the filters when the user types into the input
        setSearchFilters({})

        await searchAndSetResultsDebounced(query)
      }}
      searchText={searchText}
      searchResults={searchResults}
    />
  )
}

const QuoteTable: FC<{
  headingId: string
  quotes: KiQuote[]
}> = ({ headingId, quotes }) => (
  <TableWrapper>
    <Table aria-labelledby={headingId}>
      <THead>
        <tr>
          {orderedFields.map(field => (
            <Th key={field}>{prettyPrintKiQuoteField(field)}</Th>
          ))}
        </tr>
      </THead>

      <TBody>
        {quotes.map(quote => (
          <Tr
            key={quote.quoteLineId}
            className="sm:grid-cols-[auto,repeat(2,1fr)] md:grid-cols-[auto,repeat(3,1fr)]"
          >
            {orderedFields.map(field => (
              <Td key={field}>
                <span className="block font-normal lg:hidden">
                  {prettyPrintKiQuoteField(field)}
                </span>

                {renderKiQuoteField(field, quote)}
              </Td>
            ))}
          </Tr>
        ))}
      </TBody>
    </Table>
  </TableWrapper>
)

export interface ComparisonQuotesProps {
  defaultSearchText: string
  mainQuoteLineId: KiQuote["quoteLineId"]
  quotes: KiQuote[]
  setQuotes: (qs: KiQuote[]) => void
}

const ComparisonQuotes: FC<ComparisonQuotesProps> = ({
  defaultSearchText,
  mainQuoteLineId,
  quotes,
  setQuotes,
}) => {
  const headingId = useId()

  return (
    <section
      aria-labelledby={headingId}
      className="grid rounded-lg border border-otto-grey-300"
    >
      <h3
        id={headingId}
        className="border-b border-otto-grey-300 py-2 px-4 font-bold"
      >
        Quotes to compare with:
      </h3>

      <>
        <SearchBar
          checkedQuoteLineIds={quotes.map(q => q.quoteLineId)}
          defaultSearchText={defaultSearchText}
          mainQuoteLineId={mainQuoteLineId}
          onChangeChecked={(quote, checked) => {
            if (
              checked &&
              !quotes.map(q => q.quoteLineId).includes(quote.quoteLineId) &&
              quote
            ) {
              setQuotes([...quotes, quote])
            } else if (!checked) {
              setQuotes(quotes.filter(q => q.quoteLineId !== quote.quoteLineId))
            }
          }}
        />

        {quotes.length > 0 ? (
          <div className="overflow-hidden rounded-b-lg">
            <QuoteTable headingId={headingId} quotes={quotes} />
          </div>
        ) : (
          <div className="mx-auto py-4 text-center">
            <div className="mb-2 inline-block">
              <EmptyTableIcon />
            </div>

            <p className="mb-1 font-bold">
              No quotes were selected to compare.
            </p>

            <p>Try adjusting your search to find what you’re looking for.</p>
          </div>
        )}
      </>
    </section>
  )
}

export default ComparisonQuotes
