import { FC } from "react"
import { Button, Card } from "@appia/ui-components"

interface FooterProps {
  onSave: () => void
  onBack: () => void
  onSubmit: () => void
}
const Footer: FC<FooterProps> = ({ onSave, onBack, onSubmit }): JSX.Element => {
  return (
    <Card className=" mt-6 flex flex-row gap-2">
      <Button
        key="save"
        label="Save"
        theme="night"
        style="text"
        className="!inline-block"
        onClick={onSave}
      />
      <Button
        key="back"
        label="Back"
        theme="night"
        style="outlined"
        stretch="space-between"
        className="!inline-block"
        onClick={onBack}
      />
      <Button
        key="finish"
        label="Finish"
        theme="pop"
        style="filled"
        stretch="space-between"
        className="!inline-block"
        onClick={onSubmit}
      />
    </Card>
  )
}

export default Footer
