import { FC, useState } from "react"

import {
  KiQuote,
  Overlining,
  OverliningContext,
  PBQAAnswerReason,
  PBQAOverliningAnswer,
} from "@appia/api"
import { useGetOverliningContext } from "src/swr"
import * as RD from "@appia/remote-data"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"
import usePageName from "src/contexts/PageNameContext"

import {
  logButtonClick,
  logPBQANoteAdd,
  logPBQANoteChange,
  logPBQANoteRemove,
} from "src/amplitude"

import { Button } from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"

import MainQuote from "./MainQuote"
import ComparisonQuotes from "./ComparisonQuotes"
import Totals, { ClassCapacity } from "./Totals"
import Reasons from "./Reasons"

export const mkClassCapacity = (
  mainQuote: KiQuote,
  comparisonQuotes: KiQuote[],
  overliningContextReq: RD.RemoteData<Error, OverliningContext>,
): ClassCapacity => {
  if (
    RD.isNotAsked(overliningContextReq) ||
    RD.isLoading(overliningContextReq)
  ) {
    return { type: "loading" }
  }

  if (RD.isFailure(overliningContextReq)) {
    return { type: "error", msg: "Failed to load class capacities" }
  }

  if (mainQuote.groupClass === null) {
    return { type: "error", msg: "Main quote has no class of business" }
  }

  const capacity = overliningContextReq.data.capacities[mainQuote.groupClass]
  if (capacity === undefined) {
    return {
      type: "error",
      msg: `Missing max capacity for class of business "${mainQuote.groupClass}"`,
    }
  }

  const hasMixedClasses: boolean = comparisonQuotes.some(
    q => q.groupClass !== mainQuote.groupClass,
  )

  if (hasMixedClasses) {
    return { type: "warning", capacity }
  }

  return { type: "success", capacity }
}

export interface OverliningModalProps {
  isOpen: boolean
  mainQuote: KiQuote
  onClose: () => void
  onConfirm: (a: PBQAOverliningAnswer) => void
  answer: PBQAOverliningAnswer
}

const OverliningModal: FC<OverliningModalProps> = ({
  isOpen,
  mainQuote,
  onClose,
  onConfirm,
  answer,
}) => {
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()
  const pageName = usePageName()

  const { questionId } = answer

  const [comparisonQuotes, setcomparisonQuotes] = useState<KiQuote[]>(
    answer.answer?.selectedQuotes || [],
  )

  const { request: overliningContextReq } = useGetOverliningContext(pbqaId)

  // We use the class capacity from the main quote to work out whether we're
  // within capacity or not
  const classCapacity = mkClassCapacity(
    mainQuote,
    comparisonQuotes,
    overliningContextReq,
  )

  // The overall exposure is the sum of all the quotes' capacities
  const exposure: number = comparisonQuotes.reduce(
    (acc, q) => acc + (q.capacity || 0),
    mainQuote.capacity || 0,
  )

  // We are within capacity if the exposure is less than or equal to the class
  // capacity
  const withinCapacity: boolean | null =
    classCapacity.type === "error" || classCapacity.type === "loading"
      ? null
      : exposure <= classCapacity.capacity

  const [updatedNote, setUpdatedNote] = useState<string | null>(answer.note)
  const [updatedReasons, setUpdatedReasons] = useState<PBQAAnswerReason[]>(
    answer.reasons || [],
  )

  const [showNoteErrors, setShowNoteErrors] = useState<boolean>(false)

  return (
    <ModalTemplate
      className="sm:max-w-[calc(100%-4rem)] lg:w-full lg:max-w-[min(80rem,calc(100%-4rem))]"
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Is Ki's exposure to any one risk / assured within Ki's maximum risk line for the given class?"
      content={
        <div className="grid gap-4 pb-2">
          <MainQuote quote={mainQuote} />

          <ComparisonQuotes
            defaultSearchText={mainQuote.assuredName || ""}
            mainQuoteLineId={mainQuote.quoteLineId}
            quotes={comparisonQuotes}
            setQuotes={setcomparisonQuotes}
          />

          <Totals
            classCapacity={classCapacity}
            exposure={exposure}
            withinCapacity={withinCapacity}
          />

          {withinCapacity === false && (
            <Reasons
              noteAndReasons={{ note: updatedNote, reasons: updatedReasons }}
              onChange={({ note, reasons }) => {
                setUpdatedNote(note)
                setUpdatedReasons(reasons || [])
              }}
              showNoteErrors={showNoteErrors}
            />
          )}
        </div>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="cancel"
          theme="night"
          style="outlined"
          label="Cancel"
          onClick={() => {
            logButtonClick({
              buttonName: "Cancel",
              containerName: "Overlining modal",
              pageName,
            })

            onClose()
          }}
        />,

        <Button
          key="confirm"
          theme="pop"
          style="filled"
          label="Confirm"
          onClick={() => {
            logButtonClick({
              buttonName: "Confirm",
              containerName: "Overlining modal",
              pageName,
            })

            setShowNoteErrors(false)

            // For some reason we couldn't run the overlining check, so we
            // remain in the unanswered state
            if (withinCapacity === null) {
              onConfirm({
                ...answer,
                answer: null,
                accepted: null,
                note: null,
                reasons: [],
              })
            }

            const overliningData: Overlining = {
              mainQuote: mainQuote,
              computedCapacity: exposure,
              selectedQuotes: comparisonQuotes,
              overlined: !withinCapacity,
            }

            const updatedAnswer: PBQAOverliningAnswer = {
              ...answer,
              answer: overliningData,
            }

            const hadNoteBefore = (answer.reasons || []).length > 0

            // If the quotes are within capacity there's no need to write a note
            if (withinCapacity) {
              onConfirm({
                ...updatedAnswer,
                accepted: true,
                note: null,
                reasons: [],
              })

              if (hadNoteBefore) {
                logPBQANoteRemove({ pbqaId, surveyId, questionId })
              }

              return
            }

            const otherSelected = updatedReasons.includes("other")

            // If the quotes are not within capacity the user must add a reason
            if (!withinCapacity && updatedReasons.length === 0) {
              setShowNoteErrors(true)
              return
            }

            // If the user selects `Other` they must also write a note
            if (
              otherSelected &&
              (updatedNote === null || updatedNote.length === 0)
            ) {
              setShowNoteErrors(true)
              return
            }

            if (!hadNoteBefore) {
              // There wasn't a note/reason before, so a new one has been added
              logPBQANoteAdd({ pbqaId, surveyId, questionId })
            } else {
              // The existing note/reason has been updated
              logPBQANoteChange({ pbqaId, surveyId, questionId })
            }

            if (otherSelected) {
              onConfirm({
                ...updatedAnswer,
                accepted: false,
                note: updatedNote,
                reasons: updatedReasons,
              })
            } else {
              onConfirm({
                ...updatedAnswer,
                accepted: false,
                note: null,
                reasons: updatedReasons,
              })
            }

            return
          }}
        />,
      ]}
    />
  )
}

export default OverliningModal
