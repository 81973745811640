import { FC, ReactNode } from "react"
import classNames from "classnames"

/*
 * Using the components in this file we can create tables which reflow into
 * block layouts on smaller screens, as an alternative to simply allowing the
 * table to scroll horizontally.
 *
 * Unfortunately setting `display: block` (or `grid`, or anything else) means
 * screenreaders will no longer announce the table as a table. This is why we
 * have to add the seemingly redundant `role` attributes that eslint complains
 * about.
 *
 * Any consumers of these components also need to ensure that the cells remain
 * properly labelled for screenreaders during the block layout, for example by
 * including a copy of the column header inside each cell.
 *
 * H/T https://adrianroselli.com/2017/11/a-responsive-accessible-table.html
 */

export const TableWrapper: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={classNames("otto-focus-inset overflow-auto", className)}
    // Make the section programmatically focusable so that keyboard users
    // can scroll the table on narrow screens
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
  >
    {children}
  </div>
)

export const Table: FC<{ children: ReactNode; "aria-labelledby": string }> = ({
  children,
  "aria-labelledby": ariaLabelledBy,
}) => (
  <table
    role="table"
    aria-labelledby={ariaLabelledBy}
    className="block w-full lg:table"
  >
    {children}
  </table>
)

export const THead: FC<{ children: ReactNode }> = ({ children }) => (
  // eslint-disable-next-line jsx-a11y/no-redundant-roles
  <thead role="rowgroup" className="hidden lg:table-header-group">
    {children}
  </thead>
)

export const TBody: FC<{ children: ReactNode }> = ({ children }) => (
  // eslint-disable-next-line jsx-a11y/no-redundant-roles
  <tbody
    role="rowgroup"
    className="block divide-y divide-solid lg:table-row-group"
  >
    {children}
  </tbody>
)

export const Tr: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <tr
    role="row"
    className={classNames(
      "grid gap-4 border-otto-grey-300 px-4 py-2 lg:table-row lg:p-0",
      className,
    )}
  >
    {children}
  </tr>
)

export const Th: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <th
    role="columnheader"
    className={classNames(
      "border-b border-otto-grey-300 bg-otto-grey-200 py-2 pr-4 align-middle first-of-type:pl-4 lg:whitespace-nowrap",
      className,
    )}
  >
    {children}
  </th>
)

export const Td: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <td
    role="cell"
    className={classNames(
      "block align-middle font-bold lg:table-cell lg:whitespace-nowrap lg:py-2 lg:pr-4 lg:font-normal lg:first-of-type:pl-4",
      className,
    )}
  >
    {children}
  </td>
)
