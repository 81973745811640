import { FC, useState } from "react"

import { Button } from "@appia/ui-components"

import {
  Endorsement,
  Policy,
  blockEndorsement,
  updateEndorsementPolicy,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"

import { logButtonClick } from "src/amplitude"

const AwaitingBritCTA: FC<{
  endorsementId: Endorsement["id"]
  onFailure: () => void
  onSuccess: () => void
  policyId: Policy["policyId"] | null
}> = ({ endorsementId, onFailure, onSuccess, policyId }) => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const [blockAndUpdateReq, setBlockAndUpdateReq] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  return (
    <Button
      label="Awaiting Brit"
      disabled={!policyId}
      onClick={async () => {
        if (!policyId) {
          return
        }

        logButtonClick({
          pageName,
          buttonName: "Awaiting Brit",
          containerName: "Header",
        })

        setBlockAndUpdateReq(RD.Loading)

        try {
          blockEndorsement(apiClient, endorsementId)
          updateEndorsementPolicy(apiClient, endorsementId, { policyId })

          setBlockAndUpdateReq(RD.Success(null))
          onSuccess()
        } catch (e) {
          if (e instanceof Error) {
            Sentry.captureException(e)
            setBlockAndUpdateReq(RD.Failure(e))
            onFailure()
          }
        }
      }}
      style="filled"
      theme="night"
      size="small"
      isLoading={RD.isLoading(blockAndUpdateReq)}
    />
  )
}

export default AwaitingBritCTA
