import { FC, useEffect, useId, useState } from "react"

import { Document, Policy } from "@appia/api"
import * as RD from "@appia/remote-data"
import { useGetPolicyDocuments } from "src/swr"
import EmptySelectionIcon from "./EmptySelectionIcon"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import { Button } from "@appia/ui-components"

import { DocumentContent, DocumentTabs, MessageWrapper } from "./TabLayout"

import { compareByCreatedAt } from "src/utils/documents"

const NoPolicySelectedMessage: FC<{ onUploadFinalSlip?: () => void }> = ({
  onUploadFinalSlip,
}) => (
  <MessageWrapper>
    <span className="mb-2 inline-block">
      <EmptySelectionIcon />
    </span>

    <p className="font-bold">Nothing is selected</p>
    <p>
      Please use the search bar above to find the correct policy for this
      endorsement.
    </p>

    {onUploadFinalSlip && (
      <>
        <hr className="mt-10" />
        <p className="mx-auto -mt-3.5 bg-white px-2"> or </p>
        <p className="mt-6 font-bold">
          Can&rsquo;t find the policy you are looking for?
        </p>
        <p>You can upload a final slip to create a new policy in Otto.</p>
        <Button
          className="mt-6"
          style="outlined"
          label="Upload final slip"
          theme="night"
          onClick={onUploadFinalSlip}
        />
      </>
    )}
  </MessageWrapper>
)

export const PolicyDocumentPanel: FC<{
  pbqaDocuments: Record<string, Document[]>
  endorsementDocuments: Record<string, Document[]>
  onUploadFinalSlip?: () => void
}> = ({ pbqaDocuments, endorsementDocuments, onUploadFinalSlip }) => {
  const documents = [
    ...Object.values(pbqaDocuments).flat(),
    ...Object.values(endorsementDocuments).flat(),
  ].sort(compareByCreatedAt)

  const [activeDocumentId, setActiveDocumentId] = useState<
    Document["id"] | null
  >(null)

  useEffect(() => {
    if (documents.length > 0 && activeDocumentId === null) {
      setActiveDocumentId(documents[0].id)
    }
  }, [documents, activeDocumentId])

  const emptyStateTabId = useId()

  const title = "Policy documents"

  if (documents.length === 0) {
    return (
      <DocumentTabs
        title={title}
        activeTabId={emptyStateTabId}
        setActiveTabId={() => {
          return
        }}
        tabTriggers={[{ label: "No file is selected", value: emptyStateTabId }]}
        tabPanels={[
          {
            value: emptyStateTabId,
            content: (
              <NoPolicySelectedMessage onUploadFinalSlip={onUploadFinalSlip} />
            ),
          },
        ]}
      />
    )
  }

  return (
    <DocumentTabs
      title={title}
      activeTabId={activeDocumentId}
      setActiveTabId={setActiveDocumentId}
      tabTriggers={documents.map(({ id, name }) => ({
        label: name,
        value: id,
      }))}
      tabPanels={documents.map(document => ({
        value: document.id,
        content: <DocumentContent document={document} />,
      }))}
    />
  )
}

const PolicyDocuments: FC<{
  policy: Policy | null
  onUploadFinalSlip: () => void
}> = ({ policy, onUploadFinalSlip }) => {
  const { request: documentsRequest } = useGetPolicyDocuments(
    policy?.policyId ?? null,
  )

  if (!policy?.policyId) {
    return (
      <PolicyDocumentPanel
        pbqaDocuments={{}}
        endorsementDocuments={{}}
        onUploadFinalSlip={onUploadFinalSlip}
      />
    )
  }

  return RD.match(
    documentsRequest,
    <Loading className="mt-8" />,
    <Loading className="mt-8" />,

    ({ pbqaDocs, endorsementDocs }) => (
      <PolicyDocumentPanel
        pbqaDocuments={pbqaDocs}
        endorsementDocuments={endorsementDocs}
        onUploadFinalSlip={onUploadFinalSlip}
      />
    ),

    error => <ErrorMessage message="Something went wrong" error={error} />,
  )
}

export default PolicyDocuments
