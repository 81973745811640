import { FC } from "react"
import { Button, Card } from "@appia/ui-components"

interface EndorsementUploadFooterProps {
  onCancel: () => void
  onNext: () => void
}
const EndorsementUploadFooter: FC<EndorsementUploadFooterProps> = ({
  onCancel,
  onNext,
}): JSX.Element => {
  return (
    <Card className=" mt-6 flex flex-row gap-2">
      <Button
        key="cancel"
        label="Cancel"
        theme="night"
        style="outlined"
        stretch="space-between"
        className="!inline-block"
        onClick={onCancel}
      />
      <Button
        key="next"
        label="Next"
        theme="pop"
        style="filled"
        stretch="space-between"
        className="!inline-block"
        onClick={onNext}
      />
    </Card>
  )
}

export default EndorsementUploadFooter
