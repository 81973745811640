const NUMBER_FORMAT = new Intl.NumberFormat([], {
  notation: "compact",
  compactDisplay: "short",
})

const compact = (value: number): string => {
  return NUMBER_FORMAT.format(value)
}

export const formatAttachmentPoint = ({
  limitAmount,
  excess,
}: {
  limitAmount: number | null
  excess: number | null
}): string => {
  if (!limitAmount) {
    return "---"
  }

  if (!excess) {
    return `Primary ${compact(limitAmount)}`
  }

  return `${compact(limitAmount)} xs ${compact(excess)}`
}
