import { FC, useId, useState } from "react"
import classNames from "classnames"

import {
  KiQuote,
  PBQAOverliningAnswer,
  PBQAOverliningQuestion,
} from "@appia/api"

import { Button, ErrorMessage, FactCheckIcon } from "@appia/ui-components"

import Group from "../../components/Group"
import AddNoteButton from "../../components/AddNoteButton"

import OverliningModal from "./OverliningModal"
import WithinCapacityPill from "./OverliningModal/WithinCapacityPill"

import { NoteState, requiresNote } from "ReviewPBQA/state"

import usePageName from "src/contexts/PageNameContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import { logPBQAOverliningModalOpened } from "src/amplitude"

const OverliningQuestion: FC<{
  answer: PBQAOverliningAnswer
  isActive: boolean
  onChangeAnswer: (a: PBQAOverliningAnswer) => void
  question: PBQAOverliningQuestion
  quote: KiQuote | null
  showFieldErrors: boolean
}> = ({
  answer,
  isActive,
  onChangeAnswer,
  question,
  quote,
  showFieldErrors,
}) => {
  const pageName = usePageName()
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  const legendId = useId()

  const showNoteButton = answer?.accepted === false
  const errorState: NoteState = requiresNote(answer?.accepted, answer?.reasons)

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <Group
      aria-labelledby={legendId}
      className="flex flex-col items-stretch border-b border-otto-grey-300 lg:flex-row"
    >
      <div
        className={classNames(
          "grid w-full grid-cols-1 justify-between p-2",
          isActive ? "bg-ki-dark" : "bg-ki-light",
        )}
      >
        <legend className="mb-2" id={legendId}>
          {question.text}
        </legend>

        <Button
          label="Compare quotes"
          style="filled"
          theme="night"
          size="small"
          onClick={() => {
            logPBQAOverliningModalOpened({ pbqaId, surveyId })

            setModalOpen(true)
          }}
          icon={{ position: "left", icon: <FactCheckIcon /> }}
          disabled={quote === null}
        />
      </div>

      <div className="w-full flex-shrink-0 border-t border-otto-grey-300 bg-white p-2 text-center lg:w-32 lg:border-l lg:border-t-0">
        <span className="font-bold">Within capacity</span>

        <div className="mx-auto w-fit pt-2">
          <WithinCapacityPill
            withinCapacity={
              answer?.answer?.overlined === undefined
                ? null
                : !answer.answer.overlined
            }
          />
        </div>

        {quote !== null && showNoteButton && (
          <AddNoteButton
            pageName={pageName}
            questionId={question.id}
            reasons={answer?.reasons ?? []}
            label={question.text}
            onClick={() => {
              logPBQAOverliningModalOpened({ pbqaId, surveyId })

              setModalOpen(true)
            }}
          />
        )}

        {showFieldErrors &&
          (errorState === "notAccepted" ? (
            <ErrorMessage message="Required step" className="mt-1" />
          ) : errorState === "missingNote" ? (
            <ErrorMessage message="Add a note" className="mt-1" />
          ) : null)}
      </div>

      {quote !== null && modalOpen && (
        <OverliningModal
          isOpen={modalOpen}
          mainQuote={quote}
          onClose={() => setModalOpen(false)}
          onConfirm={updatedAnswer => {
            onChangeAnswer(updatedAnswer)
            setModalOpen(false)
          }}
          answer={answer}
        />
      )}
    </Group>
  )
}

export default OverliningQuestion
