import type { Document } from "../Documents"

export interface PolicyDocuments {
  policyId: string
  // Mapping from PBQA ID to docs
  pbqaDocs: Record<string, Document[]>
  // Mapping from endorsement ID to docs
  endorsementDocs: Record<string, Document[]>
}

export const getPolicyDocumentsUrl = (policy_id: string): string =>
  `/api/policies/${policy_id}/documents`
