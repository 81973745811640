import { FC } from "react"

const NoResultsFound: FC = () => (
  <svg
    className="mx-auto max-w-[25rem]"
    viewBox="0 0 434 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          d="M345.109 77.5791h-65.371c-2.61 0-4.726 2.1157-4.726 4.7256v92.9373c0 2.61 2.116 4.726 4.726 4.726h65.371c2.61 0 4.726-2.116 4.726-4.726V82.3047c0-2.6099-2.116-4.7256-4.726-4.7256Z"
          fill="#BFF8FD"
        />
      </g>
      <g filter="url(#c)">
        <path
          d="M301.904 79.5484c16.747 0 30.323-13.576 30.323-30.3228s-13.576-30.3228-30.323-30.3228c-16.746 0-30.322 13.576-30.322 30.3228s13.576 30.3228 30.322 30.3228Z"
          fill="#E5FCFE"
        />
      </g>
      <path
        d="M277.489 49.2255c11.092 0 20.084-8.9919 20.084-20.084 0-11.092-8.992-20.08388-20.084-20.08388-11.092 0-20.084 8.99188-20.084 20.08388 0 11.0921 8.992 20.084 20.084 20.084Z"
        fill="#E5FCFE"
      />
      <g filter="url(#d)">
        <path
          d="M375.432 39.7739h-65.371c-2.61 0-4.726 2.1158-4.726 4.7257v92.9374c0 2.61 2.116 4.726 4.726 4.726h65.371c2.61 0 4.725-2.116 4.725-4.726V44.4996c0-2.6099-2.115-4.7257-4.725-4.7257Z"
          fill="#fff"
        />
      </g>
      <path
        d="m399.172 179.458-.276.275c-1.053 1.054-1.053 2.762 0 3.815l29.45 29.45c1.054 1.054 2.762 1.054 3.815 0l.276-.276c1.053-1.053 1.053-2.761 0-3.815l-29.45-29.449c-1.054-1.054-2.762-1.054-3.815 0Z"
        fill="#01788B"
      />
      <path
        d="M393.547 21.146h-65.372c-2.61 0-4.725 2.1157-4.725 4.7256v92.9374c0 2.61 2.115 4.726 4.725 4.726h65.372c2.609 0 4.725-2.116 4.725-4.726V25.8716c0-2.6099-2.116-4.7256-4.725-4.7256Z"
        fill="#EDEEEF"
      />
      <path
        d="M100.672 117.629c10.374 0 18.784-8.41 18.784-18.7846 0-10.3743-8.41-18.7843-18.784-18.7843-10.3747 0-18.7848 8.41-18.7848 18.7843 0 10.3746 8.4101 18.7846 18.7848 18.7846Z"
        fill="#E5FCFE"
      />
      <path
        d="M220.001 23.6284h-44.35c-2.76 0-4.997 2.2374-4.997 4.9974v23.4785c0 2.7599 2.237 4.9973 4.997 4.9973h44.35c2.76 0 4.998-2.2374 4.998-4.9973V28.6258c0-2.76-2.238-4.9974-4.998-4.9974Z"
        fill="#01788B"
      />
      <path
        d="M297.686 289.051c19.03 0 34.457-15.428 34.457-34.458 0-19.031-15.427-34.458-34.457-34.458-19.031 0-34.458 15.427-34.458 34.458 0 19.03 15.427 34.458 34.458 34.458Z"
        fill="#E5FCFE"
      />
      <path
        d="M272.877 299.29c10.33 0 18.705-8.375 18.705-18.705 0-10.331-8.375-18.706-18.705-18.706-10.331 0-18.706 8.375-18.706 18.706 0 10.33 8.375 18.705 18.706 18.705Z"
        fill="#E5FCFE"
      />
      <g filter="url(#e)">
        <path
          d="M411.914 0h-54.877c-1.253 0-2.455.49788-3.341 1.38411-.887.88622-1.384 2.0882-1.384 3.34152V97.663c0 1.2533.497 2.455 1.384 3.342.886.886 2.088 1.384 3.341 1.384h65.371c1.254 0 2.456-.498 3.342-1.384.886-.887 1.384-2.0887 1.384-3.342V15.2205L411.914 0Z"
          fill="#BFF8FD"
        />
      </g>
      <g filter="url(#f)">
        <path
          d="M395.921 200.252c13.126 0 23.766-10.64 23.766-23.766 0-13.125-10.64-23.766-23.766-23.766-13.125 0-23.766 10.641-23.766 23.766 0 13.126 10.641 23.766 23.766 23.766Z"
          fill="#01ACC0"
        />
      </g>
      <path
        d="M290.484 174.061v2.362h-2.363"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M283.79 176.423h-41.153"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4.64 4.64"
      />
      <path
        d="M240.471 176.423h-2.362v-2.362"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.109 169.335v-16.54"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="5.06 5.06"
      />
      <path
        d="M238.109 150.433v-2.363h2.362"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M244.803 148.07h41.153"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4.64 4.64"
      />
      <path
        d="M288.121 148.07h2.363v2.363"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.485 155.158v16.54"
        stroke="#00E1F5"
        strokeWidth="1.26547"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="5.06 5.06"
      />
      <path
        d="M71.3961 247.978H4.72539c-2.6099 0-4.7256341 2.115-4.7256341 4.725v47.572c0 2.609 2.1157341 4.725 4.7256341 4.725H71.3961c2.6099 0 4.7257-2.116 4.7257-4.725v-47.572c0-2.61-2.1158-4.725-4.7257-4.725Z"
        fill="#EDEEEF"
      />
      <g filter="url(#g)">
        <path
          d="M66.0128 167.756 49.674 151.811c-.8861-.885-2.0872-1.382-3.3395-1.382H20.7373c-1.2533 0-2.4553.498-3.3415 1.384-.8862.886-1.3841 2.088-1.3841 3.341v74.035c0 1.254.4979 2.456 1.3841 3.342.8862.886 2.0882 1.384 3.3415 1.384H131.79c1.253 0 2.455-.498 3.341-1.384.886-.886 1.384-2.088 1.384-3.342V174.23c0-1.248-.494-2.446-1.374-3.332-.88-.885-2.075-1.386-3.324-1.393l-62.4529-.367c-.6221.002-1.2384-.12-1.8135-.357s-1.0977-.585-1.5378-1.025Z"
          fill="#01ACC0"
        />
      </g>
      <path
        d="M105.755 68.1279h109.513c3.655 0 7.161 1.4522 9.746 4.037 2.585 2.5848 4.037 6.0906 4.037 9.7461v13.4247c0 3.6555-1.452 7.1613-4.037 9.7463s-6.091 4.037-9.746 4.037h-73.59c-3.656 0-7.162 1.452-9.746 4.037-2.585 2.585-4.037 6.09-4.037 9.746v67.502c0 3.655 1.452 7.161 4.037 9.746 2.584 2.585 6.09 4.037 9.746 4.037h212.185c3.655 0 7.161 1.452 9.746 4.037 2.584 2.584 4.037 6.09 4.037 9.746v17.552"
        stroke="#EDEEEF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10.49 10.49"
      />
      <path
        d="M367.646 240.417v4.922"
        stroke="#1C2B3D"
        strokeWidth="1.68729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m197.827 43.9087 26.156-18.2961c-.466-.6136-1.068-1.1107-1.759-1.4524-.691-.3416-1.452-.5185-2.222-.5166h-44.35c-.77-.0025-1.531.1741-2.221.5158-.69.3418-1.292.8392-1.757 1.4532l26.153 18.2961Z"
        fill="#01ACC0"
      />
      <path
        d="m38.0609 279.009 37.3876-28.747c-.4187-.702-1.0121-1.283-1.7223-1.687-.7102-.403-1.5129-.616-2.3299-.617H4.72553c-.81629.002-1.61823.215-2.32769.618-.70946.404-1.30225.985-1.720594 1.686L38.0609 279.009Z"
        fill="#DDDFE2"
      />
      <g filter="url(#h)">
        <path
          d="m65.4285 70.0882-.3509-.3509c-1.6716-1.6717-4.382-1.6717-6.0537 0L11.5548 117.206c-1.67171 1.672-1.67171 4.382 0 6.054l.3508.351c1.6717 1.672 4.3821 1.672 6.0538 0l47.4691-47.4691c1.6717-1.6717 1.6717-4.382 0-6.0537Z"
          fill="#01788B"
        />
        <path
          d="M70.1282 102.255c20.5529 0 37.2138-16.6616 37.2138-37.2145S90.6811 27.8262 70.1282 27.8262c-20.553 0-37.2144 16.6614-37.2144 37.2143s16.6614 37.2145 37.2144 37.2145Z"
          fill="#EDEEEF"
        />
      </g>
      <path
        d="M416.895 87.8179v3.938h-3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M406.183 91.7559h-23.706"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="7.26 7.26"
      />
      <path
        d="M379.09 91.7559h-3.938v-3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M375.152 81.7811v-9.0575"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.47 6.47"
      />
      <path
        d="M375.152 69.7032v-3.9381h3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M385.864 65.7651h23.706"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="7.26 7.26"
      />
      <path
        d="M412.957 65.7651h3.938v3.9381"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M416.895 75.7402v9.0575"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.47 6.47"
      />
      <path
        d="M401.931 19.6904v3.9381h-3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M391.534 23.6284h-22.605"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.92 6.92"
      />
      <path
        d="M365.7 23.6285h-3.938v-3.9381M361.762 18.5088v-3.938h3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M372.159 14.5708h22.604"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.92 6.92"
      />
      <path
        d="M397.993 14.5708h3.938v3.938"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M411.775 0v15.3583h15.359v-.1378L411.909 0h-.134Z"
        fill="#00E1F5"
      />
      <path
        d="M153.244 179.968H43.2078c-.8908 0-1.7635.251-2.5174.726-.7539.474-1.3583 1.152-1.7436 1.956l-22.9429 47.851c.2831.983.8776 1.848 1.6943 2.464.8166.616 1.8113.951 2.8344.954H131.585c.413-.027.824-.085 1.229-.174.714-.138 1.385-.444 1.958-.894.572-.449 1.029-1.028 1.334-1.689l21.375-44.425c.345-.718.502-1.512.458-2.308-.045-.796-.29-1.567-.713-2.243-.423-.676-1.009-1.233-1.706-1.621-.696-.388-1.479-.593-2.276-.597Z"
        fill="#01788B"
      />
      <g filter="url(#i)">
        <path
          d="m354.454 227.673-7.739-8.38c-.442-.479-.978-.862-1.575-1.123-.598-.262-1.242-.397-1.894-.398h-23.03c-1.253 0-2.455.498-3.341 1.385-.886.886-1.384 2.088-1.384 3.341v65.765c0 1.253.498 2.455 1.384 3.342.886.886 2.088 1.384 3.341 1.384h89.197c1.253 0 2.455-.498 3.341-1.384.886-.887 1.384-2.089 1.384-3.342v-54.345c0-1.253-.498-2.455-1.384-3.341-.886-.886-2.088-1.384-3.341-1.384h-51.49c-.652-.001-1.297-.136-1.894-.397-.597-.262-1.133-.644-1.575-1.123Z"
          fill="#BFF8FD"
        />
      </g>
      <path
        d="M320.216 292.989h89.197c1.253 0 2.455-.498 3.341-1.384.886-.886 1.384-2.088 1.384-3.342V235.1h-88.015c-2.82 0-5.524 1.12-7.518 3.114-1.994 1.994-3.114 4.699-3.114 7.519v42.53c0 1.254.498 2.456 1.384 3.342.886.886 2.088 1.384 3.341 1.384Z"
        fill="#01788B"
      />
      <path
        d="M375.152 45.7124h25.597M375.152 54.7383h40.364"
        stroke="#00E1F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#j)">
        <path
          d="M257.924 87.4028h-88.605c-1.253 0-2.455.4979-3.342 1.3841-.886.8863-1.384 2.0882-1.384 3.3416v90.5745c0 1.253.498 2.455 1.384 3.342.887.886 2.089 1.384 3.342 1.384h124.048c1.253 0 2.455-.498 3.341-1.384.886-.887 1.384-2.089 1.384-3.342v-68.915l-39.971.196-.197-26.5812Z"
          fill="#01788B"
        />
        <path d="M257.924 87.4028v26.3852h40.168" fill="#00E1F5" />
        <path
          d="M182.043 104.612h47.331M182.043 113.756h47.331M181.98 122.9h62.689M181.98 132.044h62.689M181.98 141.188h62.689"
          stroke="#00E1F5"
          strokeWidth="1.68729"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M271.606 48.3472c3.804 1.1663 7.869 1.1762 11.679.0285 3.81-1.1477 7.193-3.4013 9.72-6.4748 2.528-3.0736 4.085-6.8285 4.475-10.7885.389-3.96-.406-7.9465-2.286-11.4537-6.555 1.4902-12.43 5.117-16.7 10.3101s-6.693 11.6581-6.888 18.3784ZM272.794 261.957c-2.91-.008-5.782.667-8.384 1.969 1.672 5.968 4.92 11.375 9.404 15.654 4.483 4.278 10.036 7.271 16.075 8.663 1.265-2.848 1.799-5.967 1.553-9.074s-1.265-6.103-2.963-8.717c-1.699-2.613-4.023-4.761-6.762-6.248s-5.807-2.266-8.923-2.266v.019ZM104.043 80.3985c-2.708-.4959-5.4922-.3904-8.1548.3092-2.6626.6996-5.1388 1.9761-7.2531 3.7392-2.1144 1.763-3.8152 3.9694-4.982 6.4629-1.1668 2.4934-1.771 5.213-1.7698 7.9659 0 .5002.0276.9924.0669 1.4803 4.8574-1.6273 9.3266-4.2399 13.128-7.674 3.8014-3.4342 6.8528-7.6158 8.9648-12.2835Z"
        fill="#fff"
      />
      <g filter="url(#k)">
        <path
          d="M203.58 135.38h-98.845c-2.609 0-4.725 2.116-4.725 4.726v137.043c0 2.61 2.116 4.725 4.725 4.725h98.845c2.61 0 4.725-2.115 4.725-4.725V140.106c0-2.61-2.115-4.726-4.725-4.726Z"
          fill="#BFF8FD"
        />
        <path
          d="M146 208.433h48.867M146 218.433h48.867M146 228.433h48.867M146 238.433h48.867M146 248.433h48.867M148.841 183.668v3.938h-3.938"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.153 187.606h-14.622"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10.44 10.44"
        />
        <path
          d="M115.656 187.606h-3.938v-3.938"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M111.718 176.839v-10.238"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="7.31 7.31"
        />
        <path
          d="M111.718 163.19v-3.938h3.938"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.402 159.252h14.626"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10.44 10.44"
        />
        <path
          d="M144.903 159.252h3.938v3.938"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.841 170.015v10.239"
          stroke="#00E1F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="7.31 7.31"
        />
      </g>
    </g>
    <defs>
      <filter
        id="b"
        x="269.012"
        y="75.5791"
        width="86.8225"
        height="114.389"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x="267.582"
        y="18.9028"
        width="68.6455"
        height="68.6455"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x="301.335"
        y="39.7739"
        width="82.8225"
        height="110.389"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x="346.312"
        y="-2"
        width="86.8225"
        height="114.389"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="f"
        x="368.155"
        y="152.72"
        width="55.532"
        height="55.5317"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        x="10.0117"
        y="148.429"
        width="132.504"
        height="95.4863"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="h"
        x="4.30103"
        y="26.8262"
        width="109.042"
        height="109.039"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="i"
        x="309.491"
        y="216.772"
        width="110.647"
        height="87.2163"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="j"
        x="160.593"
        y="87.4028"
        width="141.499"
        height="108.026"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <filter
        id="k"
        x="94.0098"
        y="131.38"
        width="120.296"
        height="158.495"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.470588 0 0 0 0 0.545098 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9_18360" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9_18360"
          result="shape"
        />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h433.226v305H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default NoResultsFound
