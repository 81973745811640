import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  forwardRef,
  useMemo,
} from "react"

import {
  EndorsementAssuredName,
  EndorsementType,
  EndorsementsSearchResult,
  searchEndorsementAssuredNames,
  unarchiveEndorsement,
} from "@appia/api"
import { useGetUsers, useSearchEndorsements } from "src/swr"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"

import {
  Button,
  Initials,
  RestoreIcon,
  SelectOption,
  Toast,
} from "@appia/ui-components"
import StatusPill from "./StatusPill"
import TitledSpan from "./TitledSpan"
import {
  ENDORSEMENT_TYPE_OPTIONS,
  endorsementTypeLabels,
} from "./endorsementType"

import BaseTable, {
  TableControls as BaseTableControls,
  TableSettings as BaseTableSettings,
  Column,
  configureFilter,
} from "src/components/DashboardTable"
import EmptyData from "src/components/EmptyData"

import { logButtonClick } from "src/amplitude"
import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"

import { prettyPrintDateString } from "src/utils/prettyPrinters"
import { compareByName, fullNameOrEmail } from "src/utils/users"

export const localStorageFiltersKey = "@otto/endorsements-table-archive-filters"
export const localStorageSettingsKey =
  "@otto/endorsements-table-archive-settings"

/*
 * ====================
 * Table config
 */
type ColumnKeys =
  | "assured_name"
  | "broker"
  | "umr"
  | "type"
  | "updated_at"
  | "updated_by"
  | "archived_status"
  | "action"

// Configure the columns to render
const tableColumns: Column<ColumnKeys>[] = [
  {
    columnKey: "assured_name",
    label: "Assured name",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "broker",
    label: "Broker",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "umr",
    label: "UMR",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "type",
    label: "Type",
    sortable: true,
  },
  {
    columnKey: "updated_at",
    label: "Archive date",
    sortable: true,
  },
  {
    columnKey: "updated_by",
    label: "Archived by",
    sortable: true,
  },
  {
    columnKey: "archived_status",
    label: "Previous status",
  },
  {
    columnKey: "action",
    label: "Action",
    visuallyHideLabel: true,
  },
]

// Configure the table's pagination, sorting, etc
export type TableSettings = BaseTableSettings<ColumnKeys>

export const defaultTableSettings: TableSettings = {
  pageNumber: 1,
  pageSize: 20,
  sortDirection: "desc",
  sortKey: "updated_at",
}

// The possible filters that may be active for the table
export interface TableFilters {
  assuredName: EndorsementAssuredName[]
  endorsementType: EndorsementType[]
  archivedBy: string[]
}

export const defaultTableFilters: TableFilters = {
  assuredName: [],
  endorsementType: [],
  archivedBy: [],
}

/*
 * ====================
 * Table rendering
 */

// Render an individual table cell for the given column key and endorsement
const RenderCell: FC<{
  colKey: ColumnKeys
  endorsement: EndorsementsSearchResult
  triggerToast: (type: Toast.ToastType, msg: string) => void
  updateEndorsements: () => void
}> = ({
  colKey,
  endorsement,
  triggerToast,
  updateEndorsements,
}): ReactElement => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const { request: usersRequest } = useGetUsers()
  const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

  switch (colKey) {
    case "archived_status":
      return endorsement.archivedStatus ? (
        <StatusPill status={endorsement.archivedStatus} />
      ) : (
        <EmptyData />
      )
    case "assured_name":
      return endorsement.assuredName ? (
        <TitledSpan title={endorsement.assuredName} />
      ) : (
        <EmptyData />
      )
    case "broker":
      return endorsement.brokerName ? (
        <TitledSpan title={endorsement.brokerName} />
      ) : (
        <EmptyData />
      )
    case "umr":
      return endorsement.umr ? (
        <TitledSpan title={endorsement.umr} />
      ) : (
        <EmptyData />
      )
    case "type":
      return endorsement.type ? (
        <TitledSpan title={endorsementTypeLabels[endorsement.type]} />
      ) : (
        <EmptyData />
      )
    case "updated_at":
      return <span>{prettyPrintDateString(endorsement.updatedAt)}</span>
    case "updated_by": {
      const updatedByUser = users.find(u => endorsement.updatedBy === u.id)
      const updatedByName = updatedByUser && fullNameOrEmail(updatedByUser)
      return updatedByName ? (
        <Initials
          initials={updatedByName
            .split(" ")
            .map(s => s[0])
            .join("")}
          label={updatedByName}
        />
      ) : (
        <EmptyData />
      )
    }
    case "action": {
      return (
        <Button
          label="Restore"
          theme="night"
          style="filled"
          size="small"
          icon={{ position: "right", icon: <RestoreIcon /> }}
          onClick={async () => {
            logButtonClick({
              buttonName: "Unarchive",
              containerName: "Endorsement action menu",
              pageName,
            })

            try {
              await unarchiveEndorsement(apiClient, endorsement.id)
              updateEndorsements()
              triggerToast("success", "Successfully restored endorsement")
            } catch (e) {
              if (e instanceof Error) {
                Sentry.captureException(e)
                triggerToast("error", "Failed to restore endorsement")
              }
            }
          }}
        />
      )
    }
  }
}

/*
 * ====================
 * Constants
 */
const TABLE_LABEL = "Archived endorsements table"
const TABLE_ID = "endorsements-table-archive"

export const Table = forwardRef<
  HTMLTableElement,
  {
    tableFilters: TableFilters
    tableSettings: TableSettings
    setTableSettings: Dispatch<SetStateAction<TableSettings>>
    triggerToast: (type: Toast.ToastType, msg: string) => void
  }
>(({ tableFilters, tableSettings, setTableSettings, triggerToast }, ref) => {
  const { request: endorsementsRequest, update: updateEndorsements } =
    useSearchEndorsements({
      assuredName: tableFilters.assuredName.map(({ name }) => name),
      type: tableFilters.endorsementType,
      order:
        // These cases shouldn't occur if the table UI is configured correctly
        tableSettings.sortKey === "archived_status" ||
        tableSettings.sortKey === "action"
          ? undefined
          : tableSettings.sortKey,
      orderBy: tableSettings.sortDirection,
      page: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: ["archived"],
      updatedBy: tableFilters.archivedBy,
    })

  return (
    <BaseTable
      id={TABLE_ID}
      label={TABLE_LABEL}
      dataRequest={RD.map(
        ({ items, hits }) => ({ rows: items, totalRows: hits }),
        endorsementsRequest,
      )}
      settings={tableSettings}
      onSettingsChange={setTableSettings}
      columns={tableColumns}
      renderCell={(colKey, endorsement) => (
        <RenderCell
          colKey={colKey}
          endorsement={endorsement}
          triggerToast={triggerToast}
          updateEndorsements={updateEndorsements}
        />
      )}
      tableRef={ref}
    />
  )
})

export const TableControls: FC<{
  tableFilters: TableFilters
  setTableFilters: Dispatch<SetStateAction<TableFilters>>
  setTableSettings: Dispatch<SetStateAction<TableSettings>>
}> = ({ tableFilters, setTableFilters, setTableSettings }) => {
  const { request: usersRequest } = useGetUsers()

  const onFilterChange = <K extends keyof TableFilters>(
    key: K,
    val: TableFilters[K],
  ): void => {
    setTableSettings(ts => ({ ...ts, pageNumber: 1 }))
    setTableFilters(tf => ({ ...tf, [key]: val }))
  }

  const archivedByOptions = useMemo<SelectOption[]>(() => {
    const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

    return users
      .sort(compareByName)
      .map(u => ({ value: u.id, label: fullNameOrEmail(u) }))
  }, [usersRequest])

  return (
    <BaseTableControls
      filters={[
        configureFilter<EndorsementAssuredName>({
          type: "async",
          uniqueKey: "assuredName",
          label: "Assured name",
          values: tableFilters.assuredName,
          onChange: vals => onFilterChange("assuredName", vals),
          loader: searchEndorsementAssuredNames,
          mapResultToLabel: ({ name }) => name,
          mapResultToValue: ({ name }) => name,
        }),

        configureFilter<EndorsementType>({
          type: "sync",
          uniqueKey: "endorsementType",
          label: "Endorsement type",
          values: tableFilters.endorsementType,
          onChange: vals => onFilterChange("endorsementType", vals),
          mapResultToLabel: v => endorsementTypeLabels[v],
          options: ENDORSEMENT_TYPE_OPTIONS,
        }),

        configureFilter<string>({
          type: "sync",
          uniqueKey: "archivedBy",
          label: "Archived by",
          values: tableFilters.archivedBy,
          onChange: vals => onFilterChange("archivedBy", vals),
          mapResultToLabel: v =>
            archivedByOptions.find(opt => opt.value === v)?.label ?? "",
          options: archivedByOptions,
        }),
      ]}
      onClear={() => {
        setTableSettings(defaultTableSettings)
        setTableFilters(defaultTableFilters)
      }}
      tableId={TABLE_ID}
      tableLabel={TABLE_LABEL}
    />
  )
}
