import { FC } from "react"

import { User } from "@appia/api"
import { compareByName, fullNameOrEmail } from "src/utils/users"

import { Select, XIcon } from "@appia/ui-components"

const UserSelection: FC<{
  allUsers: User[]
  teamId?: string
  selectedUserIds: string[]
  selectUser: (id: string) => void
  deselectUser: (id: string) => void
}> = ({ allUsers, teamId, selectedUserIds, selectUser, deselectUser }) => {
  /**
   * For a user to be available to be selected, they must not be in the list of
   * selected users already. In addition they must either not be in a team; or
   * they must be in this team, which handles the case where the user is in this
   * team but has been temporarily deselected.
   */
  const availableUsers = allUsers.filter(
    user =>
      (!user.teamId || user.teamId === teamId) &&
      !selectedUserIds.includes(user.id),
  )

  return (
    <div data-cy="user-select">
      <ul className="flex flex-wrap">
        {selectedUserIds.map(id => {
          const user = allUsers.find(user => user.id === id)

          if (!user) {
            return null
          }

          const displayName = fullNameOrEmail(user)

          return (
            <li
              key={user.id}
              className="mr-3 mb-4 flex items-center rounded-full border border-otto-grey-400 bg-white px-3 py-2 shadow-sm"
            >
              {displayName}
              <button
                aria-label={`Remove ${displayName} from this team's users`}
                onClick={() => deselectUser(user.id)}
                className="ml-2 w-4"
              >
                <XIcon />
              </button>
            </li>
          )
        })}
      </ul>

      <Select
        options={availableUsers.sort(compareByName).map(user => ({
          value: user.id,
          label: `${fullNameOrEmail(user)}`,
        }))}
        placeholder={`Add ${selectedUserIds.length > 0 ? "another" : "a"} user`}
        onSelect={selectUser}
        disabled={availableUsers.length === 0}
        selectedValue={null}
      />
    </div>
  )
}

export default UserSelection
