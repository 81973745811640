import { AxiosInstance, AxiosPromise } from "axios"

export interface EndorsementAssuredName {
  name: string
}

const mkSearchEndorsementAssuredNamesUrl = (query: string): string =>
  query
    ? `/api/policies/search/assureds?query=${query}`
    : `/api/policies/search/assureds`

export const searchEndorsementAssuredNames = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<EndorsementAssuredName[]> =>
  client.get(mkSearchEndorsementAssuredNamesUrl(query))
