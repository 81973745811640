import { FC } from "react"

import { DuplicateIcon, IconButton, XIcon } from "@appia/ui-components"

import * as Sentry from "@sentry/react"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

import classNames from "classnames"

export const SelectedPolicyUMR: FC<{
  onCopyFailure: () => void
  onCopySuccess: () => void
  policyIsBritFollow: boolean
  policyIsStub: boolean
  policyUMR: string | null
  standalone: boolean
  onDeselectPolicy?: () => void
}> = ({
  onCopyFailure,
  onCopySuccess,
  policyIsBritFollow,
  policyIsStub,
  policyUMR,
  standalone,
  onDeselectPolicy,
}) => {
  const pageName = usePageName()

  return (
    <div
      data-testid="selected-policy-umr"
      className={classNames(
        "flex flex-wrap items-center justify-between gap-4 border-x border-otto-grey-300 p-2",
        {
          "bg-otto-pop-50": policyUMR !== null,
          "rounded-t-md border-t": standalone,
        },
      )}
    >
      <div className="flex items-center gap-2">
        <p>
          <span className="text-otto-grey-700">
            Selected policy&rsquo;s UMR:
          </span>{" "}
          <span className="font-bold text-otto-night">
            {policyUMR ?? "No policy selected"}
          </span>
        </p>

        {policyUMR && (
          <IconButton
            color="text-otto-grey-700"
            size={4}
            icon={<DuplicateIcon />}
            label="Copy policy UMR"
            onClick={async () => {
              logButtonClick({
                pageName,
                buttonName: "Copy policy UMR",
                containerName: "Main",
              })

              try {
                navigator.clipboard.writeText(policyUMR)
                onCopySuccess()
              } catch (e) {
                if (e instanceof Error) {
                  Sentry.captureException(e)
                  onCopyFailure()
                }
              }
            }}
          />
        )}
      </div>

      <div className="flex flex-wrap items-center gap-3">
        {policyIsBritFollow && !policyIsStub && (
          <p className="justify-self-right font-bold text-otto-pop-900">
            This risk is Brit Follow
          </p>
        )}

        {policyUMR && onDeselectPolicy && (
          <IconButton
            color="text-otto-grey-800"
            size={6}
            icon={<XIcon />}
            label="Deselect policy"
            onClick={() => {
              logButtonClick({
                buttonName: "Deselect policy",
                containerName: "Main",
                pageName,
              })

              onDeselectPolicy()
            }}
          />
        )}
      </div>
    </div>
  )
}

export const EndorsementUMR: FC<{ endorsementUMR: string | null }> = ({
  endorsementUMR,
}) => (
  <div className="rounded-t-md border border-b-0 border-otto-grey-300 bg-otto-pop-50 p-2">
    <p>
      <span className="text-otto-grey-700">Endorsement&rsquo;s UMR:</span>{" "}
      <span className="font-bold text-otto-night">{endorsementUMR}</span>
    </p>
  </div>
)
