import { FC } from "react"

import * as RD from "@appia/remote-data"

import { Button } from "@appia/ui-components"

import ErrorMessage from "src/components/ErrorMessage"
import ModalTemplate from "src/components/ModalTemplate"
import SuccessModal from "./SuccessModal"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const Modal: FC<{
  isOpen: boolean
  onClose: () => void
  showCopyHeadersheetButton: boolean
  request: RD.RemoteData<Error, unknown>
}> = ({
  isOpen,
  onClose,
  showCopyHeadersheetButton,
  request: confirmRequest,
}) => {
  const pageName = usePageName()

  return RD.match(
    confirmRequest,
    null,
    null,

    () => (
      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        showCopyHeadersheetButton={showCopyHeadersheetButton}
        pageName={pageName}
      />
    ),

    error => (
      <ModalTemplate
        isOpen={isOpen}
        onClose={onClose}
        title="Not confirmed"
        content={
          <ErrorMessage
            message="Sorry, there was an error while saving your changes. Please try again."
            error={error}
          />
        }
        actions={[
          <Button
            key="return"
            label="Return to PBQA"
            style="outlined"
            theme="night"
            onClick={() => {
              onClose()
              logButtonClick({
                buttonName: "Return to PBQA",
                containerName: "Failure modal",
                pageName,
              })
            }}
          />,
        ]}
      />
    ),
  )
}

export default Modal
