/**
 * From Tailwind UI: https://tailwindui.com/components/application-ui/application-shells/stacked#component-3dc007ccdc462a3767d28be1119c96a7
 */
import { FC, Fragment } from "react"
import { NavLink } from "react-router-dom"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { MenuIcon, UserIcon, XIcon } from "./Icons"
import { Logo, LogoBackgroundPattern } from "./Logo"
import classNames from "classnames"

export interface HeaderLink {
  name: string
  href: string
  hidden?: boolean
}

export interface HeaderProps {
  navLinks: HeaderLink[]
  userLinks: HeaderLink[]
  user?: {
    displayName: string
    teamName?: string
  }
  onLinkClick: (args: { name: string; href: string }) => void
}

const Header: FC<HeaderProps> = ({
  navLinks,
  userLinks,
  user,
  onLinkClick,
}) => (
  <div>
    <Disclosure
      as="nav"
      aria-label="Site"
      className="relative bg-otto-night forced-colors:border-b"
    >
      {({ open }) => (
        <>
          <div className="relative z-10 mx-auto max-w-screen px-4 sm:px-6 lg:px-8">
            <div className="flex h-[3.25rem] items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0 text-otto-pop">
                  <NavLink to="/" className="otto-focus block rounded">
                    <Logo />
                  </NavLink>
                </div>

                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navLinks.map(link => (
                      <NavLink
                        key={link.name}
                        to={link.href}
                        className={({ isActive }) =>
                          classNames(
                            "otto-focus text-white hover:bg-otto-mid hover:bg-opacity-75",
                            "rounded-md px-3 py-2 text-base font-medium",
                            { "bg-otto-mid text-white": isActive },
                          )
                        }
                        hidden={link.hidden ?? false}
                        aria-current="page"
                        onClick={() => onLinkClick(link)}
                      >
                        {link.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>

              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  {user && (
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="otto-focus flex max-w-xs items-center rounded-full text-base text-otto-pop">
                          <span className="sr-only">Open user menu</span>
                          <span className="block h-8 w-8 rounded-full border border-otto-pop p-1">
                            <UserIcon />
                          </span>

                          <div className="mx-2 text-left">
                            <span className="block">{user.displayName}</span>
                            {user.teamName && (
                              <span className="block text-sm uppercase">
                                {user.teamName}
                              </span>
                            )}
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="otto-focus-inset absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                          {userLinks.map(link => (
                            <Menu.Item key={link.name}>
                              {({ active }) => (
                                <a
                                  href={link.href}
                                  className={classNames(
                                    active ? "bg-otto-grey-200" : "",
                                    "block px-4 py-2 text-base",
                                  )}
                                  onClick={() => onLinkClick(link)}
                                >
                                  {link.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>

              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="otto-focus inline-flex items-center justify-center rounded-md p-2 text-otto-pop hover:bg-otto-mid">
                  <span className="sr-only">Open main menu</span>
                  <span className="block h-6 w-6">
                    {open ? <XIcon /> : <MenuIcon />}
                  </span>
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {navLinks.map(link => (
                <NavLink
                  key={link.name}
                  to={link.href}
                  className={({ isActive }) =>
                    classNames(
                      "otto-focus text-white hover:bg-otto-mid hover:bg-opacity-75",
                      "block rounded-md px-3 py-2 text-base font-medium",
                      { "bg-otto-mid text-white": isActive },
                    )
                  }
                  aria-current="page"
                  onClick={() => onLinkClick(link)}
                >
                  {link.name}
                </NavLink>
              ))}
            </div>

            {user && (
              <div className="border-t border-otto-night pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <span className="block h-10 w-10 rounded-full border border-otto-pop p-2 text-otto-pop">
                      <UserIcon />
                    </span>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      <span className="block">{user.displayName}</span>
                      {user.teamName && (
                        <span className="block text-sm uppercase">
                          {user.teamName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-3 space-y-1 px-2">
                  {userLinks.map(link => (
                    <a
                      key={link.name}
                      href={link.href}
                      className={classNames(
                        "otto-focus block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-otto-mid hover:bg-opacity-75",
                      )}
                      onClick={() => onLinkClick(link)}
                    >
                      {link.name}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </Disclosure.Panel>

          <div className="absolute top-0 bottom-0 z-0 hidden w-full overflow-hidden md:block">
            <div className="absolute top-0 h-[200px] w-full translate-x-[130px] translate-y-[30px] scale-[1.8]">
              <LogoBackgroundPattern />
            </div>
          </div>
        </>
      )}
    </Disclosure>
  </div>
)

export default Header
