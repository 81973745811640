import { FC, useId } from "react"

import { PBQAAnswer, PBQABooleanAnswer, PBQABooleanQuestion } from "@appia/api"

import AcceptedRadioButtons from "../AcceptedRadioButtons"
import BoundingBoxCTA from "../components/BoundingBoxCTA"
import Group from "../components/Group"

import classNames from "classnames"

const BooleanQuestion: FC<{
  answer: PBQABooleanAnswer
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  question: PBQABooleanQuestion
  showFieldErrors: boolean
}> = ({
  answer,
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  question,
  showFieldErrors,
}) => {
  const legendId = useId()

  return (
    <Group
      aria-labelledby={legendId}
      className="flex flex-col items-stretch border-b border-otto-grey-300 lg:flex-row"
    >
      <div
        className={classNames(
          "flex w-full items-center p-2",
          isActive ? "bg-ki-dark" : "bg-ki-light",
        )}
      >
        <div className="flex items-start gap-2">
          {answer.boundingBox && (
            <BoundingBoxCTA
              questionLabel={question.text}
              onClick={onJumpToBoundingBox}
            />
          )}

          <legend id={legendId}>{question.text}</legend>
        </div>
      </div>

      <AcceptedRadioButtons
        questionId={question.id}
        questionText={question.text}
        accepted={answer.accepted}
        onChangeAcceptance={accepted =>
          onChangeAnswer({ ...answer, accepted, answer: accepted })
        }
        noteAndReasons={{ note: answer.note, reasons: answer.reasons }}
        onChangeNoteAndReasons={({ note, reasons }) =>
          onChangeAnswer({ ...answer, note, reasons })
        }
        showFieldErrors={showFieldErrors}
        paddingTop="lg:pt-3"
      />
    </Group>
  )
}

export default BooleanQuestion
