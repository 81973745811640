import { FC, useId, useState } from "react"

import { Syndicate, updateSyndicatePartners } from "@appia/api"
import { useGetSyndicatePartners } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import { Button, ModalDescription } from "@appia/ui-components"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import ModalTemplate from "src/components/ModalTemplate"

import SyndicateForm from "./SyndicateForm"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import { logButtonClick } from "src/amplitude"

const CONTAINER_NAME = "Syndicate modal"

const SyndicateModal: FC<{
  isOpen: boolean
  existingSyndicates: Syndicate[]
  onClose: () => void
  onSuccess: () => void
}> = ({ isOpen, existingSyndicates, onClose, onSuccess }) => {
  const apiClient = useApiClient()
  const pageName = usePageName()
  const { pbqaId } = usePBQASurvey()

  const { request: syndicateReq } = useGetSyndicatePartners()

  const [selectedSyndicatesIds, setSelectedSyndicatesIds] = useState<
    Syndicate["id"][]
  >(existingSyndicates.map(s => s.id))

  const hasSelectedSyndicates = selectedSyndicatesIds.length > 0

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const [submitRequest, setSubmitRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const handleSyndicateSubmit = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Submit",
      containerName: CONTAINER_NAME,
      pageName,
    })

    if (!hasSelectedSyndicates) {
      setShowErrorMessage(true)
      return
    }

    setSubmitRequest(RD.Loading)
    try {
      await updateSyndicatePartners(apiClient, pbqaId, selectedSyndicatesIds)
      setSubmitRequest(RD.Success(null))
      onSuccess()
    } catch (error) {
      if (error instanceof Error) {
        setSubmitRequest(RD.Failure(error))
        Sentry.captureException(error)
      }
    }
  }

  const handleCheckboxChange = (
    syndicateId: Syndicate["id"],
    isChecked: boolean,
  ): void => {
    const filteredIds = selectedSyndicatesIds.filter(id => id !== syndicateId)
    setSelectedSyndicatesIds(
      isChecked ? [...filteredIds, syndicateId] : filteredIds,
    )
  }

  const formId = useId()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Manage syndicates"
      content={
        <ModalDescription as="div" className="grid gap-2">
          {RD.match(
            syndicateReq,
            <Loading />,
            <Loading />,

            syndicates => (
              <>
                <SyndicateForm
                  formId={formId}
                  syndicates={syndicates}
                  selectedSyndicatesIds={selectedSyndicatesIds}
                  onCheckboxChange={handleCheckboxChange}
                  onSubmit={handleSyndicateSubmit}
                />

                {showErrorMessage && !hasSelectedSyndicates && (
                  <ErrorMessage message="Please select one or more syndicates" />
                )}
              </>
            ),

            synError => (
              <ErrorMessage
                message="Failed to load syndicates"
                error={synError}
              />
            ),
          )}

          {RD.isFailure(submitRequest) && (
            <ErrorMessage
              message="Failed to update syndicates"
              error={submitRequest.error}
            />
          )}
        </ModalDescription>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => {
            onClose()
            logButtonClick({
              buttonName: "Cancel",
              containerName: CONTAINER_NAME,
              pageName,
            })
          }}
        />,

        <Button
          key="submit"
          form={formId}
          label="Submit"
          theme="pop"
          style="filled"
          isLoading={RD.isLoading(submitRequest)}
        />,
      ]}
    />
  )
}

export default SyndicateModal
