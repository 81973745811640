import { FC } from "react"

import { ErrorMessage, WarningMessage } from "@appia/ui-components"
import Loading from "src/components/Loading"

import WithinCapacityPill from "./WithinCapacityPill"

import { formatCurrency } from "src/utils/prettyPrinters"

export type ClassCapacity =
  | { type: "loading" }
  | { type: "success"; capacity: number }
  | { type: "warning"; capacity: number }
  | { type: "error"; msg: string }

const Totals: FC<{
  classCapacity: ClassCapacity
  exposure: number
  withinCapacity: boolean | null
}> = ({ classCapacity, exposure, withinCapacity }) => {
  const dtCss = `font-bold pt-4 first:pt-0 sm:pt-0`

  return (
    <dl className="mx-[-1rem] grid w-[calc(100%+2rem)] gap-x-4 gap-y-1 border-t border-otto-grey-300 px-4 pt-4 text-center sm:grid-flow-col sm:grid-cols-3 sm:grid-rows-[auto,auto]">
      <dt className={dtCss}>Overall exposure</dt>
      <dd>{formatCurrency(exposure, "USD")}</dd>

      <dt className={dtCss}>Ki maximum capacity</dt>
      <dd className="flex flex-col gap-1">
        {classCapacity.type === "loading" && <Loading />}

        {(classCapacity.type === "success" ||
          classCapacity.type === "warning") && (
          <span className="block">
            {formatCurrency(classCapacity.capacity, "USD")}
          </span>
        )}

        {classCapacity.type === "warning" && (
          <WarningMessage message="You have selected quotes from multiple classes. The check is done against the max capacity of the main quote's class." />
        )}

        {classCapacity.type === "error" && (
          <ErrorMessage
            message={`Overlining check failed: ${classCapacity.msg}`}
          />
        )}
      </dd>

      <dt className={dtCss}>Within capacity</dt>
      <dd className="mx-auto w-fit">
        <WithinCapacityPill withinCapacity={withinCapacity} />
      </dd>
    </dl>
  )
}

export default Totals
