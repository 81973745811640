import { ContractsPipelineSearchResult } from "@appia/api"

interface LinkDetails {
  label: string
  ariaLabel: string
  href: string
}

export function getContractLinkDetails(
  contract: ContractsPipelineSearchResult,
): LinkDetails | null {
  const forInsuredName = contract.insured ? `for ${contract.insured}` : ""

  switch (contract.versionStatus) {
    case "archived":
      return null
    case "created":
    case "in_progress":
      return {
        label: "Review",
        ariaLabel: `Review Contract ${forInsuredName}`,
        href: `/contract/review/${contract.id}/${contract.latestVersionId}`,
      }
    case "completed":
      return {
        label: "View details",
        ariaLabel: `View Contract details ${forInsuredName}`,
        href: `/contract/view/${contract.id}`,
      }
    default:
      return null
  }
}
