import { Document, KiAnswer, PBQAAnswer, PBQAQuestion } from "@appia/api"

import type { BoundingBox as PDFViewerBoundingBox } from "src/components/PDFViewer"

import { dequal } from "dequal"

const toLowerStripPunctuation = (s: string): string =>
  s.replace(/[^0-9a-zA-Z]/g, "").toLowerCase()

/**
 * Does the PBQAAnswer match the KiAnswer?
 */
export const answersMatch = (
  p: PBQAAnswer,
  k: KiAnswer | undefined,
): boolean => {
  // If both are null / undefined then it's a match; if only one is, then it's
  // not a match
  if (p.answer === null) {
    return k === null || k === undefined
  } else if (k === null || k === undefined) {
    return p.answer === null
  }
  // Decimal and integer answers
  else if ("unit" in p && typeof k === "object" && "unit" in k) {
    return p.unit === k.unit && p.answer === k.answer
  }
  // Broker answers
  else if (
    typeof p.answer === "object" &&
    "alias" in p.answer &&
    typeof k === "object" &&
    "alias" in k
  ) {
    return dequal(p.answer, k)
  }
  // Multi-select option questions
  else if (Array.isArray(p.answer) && Array.isArray(k)) {
    const sortedAnswer = [...p.answer].map(s => s.trim()).sort()
    const sortedKiAnswer = [...k].map(s => s.trim()).sort()
    return dequal(sortedAnswer, sortedKiAnswer)
  }
  // String answers
  else if (typeof p.answer === "string" && typeof k === "string") {
    return toLowerStripPunctuation(p.answer) === toLowerStripPunctuation(k)
  } else {
    return dequal(p.answer, k)
  }
}

/**
 * Construct `PDFViewerBoundingBox`s from `PBQAAnswer` bounding box data
 */
export type BoundingBoxWithIdentifier = {
  boundingBox: PDFViewerBoundingBox
  fieldIdentifier: PBQAQuestion["id"]
}

export const answerToBoundingBox = (
  answer: PBQAAnswer,
  documentId: Document["id"],
): BoundingBoxWithIdentifier[] => {
  if (!answer.boundingBox) {
    return []
  }

  if (answer.boundingBox.documentId !== documentId) {
    return []
  }

  const { pageNumber, x0, y0, x1, y1 } = answer.boundingBox

  return [
    {
      boundingBox: {
        documentId,
        pageNumber,
        rects: [{ x: x0, y: y0, width: x1 - x0, height: y1 - y0 }],
      },
      fieldIdentifier: answer.questionId,
    },
  ]
}
