import { FC, ReactNode, useId, useState } from "react"
import ScreenTemplate from "src/templates/ScreenTemplate"
import { Button, Card, Link, Toast } from "@appia/ui-components"
import { logButtonClick } from "src/amplitude"
import { FormField, mkInvalidFormField } from "@appia/form-data"
import { WebhookCreate, createWebhook } from "@appia/api"
import useApiClient from "src/contexts/ApiClientContext"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import ErrorMessage from "src/components/ErrorMessage"
import SharedInputs from "./SharedInputs"
import ToastViewport from "src/components/ToastViewport"
import { useGetWebhooks } from "src/swr"
import { useNavigate } from "react-router-dom"

const PAGE_NAME = "Create webhook"
export const EMPTY_CALLBACKURI = "Please fill in the callback URI"
export const EMPTY_SCOPE = "Please fill in the scope"

const CreateWebhookScreen: FC = () => {
  const apiClient = useApiClient()
  const headingId = useId()
  const navigate = useNavigate()

  const { update: updateWebhooks } = useGetWebhooks()

  const [formFields, setFormFields] = useState<{
    callbackURI: FormField<string>
    scope: FormField<string[], string[]>
  }>({
    callbackURI: mkInvalidFormField("", EMPTY_CALLBACKURI),
    scope: mkInvalidFormField([], EMPTY_SCOPE),
  })
  const [showFormFieldErrors, setShowFormFieldErrors] = useState<boolean>(false)

  const [createWebhookRequest, setCreateWebhookRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  const onSubmitForm = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Create webhook",
      containerName: "Main",
      pageName: PAGE_NAME,
    })

    setShowFormFieldErrors(false)
    setCreateWebhookRequest(RD.NotAsked)

    if (
      formFields.callbackURI.validated.valid === false ||
      formFields.scope.validated.valid === false
    ) {
      return setShowFormFieldErrors(true)
    }

    const creationData: WebhookCreate = {
      callbackUri: formFields.callbackURI.validated.data,
      scope: formFields.scope.validated.data,
    }

    try {
      setCreateWebhookRequest(RD.Loading)
      await createWebhook(apiClient, creationData)
      setCreateWebhookRequest(RD.Success(null))

      updateWebhooks()

      sessionStorage.setItem(
        "toastMessage",
        `Successfully created webhook ${creationData.callbackUri}`,
      )
      navigate("/settings/webhooks")
    } catch (e) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        setCreateWebhookRequest(RD.Failure(e))
        setAndTriggerToast(
          "error",
          `Failed to create webhook ${creationData.callbackUri}`,
        )
      }
    }
  }

  return (
    <ScreenTemplate
      pageTitle="Create webhook"
      layout={{
        type: "regular",
        backPath: "/settings/webhooks",
      }}
    >
      <Card>
        <div className="mb-4">
          <h2
            className="mb-1 text-xl font-medium text-otto-grey-900"
            id={headingId}
          >
            Add a new webhook
          </h2>
        </div>
        <form
          aria-labelledby={headingId}
          className="grid gap-6"
          onSubmit={async ev => {
            ev.preventDefault()
            onSubmitForm()
          }}
        >
          <SharedInputs
            callbackURI={formFields.callbackURI}
            scope={formFields.scope}
            setFormFields={setFormFields}
            showFormFieldErrors={showFormFieldErrors}
            emptyCallbackURI={EMPTY_CALLBACKURI}
            emptyScope={EMPTY_SCOPE}
          />

          <div className="flex justify-end">
            <div className="mr-4">
              <Link
                href="/settings/webhooks"
                theme="night"
                style="outlined"
                label="Cancel"
                onClick={() => {
                  logButtonClick({
                    buttonName: "Cancel",
                    linkHref: "/settings/webhooks",
                    containerName: "Main",
                    pageName: PAGE_NAME,
                  })
                }}
              />
            </div>

            <Button
              theme="night"
              style="filled"
              label="Create"
              isLoading={RD.isLoading(createWebhookRequest)}
            />
          </div>

          {RD.isFailure(createWebhookRequest) && (
            <ErrorMessage
              message="Failed to create webhook"
              error={createWebhookRequest.error}
            />
          )}
        </form>
      </Card>

      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />
      <ToastViewport />
    </ScreenTemplate>
  )
}

export default CreateWebhookScreen
