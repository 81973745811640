import { FC, useCallback, useEffect, useState } from "react"

import { logout } from "@appia/api"
import { useGetCurrentUser } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"

import { Button, Card } from "@appia/ui-components"
import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"

const Logout: FC = () => {
  const apiClient = useApiClient()
  const { update: updateCurrentUser } = useGetCurrentUser()

  useDocumentTitle("Log out")

  const [logoutRequest, setLogoutRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const tryLogout = useCallback(async (): Promise<void> => {
    if (!apiClient || RD.isLoading(logoutRequest)) {
      return
    }

    setLogoutRequest(RD.Loading)

    try {
      await logout(apiClient)
      updateCurrentUser()
      setLogoutRequest(RD.Success(null))
      window.location.href = "/login"
    } catch (e) {
      if (e instanceof Error) {
        setLogoutRequest(RD.Failure(e))
        Sentry.captureException(e)
      }
    }
  }, [apiClient, updateCurrentUser, logoutRequest])

  useEffect(() => {
    tryLogout()
    // Deliberately leave the dependency array empty so this only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main>
      <div className="mx-auto my-12 max-w-max">
        <Card>
          {RD.match(
            logoutRequest,

            <Loading />,

            <div className="grid gap-2">
              <Loading />
              <p>Logging you out...</p>
            </div>,

            () => (
              <p>Successfully logged out. Redirecting to login...</p>
            ),

            error => (
              <>
                <ErrorMessage
                  error={error}
                  message="There was an error while logging you out"
                />
                <Button
                  className="mt-4"
                  label="Try again"
                  stretch="space-around"
                  theme="pop"
                  style="filled"
                  onClick={tryLogout}
                />
              </>
            ),
          )}
        </Card>
      </div>
    </main>
  )
}

export default Logout
