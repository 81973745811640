import { FC, ReactNode, useId, useState } from "react"
import ScreenTemplate from "src/templates/ScreenTemplate"
import {
  Button,
  Card,
  Link,
  Toast,
  ToggleWithDescription,
} from "@appia/ui-components"
import { logButtonClick } from "src/amplitude"
import { useNavigate, useParams } from "react-router-dom"
import * as RD from "@appia/remote-data"
import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"
import { Webhook, WebhookUpdate, updateWebhook } from "@appia/api"
import useApiClient from "src/contexts/ApiClientContext"
import * as Sentry from "@sentry/react"
import { FormField, mkValidFormField } from "@appia/form-data"
import SharedInputs from "./SharedInputs"
import { EMPTY_CALLBACKURI, EMPTY_SCOPE } from "./CreateWebhookScreen"
import ToastViewport from "src/components/ToastViewport"
import { useGetWebhook, useGetWebhooks } from "src/swr"

const PAGE_NAME = "Edit webhook"

const EditWebhookScreenInner: FC<{
  webhook: Webhook
  updateWebhookSwr: () => void
}> = ({ webhook, updateWebhookSwr }) => {
  const apiClient = useApiClient()
  const headingId = useId()
  const navigate = useNavigate()

  const { update: updateWebhooks } = useGetWebhooks()

  const [formFields, setFormFields] = useState<{
    callbackURI: FormField<string>
    scope: FormField<string[], string[]>
  }>({
    callbackURI: mkValidFormField(webhook.callbackUri, webhook.callbackUri),
    scope: mkValidFormField(webhook.scope, webhook.scope),
  })

  const [enableField, setEnableField] = useState<boolean>(webhook.enabled)

  const [showFormFieldErrors, setShowFormFieldErrors] = useState<boolean>(false)

  const [updateWebhookRequest, setUpdateWebhookRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  const onSubmitForm = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Edit webhook",
      containerName: "Main",
      pageName: PAGE_NAME,
    })

    setShowFormFieldErrors(false)
    setUpdateWebhookRequest(RD.NotAsked)

    if (
      formFields.callbackURI.validated.valid === false ||
      formFields.scope.validated.valid === false
    ) {
      return setShowFormFieldErrors(true)
    }

    const updateData: WebhookUpdate = {
      callbackUri: formFields.callbackURI.validated.data,
      enabled: enableField,
      scope: formFields.scope.validated.data,
    }

    try {
      setUpdateWebhookRequest(RD.Loading)
      await updateWebhook(apiClient, webhook.id, updateData)
      setUpdateWebhookRequest(RD.Success(null))

      updateWebhooks()
      updateWebhookSwr()

      sessionStorage.setItem(
        "toastMessage",
        `Successfully updated webhook ${updateData.callbackUri}`,
      )
      navigate("/settings/webhooks")
    } catch (e) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        setUpdateWebhookRequest(RD.Failure(e))
        setAndTriggerToast(
          "error",
          `Failed to update webhook ${updateData.callbackUri}`,
        )
      }
    }
  }

  return (
    <>
      <Card>
        <form
          aria-labelledby={headingId}
          onSubmit={async ev => {
            ev.preventDefault()
            onSubmitForm()
          }}
        >
          <div className="mb-4">
            <h2
              className="mb-1 text-xl font-medium text-otto-grey-900"
              id={headingId}
            >
              Manage webhook
            </h2>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <SharedInputs
              callbackURI={formFields.callbackURI}
              scope={formFields.scope}
              setFormFields={setFormFields}
              showFormFieldErrors={showFormFieldErrors}
              emptyCallbackURI={EMPTY_CALLBACKURI}
              emptyScope={EMPTY_SCOPE}
            />

            <dl>
              <dt>Secret key</dt>
              <dd className="text-otto-grey-700" data-testid="secret-key">
                {webhook.key}
              </dd>
            </dl>

            <ToggleWithDescription
              checked={enableField}
              onChange={value => setEnableField(value)}
              title="Enable webhook"
              description="Disabling the webhook means events will not be sent or recorded."
            />

            <div className="flex justify-end gap-4">
              <Link
                href="/settings/webhooks"
                theme="night"
                style="outlined"
                label="Cancel"
                onClick={() => {
                  logButtonClick({
                    buttonName: "Go back",
                    containerName: "Main",
                    pageName: PAGE_NAME,
                    linkHref: "/settings/webhooks",
                  })
                }}
              />

              <Button
                theme="night"
                style="filled"
                label="Update"
                isLoading={RD.isLoading(updateWebhookRequest)}
              />
            </div>
          </div>

          {RD.isFailure(updateWebhookRequest) && (
            <ErrorMessage
              message="Failed to update webhook"
              error={updateWebhookRequest.error}
            />
          )}
        </form>
      </Card>

      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />
      <ToastViewport />
    </>
  )
}

const EditWebhookScreen: FC = () => {
  const { id } = useParams<{ id: string }>()
  if (id === undefined) {
    throw new Error("Missing webhook id")
  }

  const { request: webhookRequest, update: updateWebhookRequest } =
    useGetWebhook(id)

  return (
    <ScreenTemplate
      layout={{ type: "regular", backPath: "/settings/webhooks" }}
      pageTitle={PAGE_NAME}
    >
      {RD.match(
        webhookRequest,
        <Loading />,
        <Loading />,
        webhook => (
          <EditWebhookScreenInner
            webhook={webhook}
            updateWebhookSwr={updateWebhookRequest}
          />
        ),
        error => (
          <Card>
            <ErrorMessage message="Failed to load webhook" error={error} />
          </Card>
        ),
      )}
    </ScreenTemplate>
  )
}

export default EditWebhookScreen
