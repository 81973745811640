import {
  INVALID_FIELD_ERROR_MESSAGE,
  parseEndorsementNumber,
  parseEndorsementType,
  parseInsuredName,
  parseSourceUploadType,
  parseUmr,
} from "./parsers"
import { ValidatedData, mkInvalidData } from "@appia/form-data"
import { EndorsementUploadFormField } from "./index"

export function validateField<Data>(
  field: keyof EndorsementUploadFormField,
  value: string,
): ValidatedData<Data> {
  switch (field) {
    case "umr":
      return parseUmr(value) as ValidatedData<Data>
    case "insuredName":
      return parseInsuredName(value) as ValidatedData<Data>
    case "endorsementType":
      return parseEndorsementType(value) as ValidatedData<Data>
    case "endorsementNumber":
      return parseEndorsementNumber(value) as ValidatedData<Data>
    case "source":
      return parseSourceUploadType(value) as ValidatedData<Data>
    default:
      return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }
}
