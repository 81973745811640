import { FC } from "react"
import { Button, ModalDescription } from "@appia/ui-components"
import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"
import ModalTemplate from "src/components/ModalTemplate"

const CONTAINER_NAME = "Unsaved Changes modal"

const UnsavedChangesModal: FC<{
  isOpen: boolean
  onClose: () => void
  onLeave: () => void
}> = ({ isOpen, onClose, onLeave }) => {
  const pageName = usePageName()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Unsaved Changes"
      content={
        <ModalDescription as="div">
          <p>You have unsaved changes. Are you sure you want to leave?</p>
        </ModalDescription>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => {
            logButtonClick({
              buttonName: "Cancel",
              containerName: CONTAINER_NAME,
              pageName,
            })
            onClose()
          }}
        />,

        <Button
          key="Leave"
          label="Leave"
          theme="pop"
          style="filled"
          onClick={() => {
            logButtonClick({
              buttonName: "Leave",
              containerName: CONTAINER_NAME,
              pageName,
            })
            onLeave()
          }}
        />,
      ]}
    />
  )
}

export default UnsavedChangesModal
