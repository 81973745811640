import { FC } from "react"
import {
  ContractHeaderItem,
  ContractSectionItem,
  MAJOR_CLASS_CODE_DESCRIPTION_MAPPING,
  MINOR_CLASS_CODE_DESCRIPTION_MAPPING,
  SUB_CLASS_CODE_DESCRIPTION_MAPPING,
} from "@appia/api"
import {
  formatDecimal2DP,
  prettyPrintKiJourneyType,
  prettyPrintSnakeCase,
} from "src/utils/prettyPrinters"
import { orderedSyndicateNames } from "src/utils/orderedSyndicateNames"

interface DetailProps {
  title: string
  value: string | number | null
}

const Detail: FC<DetailProps> = ({ title, value }) => {
  return (
    <td className="pb-4">
      <span className="font-bold">{title}</span>
      <p>{value || "N/A"}</p>
    </td>
  )
}

interface LayerDetailsProps {
  layer: ContractSectionItem
  brokerName: ContractHeaderItem["brokerName"]
  conductRating: ContractHeaderItem["conductRating"]
  customerType: ContractHeaderItem["customerType"]
}

const LayerDetails: FC<LayerDetailsProps> = ({
  layer,
  brokerName,
  conductRating,
  customerType,
}) => {
  const classType = layer.lines[0].syndicateData.syndicateData.classType
  const majorClass = layer.lines[0].syndicateData.syndicateData.majorClass
  const minorClass = layer.lines[0].syndicateData.syndicateData.minorClass
  const subClass = layer.lines[0].syndicateData.syndicateData.subClass
  const uwReference =
    layer.lines?.[0]?.syndicateData?.syndicateData?.referenceSplit?.[0]
      ?.britPolicyReference ?? "N/A"
  const journeyType = layer.lines[0].syndicateData.syndicateData.kiJourney
  const writtenPremium = layer.lines[0].writtenPremium

  const leaderName = "N/A"
  const leaderLine = "N/A"

  return (
    <table className="mx-4 mt-4 w-full">
      <tbody>
        <tr className="grid grid-cols-3">
          <Detail
            title="Class type"
            value={prettyPrintSnakeCase(classType ? classType : "N/A")}
          />
          <Detail
            title="Major class"
            value={MAJOR_CLASS_CODE_DESCRIPTION_MAPPING[majorClass]}
          />
          <Detail
            title="Minor class"
            value={MINOR_CLASS_CODE_DESCRIPTION_MAPPING[minorClass]}
          />
        </tr>
        <tr className="grid grid-cols-3">
          <Detail
            title="Sub class"
            value={
              subClass ? SUB_CLASS_CODE_DESCRIPTION_MAPPING[subClass] : "N/A"
            }
          />
          <Detail title="Broker contact" value={brokerName} />
          <Detail title="UW reference" value={uwReference} />
        </tr>
        <tr className="grid grid-cols-3">
          <Detail title="Conduct rating" value={conductRating} />
          <Detail
            title="Journey type"
            value={prettyPrintKiJourneyType(journeyType)}
          />
          <Detail title="Agg risk data" value={layer.aggregateRiskData} />
        </tr>
        <tr className="grid grid-cols-3">
          <Detail title="Limit" value={formatDecimal2DP(layer.limitAmount)} />
          <Detail title="Excess" value={formatDecimal2DP(layer.excess)} />
          <Detail
            title="Deductible"
            value={formatDecimal2DP(layer.deductible)}
          />
        </tr>
        <tr className="grid grid-cols-3">
          <Detail title="Cyber exposure" value={layer.cyberExposure} />
          <Detail title="Cyber war exposure" value={layer.cyberWarExposure} />
          <Detail
            title="Written Premium"
            value={formatDecimal2DP(writtenPremium)}
          />
        </tr>
        <tr className="grid grid-cols-3">
          <Detail title="Leader" value={leaderName} />
          <Detail title="Leader line" value={leaderLine} />
          <Detail title="Customer type" value={customerType} />
        </tr>
        <tr className="grid grid-cols-1">
          <Detail title="Syndicates" value={orderedSyndicateNames(layer)} />
        </tr>
      </tbody>
    </table>
  )
}

export default LayerDetails
