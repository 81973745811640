import { AxiosInstance, AxiosPromise } from "axios"

export const archivePBQA = (
  client: AxiosInstance,
  pbqa_id: string,
): AxiosPromise => client.post(`/api/pbqa/${pbqa_id}/archive`)

export const unarchivePBQA = (
  client: AxiosInstance,
  pbqa_id: string,
): AxiosPromise => client.post(`/api/pbqa/${pbqa_id}/unarchive`)
