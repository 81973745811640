import { FC } from "react"

import { Pill } from "@appia/ui-components"

const WithinCapacityPill: FC<{
  withinCapacity: boolean | null
}> = ({ withinCapacity }) => {
  switch (withinCapacity) {
    case true:
      return <Pill type="success" label="Yes" />
    case false:
      return <Pill type="error" label="No" />
    case null:
      return <Pill type="neutral" label="Unknown" />
  }
}

export default WithinCapacityPill
