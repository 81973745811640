import { FC, useCallback, useEffect, useState } from "react"

import { Policy, searchPolicies } from "@appia/api"
import * as Sentry from "@sentry/react"

import { useDebouncedCallback } from "use-debounce"

import { SearchBar } from "@appia/ui-components"
import { buildAdvancedSearchQuery } from "src/utils/advancedSearch"
import { Filters, PolicySearchFilters, searchKeyMapping } from "./Filters"
import { ResultsHeader, SearchResult } from "./Results"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const PolicySearchBar: FC<{
  endorsementUMR: string
  onChangePolicy: (p: Policy) => void
  selectedPolicy: Policy | null
  setOpen: (open: boolean) => void
  open: boolean
}> = ({ endorsementUMR, onChangePolicy, selectedPolicy, setOpen, open }) => {
  const pageName = usePageName()
  const apiClient = useApiClient()

  const [searchText, setSearchText] = useState<string>("")
  const [searchFilters, setSearchFilters] = useState<
    Partial<PolicySearchFilters>
  >({})
  const [searchResults, setSearchResults] = useState<Policy[]>([])

  const searchAndSetResults = useCallback(
    async (query: string): Promise<void> => {
      try {
        const { data: results } = await searchPolicies(apiClient, query)
        setSearchResults(results)
      } catch (e) {
        if (e instanceof Error) {
          Sentry.captureException(e)
        }
      }
    },
    [apiClient, setSearchResults],
  )

  const searchAndSetResultsDebounced = useDebouncedCallback(
    searchAndSetResults,
    600,
  )

  useEffect(() => {
    // Initialise the list of options when the component mounts
    searchAndSetResults(endorsementUMR)
  }, [endorsementUMR, searchAndSetResults])

  return (
    <div className="rounded-t-md bg-otto-mid">
      <SearchBar
        beforeResults={searchResults.length > 0 && <ResultsHeader />}
        filters={onSubmit => (
          <Filters
            filters={searchFilters}
            onSubmit={async updatedFilters => {
              logButtonClick({
                buttonName: "Submit",
                containerName: "Search filters",
                pageName,
              })

              setSearchFilters(updatedFilters)

              const query = buildAdvancedSearchQuery(
                updatedFilters,
                searchKeyMapping,
              )
              setSearchText(query)

              onSubmit()

              await searchAndSetResults(query)
            }}
            onReset={() => {
              logButtonClick({
                buttonName: "Reset",
                containerName: "Search filters",
                pageName,
              })

              setSearchFilters({})

              onSubmit()
            }}
          />
        )}
        getOptionId={opt => opt.policyId}
        label="Search for a policy"
        visuallyHideLabel
        options={searchResults}
        placeholder="Search by UMR"
        searchText={searchText}
        selectedValue={selectedPolicy}
        renderOption={policy => <SearchResult policy={policy} />}
        onSelect={policyId => {
          const selectedPolicy =
            searchResults.find(p => p.policyId === policyId) || null
          if (selectedPolicy) {
            onChangePolicy(selectedPolicy)
          }
        }}
        onSearch={async query => {
          setSearchText(query)

          // Reset the filters when the user types into the input
          setSearchFilters({})

          await searchAndSetResultsDebounced(query)
        }}
        setOpen={setOpen}
        open={open}
      />
    </div>
  )
}

export default PolicySearchBar
