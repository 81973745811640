import { AxiosInstance, AxiosPromise } from "axios"

export interface EndorsementUmr {
  value: string
}

const searchEndorsementUmrsUrl = (query: string): string =>
  query
    ? `/api/policies/search/umr?scope=endorsements&query=${query}`
    : `/api/policies/search/umr?scope=endorsements`

export const searchEndorsementUmrs = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<EndorsementUmr[]> => client.get(searchEndorsementUmrsUrl(query))
