import { FC, ReactNode } from "react"
import MatchingFieldsPercentPill from "./MatchingFieldsPercentPill"
import { PBQAReviewState } from "src/screens/ReviewPBQA/state"
import { KiQuote, PBQASurvey } from "@appia/api"
import { ID, SrEndOfSentence } from "src/components/A11y"
import { formatCurrency, formatDecimal2DP } from "src/utils/prettyPrinters"
import EmptyData from "src/components/EmptyData"
import { Button } from "@appia/ui-components"

const ASSURED_NAME_LABEL = "Assured name"
const QUOTE_LINE_ID_LABEL = (
  <>
    Quote line <ID />
  </>
)
const LIMIT_LABEL = "Limit"
const PREMIUM_LABEL = "Premium"
const MATCH_LABEL = "Match"
const VIEW_MODAL_BUTTON_LABEL = " "

const SearchResultField: FC<{
  className?: string
  label: ReactNode
  value: ReactNode
}> = ({ className, label, value }) => (
  <div className={className}>
    <dt className="pb-1 text-sm text-otto-grey-700 lg:hidden">
      {label}
      <span className="sr-only">:&nbsp;</span>
    </dt>
    <dd className="text-base">
      {value}
      <SrEndOfSentence />
    </dd>
  </div>
)

export const QuoteLineMatchingSearchResults: FC<{
  activeSurvey: PBQASurvey | null
  quote: KiQuote
  state: PBQAReviewState
  handleOpenModal: (
    quoteData: KiQuote | undefined,
    quoteMatchResult: number,
  ) => void
  searchResults: KiQuote[]
  quoteLineMatchScore: number
}> = ({
  activeSurvey,
  quote,
  handleOpenModal,
  searchResults,
  quoteLineMatchScore,
}) => {
  if (!activeSurvey) {
    return null
  }

  return (
    <dl
      className="grid gap-2 break-words py-1 sm:grid-cols-6 md:grid-cols-[minmax(0,2fr),minmax(0,2fr),minmax(0,3fr)] lg:grid-cols-[minmax(0,3fr),minmax(0,2.5fr),minmax(0,2.5fr),minmax(0,2.5fr),minmax(0,2fr),minmax(0,3fr)] lg:gap-4"
      data-testid="quoteline-matching-search-result"
    >
      <SearchResultField label={ASSURED_NAME_LABEL} value={quote.assuredName} />

      <SearchResultField
        label={QUOTE_LINE_ID_LABEL}
        value={<span data-testid="quote-line-id">{quote.quoteLineId}</span>}
      />

      <SearchResultField
        label={LIMIT_LABEL}
        value={
          quote.limitCurrency === null
            ? formatDecimal2DP(quote.limit)
            : formatCurrency(quote.limit, quote.limitCurrency)
        }
      />

      <SearchResultField
        label={PREMIUM_LABEL}
        value={
          quote.premium === null ? (
            <EmptyData />
          ) : quote.premiumCurrency === null ? (
            formatDecimal2DP(quote.premium)
          ) : (
            formatCurrency(quote.premium, quote.premiumCurrency)
          )
        }
      />

      <div data-testid="percentage-pill-quoteline-matching">
        <SearchResultField
          label={MATCH_LABEL}
          value={
            activeSurvey ? (
              <MatchingFieldsPercentPill matchValue={quoteLineMatchScore} />
            ) : null
          }
        />
      </div>

      <SearchResultField
        label={VIEW_MODAL_BUTTON_LABEL}
        value={
          activeSurvey ? (
            <Button
              key=""
              id="search-result-view-details-button"
              label="View details"
              style="text"
              theme="night"
              onClick={event => {
                event.stopPropagation()
                const quoteData = searchResults.find(
                  q => q.quoteLineId === quote.quoteLineId,
                )
                handleOpenModal(quoteData, quoteLineMatchScore)
              }}
            />
          ) : null
        }
      />
    </dl>
  )
}

export const QuoteLineResultsHeader: FC = () => (
  <div className="sticky top-0 z-10 bg-white">
    <p className="border-b border-b-otto-grey-300 px-3 py-2 text-otto-grey-700">
      Search results
    </p>
    <ol
      aria-label="Search result fields"
      // 8rem is the approximate width of the `MatchingFieldsPill`
      className="list-style-none w-full grid-cols-[2.5fr,2.5fr,2fr,2.5fr,2fr,3fr] gap-5 border-b border-b-otto-grey-300 py-3 pl-3 pr-2 lg:grid"
    >
      {[
        ASSURED_NAME_LABEL,
        QUOTE_LINE_ID_LABEL,
        LIMIT_LABEL,
        PREMIUM_LABEL,
        MATCH_LABEL,
        VIEW_MODAL_BUTTON_LABEL,
      ].map((label, i) => (
        <li key={i} className="text-otto-grey-700">
          {label}
        </li>
      ))}
    </ol>
  </div>
)
