import { FC, useId } from "react"

import { InputLabel } from "./Input"
import classNames from "classnames"

const CharacterLimit: FC<{
  id: string
  maxLengthChars: number
  value: string | null
}> = ({ id, maxLengthChars, value }) => (
  <p
    id={id}
    className="flex items-center justify-between gap-1 text-sm text-otto-grey-700"
  >
    <span>Character limit:</span>
    <span aria-hidden>
      {value?.length || 0} / {maxLengthChars}
    </span>
    <span className="sr-only">
      {value?.length || 0} of {maxLengthChars}.
    </span>
  </p>
)

const TextArea: FC<{
  "aria-describedby"?: string
  hasError?: boolean
  label: string
  maxLengthChars?: number
  onChange: (s: string) => void
  placeholder: string
  required?: boolean
  spellCheck?: boolean
  value: string | null
  visuallyHideLabel?: boolean
}> = ({
  "aria-describedby": ariaDescribedBy,
  hasError = false,
  label,
  maxLengthChars,
  onChange,
  placeholder,
  required = false,
  spellCheck = true,
  value,
  visuallyHideLabel = false,
}) => {
  const charLimitId = useId()

  const ariaDescribedByStr =
    maxLengthChars === undefined
      ? ariaDescribedBy
      : ariaDescribedBy === undefined
      ? charLimitId
      : `${charLimitId} ${ariaDescribedBy}`

  const textarea = (
    <textarea
      aria-invalid={hasError ? true : undefined}
      aria-describedby={ariaDescribedByStr}
      className={classNames(
        "otto-focus-inset w-full rounded border p-2 text-base",
        {
          "border-otto-grey-400 placeholder:text-otto-grey-700": !hasError,
          "otto-focus-bright-red border-otto-bright-red text-otto-deep-red placeholder-otto-deep-red focus:border-otto-bright-red":
            hasError,
        },
      )}
      maxLength={maxLengthChars}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      rows={5}
      spellCheck={spellCheck}
      required={required}
      value={value || ""}
    />
  )

  return (
    <div>
      {visuallyHideLabel ? (
        <label>
          <span className="sr-only">{label}</span>
          {textarea}
        </label>
      ) : (
        <InputLabel label={label}>{textarea}</InputLabel>
      )}

      {maxLengthChars !== undefined && (
        <CharacterLimit
          id={charLimitId}
          maxLengthChars={maxLengthChars}
          value={value}
        />
      )}
    </div>
  )
}

export default TextArea
