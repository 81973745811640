import {
  EnergySectionFormField,
  LayerFormField,
  SectionFormField,
} from "./types"
import {
  parseSectionPercentage,
  parseSectionValueAndCurrency,
} from "../parsers"
import { isKi1618EnergyLayer } from "./formUtils"

export const isLayerValid = (layer: LayerFormField): boolean => {
  const isKiEnergyLayer = isKi1618EnergyLayer(layer)

  const areCommonFieldsValid = Object.values(layer.sections).every(section =>
    validateCommonSectionFields(section),
  )

  const areNonEnergyFieldsValid = !isKiEnergyLayer
    ? layer.minorClass.validated.valid && layer.subClass.validated.valid
    : true

  const isEnergyLayerValid = isKiEnergyLayer
    ? Object.values(layer.sections)
        .filter(section => section.nominatedSyndicateId === 1618)
        .every(section =>
          validateEnergySectionFields(section as EnergySectionFormField),
        )
    : true

  return areCommonFieldsValid && areNonEnergyFieldsValid && isEnergyLayerValid
}

const validateCommonSectionFields = (section: SectionFormField): boolean => {
  return (
    section.policyRef.validated.valid &&
    section.eea.validated.valid &&
    section.premiumAllocationValueAndCurrency.validated.valid &&
    section.premiumAllocationPercent.validated.valid
  )
}

const validateEnergySectionFields = (
  section: EnergySectionFormField,
): boolean => {
  return (
    section.limitAllocationValueAndCurrency.validated.valid &&
    section.limitAllocationPercent.validated.valid &&
    section.groupClass.validated.valid
  )
}

export const validateSectionFormField = (
  sectionFormField: SectionFormField,
  totalAllocatedPremium: number,
  sectionCountCheck: boolean,
  isTotalAllocatedPremiumValid: boolean,
): SectionFormField => {
  const premiumAllocationValueAndCurrency = {
    ...sectionFormField.premiumAllocationValueAndCurrency,
    validated: parseSectionValueAndCurrency(
      sectionFormField.premiumAllocationValueAndCurrency.raw,
      totalAllocatedPremium,
      sectionFormField.layerWrittenPremium ?? 0,
    ),
    showError:
      !sectionFormField.premiumAllocationValueAndCurrency.validated.valid ||
      (sectionCountCheck && !isTotalAllocatedPremiumValid),
  }

  const premiumAllocationPercent = {
    ...sectionFormField.premiumAllocationPercent,
    validated: parseSectionPercentage(
      sectionFormField.premiumAllocationPercent.raw,
      totalAllocatedPremium,
      sectionFormField.layerWrittenPremium ?? 0,
    ),
    showError:
      !sectionFormField.premiumAllocationPercent.validated.valid ||
      (sectionCountCheck && !isTotalAllocatedPremiumValid),
  }

  // Validate energy-specific fields if the section is of type EnergySectionFormField
  let limitAllocationValueAndCurrency, limitAllocationPercent, groupClass

  if ("limitAllocationValueAndCurrency" in sectionFormField) {
    const energySectionFormFields = sectionFormField as EnergySectionFormField
    limitAllocationValueAndCurrency = {
      ...energySectionFormFields.limitAllocationValueAndCurrency,
      validated: parseSectionValueAndCurrency(
        energySectionFormFields.limitAllocationValueAndCurrency.raw,
        totalAllocatedPremium,
        energySectionFormFields.layerWrittenPremium ?? 0,
      ),
      showError:
        !energySectionFormFields.limitAllocationValueAndCurrency.validated
          .valid ||
        (sectionCountCheck && !isTotalAllocatedPremiumValid),
    }

    limitAllocationPercent = {
      ...energySectionFormFields.limitAllocationPercent,
      validated: parseSectionPercentage(
        energySectionFormFields.limitAllocationPercent.raw,
        totalAllocatedPremium,
        energySectionFormFields.layerWrittenPremium ?? 0,
      ),
      showError:
        !energySectionFormFields.limitAllocationPercent.validated.valid ||
        (sectionCountCheck && !isTotalAllocatedPremiumValid),
    }

    groupClass = {
      ...energySectionFormFields.groupClass,
      showError: !energySectionFormFields.groupClass.validated.valid,
    }
  }

  return {
    ...sectionFormField,
    policyRef: {
      ...sectionFormField.policyRef,
      showError: !sectionFormField.policyRef.validated.valid,
    },
    eea: {
      ...sectionFormField.eea,
      showError: !sectionFormField.eea.validated.valid,
    },
    premiumAllocationValueAndCurrency,
    premiumAllocationPercent,
    ...(limitAllocationValueAndCurrency && { limitAllocationValueAndCurrency }),
    ...(limitAllocationPercent && { limitAllocationPercent }),
    ...(groupClass && { groupClass }),
  }
}
