import { FC } from "react"

const ReadOnlyEEAData: FC<{
  nonEEASplit: string | null
}> = ({ nonEEASplit }) => (
  <dl>
    <dt className="mb-1">
      <span aria-hidden>Non-EEA %</span>
    </dt>

    <dd className="block flex min-h-[2rem] w-full items-center justify-between gap-1 rounded border border-otto-grey-400 bg-otto-grey-200 px-2 text-base">
      <span className="truncate">{nonEEASplit}</span>
      <span>%</span>
    </dd>
  </dl>
)

export default ReadOnlyEEAData
