// Convert an Excel-style column header (e.g. `F` or `AB`) to a numerical index
// starting from 0
export const columnToIndex = (s: string): number => {
  s = s.toUpperCase()
  let idx = 0
  const len = s.length
  for (let pos = 0; pos < len; pos++) {
    // The charCode for 'A' is 65; we subtract 64 so that we multiply everything
    // by 1 rather than by 0
    idx += (s.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1)
  }
  return idx - 1
}

// Convert a numerical index starting from 0 to an Excel-style column header
// H/T https://stackoverflow.com/a/64456745
export const indexToColumn = (i: number): string => {
  let letters = ""
  while (i >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i % 26] + letters
    i = Math.floor(i / 26) - 1
  }
  return letters
}
