import type { SVGComponent } from "./IconWrapper"

const FactCheckSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 3H4.5C3.4 3 2.5 3.9 2.5 5V19C2.5 20.1 3.4 21 4.5 21H20.5C21.6 21 22.5 20.1 22.5 19V5C22.5 3.9 21.6 3 20.5 3ZM4.5 19V5H20.5V19H4.5ZM19.91 10.42L18.49 9L15.32 12.17L13.91 10.75L12.5 12.16L15.32 15L19.91 10.42ZM5.5 7H10.5V9H5.5V7ZM10.5 11H5.5V13H10.5V11ZM5.5 15H10.5V17H5.5V15Z"
      fill="currentColor"
    />
  </svg>
)

export default FactCheckSVG
