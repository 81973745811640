import { EndorsementType } from "@appia/api"
import { SelectOption } from "@appia/ui-components"

export const endorsementTypeLabels: Record<EndorsementType, string> = {
  request: "Requests",
  notice: "Notifications",
}

export const ENDORSEMENT_TYPES: EndorsementType[] = ["request", "notice"]

export const ENDORSEMENT_TYPE_OPTIONS: SelectOption[] = ENDORSEMENT_TYPES.map(
  type => ({
    value: type,
    label: endorsementTypeLabels[type],
  }),
)
