import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  forwardRef,
  useMemo,
} from "react"

import {
  Broker,
  PBQAAssuredName,
  PBQASearchResult,
  Umr,
  searchBrokers,
  searchPBQAAssuredNames,
  searchUmr,
  unarchivePBQA,
} from "@appia/api"
import { useGetUsers, useSearchPBQAs } from "src/swr"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"

import {
  Button,
  Initials,
  RestoreIcon,
  SelectOption,
  Toast,
} from "@appia/ui-components"
import StatusPill from "src/components/StatusPill"

import BaseTable, {
  TableControls as BaseTableControls,
  TableSettings as BaseTableSettings,
  Column,
  configureFilter,
} from "src/components/DashboardTable"
import EmptyData from "src/components/EmptyData"

import { logButtonClick, logPBQAUnarchive } from "src/amplitude"
import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"

import {
  prettyPrintBroker,
  prettyPrintDateString,
} from "src/utils/prettyPrinters"
import { compareByName, fullNameOrEmail } from "src/utils/users"

export const localStorageFiltersKey = "@otto/pbqa-table-archive-filters"
export const localStorageSettingsKey = "@otto/pbqa-table-archive-settings"

/*
 * ====================
 * Table config
 */
type ColumnKeys =
  | "assured_name"
  | "broker"
  | "umr"
  | "updated_by"
  | "inception_date"
  | "updated_at"
  | "archived_status"
  | "action"

// Configure the columns to render
const tableColumns: Column<ColumnKeys>[] = [
  {
    columnKey: "assured_name",
    label: "Assured name",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "broker",
    label: "Broker",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "umr",
    label: "UMR",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "updated_at",
    label: "Archive date",
    sortable: true,
  },
  {
    columnKey: "inception_date",
    label: "Inception date",
    sortable: true,
  },
  {
    columnKey: "updated_by",
    label: "Archived by",
    sortable: true,
  },
  {
    columnKey: "archived_status",
    label: "Previous status",
  },
  {
    columnKey: "action",
    label: "Action",
    visuallyHideLabel: true,
  },
]

// Configure the table's pagination, sorting, etc
export type TableSettings = BaseTableSettings<ColumnKeys>

export const defaultTableSettings: TableSettings = {
  pageNumber: 1,
  pageSize: 20,
  sortDirection: "desc",
  sortKey: "updated_at",
}

// The possible filters that may be active for the table
export interface TableFilters {
  assuredName: PBQAAssuredName[]
  broker: Broker[]
  umr: Umr[]
  archivedBy: string[]
}

export const defaultTableFilters: TableFilters = {
  assuredName: [],
  broker: [],
  umr: [],
  archivedBy: [],
}

/*
 * ====================
 * Table rendering
 */

// Render an individual table cell for the given column key and PBQA
const RenderCell: FC<{
  colKey: ColumnKeys
  pbqa: PBQASearchResult
  triggerToast: (type: Toast.ToastType, msg: string) => void
  updatePBQAs: () => void
}> = ({ colKey, pbqa, triggerToast, updatePBQAs }): ReactElement => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const { request: usersRequest } = useGetUsers()
  const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

  switch (colKey) {
    case "archived_status":
      return pbqa.archivedStatus ? (
        <StatusPill status={pbqa.archivedStatus} />
      ) : (
        <EmptyData />
      )
    case "assured_name":
      return pbqa.assuredName ? (
        <span title={pbqa.assuredName}>{pbqa.assuredName}</span>
      ) : (
        <EmptyData />
      )
    case "broker":
      return pbqa.brokerName ? (
        <span title={pbqa.brokerName}>{pbqa.brokerName}</span>
      ) : (
        <EmptyData />
      )
    case "umr":
      return pbqa.umr ? <span title={pbqa.umr}>{pbqa.umr}</span> : <EmptyData />
    case "inception_date":
      return pbqa.inceptionDate ? (
        <span>{prettyPrintDateString(pbqa.inceptionDate)}</span>
      ) : (
        <EmptyData />
      )
    case "updated_at":
      return <span>{prettyPrintDateString(pbqa.updatedAt)}</span>
    case "updated_by": {
      const updatedByUser = users.find(u => pbqa.updatedBy === u.id)
      const updatedByName = updatedByUser && fullNameOrEmail(updatedByUser)
      return updatedByName ? (
        <Initials
          initials={updatedByName
            .split(" ")
            .map(s => s[0])
            .join("")}
          label={updatedByName}
        />
      ) : (
        <EmptyData />
      )
    }
    case "action": {
      return (
        <Button
          label="Restore"
          theme="night"
          style="filled"
          size="small"
          icon={{ position: "right", icon: <RestoreIcon /> }}
          onClick={async () => {
            logButtonClick({
              buttonName: "Unarchive",
              containerName: "PBQA action menu",
              pageName,
            })

            logPBQAUnarchive({ pbqaId: pbqa.id })

            try {
              await unarchivePBQA(apiClient, pbqa.id)
              updatePBQAs()
              triggerToast("success", "Successfully restored PBQA")
            } catch (e) {
              if (e instanceof Error) {
                Sentry.captureException(e)
                triggerToast("error", "Failed to restore PBQA")
              }
            }
          }}
        />
      )
    }
  }
}

/*
 * ====================
 * Constants
 */
const TABLE_LABEL = "PBQA archived slips table"
const TABLE_ID = "pbqa-table-archive"

export const Table = forwardRef<
  HTMLTableElement,
  {
    tableFilters: TableFilters
    tableSettings: TableSettings
    setTableSettings: Dispatch<SetStateAction<TableSettings>>
    triggerToast: (type: Toast.ToastType, msg: string) => void
  }
>(({ tableFilters, tableSettings, setTableSettings, triggerToast }, ref) => {
  const { request: pbqasRequest, update: updatePBQAs } = useSearchPBQAs({
    order:
      // These cases shouldn't occur if the table UI is configured correctly
      tableSettings.sortKey === "archived_status" ||
      tableSettings.sortKey === "action"
        ? undefined
        : tableSettings.sortKey,
    orderBy: tableSettings.sortDirection,
    page: tableSettings.pageNumber,
    pageSize: tableSettings.pageSize,

    assuredName: tableFilters.assuredName.map(({ name }) => name),
    brokerId: tableFilters.broker.map(({ id }) => id),
    status: ["archived"],
    umr: tableFilters.umr.map(({ value }) => value),
    updatedBy: tableFilters.archivedBy,
  })

  return (
    <BaseTable
      id={TABLE_ID}
      label={TABLE_LABEL}
      dataRequest={RD.map(
        ({ items, hits }) => ({ rows: items, totalRows: hits }),
        pbqasRequest,
      )}
      settings={tableSettings}
      onSettingsChange={setTableSettings}
      columns={tableColumns}
      renderCell={(colKey, pbqa) => (
        <RenderCell
          colKey={colKey}
          pbqa={pbqa}
          triggerToast={triggerToast}
          updatePBQAs={updatePBQAs}
        />
      )}
      tableRef={ref}
    />
  )
})

export const TableControls: FC<{
  tableFilters: TableFilters
  setTableFilters: Dispatch<SetStateAction<TableFilters>>
  setTableSettings: Dispatch<SetStateAction<TableSettings>>
}> = ({ tableFilters, setTableFilters, setTableSettings }) => {
  const { request: usersRequest } = useGetUsers()

  const onFilterChange = <K extends keyof TableFilters>(
    key: K,
    val: TableFilters[K],
  ): void => {
    setTableSettings(ts => ({ ...ts, pageNumber: 1 }))
    setTableFilters(tf => ({ ...tf, [key]: val }))
  }

  const archivedByOptions = useMemo<SelectOption[]>(() => {
    const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

    return users
      .sort(compareByName)
      .map(u => ({ value: u.id, label: fullNameOrEmail(u) }))
  }, [usersRequest])

  return (
    <BaseTableControls
      filters={[
        configureFilter<PBQAAssuredName>({
          type: "async",
          uniqueKey: "assuredName",
          label: "Assured name",
          values: tableFilters.assuredName,
          onChange: vals => onFilterChange("assuredName", vals),
          loader: searchPBQAAssuredNames,
          mapResultToLabel: ({ name }) => name,
          mapResultToValue: ({ name }) => name,
        }),

        configureFilter<Broker>({
          type: "async",
          uniqueKey: "broker",
          label: "Broker",
          values: tableFilters.broker,
          onChange: vals => onFilterChange("broker", vals),
          loader: searchBrokers,
          mapResultToLabel: prettyPrintBroker,
          mapResultToValue: ({ id }) => id,
        }),

        configureFilter<Umr>({
          type: "async",
          uniqueKey: "umr",
          label: "UMR",
          values: tableFilters.umr,
          onChange: vals => onFilterChange("umr", vals),
          loader: searchUmr,
          mapResultToLabel: ({ value }) => value,
          mapResultToValue: ({ value }) => value,
        }),

        configureFilter<string>({
          type: "sync",
          uniqueKey: "archivedBy",
          label: "Archived by",
          values: tableFilters.archivedBy,
          onChange: vals => onFilterChange("archivedBy", vals),
          mapResultToLabel: v =>
            archivedByOptions.find(opt => opt.value === v)?.label ?? "",
          options: archivedByOptions,
        }),
      ]}
      onClear={() => {
        setTableSettings(defaultTableSettings)
        setTableFilters(defaultTableFilters)
      }}
      tableId={TABLE_ID}
      tableLabel={TABLE_LABEL}
    />
  )
}
