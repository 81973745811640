import { FC, PropsWithChildren, ReactElement } from "react"

import { SimpleHeader } from "@appia/ui-components"

import ErrorBoundary from "src/templates/ScreenErrorBoundary"

import "./Templates.css"

export const ScreenTemplateWithHeader: FC<
  PropsWithChildren<{
    pageTitle: string
    actionButton: ReactElement
  }>
> = ({ pageTitle, actionButton, children }) => (
  <div className="ScreenTemplateWithHeader h-screen">
    <SimpleHeader />

    <header className="bg-white shadow forced-colors:border-b">
      <div className="mx-auto flex h-16 max-w-screen items-center gap-4 px-4 sm:px-6 lg:px-8">
        {pageTitle && (
          <h1
            data-cy="page-title"
            className="text-lg font-bold leading-tight text-otto-grey-900 md:text-2xl"
          >
            {pageTitle}
          </h1>
        )}

        <div className="ml-auto">{actionButton}</div>
      </div>
    </header>

    <main className="main">
      <div className="mx-auto h-full py-4">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </main>
  </div>
)

export const ScreenTemplateWithoutHeader: FC<{
  icon: ReactElement
  heading: string
  body: ReactElement
}> = ({ icon, heading, body }) => (
  <div className="ScreenTemplateWithoutHeader h-screen">
    <SimpleHeader />

    <main className="main">
      <div className="mx-auto h-full max-w-screen py-4 px-4 sm:px-6 lg:px-8">
        <ErrorBoundary>
          <div className="flex h-full flex-col items-center justify-center">
            {icon}
            <h1 className="mt-4 text-4xl font-bold">{heading}</h1>
            {body}
            <p className="mt-2 w-[14rem] text-center">
              You can now close this window. Thank you for using Otto.
            </p>
          </div>
        </ErrorBoundary>
      </div>
    </main>
  </div>
)
