import { FC, useId } from "react"
import * as RD from "@appia/remote-data"
import { Document } from "@appia/api"
import { Card, DownloadIcon, IconButton } from "@appia/ui-components"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import { downloadFile } from "src/utils/download"
import { logButtonClick, logFileDownload } from "src/amplitude"
import DefinitionList from "src/components/DefinitionList"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"

interface ContractDocumentsProps {
  documentsRequest: RD.RemoteData<Error, Document[]>
  pageName: string
}

const ContractDocuments: FC<ContractDocumentsProps> = ({
  documentsRequest,
  pageName,
}) => {
  const documentsLabelId = useId()

  return RD.match(
    documentsRequest,
    <Loading />,
    <Loading />,
    (documents: Document[]) => (
      <section className="pt-4" aria-labelledby={documentsLabelId}>
        <h3 id={documentsLabelId} className="mb-2 text-xl">
          Documents
        </h3>

        {documents.map(doc => (
          <Card
            key={doc.id}
            className="mb-4 flex w-full items-start justify-between gap-8"
          >
            <DefinitionList
              className="contents"
              items={[
                { label: "Name", value: doc.name },
                {
                  label: "Uploaded on",
                  value: prettyPrintDateString(doc.createdAt),
                },
              ]}
            />

            <IconButton
              icon={<DownloadIcon />}
              size={6}
              label={`Download document ${doc.name}`}
              onClick={async () => {
                await downloadFile(doc.url, doc.name, doc.mimetype)

                logFileDownload({ fileName: doc.name })

                logButtonClick({
                  buttonName: "Download",
                  containerName: "Main",
                  pageName: pageName,
                })
              }}
            />
          </Card>
        ))}
      </section>
    ),
    documentError => (
      <ErrorMessage message="Failed to load documents" error={documentError} />
    ),
  )
}

export default ContractDocuments
