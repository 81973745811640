import { ContractResponse, KiSyndicateData } from "@appia/api"

export const hasKiLinesInContractResponse = (
  contractResponse: ContractResponse,
): boolean =>
  contractResponse.sections.some(section =>
    section.lines.some(line => line.nominatedSyndicateId === 1618),
  )

export const getKiSyndicateData = (
  contractResponse: ContractResponse,
): KiSyndicateData | null => {
  const kiLine = contractResponse.sections[0]?.lines.find(
    line => line.nominatedSyndicateId === 1618,
  )

  const kiLineData = kiLine ? kiLine.syndicateData.syndicateData : null

  return kiLineData
}

export const getKiProgrammeRef = (
  contractResponse: ContractResponse,
): string => {
  const kiProgrammeRef = contractResponse.sections[0]?.lines.find(
    line => line.nominatedSyndicateId === 1618,
  )?.syndicateData.syndicateData.britProgrammeReference

  return kiProgrammeRef ? kiProgrammeRef : "N/A"
}
