import { FC, useId } from "react"
import { PBQAAnswer } from "@appia/api"
import { QuestionAnswerState, QuestionAnswerStateEEA } from "ReviewPBQA/state"

import { InputLabel, formatAmount } from "@appia/ui-components"

import NonBooleanQuestion from "./NonBooleanQuestion"
import QuestionInput from "../components/QuestionInput"
import ReadOnlyEEAData from "./ReadOnlyEEAData"

const EEAQuestionWrapper: FC<{
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  questionAnswerState: Extract<QuestionAnswerState, QuestionAnswerStateEEA>
  showFieldErrors: boolean
}> = ({
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  questionAnswerState,
  showFieldErrors,
}) => {
  const inputId = useId()
  const inputLabelId = useId()

  const { question, answer } = questionAnswerState

  return (
    <NonBooleanQuestion
      isActive={isActive}
      onChangeAnswer={onChangeAnswer}
      onJumpToBoundingBox={onJumpToBoundingBox}
      questionAnswerState={questionAnswerState}
      questionComponent={
        <div className="grid w-full grid-cols-1 items-stretch justify-between gap-4 lg:grid-cols-2">
          <InputLabel
            htmlFor={inputId}
            id={inputLabelId}
            label={question.label}
          >
            <QuestionInput
              inputId={inputId}
              inputLabelId={inputLabelId}
              onChangeAnswer={onChangeAnswer}
              questionAnswerState={questionAnswerState}
              showChangedValuesWarning={false}
              showFieldErrors={showFieldErrors}
            />
          </InputLabel>

          <ReadOnlyEEAData
            nonEEASplit={formatAmount(100 - (answer.answer ?? 0))}
          />
        </div>
      }
      showFieldErrors={showFieldErrors}
    />
  )
}

export default EEAQuestionWrapper
