import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from "react"
import { NavLink } from "react-router-dom"

import {
  Broker,
  PBQAAssuredName,
  PBQASearchResult,
  PBQAStatus,
  Umr,
  archivePBQA,
  assignPBQA,
  duplicatePBQA,
  searchBrokers,
  searchPBQAAssuredNames,
  searchUmr,
  unassignPBQA,
} from "@appia/api"
import { useGetUsers, useSearchPBQAs } from "src/swr"
import * as RD from "@appia/remote-data"

import { logButtonClick, logPBQAArchive, logPBQADuplicate } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"

import {
  ModalOverlay,
  SelectOption,
  SpinnerIcon,
  Toast,
} from "@appia/ui-components"
import StatusPill, { prettyPrintStatus } from "src/components/StatusPill"

import BaseTable, {
  TableControls as BaseTableControls,
  TableSettings as BaseTableSettings,
  Column,
  configureFilter,
} from "src/components/DashboardTable"
import OwnerSelection from "src/components/OwnerSelection"
import EmptyData from "src/components/EmptyData"

import PBQAActionCTA from "./PBQAActionCTA"
import PBQAActionMenu from "./PBQAActionMenu"
import doPBQAAction from "../../utils/doPBQAAction"

import useApiClient from "src/contexts/ApiClientContext"

import {
  prettyPrintBroker,
  prettyPrintDateString,
} from "src/utils/prettyPrinters"
import { compareByName, fullNameOrEmail } from "src/utils/users"

export const localStorageFiltersKey = "@otto/pbqa-table-filters"
export const localStorageSettingsKey = "@otto/pbqa-table-settings"

/*
 * ====================
 * Table config
 */
type ColumnKeys =
  | "assured_name"
  | "broker"
  | "inception_date"
  | "owned_by"
  | "submission_date"
  | "umr"
  | "status"
  | "action"

// Configure the columns to render
const tableColumns: Column<ColumnKeys>[] = [
  {
    columnKey: "assured_name",
    label: "Assured name",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "broker",
    label: "Broker",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "umr",
    label: "UMR",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "owned_by",
    label: "Owner",
    sortable: true,
  },
  {
    columnKey: "inception_date",
    label: "Inception date",
    sortable: true,
  },
  {
    columnKey: "submission_date",
    label: "Upload date",
    sortable: true,
  },
  {
    columnKey: "status",
    label: "Status",
  },
  {
    columnKey: "action",
    label: "Action",
    visuallyHideLabel: true,
  },
]

// Configure the table's pagination, sorting, etc
export type TableSettings = BaseTableSettings<ColumnKeys>

export const defaultTableSettings: TableSettings = {
  pageNumber: 1,
  pageSize: 20,
  sortDirection: "desc",
  sortKey: "submission_date",
}

// The possible filters that may be active for the table
export interface TableFilters {
  assuredName: PBQAAssuredName[]
  broker: Broker[]
  umr: Umr[]
  ownerId: string[]
  status: PBQAStatus[]
}

export const defaultTableFilters: TableFilters = {
  assuredName: [],
  broker: [],
  umr: [],
  ownerId: [],
  status: [],
}

/*
 * ====================
 * Table rendering
 */

// Render an individual table cell for the given column key and PBQA
const RenderCell: FC<{
  colKey: ColumnKeys
  pbqa: PBQASearchResult
  setLoadingOverlay: (text: string | null) => void
  triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
  updatePBQAs: () => void
}> = ({
  colKey,
  pbqa,
  setLoadingOverlay,
  triggerToast,
  updatePBQAs,
}): ReactElement => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  switch (colKey) {
    case "assured_name":
      return pbqa.assuredName ? (
        <span title={pbqa.assuredName}>{pbqa.assuredName}</span>
      ) : (
        <EmptyData />
      )
    case "broker":
      return pbqa.brokerName ? (
        <span title={pbqa.brokerName}>{pbqa.brokerName}</span>
      ) : (
        <EmptyData />
      )
    case "umr":
      return pbqa.umr ? <span title={pbqa.umr}>{pbqa.umr}</span> : <EmptyData />
    case "owned_by": {
      const buttonLabel = pbqa.assuredName
        ? `Change PBQA owner for ${pbqa.assuredName}`
        : `Change PBQA owner`
      return (
        <OwnerSelection
          buttonLabel={buttonLabel}
          ownerId={pbqa.ownerId}
          onAssign={async (assigneeId, assigneeName) =>
            doPBQAAction(
              () => assignPBQA(apiClient, pbqa.id, assigneeId),
              "Assigning PBQA",
              () => `Successfully assigned PBQA to ${assigneeName}`,
              "Failed to assign PBQA",
              setLoadingOverlay,
              triggerToast,
              updatePBQAs,
            )
          }
          onUnassign={async () =>
            doPBQAAction(
              () => unassignPBQA(apiClient, pbqa.id),
              "Removing PBQA owner",
              () => "Successfully removed PBQA owner",
              "Failed to remove PBQA owner",
              setLoadingOverlay,
              triggerToast,
              updatePBQAs,
            )
          }
        />
      )
    }
    case "inception_date":
      return pbqa.inceptionDate ? (
        <span>{prettyPrintDateString(pbqa.inceptionDate)}</span>
      ) : (
        <EmptyData />
      )
    case "submission_date":
      return <span>{prettyPrintDateString(pbqa.createdAt)}</span>
    case "status":
      return <StatusPill status={pbqa.status} />
    case "action": {
      return (
        <div className="flex items-center justify-between gap-1">
          <div className="w-full">
            <PBQAActionCTA pbqa={pbqa} tableLabel={TABLE_LABEL} />
          </div>

          <PBQAActionMenu
            pbqa={pbqa}
            onArchive={() => {
              logButtonClick({
                buttonName: "Archive",
                containerName: "PBQA action menu",
                pageName,
              })

              logPBQAArchive({ pbqaId: pbqa.id })

              doPBQAAction(
                () => archivePBQA(apiClient, pbqa.id),
                "Archiving PBQA",
                () => "Successfully archived PBQA",
                "Failed to archive PBQA",
                setLoadingOverlay,
                triggerToast,
                updatePBQAs,
              )
            }}
            onDuplicate={() => {
              logButtonClick({
                buttonName: "Duplicate",
                containerName: "PBQA action menu",
                pageName,
              })

              logPBQADuplicate({ pbqaId: pbqa.id })

              doPBQAAction(
                async () => {
                  const {
                    data: { pbqaId },
                  } = await duplicatePBQA(apiClient, pbqa.id)
                  return pbqaId
                },
                "Duplicating PBQA",
                duplicatePBQAId => (
                  <p>
                    Duplicated successfully.{" "}
                    <NavLink
                      to={`/pbqa/review/${duplicatePBQAId}`}
                      className="underline"
                    >
                      View new PBQA
                    </NavLink>
                  </p>
                ),
                "Failed to duplicate PBQA",
                setLoadingOverlay,
                triggerToast,
                updatePBQAs,
              )
            }}
          />
        </div>
      )
    }
  }
}

/*
 * ====================
 * Constants
 */
const TABLE_LABEL = "PBQA slips table"
const TABLE_ID = "pbqa-table-active"

const STATUSES: PBQAStatus[] = [
  "created",
  "processing",
  "review_required",
  "review_in_progress",
  "review_complete",
  "failed",
]

export const Table = forwardRef<
  HTMLTableElement,
  {
    tableFilters: TableFilters
    tableSettings: TableSettings
    setTableSettings: Dispatch<SetStateAction<TableSettings>>
    triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
  }
>(({ tableFilters, tableSettings, setTableSettings, triggerToast }, ref) => {
  const { request: pbqasRequest, update: updatePBQAs } = useSearchPBQAs({
    assuredName: tableFilters.assuredName.map(({ name }) => name),
    brokerId: tableFilters.broker.map(({ id }) => id),
    order:
      // These cases shouldn't occur if the table UI is configured correctly
      tableSettings.sortKey === "status" || tableSettings.sortKey === "action"
        ? undefined
        : tableSettings.sortKey,
    orderBy: tableSettings.sortDirection,
    ownerId: tableFilters.ownerId,
    page: tableSettings.pageNumber,
    pageSize: tableSettings.pageSize,
    status: tableFilters.status,
    umr: tableFilters.umr.map(({ value }) => value),
  })

  const [loadingOverlay, setLoadingOverlay] = useState<string | null>(null)

  return (
    <>
      <BaseTable
        id={TABLE_ID}
        label={TABLE_LABEL}
        dataRequest={RD.map(
          ({ items, hits }) => ({ rows: items, totalRows: hits }),
          pbqasRequest,
        )}
        settings={tableSettings}
        onSettingsChange={setTableSettings}
        columns={tableColumns}
        renderCell={(colKey, pbqa) => (
          <RenderCell
            colKey={colKey}
            pbqa={pbqa}
            setLoadingOverlay={setLoadingOverlay}
            triggerToast={triggerToast}
            updatePBQAs={updatePBQAs}
          />
        )}
        tableRef={ref}
      />

      {loadingOverlay && (
        <ModalOverlay
          bgColor="bg-white"
          isOpen
          onClose={() => {
            setLoadingOverlay(null)
          }}
        >
          <div className="isolate inline-block text-center">
            <p className="mb-4 text-5xl font-bold">{loadingOverlay}</p>
            <SpinnerIcon className="mx-auto w-12 text-otto-pop" />
          </div>
        </ModalOverlay>
      )}
    </>
  )
})

export const TableControls: FC<{
  tableFilters: TableFilters
  setTableFilters: Dispatch<SetStateAction<TableFilters>>
  setTableSettings: Dispatch<SetStateAction<TableSettings>>
}> = ({ tableFilters, setTableFilters, setTableSettings }) => {
  const { request: usersRequest } = useGetUsers()

  const onFilterChange = <K extends keyof TableFilters>(
    key: K,
    val: TableFilters[K],
  ): void => {
    setTableSettings(ts => ({ ...ts, pageNumber: 1 }))
    setTableFilters(tf => ({ ...tf, [key]: val }))
  }

  const ownerOptions = useMemo<SelectOption[]>(() => {
    const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

    const unassignedOpt = { value: "unassigned", label: "Unassigned" }

    const usersOpts = users
      .sort(compareByName)
      .map(u => ({ value: u.id, label: fullNameOrEmail(u) }))

    return [unassignedOpt, ...usersOpts]
  }, [usersRequest])

  return (
    <BaseTableControls
      filters={[
        configureFilter<PBQAAssuredName>({
          type: "async",
          uniqueKey: "assuredName",
          label: "Assured name",
          values: tableFilters.assuredName,
          onChange: vals => onFilterChange("assuredName", vals),
          loader: searchPBQAAssuredNames,
          mapResultToLabel: ({ name }) => name,
          mapResultToValue: ({ name }) => name,
        }),

        configureFilter<Broker>({
          type: "async",
          uniqueKey: "broker",
          label: "Broker",
          values: tableFilters.broker,
          onChange: vals => onFilterChange("broker", vals),
          loader: searchBrokers,
          mapResultToLabel: prettyPrintBroker,
          mapResultToValue: ({ id }) => id,
        }),

        configureFilter<Umr>({
          type: "async",
          uniqueKey: "umr",
          label: "UMR",
          values: tableFilters.umr,
          onChange: vals => onFilterChange("umr", vals),
          loader: searchUmr,
          mapResultToLabel: ({ value }) => value,
          mapResultToValue: ({ value }) => value,
        }),

        configureFilter<string>({
          type: "sync",
          uniqueKey: "ownerId",
          label: "Owner",
          values: tableFilters.ownerId,
          onChange: vals => onFilterChange("ownerId", vals),
          mapResultToLabel: v =>
            ownerOptions.find(opt => opt.value === v)?.label ?? "",
          options: ownerOptions,
        }),

        configureFilter<PBQAStatus>({
          type: "sync",
          uniqueKey: "status",
          label: "Status",
          values: tableFilters.status,
          onChange: vals => onFilterChange("status", vals),
          mapResultToLabel: prettyPrintStatus,
          options: STATUSES.map(status => ({
            value: status,
            label: prettyPrintStatus(status),
          })),
        }),
      ]}
      onClear={() => {
        setTableSettings(defaultTableSettings)
        setTableFilters(defaultTableFilters)
      }}
      tableId={TABLE_ID}
      tableLabel={TABLE_LABEL}
    />
  )
}
