import React, { FC, useEffect, useId, useRef, useState } from "react"
import { DragDrop } from "@uppy/react"
import * as Sentry from "@sentry/react"
import { ErrorMessage, FileUploadArea } from "@appia/ui-components"

import {
  Failure,
  Loading,
  NotAsked,
  RemoteData,
  isFailure,
  isLoading,
  match,
} from "@appia/remote-data"

import {
  ConfirmFileUploadRequest,
  ContractFileSource,
  ContractFileType,
  UploadFileRequest,
  confirmFileUpload,
  generateUploadUrl,
} from "@appia/api"

import { SuccessResponse } from "@uppy/core"

import useUppy from "@appia/file-upload"
import { StorageProvider } from "@appia/file-upload/src/storageProvider"
import useApiClient from "src/contexts/ApiClientContext"
import * as RD from "@appia/remote-data"

import SuccessModal from "./SuccessModal"
import "../../../components/UploadDocument/UploadArea.css"

import {
  logContractDocumentUploadComplete,
  logContractDocumentUploadStart,
} from "../../../amplitude"

import ProgressBar from "../../../components/UploadDocument/ProgressBar"

import { ACCEPTED_FILE_TYPES, FILETYPE_MSG } from "./acceptedFileTypes"
import { ERROR_MESSAGES } from "./errorMessages"
import classNames from "classnames"

const PROGRESS_ID = "upload-progress"

interface DocumentsUploadAreaProps {
  type: keyof typeof ContractFileType
  source: keyof typeof ContractFileSource
  disabled?: boolean
  contractVersionId: number
  umr: string
}

export const DocumentsUploadArea: FC<DocumentsUploadAreaProps> = ({
  type,
  source,
  disabled,
  contractVersionId,
  umr,
}) => {
  const [uploadRequest, setUploadRequest] =
    useState<RemoteData<Error, SuccessResponse>>(NotAsked)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const typeRef = useRef(type)
  const sourceRef = useRef(source)
  const contractVersionIdRef = useRef(contractVersionId)
  const umrRef = useRef(umr)

  useEffect(() => {
    typeRef.current = type
    sourceRef.current = source
    contractVersionIdRef.current = contractVersionId
    umrRef.current = umr
  }, [type, source, contractVersionId, umr])

  const apiClient = useApiClient()

  const uppy = useUppy({
    maxMegabytes: 50,
    acceptedDocumentTypes: ACCEPTED_FILE_TYPES,
    provider: StorageProvider.GCS,
    getUploadParameters: async file => {
      if (!file.type) {
        Sentry.captureException(new Error(ERROR_MESSAGES.fileTypeUnrecognised))
        throw new Error(ERROR_MESSAGES.fileTypeUnrecognised)
      }

      const uploadRequest: UploadFileRequest = {
        type: typeRef.current,
        source: sourceRef.current,
        umr: umrRef.current,
        file_name: file.name ?? "UNKNOWN_FILE_NAME",
        content_type: file.type,
        contract_version_id: contractVersionIdRef.current,
      }

      logContractDocumentUploadStart({
        fileId: file.id,
        umr: uploadRequest.umr,
        type: uploadRequest.type,
        mimetype: file.type,
      })

      const response = await generateUploadUrl(apiClient, uploadRequest)

      if (response.status !== 200) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.serverUploadError(
              response.status,
              response.statusText,
              uploadRequest.umr,
              uploadRequest.file_name,
            ),
          ),
        )
        throw new Error(
          ERROR_MESSAGES.serverUploadError(
            response.status,
            response.statusText,
            uploadRequest.umr,
            uploadRequest.file_name,
          ),
        )
      }

      const { upload_url, file_id: gcpFileId } = response.data
      if (!upload_url) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.missingUploadUrl(
              uploadRequest.umr,
              uploadRequest.file_name,
            ),
          ),
        )
        throw new Error(ERROR_MESSAGES.genericError)
      }

      if (!gcpFileId) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.missingFileId(
              uploadRequest.umr,
              uploadRequest.file_name,
            ),
          ),
        )
        throw new Error(ERROR_MESSAGES.genericError)
      }

      uppy.setFileMeta(file.id, {
        fileId: gcpFileId,
        umr: uploadRequest.umr,
        fileName: uploadRequest.file_name,
      })

      return {
        url: upload_url,
        method: "PUT",
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      }
    },
    onStartLoad: () => setUploadRequest(Loading),
    onProgress: setUploadProgress,
    onError: async uppyError => {
      Sentry.captureException(uppyError.error)
      setUploadRequest(Failure(new Error(ERROR_MESSAGES.genericError)))
    },
    onSuccess: async (res, file) => {
      if (!file) {
        Sentry.captureException(ERROR_MESSAGES.undefinedFile)
        throw new Error(ERROR_MESSAGES.genericError)
      }

      const fileId = file.meta["fileId"] as string
      const umr = file.meta["umr"] as string
      const fileName = file.meta["fileName"] as string

      if (fileId) {
        const confirmUploadRequest: ConfirmFileUploadRequest = {
          file_id: fileId,
        }
        logContractDocumentUploadComplete({ fileId })
        const response = await confirmFileUpload(
          apiClient,
          confirmUploadRequest,
        )
        if (response.status !== 200) {
          Sentry.captureException(
            new Error(
              ERROR_MESSAGES.serverConfirmError(
                response.status,
                response.statusText,
                umr,
                fileName,
              ),
            ),
          )
          throw new Error(
            ERROR_MESSAGES.serverConfirmError(
              response.status,
              response.statusText,
              umr,
              fileName,
            ),
          )
        }
        setUploadRequest(RD.Success(res))
        setIsSuccessModalOpen(true)
      } else {
        Sentry.captureException(ERROR_MESSAGES.missingFileIdMetadata)
        throw new Error(ERROR_MESSAGES.genericError)
      }
    },
  })

  const loadingId = useId()
  const errorId = useId()

  return (
    <div
      className={classNames(
        "flex h-full flex-col items-center justify-center gap-6",
        {
          "cursor-not-allowed": disabled,
        },
      )}
    >
      <div
        className={classNames("w-full", { "pointer-events-none": disabled })}
      >
        <div className="mx-auto w-full max-w-md">
          <div role="alert" className="mx-auto w-full max-w-full bg-white">
            {match(
              uploadRequest,
              null,
              <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-otto-grey-100 bg-opacity-75 transition-opacity">
                <ProgressBar id={PROGRESS_ID} progress={uploadProgress} />
              </div>,
              () => (
                <SuccessModal
                  isOpen={isSuccessModalOpen}
                  onClose={() => {
                    setIsSuccessModalOpen(false)
                    setUploadRequest(NotAsked)
                    setUploadProgress(0)
                  }}
                />
              ),
              error => (
                <div className="mb-8">
                  <ErrorMessage message={`Upload failed: ${error.message}`} />
                </div>
              ),
            )}
          </div>
          <div className="relative mx-auto w-full max-w-md">
            {disabled && (
              <div
                data-testid="upload-overlay"
                className="pointer-events-none absolute inset-0 rounded-md bg-[#CCCCCC] opacity-50"
                style={{ zIndex: 10 }}
              ></div>
            )}
            <div
              data-testid="upload-area"
              className={classNames(
                "flex flex-col items-center justify-center rounded-md border-2 bg-white",
                {
                  "pointer-events-none": disabled,
                },
              )}
            >
              <FileUploadArea
                label={
                  <div className="grid gap-2 text-center">
                    <p className="text-xl font-bold">Drop your file here</p>
                    <p className="text-base">
                      Or browse files from your computer
                    </p>
                    <p className="text-base">{FILETYPE_MSG}</p>
                  </div>
                }
                input={
                  <div className="relative">
                    <DragDrop
                      uppy={uppy}
                      className="absolute bottom-0 left-0 right-0 top-0 cursor-pointer opacity-0"
                      aria-describedby={
                        isLoading(uploadRequest)
                          ? loadingId
                          : isFailure(uploadRequest)
                          ? errorId
                          : undefined
                      }
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
