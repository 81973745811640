import { FC } from "react"
import {
  Callout,
  Input,
  InputLabel,
  Select,
  SelectOption,
  UnitsInput,
} from "@appia/ui-components"
import { currencyOptions } from "./mockData"
import { ValueAndCurrency } from "./parsers"
import InputWithError from "src/components/InputWithError"
import { FormField } from "@appia/form-data"
import usePolicyReference from "./utils/usePolicyReference"

const eeaOrRowOptions: SelectOption[] = [
  { value: "eea", label: "EEA" },
  { value: "row", label: "ROW" },
]

interface ContractSectionsPanelProps {
  policyReference: FormField<string, string> & { showError: boolean }
  nominatedSyndicateId: number | null
  onChangePolicyReference: (value: string) => void
  eeaOrRow: FormField<boolean, boolean | null> & { showError: boolean }
  onChangeEeaOrRow: (value: boolean | null) => void
  premiumAllocationValueAndCurrency: FormField<
    ValueAndCurrency,
    ValueAndCurrency
  > & { showError: boolean }
  onChangePremiumAllocationValueAndCurrency: (value: ValueAndCurrency) => void
  premiumAllocationPercent: FormField<number, number | null> & {
    showError: boolean
  }
  onChangePremiumAllocationPercent: (value: number | null) => void
}

const ContractSectionsPanel: FC<ContractSectionsPanelProps> = ({
  policyReference,
  nominatedSyndicateId,
  onChangePolicyReference,
  eeaOrRow,
  onChangeEeaOrRow,
  premiumAllocationValueAndCurrency,
  onChangePremiumAllocationValueAndCurrency,
  premiumAllocationPercent,
  onChangePremiumAllocationPercent,
}) => {
  const { isEditing, originalReference, handleChangeOfPolicyReference } =
    usePolicyReference(policyReference, onChangePolicyReference)

  return (
    <div>
      {nominatedSyndicateId === 1618 ? (
        <section className="ml-6 mb-8 mt-4">
          <InputLabel
            label="Ki Policy Reference"
            className="!mb-0 py-2 !font-bold"
          >
            <div className="mb-2 w-1/2">
              <InputWithError
                errorTestId="policy-reference-error-message"
                formField={policyReference}
                showFormFieldErrors={policyReference.showError}
                input={errorId => (
                  <Input
                    hasError={!!errorId}
                    id="policy-reference"
                    data-testid="policy-reference"
                    onChange={handleChangeOfPolicyReference}
                    type="text"
                    value={policyReference.raw}
                  />
                )}
              />
              {isEditing && (
                <div className="text-gray-600 mb-2 text-sm">
                  Original Policy Reference: {originalReference}
                </div>
              )}
            </div>
          </InputLabel>
          <InputLabel label="EEA or ROW" className="!mb-0 py-2 !font-bold">
            <div className="mb-2 w-1/2">
              <InputWithError
                errorTestId="eea-or-row-error-message"
                formField={eeaOrRow}
                showFormFieldErrors={eeaOrRow.showError}
                input={errorId => (
                  <Select
                    errorMessageId={errorId}
                    options={eeaOrRowOptions}
                    selectedValue={
                      eeaOrRow.raw !== null
                        ? eeaOrRow.raw
                          ? "eea"
                          : "row"
                        : null
                    }
                    onSelect={(value: string) => {
                      onChangeEeaOrRow(
                        value === "eea" ? true : value === "row" ? false : null,
                      )
                    }}
                    placeholder=""
                  />
                )}
              />
            </div>
          </InputLabel>
          <InputLabel
            label="Premium Allocation Value"
            className="!mb-0 py-2 !font-bold"
          >
            <div className="flex w-3/4">
              <div className="w-8/12">
                <InputWithError
                  errorTestId="premium-allocation-value-error-message"
                  formField={premiumAllocationValueAndCurrency}
                  showFormFieldErrors={
                    premiumAllocationValueAndCurrency.showError
                  }
                  input={errorId => (
                    <UnitsInput
                      errorMessageId={errorId}
                      id="currency-select"
                      labelId="currency-select-label"
                      amountTestId="premium-allocation-value"
                      unitsTestId="premium-allocation-currency"
                      onChange={onChangePremiumAllocationValueAndCurrency}
                      units={currencyOptions.map(option => option.label)}
                      value={{
                        amount: premiumAllocationValueAndCurrency.raw.amount,
                        unit: premiumAllocationValueAndCurrency.raw.unit,
                      }}
                      precision={2}
                    />
                  )}
                />
              </div>
              <span className="mx-2.5 flex h-8 items-center text-lg font-bold">
                /
              </span>
              <div className="w-3/12">
                <InputWithError
                  errorTestId="premium-allocation-percent-error-message"
                  formField={premiumAllocationPercent}
                  showFormFieldErrors={premiumAllocationPercent.showError}
                  input={errorId => (
                    <UnitsInput
                      errorMessageId={errorId}
                      id="percentage-select"
                      labelId="percentage-select-label"
                      amountTestId="premium-allocation-percent"
                      onChange={event => {
                        onChangePremiumAllocationPercent(event.amount)
                      }}
                      units={"%"}
                      value={{
                        amount: premiumAllocationPercent.raw,
                        unit: null,
                      }}
                      precision={2}
                    />
                  )}
                />
              </div>
            </div>
          </InputLabel>
        </section>
      ) : (
        <Callout type="information">
          <p className="mx-1 font-bold">Ki 1618 is not on contract.</p>
        </Callout>
      )}
    </div>
  )
}

export default ContractSectionsPanel
