import { FC } from "react"
import EmptyData from "src/components/EmptyData"
import { isEmpty } from "src/utils/typeRefinements"

export interface Row {
  cells: string[]
}

export interface Props {
  caption: string
  headers: string[]
  rows: Row[]
}

export const Table: FC<Props> = ({ caption, headers, rows }) => (
  <table className="w-full">
    <caption className="sr-only">{caption}</caption>
    <thead>
      <tr className="text-left font-bold">
        {headers.map((header, i) => (
          <th key={i}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.length > 0 ? (
        rows.map(({ cells }, i) => (
          <tr key={i} className="border-b last:border-none">
            {cells.map((cell, i) => (
              <td
                title={cell}
                className="max-w-[8rem] truncate pr-5 last:pr-0"
                key={i}
              >
                {isEmpty(cell) ? <EmptyData /> : cell}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          {headers.map((_, i) => (
            <td key={i}>
              <EmptyData />
            </td>
          ))}
        </tr>
      )}
    </tbody>
  </table>
)
