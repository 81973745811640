import React from "react"
import ScreenTemplate from "src/templates/ScreenTemplate"
import { useLogPageView } from "src/amplitude"
import { PageNameContext } from "src/contexts/PageNameContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import UploadArea from "./UploadArea"
import { useLocation } from "react-router-dom"

const PAGE_NAME = "Upload Endorsement"

const EndorsementUploadAreaScreen: React.FC = (): JSX.Element => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const location = useLocation()
  const stateAnswers = location.state

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        layout={{
          type: "regular",
          backPath: "/endorsements/upload",
          state: stateAnswers,
        }}
      >
        <UploadArea stateAnswers={stateAnswers} />
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default EndorsementUploadAreaScreen
