import { FC, ReactNode } from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"

import { Callout, InformationCircleIcon, SearchBar } from "@appia/ui-components"
import { ID } from "src/components/A11y"
import EmptyData from "src/components/EmptyData"

import { KiQuote } from "@appia/api"

import usePageName from "src/contexts/PageNameContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import { PBQAReviewState } from "ReviewPBQA/state"

import Filters from "./Filters"
import {
  KiQuoteSearchFilters,
  buildKiQuoteSearchQuery,
} from "ReviewPBQA/kiQuoteSearch"

import * as RD from "@appia/remote-data"
import { useGetPBQAInfo } from "src/swr"

import { logButtonClick } from "src/amplitude"
import { prettyPrintKiJourneyType } from "src/utils/prettyPrinters"
import { useDebouncedCallback } from "use-debounce"
import { PBQASurveyWithDetails } from "src/screens/ReviewPBQA/useGetSurveys"
import {
  QuoteLineMatchingSearchResults,
  QuoteLineResultsHeader,
} from "./QuoteLineMatchingSearchResults"
import calculateQuoteLineMatching from "./calculateQuoteLineMatchingPercent"

export const SelectedQuote: FC<{
  selectedQuote: KiQuote | null
}> = ({ selectedQuote }) => {
  const { pbqaId } = usePBQASurvey()
  const { request: pbqaInfoRequest } = useGetPBQAInfo(pbqaId)

  const originalPBQAId =
    (RD.isSuccess(pbqaInfoRequest) && pbqaInfoRequest.data.originalPbqaId) ||
    null

  return (
    <div
      role="alert"
      data-testid="selected-quote-alert"
      className={classNames("border-t border-t-otto-grey-400 p-3", {
        "bg-ki-light": !!selectedQuote,
      })}
    >
      <p className="mb-1 text-otto-grey-800">Selected quote:</p>

      {selectedQuote ? (
        <dl className="grid gap-x-4 gap-y-1 font-bold text-black md:grid-cols-[1fr,auto]">
          <div>
            <dt className="sr-only">Assured name:</dt>
            <dd>{selectedQuote.assuredName || <EmptyData />}</dd>
          </div>

          <div className="flex flex-wrap gap-x-1">
            <dt>
              Quote Line <ID />:
            </dt>
            <dd data-testid="selected-quote-line-id">
              {selectedQuote.quoteLineId}
            </dd>
          </div>

          <div>
            <dt className="sr-only">Quote journey type:</dt>
            <dd>{prettyPrintKiJourneyType(selectedQuote.journeyType)}</dd>
          </div>

          <div className="flex flex-wrap gap-x-1">
            <dt>
              Quote <ID />:
            </dt>
            <dd data-testid="selected-quote-id">
              {selectedQuote.quoteId ? selectedQuote.quoteId : "-"}
            </dd>
          </div>

          {selectedQuote.riskType === "assisted_new_business" && (
            <div>
              <dt className="sr-only">Quote risk type:</dt>
              <dd>Assisted Journey Quote</dd>
            </div>
          )}
        </dl>
      ) : (
        <p className="font-bold">No quote selected</p>
      )}

      {originalPBQAId && (
        <p className="mt-2 flex flex-wrap justify-between gap-x-4 gap-y-1">
          <span className="text-otto-grey-700">
            <InformationCircleIcon className="inline w-5" /> This is a
            duplicated PBQA checklist
          </span>
          <NavLink to={`/pbqa/review/${originalPBQAId}`} className="underline">
            View original PBQA
          </NavLink>
        </p>
      )}
    </div>
  )
}

const Strong: FC<{ children: ReactNode }> = ({ children }) => (
  <strong className="font-bold uppercase">{children}</strong>
)

const QuoteCallout: FC<{ selectedQuote: KiQuote }> = ({ selectedQuote }) => {
  const { firmOrderNoticeRequestedAt, validUntil, isIndication } = selectedQuote

  const expired: boolean = validUntil
    ? new Date(validUntil).valueOf() < Date.now()
    : false

  if (expired) {
    return firmOrderNoticeRequestedAt ? (
      <Callout style="filled" type="warning" rounded={false}>
        <p>
          This quote is <Strong>not valid</Strong>, please check all syndicates
          on risk have <Strong>FON</Strong>s before binding.
        </p>
      </Callout>
    ) : (
      <Callout style="filled" type="error" rounded={false}>
        <p>
          This quote is <Strong>not valid</Strong>, do not bind this risk unless
          agreed by an underwriter.
        </p>
      </Callout>
    )
  } else if (isIndication) {
    return (
      <Callout style="filled" type="error" rounded={false}>
        <p>
          <Strong>THIS IS AN INDICATION</Strong>, do not bind this risk unless
          explicitly agreed by an underwriter.
        </p>
      </Callout>
    )
  } else {
    return null
  }
}

const KiQuoteSearchBar: FC<{
  onManuallyChangeQuote: (q: KiQuote) => void
  selectedQuote: KiQuote | null
  state: PBQAReviewState
  searchResults: KiQuote[]
  quoteSearchBarTestId: string
  searchAndSetResults: (q: string) => Promise<void>
  activeSurvey: PBQASurveyWithDetails
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchFilters: Partial<KiQuoteSearchFilters>
  setSearchFilters: React.Dispatch<
    React.SetStateAction<Partial<KiQuoteSearchFilters>>
  >
  handleOpenModal: (
    quoteData: KiQuote | undefined,
    quoteMatchResult: number,
  ) => void
  setOpen: (open: boolean) => void
  open: boolean
  setHasConfirmedQuoteDetailsModal: (
    hasConfirmedQuoteDetailsModal: boolean,
  ) => void
  hasConfirmedQuoteDetailsModal: boolean
}> = ({
  onManuallyChangeQuote,
  selectedQuote,
  state,
  searchResults,
  quoteSearchBarTestId,
  searchAndSetResults,
  activeSurvey,
  searchText,
  setSearchText,
  searchFilters,
  setSearchFilters,
  handleOpenModal,
  setOpen,
  open,
  setHasConfirmedQuoteDetailsModal,
  hasConfirmedQuoteDetailsModal,
}) => {
  const pageName = usePageName()

  const searchAndSetResultsDebounced = useDebouncedCallback(
    searchAndSetResults,
    600,
  )

  const sortedSearchResultsWithMatchScore = searchResults
    .map(quote => {
      return {
        quote,
        matchScore: calculateQuoteLineMatching(activeSurvey, quote, state),
      }
    })
    .sort((a, b) => b.matchScore - a.matchScore)

  const sortedSearchResults = sortedSearchResultsWithMatchScore.map(
    item => item.quote,
  )

  const matchScore = (quote: KiQuote): number =>
    sortedSearchResultsWithMatchScore[sortedSearchResults.indexOf(quote)]
      .matchScore

  return (
    <div
      data-cy="quote-search"
      data-testid={quoteSearchBarTestId}
      className="border-b border-otto-grey-400 pt-3"
    >
      <div className="mb-3 px-4 py-1">
        <SearchBar
          beforeResults={
            sortedSearchResults.length > 0 && <QuoteLineResultsHeader />
          }
          filters={onSubmit => (
            <Filters
              filters={searchFilters}
              onSubmit={async updatedFilters => {
                logButtonClick({
                  buttonName: "Submit",
                  containerName: "Search filters",
                  pageName,
                })

                setSearchFilters(updatedFilters)

                const query = buildKiQuoteSearchQuery(updatedFilters)
                setSearchText(query)

                onSubmit()

                await searchAndSetResults(query)
              }}
              onReset={() => {
                logButtonClick({
                  buttonName: "Reset",
                  containerName: "Search filters",
                  pageName,
                })

                setSearchFilters({})

                onSubmit()
              }}
            />
          )}
          getOptionId={opt => opt.quoteLineId}
          label="Search for a quote"
          options={sortedSearchResults}
          placeholder="Search by assured name"
          searchText={searchText}
          selectedValue={selectedQuote}
          renderOption={quote => (
            <QuoteLineMatchingSearchResults
              activeSurvey={activeSurvey}
              quote={quote}
              state={state}
              handleOpenModal={handleOpenModal}
              searchResults={sortedSearchResults}
              quoteLineMatchScore={matchScore(quote)}
            />
          )}
          onSelect={quoteLineId => {
            const selectedQuote =
              sortedSearchResults.find(q => q.quoteLineId === quoteLineId) ||
              null
            if (selectedQuote) {
              onManuallyChangeQuote(selectedQuote)
            }
          }}
          onSearch={async query => {
            setSearchText(query)

            // Reset the filters when the user types into the input
            setSearchFilters({})

            await searchAndSetResultsDebounced(query)
          }}
          visuallyHideLabel
          setOpen={setOpen}
          open={open}
          setHasConfirmedQuoteDetailsModal={setHasConfirmedQuoteDetailsModal}
          hasConfirmedQuoteDetailsModal={hasConfirmedQuoteDetailsModal}
        />
      </div>

      {selectedQuote && <QuoteCallout selectedQuote={selectedQuote} />}

      <SelectedQuote selectedQuote={selectedQuote} />
    </div>
  )
}

export default KiQuoteSearchBar
