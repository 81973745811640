export const toArray = <T>(t: T | T[]): T[] => (Array.isArray(t) ? t : [t])

export interface GroupBy<Tag, Item> {
  tag: Tag
  items: Item[]
}

/**
 * Group together adjacent array items that have the same value for some
 * property. This does not change the order of the array items
 */
export const groupBy = <Tag, Item>(
  getMatchField: (currentItem: Item) => Tag,
  items: Item[],
): GroupBy<Tag, Item>[] =>
  items.reduce<GroupBy<Tag, Item>[]>((acc, item) => {
    const currentGroup = acc[acc.length - 1] ?? null

    const currentItemTag = getMatchField(item)

    // If there's no existing question group (because we've only just started
    // iterating) or the current group has a different tag to this group, start
    // a new group
    if (currentGroup === null || currentItemTag !== currentGroup.tag) {
      return [...acc, { items: [item], tag: currentItemTag }]
    }
    // Otherwise, add this item to the current group since the tags match
    else {
      return acc.slice(0, acc.length - 1).concat({
        ...currentGroup,
        items: [...currentGroup.items, item],
      })
    }
  }, [])
