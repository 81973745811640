import { FC } from "react"
import * as Toolbar from "@radix-ui/react-toolbar"

import { DownloadIcon, IconButton } from "@appia/ui-components"

import usePageName from "src/contexts/PageNameContext"

import { downloadFile } from "src/utils/download"
import { logButtonClick, logFileDownload } from "src/amplitude"

import { Document } from "@appia/api"

const CONTAINER_NAME = "Excel toolbar"

const ExcelToolbar: FC<{
  excelElementId: string
  documentName: Document["name"]
  documentUrl: Document["url"]
  documentMimeType: Document["mimetype"]
}> = ({ excelElementId, documentName, documentUrl, documentMimeType }) => {
  const pageName = usePageName()

  return (
    <Toolbar.Root
      aria-controls={excelElementId}
      aria-label="Excel controls"
      className="flex h-12 w-full items-center bg-black px-4 forced-colors:border-b"
    >
      <div className="ml-auto flex items-center">
        <Toolbar.Button asChild>
          <IconButton
            label="Download document"
            icon={<DownloadIcon />}
            color="text-white"
            size={6}
            onClick={async () => {
              downloadFile(documentUrl, documentName, documentMimeType)

              logFileDownload({ fileName: documentName })

              logButtonClick({
                buttonName: "Download",
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />
        </Toolbar.Button>
      </div>
    </Toolbar.Root>
  )
}

export default ExcelToolbar
