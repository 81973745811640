import { useState } from "react"
import { FormField } from "@appia/form-data"

interface PolicyReferenceHook {
  isEditing: boolean
  originalReference: string
  handleChangeOfPolicyReference: (value: string) => void
}

const usePolicyReference = (
  policyReference: FormField<string, string>,
  onChangePolicyReference: (value: string) => void,
): PolicyReferenceHook => {
  const [originalReference] = useState(policyReference.raw)
  const [isEditing, setIsEditing] = useState(false)

  const handleChangeOfPolicyReference = (value: string): void => {
    setIsEditing(value !== originalReference)
    onChangePolicyReference(value)
  }

  return <PolicyReferenceHook>{
    isEditing,
    originalReference,
    handleChangeOfPolicyReference,
  }
}

export default usePolicyReference
