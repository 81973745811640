import type { SVGComponent } from "./IconWrapper"

const AddNoteSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H5.17L4 17.17V4H20V16ZM13 6H11V9H8V11H11V14H13V11H16V9H13V6Z"
      fill="currentColor"
    />
  </svg>
)

export default AddNoteSVG
