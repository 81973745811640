import { FC, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"

import ScreenTemplate from "src/templates/ScreenTemplate"

import Survey from "./Survey"

import { PBQAFlowType } from "@appia/api"
import * as RD from "@appia/remote-data"
import { useGetCurrentUser, useGetPBQADocuments } from "src/swr"

import { useLogPBQAOpen, useLogPageView } from "src/amplitude"

import useGetSurveys from "./useGetSurveys"
import useGetPBQASyndicates from "./useGetPBQASyndicates"
import useGetPBQAQuote from "./useGetPBQAQuote"

import useDocumentTitle from "src/hooks/useDocumentTitle"
import { PageNameContext } from "src/contexts/PageNameContext"

import { compareByFileExtension } from "src/utils/documents"

const PAGE_NAME = "Review PBQA"

const ReviewScreen: FC = () => {
  const { id: pbqaId, section: surveySection } = useParams<{
    id: string
    section?: string
  }>()

  if (pbqaId === undefined) {
    throw new Error("Missing pbqaId in ReviewPBQA screen")
  }

  useLogPageView({ pageName: PAGE_NAME })
  useLogPBQAOpen({ pbqaId })

  useDocumentTitle(PAGE_NAME)

  const { request: userRequest } = useGetCurrentUser()

  const flowType: PBQAFlowType = RD.isSuccess(userRequest)
    ? userRequest.data.supportedFlows.pbqa
    : "basic"

  const surveysReq = useGetSurveys(pbqaId)
  const selectedSyndicatesReq = useGetPBQASyndicates(pbqaId)
  const quoteReq = useGetPBQAQuote(pbqaId)

  const [activeDocumentId, setActiveDocumentId] = useState<string | null>(null)

  const [activeSheetName, setActiveSheetName] = useState<string | null>(null)

  const { request: documentsRequest } = useGetPBQADocuments(pbqaId)

  const documents = useMemo(() => {
    if (!RD.isSuccess(documentsRequest)) {
      return []
    }

    return [...documentsRequest.data].sort(compareByFileExtension)
  }, [documentsRequest])

  /**
   * Set the active document ID when the documents load
   */
  useEffect(() => {
    if (!activeDocumentId && documents[0]) {
      setActiveDocumentId(documents[0].id)
    }
  }, [documents, activeDocumentId, setActiveDocumentId])

  if (flowType !== "ki") {
    return (
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        noMaxContentWidth
        layout={{ type: "regular", backPath: "/pbqa" }}
      >
        <div className="p-4">
          <ErrorMessage
            message={`Unsupported PBQA flow type: ${flowType}. Only Ki PBQAs are currently supported.`}
          />
        </div>
      </ScreenTemplate>
    )
  }

  return (
    <ScreenTemplate
      pageTitle={PAGE_NAME}
      noMaxContentWidth
      noPadding
      layout={{ type: "simple" }}
    >
      <PageNameContext.Provider value={PAGE_NAME}>
        {RD.matchAll(
          [surveysReq, selectedSyndicatesReq, quoteReq],

          <Loading className="mt-4" />,
          <Loading className="mt-4" />,

          (surveys, selectedSyndicates, quote) => {
            if (surveys.length === 0) {
              return (
                <div className="p-4">
                  <ErrorMessage message="No surveys were loaded. Please contact the Otto team for support." />
                </div>
              )
            }

            if (surveys.some(s => s.groupingHeaders.length === 0)) {
              return (
                <div className="p-4">
                  <ErrorMessage message="A survey contained zero sections. Please contact the Otto team for support." />
                </div>
              )
            }

            return (
              <Survey
                activeDocumentId={activeDocumentId}
                activeSectionUrl={surveySection ?? null}
                activeSheetName={activeSheetName}
                documents={documents}
                pbqaId={pbqaId}
                quote={quote}
                selectedSyndicates={selectedSyndicates}
                setActiveDocumentId={setActiveDocumentId}
                setActiveSheetName={setActiveSheetName}
                surveys={surveys}
              />
            )
          },

          (surveysErr, syndicatesErr, quoteErr) => {
            const errMsgs: [Error, string][] = []

            if (surveysErr) {
              errMsgs.push([surveysErr, "Failed to load PBQA surveys"])
            }

            if (syndicatesErr) {
              errMsgs.push([syndicatesErr, "Failed to load PBQA syndicates"])
            }

            if (quoteErr) {
              errMsgs.push([quoteErr, "Failed to load existing PBQA quote"])
            }

            return (
              <div className="grid gap-4 p-4">
                {errMsgs.length > 0 ? (
                  errMsgs.map(([error, message], i) => (
                    <ErrorMessage key={i} error={error} message={message} />
                  ))
                ) : (
                  <ErrorMessage message="Something went wrong" />
                )}
              </div>
            )
          },
        )}
      </PageNameContext.Provider>
    </ScreenTemplate>
  )
}

export default ReviewScreen
