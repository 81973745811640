import { FC, ReactNode } from "react"

interface Props {
  caption: string
  headers: ReactNode[]
  data: ReactNode[][]
}

const StaticTable: FC<Props> = ({ caption, headers, data }) => {
  return (
    <table className="w-full">
      <caption className="sr-only">{caption}</caption>

      <thead>
        <tr className="[&>th]:px-2 [&>th]:py-2 [&>th:first-of-type]:pl-3 [&>th:last-of-type]:pr-3 border border-otto-grey-300 bg-otto-grey-200 text-left font-bold forced-colors:border-y">
          {headers.map((header, index) => {
            return (
              <th
                key={index}
                className=" border-r border-otto-grey-300 first-of-type:border-l"
              >
                {header}
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className="[&>td]:px-2 [&>td]:py-1 [&>td:first-of-type]:pl-3 [&>td:last-of-type]:pr-3 border-b border-otto-grey-300 text-left"
          >
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                className="border-r border-otto-grey-300 first-of-type:border-l"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StaticTable
