import axios, { AxiosInstance, AxiosPromise } from "axios"

export enum ContractFileType {
  STAMPED_SLIP = "Stamped Slip",
  SOV = "SOV",
  PREMIUM_SPLIT_FILE = "Premium split file",
}

export enum ContractFileSource {
  WHITESPACE = "Whitespace",
  PPL = "PPL",
  EMAIL = "Email",
  PLATFORM = "Platform",
  INTERCOM = "Intercom",
  OTHER = "Other",
}

export interface PreUploadFileRequest {
  type: keyof typeof ContractFileType
  umr: string
  source: keyof typeof ContractFileSource
  external_id: string
  file_name: string
  content_type: string
}

export interface UploadFileRequest {
  type: keyof typeof ContractFileType
  umr: string
  source: keyof typeof ContractFileSource
  file_name: string
  content_type: string
  contract_version_id: number
}

export interface PreUploadFileResponse {
  file_id: string
  upload_url: string
}

export interface ConfirmFileUploadRequest {
  file_id: string
}

export const generatePreUploadUrl = (
  client: AxiosInstance,
  body: PreUploadFileRequest,
): AxiosPromise<PreUploadFileResponse> =>
  client.post("/api/files/pre-upload", body, {
    transformResponse: axios.defaults.transformResponse,
  })

export const generateUploadUrl = (
  client: AxiosInstance,
  body: UploadFileRequest,
): AxiosPromise<PreUploadFileResponse> =>
  client.post("/api/files/upload", body, {
    transformResponse: axios.defaults.transformResponse,
  })

export const confirmFileUpload = (
  client: AxiosInstance,
  body: ConfirmFileUploadRequest,
): AxiosPromise => client.post("/api/files/confirm-upload", body)
