export const MinorClassCode = {
  HOUSEHOLD_REMOVALS: "GH",
  GOODS_IN_TRANSIT: "GT",
  AVIATION_WAR_MACHINERY_OR_EQUIPMENT: "MA",
  CARGO_LINESLIPS: "CL",
  CARGO_OPEN_MARKET: "CO",
  CARGO_TREATIES: "CT",
  CARGO_BINDERS: "CZ",
  LIABILITY_DIRECT: "LD",
  LIABILITY_RETRO: "LR",
  LIABILITY_PRO_RATA: "LQ",
  ACCIDENT_DIRECT: "AD",
  ACCIDENT_RETRO: "AR",
  COMMERCIAL_US_FACILITIES_FINANCIAL: "PF",
  COMMERCIAL_US: "PU",
  MISCELLANEOUS_US: "MU",
  COMMERCIAL_WW: "PW",
  MISCELLANEOUS_WW: "MW",
  MISCELLANEOUS_CM: "CM",
  CANCELLATION_ABANDONMENT: "CN",
  FILMS_TV: "FT",
  NON_APPEARANCE: "NA",
  PRIZE_INDEMNITY: "PZ",
  HULL_TREATIES_TRAVEL: "TR",
  ENGINEERING: "CP",
  CPR_CYBER: "CU",
  BCAP_Cyber: "CJ",
  RISK_CAT_COMBINED: "CX",
  CYBER_PRIVACY: "CY",
  INTELLECTUAL_PROPERTY: "IP",
  TECHNOLOGY: "TC",
  AGRICULTURE: "HA",
  RESIDENTIAL_PROPERTY_OWNERS: "HK",
  SPECIALIST_SCHEMES: "HS",
  SME_COMMERCIAL: "PC",
  ABORTED_BID_COSTS: "AB",
  D_AND_O_NOT_PROFIT: "DN",
  D_AND_O_GENERAL: "DO",
  D_AND_O_PRIVATE: "DP",
  D_AND_O_PUBLIC: "DQ",
  D_AND_O_GENERAL_1_A_SIDE: "DS",
  D_AND_O_PUBLIC_1_A_SIDE: "DT",
  EMPLOYMENT_PRACTICE_LIABILITY: "EP",
  FIDUCIARY: "FY",
  LEGAL_EXPENSES_AFTER: "LA",
  LEGAL_EXPENSES_BEFORE: "LB",
  LEGAL_EXPENSES_GENERAL: "LE",
  LOSS_MITIGATION: "LM",
  MISCELLANEOUS: "MC",
  PROPERTY_LIABILITY: "PL",
  PROPERTY_TRANSPORTATION: "PT",
  TAX_OPINION: "TO",
  WARRANTIES_AND_INDEMNITIES: "WI",
  ENERGY_DOWNSTREAM: "ED",
  OFFSHORE_ENERGY: "EO",
  COST_OF_CONTROL: "EC",
  ONSHORE_ENERGY_ENTERTAINERS: "EN",
  ENERGY_TREATY: "ET",
  ENERGY_RENEWABLE: "EE",
  ENERGY_CONSTRUCTION: "ES",
  ENERGY_GOM_WIND: "EX",
  ENERGY_LIABILITY: "EL",
  BBB: "BB",
  CASH_IN_TRANSIT: "CA",
  CREDIT_CARDS: "CC",
  CREDIT_CARD: "CD",
  COMMERCIAL_CRIME: "CR",
  FRAUDULENT_ASSIGNED_ACCOUNTS_FACTORING: "FA",
  FIDELITY: "FD",
  SPECIE: "SP",
  UNAUTHORIZED_TRADING: "UT",
  BLENDED: "BL",
  ORGANIZATIONAL_LIABILITY: "OL",
  PROFESSIONAL_INDEMNITY: "PI",
  TRUSTEE_LIABILITY: "TL",
  FLOOD: "FO",
  HIGH_VALUE_HOMEOWNERS: "HV",
  HOUSEHOLD_US: "HU",
  HOUSEHOLD_WW: "HW",
  MEDICAL_MALPRACTICE: "MM",
  BLUEWATER: "HM",
  MISCELLANEOUS_HULL: "HL",
  OTHER_HO: "HO",
  SPECIALIST: "HZ",
  IV: "FF",
  BUILDERS_RISK: "HB",
  MARINE: "MR",
  ENERGY: "EG",
  CLUB_BUSINESS: "CB",
  LIABILITY_TREATY: "LT",
  MARINE_LIABILITY: "MB",
  NON_MARINE: "NM",
  ONSHORE: "ON",
  KIDNAP_AND_RANSOM: "KR",
  AVIATION_PASSENGER: "AA",
  AVIATION_CREW: "AC",
  ARMED_FORCES: "AF",
  ACCIDENT_AND_SICKNESS_GROUP: "AG",
  ACCIDENT_AND_SICKNESS_INDIVIDUAL: "AI",
  AVIATION_OTHER: "AO",
  AVIATION_PILOT: "AP",
  ACCIDENT_AND_SICKNESS_OTHER: "AS",
  DYNCORP: "DY",
  GENERAL_PA_GROUP: "GG",
  GENERAL_PA_INDIVIDUAL: "GI",
  GENERAL_PA_OTHER: "GO",
  GUARDS: "GU",
  KNOWN_ACCUMULATION: "KA",
  MEDICAL: "MD",
  OTHER: "OT",
  PERSONAL_ACCIDENT: "PA",
  SMALL_CRAFT_SPORTS_SURPLUS_TREATY_CAT_EXPOSED: "SC",
  SHIPS_OFFICERS: "SF",
  SHIP_OTHER: "SH",
  SPORTS_INDIVIDUAL: "SI",
  SPORTS_OTHER: "SO",
  SHIPS_CREW: "SR",
  SPORTS_TEAM_SURPLUS_TREATY: "ST",
  TRUCKING: "TK",
  FINE_ARTS_WAR: "WA",
  INTERNATIONAL_MEDICAL: "IM",
  INTERNATIONAL_D_AND_O_CONSTRUCTION: "PD",
  PROFESSIONS: "PP",
  EMPLOYERS_LIABILITY: "EM",
  PUBLIC_LIABILITY: "PB",
  PRODUCT_GUARANTEE_RECALL: "PG",
  LIABILITY_US_SC_GL: "LU",
  COMMERCIAL_UK: "ZZ",
  SOCIAL_HOUSING: "HE",
  COMMERCIAL_PROPERTY_OWNERS: "PK",
  JEWELLERS_BLOCK: "JB",
  TERRORISM: "TE",
  AVIATION: "AV",
  TERRORISM_GLACIER_RE: "GE",
  TRIPRA: "TP",
  TRIA: "TU",
  FTC: "FC",
  MTC: "GM",
  SME_CYBER_PRIVACY: "VC",
  SME_CPR_PORTAL: "VP",
  SME_TECH: "VT",
  SME_TECH_PORTAL: "VV",
  US_CYBER_PRIVACY: "UC",
  US_CPR: "UP",
  WW_CYBER_PRIVACY: "WC",
  WW_CPR: "WP",
} as const

// Local and CI formatters are at odds with each other, hence disabling for next line.
// prettier-ignore
export type MinorClassCode = typeof MinorClassCode[keyof typeof MinorClassCode];

export const MINOR_CLASS_CODE_DESCRIPTION_MAPPING: Record<
  MinorClassCode,
  string
> = {
  [MinorClassCode.HOUSEHOLD_REMOVALS]: "GH - HOUSEHOLD REMOVALS",
  [MinorClassCode.GOODS_IN_TRANSIT]: "GT - GOODS IN TRANSIT",
  [MinorClassCode.AVIATION_WAR_MACHINERY_OR_EQUIPMENT]:
    "MA - Aviation War/Machinery or Equipment",
  [MinorClassCode.CARGO_LINESLIPS]: "CL - Cargo - Lineslips",
  [MinorClassCode.CARGO_OPEN_MARKET]: "CO - Cargo - Open Market",
  [MinorClassCode.CARGO_TREATIES]: "CT - CARGO TREATIES",
  [MinorClassCode.CARGO_BINDERS]: "CZ - Cargo - Binders",
  [MinorClassCode.LIABILITY_DIRECT]: "LD - LIABILITY - DIRECT",
  [MinorClassCode.LIABILITY_RETRO]: "LR - LIABILITY - RETRO",
  [MinorClassCode.LIABILITY_PRO_RATA]: "LQ - LIABILITY - PRO RATA",
  [MinorClassCode.ACCIDENT_DIRECT]: "AD - ACCIDENT - DIRECT",
  [MinorClassCode.ACCIDENT_RETRO]: "AR - ACCIDENT - RETRO",
  [MinorClassCode.COMMERCIAL_US_FACILITIES_FINANCIAL]:
    "PF - Commercial US Facilities/Financial",
  [MinorClassCode.COMMERCIAL_US]: "PU - COMMERCIAL US",
  [MinorClassCode.MISCELLANEOUS_US]: "MU - MISCELLANEOUS US",
  [MinorClassCode.COMMERCIAL_WW]: "PW - COMMERCIAL WW",
  [MinorClassCode.MISCELLANEOUS_WW]: "MW - MISCELLANEOUS WW",
  [MinorClassCode.MISCELLANEOUS_CM]: "CM - MISCELLANEOUS",
  [MinorClassCode.CANCELLATION_ABANDONMENT]: "CN - CANCELLATION/ABANDONMENT",
  [MinorClassCode.FILMS_TV]: "FT - FILMS/TV",
  [MinorClassCode.NON_APPEARANCE]: "NA - NON APPEARANCE",
  [MinorClassCode.PRIZE_INDEMNITY]: "PZ - PRIZE INDEMNITY",
  [MinorClassCode.HULL_TREATIES_TRAVEL]: "TR - Hull Treaties/Travel",
  [MinorClassCode.ENGINEERING]: "CP - ENGINEERING",
  [MinorClassCode.CPR_CYBER]: "CU - CPR CYBER",
  [MinorClassCode.BCAP_Cyber]: "CJ - BCAP Cyber",
  [MinorClassCode.RISK_CAT_COMBINED]: "CX - RISK & CAT COMBINED",
  [MinorClassCode.CYBER_PRIVACY]: "CY - CYBER / PRIVACY",
  [MinorClassCode.INTELLECTUAL_PROPERTY]: "IP - INTELLECTUAL PROPERTY",
  [MinorClassCode.TECHNOLOGY]: "TC - TECHNOLOGY",
  [MinorClassCode.AGRICULTURE]: "HA - AGRICULTURE",
  [MinorClassCode.RESIDENTIAL_PROPERTY_OWNERS]:
    "HK - RESIDENTIAL PROPERTY OWNERS",
  [MinorClassCode.SPECIALIST_SCHEMES]: "HS - SPECIALIST SCHEMES",
  [MinorClassCode.SME_COMMERCIAL]: "PC - SME COMMERCIAL",
  [MinorClassCode.ABORTED_BID_COSTS]: "AB - ABORTED BID COSTS",
  [MinorClassCode.D_AND_O_NOT_PROFIT]: "DN - D & O NOT PROFIT",
  [MinorClassCode.D_AND_O_GENERAL]: "DO - D & O GENERAL",
  [MinorClassCode.D_AND_O_PRIVATE]: "DP - D & O PRIVATE",
  [MinorClassCode.D_AND_O_PUBLIC]: "DQ - D & O PUBLIC",
  [MinorClassCode.D_AND_O_GENERAL_1_A_SIDE]: "DS - D & O GENERAL - 1 A SIDE",
  [MinorClassCode.D_AND_O_PUBLIC_1_A_SIDE]: "DT - D & O PUBLIC - 1 A SIDE",
  [MinorClassCode.EMPLOYMENT_PRACTICE_LIABILITY]:
    "EP - EMPLOYMENT PRACTICE LIABILITY",
  [MinorClassCode.FIDUCIARY]: "FY - FIDUCIARY",
  [MinorClassCode.LEGAL_EXPENSES_AFTER]: "LA - LEGAL EXPENSES - AFTER",
  [MinorClassCode.LEGAL_EXPENSES_BEFORE]: "LB - LEGAL EXPENSES - BEFORE",
  [MinorClassCode.LEGAL_EXPENSES_GENERAL]: "LE - LEGAL EXPENSES - GENERAL",
  [MinorClassCode.LOSS_MITIGATION]: "LM - LOSS MITIGATION",
  [MinorClassCode.MISCELLANEOUS]: "MC - MISCELLANEOUS",
  [MinorClassCode.PROPERTY_LIABILITY]: "PL - PROPERTY LIABILITY",
  [MinorClassCode.PROPERTY_TRANSPORTATION]: "PT - PROPERTY TRANSPORTATION",
  [MinorClassCode.TAX_OPINION]: "TO - TAX OPINION",
  [MinorClassCode.WARRANTIES_AND_INDEMNITIES]: "WI - WARRANTIES & INDEMNITIES",
  [MinorClassCode.ENERGY_DOWNSTREAM]: "ED - ENERGY DOWNSTREAM",
  [MinorClassCode.OFFSHORE_ENERGY]: "EO - OFFSHORE ENERGY",
  [MinorClassCode.COST_OF_CONTROL]: "EC - COST OF CONTROL",
  [MinorClassCode.ONSHORE_ENERGY_ENTERTAINERS]:
    "EN - Onshore Energy/Entertainers",
  [MinorClassCode.ENERGY_TREATY]: "ET - ENERGY TREATY",
  [MinorClassCode.ENERGY_RENEWABLE]: "EE - ENERGY RENEWABLE",
  [MinorClassCode.ENERGY_CONSTRUCTION]: "ES - ENERGY CONSTRUCTION",
  [MinorClassCode.ENERGY_GOM_WIND]: "EX - ENERGY GOM WIND",
  [MinorClassCode.ENERGY_LIABILITY]: "EL - ENERGY LIABILITY",
  [MinorClassCode.BBB]: "BB - BBB",
  [MinorClassCode.CASH_IN_TRANSIT]: "CA - CASH IN TRANSIT",
  [MinorClassCode.CREDIT_CARDS]: "CC - Credit Cards",
  [MinorClassCode.CREDIT_CARD]: "CD - CREDIT CARD",
  [MinorClassCode.COMMERCIAL_CRIME]: "CR - COMMERCIAL CRIME",
  [MinorClassCode.FRAUDULENT_ASSIGNED_ACCOUNTS_FACTORING]:
    "FA - FRAUDULENT ASSIGNED ACCOUNTS / FACTORING",
  [MinorClassCode.FIDELITY]: "FD - FIDELITY",
  [MinorClassCode.SPECIE]: "SP - SPECIE",
  [MinorClassCode.UNAUTHORIZED_TRADING]: "UT - UNAUTHORISED TRADING",
  [MinorClassCode.BLENDED]: "BL - BLENDED ( FIDELITY  PI, D & O )",
  [MinorClassCode.ORGANIZATIONAL_LIABILITY]: "OL - ORGANISATIONAL LIABILITY",
  [MinorClassCode.PROFESSIONAL_INDEMNITY]: "PI - PROFESSIONAL INDEMNITY",
  [MinorClassCode.TRUSTEE_LIABILITY]: "TL - TRUSTEE LIABILITY",
  [MinorClassCode.FLOOD]: "FO - FLOOD",
  [MinorClassCode.HIGH_VALUE_HOMEOWNERS]: "HV - HIGH VALUE HOMEOWNERS",
  [MinorClassCode.HOUSEHOLD_US]: "HU - HOUSEHOLD US",
  [MinorClassCode.HOUSEHOLD_WW]: "HW - HOUSEHOLD WW",
  [MinorClassCode.MEDICAL_MALPRACTICE]: "MM - MEDICAL MALPRACTICE",
  [MinorClassCode.BLUEWATER]: "HM - Bluewater",
  [MinorClassCode.MISCELLANEOUS_HULL]: "HL - MISCELLANEOUS HULL",
  [MinorClassCode.OTHER_HO]: "HO - Other",
  [MinorClassCode.SPECIALIST]: "HZ - Specialist",
  [MinorClassCode.IV]: "FF - IV",
  [MinorClassCode.BUILDERS_RISK]: "HB - Builders Risk",
  [MinorClassCode.MARINE]: "MR - MARINE",
  [MinorClassCode.ENERGY]: "EG - ENERGY",
  [MinorClassCode.CLUB_BUSINESS]: "CB - Club Business",
  [MinorClassCode.LIABILITY_TREATY]: "LT - LIABILITY TREATY",
  [MinorClassCode.MARINE_LIABILITY]: "MB - MARINE LIABILITY",
  [MinorClassCode.NON_MARINE]: "NM - NON-MARINE",
  [MinorClassCode.ONSHORE]: "ON - ONSHORE",
  [MinorClassCode.KIDNAP_AND_RANSOM]: "KR - Kidnap & Ransom",
  [MinorClassCode.AVIATION_PASSENGER]: "AA - Aviation Passenger",
  [MinorClassCode.AVIATION_CREW]: "AC - Aviation Crew",
  [MinorClassCode.ARMED_FORCES]: "AF - Armed Forces",
  [MinorClassCode.ACCIDENT_AND_SICKNESS_GROUP]:
    "AG - Accident & Sickness Group",
  [MinorClassCode.ACCIDENT_AND_SICKNESS_INDIVIDUAL]:
    "AI - Accident & Sickness Individual",
  [MinorClassCode.AVIATION_OTHER]: "AO - Aviation Other",
  [MinorClassCode.AVIATION_PILOT]: "AP - Aviation Pilot",
  [MinorClassCode.ACCIDENT_AND_SICKNESS_OTHER]:
    "AS - Accident & Sickness Other",
  [MinorClassCode.DYNCORP]: "DY - DynCorp",
  [MinorClassCode.GENERAL_PA_GROUP]: "GG - General PA Group",
  [MinorClassCode.GENERAL_PA_INDIVIDUAL]: "GI - General PA Individual",
  [MinorClassCode.GENERAL_PA_OTHER]: "GO - General PA Other",
  [MinorClassCode.GUARDS]: "GU - Guards",
  [MinorClassCode.KNOWN_ACCUMULATION]: "KA - Known Accumulation",
  [MinorClassCode.MEDICAL]: "MD - Medical",
  [MinorClassCode.OTHER]: "OT - Other",
  [MinorClassCode.PERSONAL_ACCIDENT]: "PA - PERSONAL ACCIDENT",
  [MinorClassCode.SMALL_CRAFT_SPORTS_SURPLUS_TREATY_CAT_EXPOSED]:
    "SC - Small Craft/Sports/Surplus Treaty Cat Exposed",
  [MinorClassCode.SHIPS_OFFICERS]: "SF - Ships Officers",
  [MinorClassCode.SHIP_OTHER]: "SH - Ship Other",
  [MinorClassCode.SPORTS_INDIVIDUAL]: "SI - SPORTS INDIVIDUAL",
  [MinorClassCode.SPORTS_OTHER]: "SO - Sports Other",
  [MinorClassCode.SHIPS_CREW]: "SR - Ships Crew",
  [MinorClassCode.SPORTS_TEAM_SURPLUS_TREATY]:
    "ST - Sports Team/Surplus Treaty",
  [MinorClassCode.TRUCKING]: "TK - Trucking",
  [MinorClassCode.FINE_ARTS_WAR]: "WA - Fine Arts/War",
  [MinorClassCode.INTERNATIONAL_MEDICAL]: "IM - International Medical",
  [MinorClassCode.INTERNATIONAL_D_AND_O_CONSTRUCTION]:
    "PD - International D&O/Construction",
  [MinorClassCode.PROFESSIONS]: "PP - PROFESSIONS",
  [MinorClassCode.EMPLOYERS_LIABILITY]: "EM - EMPLOYERS LIABILITY",
  [MinorClassCode.PUBLIC_LIABILITY]: "PB - PUBLIC LIABILITY",
  [MinorClassCode.PRODUCT_GUARANTEE_RECALL]: "PG - Product Guarentee & Recall",
  [MinorClassCode.LIABILITY_US_SC_GL]: "LU - LIABILITY US SC GL",
  [MinorClassCode.COMMERCIAL_UK]: "ZZ - COMMERCIAL UK",
  [MinorClassCode.SOCIAL_HOUSING]: "HE - SOCIAL HOUSING",
  [MinorClassCode.COMMERCIAL_PROPERTY_OWNERS]:
    "PK - COMMERCIAL PROPERTY OWNERS",
  [MinorClassCode.JEWELLERS_BLOCK]: "JB - JEWELLERS BLOCK",
  [MinorClassCode.TERRORISM]: "TE - TERRORISM",
  [MinorClassCode.AVIATION]: "AV - AVIATION",
  [MinorClassCode.TERRORISM_GLACIER_RE]: "GE - Terrorism Glacier Re",
  [MinorClassCode.TRIPRA]: "TP - TRIPRA",
  [MinorClassCode.TRIA]: "TU - TRIA",
  [MinorClassCode.FTC]: "FC - FTC",
  [MinorClassCode.MTC]: "GM - MTC",
  [MinorClassCode.SME_CYBER_PRIVACY]: "VC - SME Cyber/Privacy",
  [MinorClassCode.SME_CPR_PORTAL]: "VP - SME CPR Portal",
  [MinorClassCode.SME_TECH]: "VT - SME TECH",
  [MinorClassCode.SME_TECH_PORTAL]: "VV - SME TECH PORTAL",
  [MinorClassCode.US_CYBER_PRIVACY]: "UC - US Cyber / Privacy",
  [MinorClassCode.US_CPR]: "UP - US CPR",
  [MinorClassCode.WW_CYBER_PRIVACY]: "WC - WW Cyber / Privacy",
  [MinorClassCode.WW_CPR]: "WP - WW CPR",
} as const
