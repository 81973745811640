import {
  CONDUCT_RATING_HIGH,
  CONDUCT_RATING_LOW,
  CONDUCT_RATING_MEDIUM,
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_INSURANCE_CARRIER,
  CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE,
  CUSTOMER_TYPE_MICRO_ENTERPRISE,
  CUSTOMER_TYPE_SMALL_CORPORATE,
  ConductRating,
  CustomerType,
} from "@appia/api"
import { ValidatedData, mkInvalidData, mkValidData } from "@appia/form-data"

export const INVALID_FIELD_ERROR_MESSAGE = "Please fill in this field."

const parseBrokerContactName = (value: string): ValidatedData<string> => {
  if (value.replace(/\s+/g, "").length === 0) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }

  return mkValidData(value)
}

const isCustomerType = (value: unknown): value is CustomerType =>
  value === CUSTOMER_TYPE_INDIVIDUAL ||
  value === CUSTOMER_TYPE_MICRO_ENTERPRISE ||
  value === CUSTOMER_TYPE_SMALL_CORPORATE ||
  value === CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE ||
  value === CUSTOMER_TYPE_INSURANCE_CARRIER

const parseCustomerType = (value: string | null): ValidatedData<CustomerType> =>
  isCustomerType(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const isConductRating = (value: unknown): value is ConductRating =>
  value === CONDUCT_RATING_HIGH ||
  value === CONDUCT_RATING_MEDIUM ||
  value === CONDUCT_RATING_LOW

const parseConductRating = (
  value: string | null,
): ValidatedData<ConductRating> =>
  isConductRating(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

export { parseBrokerContactName, parseCustomerType, parseConductRating }
