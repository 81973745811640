import { PBQAFlowType } from "./PBQA"
import { Team } from "./Teams"

export interface User {
  id: string
  forename?: string
  surname?: string
  email: string
  teamId?: Team["id"]
  companyId: string
}

export interface Me {
  supportedFlows: {
    pbqa: PBQAFlowType
    endorsements: boolean
  }
  user: User
}

export const getCurrentUserUrl = "/auth/me"

export const getUsersUrl = "/api/users"

export const getTeamUsersUrl = (teamId: Team["id"]): string =>
  `/api/users/?team_id=${teamId}`
