import { FC } from "react"
import ErrorCallout from "@appia/ui-components/src/Error"

const RarcDefaultWarningBanner: FC<{
  createdAt?: string
  journeyConfigId?: number | null
}> = ({ createdAt, journeyConfigId }) => {
  const today = new Date().toISOString().split("T")[0]
  const createdDate = createdAt?.split("T")[0]

  if (journeyConfigId && journeyConfigId >= 5 && createdDate === today) {
    return (
      <div className="mx-auto mt-6 mb-2 max-w-2xl">
        <ErrorCallout
          message={
            "This contract was quoted today and as a result the <strong>RARC WILL BE DEFAULTED</strong>. Unless absolutely necessary, please wait until tomorrow to process."
          }
        />
      </div>
    )
  }

  return null
}

export default RarcDefaultWarningBanner
