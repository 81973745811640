import { FC, ReactNode, useState } from "react"
import { Navigate } from "react-router-dom"

import {
  ArchiveIcon,
  Card,
  ClipboardListIcon,
  Link,
  Tabs,
  Toast,
} from "@appia/ui-components"
import ToastViewport from "src/components/ToastViewport"

import ScreenTemplate from "src/templates/ScreenTemplate"
import * as ActiveContractsTable from "./ActiveContractsTable"
import * as ArchiveContractsTable from "./ArchiveContractsTable"

import * as RD from "@appia/remote-data"
import { useGetCurrentUser } from "src/swr"

import { PageNameContext } from "src/contexts/PageNameContext"

import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import useLocalStorageState from "src/hooks/useLocalStorageState"
import { useTableFilters } from "src/components/DashboardTable"
import ContractModal from "../../components/ContractModal"

const PAGE_NAME = "Contracts ready to process"

type TabValue = "activeContracts" | "archiveContracts"

const activeContractsValue: TabValue = "activeContracts"
const archiveContractsValue: TabValue = "archiveContracts"

const tabsConfig: {
  value: TabValue
  text: string
  icon: typeof ArchiveIcon
}[] = [
  { value: activeContractsValue, text: "Active", icon: ClipboardListIcon },
  { value: archiveContractsValue, text: "Archived", icon: ArchiveIcon },
]

const ContractScreen: FC = () => {
  useDocumentTitle(PAGE_NAME)
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const { request: userRequest } = useGetCurrentUser()
  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  const [activeTableSettings, setActiveTableSettings] =
    useLocalStorageState<ActiveContractsTable.TableSettings>(
      ActiveContractsTable.localStorageSettingsKey,
      ActiveContractsTable.defaultTableSettings,
    )

  const [activeTableFilters, setActiveTableFilters] =
    useTableFilters<ActiveContractsTable.TableFilters>(
      ActiveContractsTable.localStorageFiltersKey,
      ActiveContractsTable.defaultTableFilters,
    )

  const [archiveTableSettings, setArchiveTableSettings] =
    useLocalStorageState<ArchiveContractsTable.TableSettings>(
      ArchiveContractsTable.localStorageSettingsKey,
      ArchiveContractsTable.defaultTableSettings,
    )

  const [archiveTableFilters, setArchiveTableFilters] =
    useTableFilters<ArchiveContractsTable.TableFilters>(
      ArchiveContractsTable.localStorageFiltersKey,
      ArchiveContractsTable.defaultTableFilters,
    )

  const [modalOpenPBQA, setModalOpenPBQA] = useState(false)
  const [policyId, setPolicyId] = useState("")
  const [activeTab, setActiveTab] = useState<TabValue>("activeContracts")
  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const handleOpenModal = (id: string): void => {
    setPolicyId(id)
    setModalOpenPBQA(true)
  }

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  if (!user) {
    return <Navigate to="/" />
  }

  return (
    <ScreenTemplate pageTitle={PAGE_NAME} layout={{ type: "regular" }}>
      <PageNameContext.Provider value={PAGE_NAME}>
        <div className="flex min-h-full flex-col pb-4">
          {user.teamId ? (
            <div className="flex grow flex-col">
              {activeTab === "activeContracts" ? (
                <ActiveContractsTable.TableControls
                  handleOpenModal={handleOpenModal}
                  tableFilters={activeTableFilters}
                  setTableFilters={setActiveTableFilters}
                  setTableSettings={setActiveTableSettings}
                />
              ) : (
                <ArchiveContractsTable.TableControls
                  handleOpenModal={handleOpenModal}
                  tableFilters={archiveTableFilters}
                  setTableFilters={setArchiveTableFilters}
                  setTableSettings={setArchiveTableSettings}
                />
              )}

              <Tabs.Root
                asChild
                value={activeTab}
                onValueChange={s => {
                  if (s === "activeContracts" || s === "archiveContracts") {
                    setActiveTab(s)
                  }
                }}
              >
                <>
                  <div className="overflow-hidden rounded-lg rounded-b-none border border-b-0 border-otto-grey-300 bg-white">
                    <Tabs.List aria-label="Tables" className="max-w-min">
                      {tabsConfig.map(({ value, text, icon: Icon }) => (
                        <Tabs.Trigger
                          key={value}
                          value={value}
                          className="flex items-center justify-center gap-2 py-3 px-7"
                        >
                          <Icon className="w-5" />
                          <span>{text}</span>
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                  </div>

                  <Tabs.Content asChild value={activeContractsValue}>
                    <ActiveContractsTable.Table
                      handleOpenModal={handleOpenModal}
                      tableFilters={activeTableFilters}
                      tableSettings={activeTableSettings}
                      setTableSettings={setActiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>

                  <Tabs.Content asChild value={archiveContractsValue}>
                    <ArchiveContractsTable.Table
                      handleOpenModal={handleOpenModal}
                      tableFilters={archiveTableFilters}
                      tableSettings={archiveTableSettings}
                      setTableSettings={setArchiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>
                </>
              </Tabs.Root>
            </div>
          ) : (
            <Card className="m-auto w-max">
              <p className="mb-4 text-center">
                Looks like you haven&lsquo;t joined a team yet. You need to join
                a team to view Contract slips.
              </p>

              <div className="mx-auto">
                <Link
                  href="/teams"
                  label="Join a team"
                  theme="night"
                  style="filled"
                  className="mx-auto"
                  onClick={() => {
                    logButtonClick({
                      buttonName: "Join a team",
                      linkHref: "/teams",
                      containerName: "Main",
                      pageName: PAGE_NAME,
                    })
                  }}
                />
              </div>
            </Card>
          )}
        </div>

        {modalOpenPBQA && (
          <ContractModal
            isOpen={modalOpenPBQA}
            onClose={() => setModalOpenPBQA(false)}
            onSuccess={() => setModalOpenPBQA(false)}
            contractPolicyId={policyId}
            triggerToast={setAndTriggerToast}
          />
        )}

        <Toast.Toast
          open={toastState.open}
          onOpenChange={toastState.onOpenChange}
          type={toastType}
          message={toastMessage}
        />

        <ToastViewport />
      </PageNameContext.Provider>
    </ScreenTemplate>
  )
}

export default ContractScreen
