import { FC, Ref } from "react"

import { HotTable } from "@handsontable/react"
import {
  AutoRowSize,
  CopyPaste,
  ManualColumnResize,
  ManualRowResize,
  registerPlugin,
} from "handsontable/plugins"
import "handsontable/dist/handsontable.full.css"

registerPlugin(AutoRowSize)
registerPlugin(CopyPaste)
registerPlugin(ManualColumnResize)
registerPlugin(ManualRowResize)

export interface CellHighlight {
  row: number
  col: number
  className: string
}

const Spreadsheet: FC<{
  cellHighlights: CellHighlight[]
  data: unknown[][]
  hotTableRef?: Ref<HotTable>
  id?: string
}> = ({ data, cellHighlights, hotTableRef, id }) => (
  <HotTable
    cell={cellHighlights}
    data={data}
    id={id}
    ref={hotTableRef}
    height="100%"
    // Column width
    colWidths={100}
    // Allow columns to be resized
    manualColumnResize
    // Allow rows to be resized
    manualRowResize
    // Set the height of each row based on the contents of all its cells, even
    // those off-screen; otherwise horizontal scrolling can be janky
    autoRowSize
    // Show A, B, C... above rows
    colHeaders
    // Show 1, 2, 3... to left of columns
    rowHeaders
    // Prevent cells being edited
    readOnly
    // Don't show the handle when selecting cells that allows you to mass- copy
    // their contents into other cells
    fillHandle={false}
    // Allow the user to select either a single cell or a single range of cells
    // (but not multiple ranges)
    selectionMode="range"
    licenseKey="non-commercial-and-evaluation"
  />
)

export default Spreadsheet
