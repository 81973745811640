import { FC } from "react"

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FirstPageIcon,
  IconButton,
  LastPageIcon,
  Select,
} from "@appia/ui-components"

import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"

const MIN_PAGE_NUMBER = 1

const PageCount: FC<{
  pageNumber: number
  pageSize: number
  totalHits: number
}> = ({ pageNumber, pageSize, totalHits }) => {
  const indexPage = pageNumber - MIN_PAGE_NUMBER // Use 0-indexed pages for this calculation
  const start = indexPage * pageSize + 1
  let end = indexPage * pageSize + pageSize
  end = end > totalHits ? totalHits : end

  return (
    <p className="mr-4 whitespace-nowrap">
      {totalHits <= 0 ? (
        "0 results"
      ) : (
        <>
          <span aria-hidden>
            {start}&ndash;{end} of {totalHits}
          </span>

          <span className="sr-only">
            {`Rows ${start} to ${end} of ${totalHits}`}
          </span>
        </>
      )}
    </p>
  )
}

const Pagination: FC<{
  tableLabel: string
  totalHits: number
  pageNumber: number
  setPageNumber: (n: number) => void
  pageSize: number
  setPageSize: (n: number) => void
}> = ({
  tableLabel,
  totalHits,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
}) => {
  const pageName = usePageName()

  const pages = Math.ceil(totalHits / pageSize)

  return (
    <nav
      role="navigation"
      aria-label={`${tableLabel} pagination`}
      className="flex items-center gap-2"
      data-cy="pagination"
    >
      <label className="mr-4 hidden items-center justify-center gap-1 sm:flex">
        <span className="inline-block">Rows per page:</span>
        <Select
          placeholder="5"
          options={[5, 10, 20, 50].map(n => ({
            value: n.toString(),
            label: n.toString(),
          }))}
          onSelect={v => {
            if (v !== null) {
              setPageSize(parseInt(v, 10))
            }
          }}
          selectedValue={pageSize.toString()}
        />
      </label>

      <PageCount
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalHits={totalHits}
      />

      <IconButton
        data-cy="first-page"
        label="Go to first page"
        size={6}
        icon={<FirstPageIcon />}
        onClick={() => {
          setPageNumber(MIN_PAGE_NUMBER)
          logButtonClick({
            buttonName: "First page",
            containerName: tableLabel,
            pageName,
          })
        }}
        disabled={pageNumber <= MIN_PAGE_NUMBER}
      />

      <IconButton
        data-cy="previous-page"
        label="Go to previous page"
        size={6}
        icon={<ChevronLeftIcon />}
        onClick={() => {
          setPageNumber(pageNumber - 1)
          logButtonClick({
            buttonName: "Previous page",
            containerName: tableLabel,
            pageName,
          })
        }}
        disabled={pageNumber <= MIN_PAGE_NUMBER}
      />

      <IconButton
        data-cy="next-page"
        label="Go to next page"
        size={6}
        icon={<ChevronRightIcon />}
        onClick={() => {
          setPageNumber(pageNumber + 1)
          logButtonClick({
            buttonName: "Next page",
            containerName: tableLabel,
            pageName,
          })
        }}
        disabled={pageNumber >= pages}
      />

      <IconButton
        data-cy="last-page"
        label="Go to last page"
        size={6}
        icon={<LastPageIcon />}
        onClick={() => {
          setPageNumber(pages)
          logButtonClick({
            buttonName: "Last page",
            containerName: tableLabel,
            pageName,
          })
        }}
        disabled={pageNumber >= pages}
      />
    </nav>
  )
}

export default Pagination
