import classNames from "classnames"
import { FC, KeyboardEvent, ReactNode } from "react"

export interface ControlledDisclosureProps {
  title: ReactNode
  children: ReactNode
  open: boolean
  onClick: () => void
  showError?: boolean
}

const ControlledDisclosure: FC<ControlledDisclosureProps> = ({
  title,
  children,
  open,
  onClick,
  showError = false,
}) => (
  <div
    className={`overflow-hidden rounded-md border ${
      showError ? "border-otto-red-500" : "border-otto-grey-300"
    }`}
  >
    <button
      aria-expanded={open}
      className={classNames(
        "otto-focus-inset flex w-full items-center gap-4 py-3 px-4",
        open ? "bg-[#C0F8FD]" : "bg-white",
        { "border-r-[6px] border-[#00E1F5]": open },
      )}
      onClick={onClick}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick()
        }
      }}
    >
      {typeof title === "string" ? (
        <span className="text-left font-bold">{title}</span>
      ) : (
        title
      )}
    </button>
    {open && children}
  </div>
)

export default ControlledDisclosure
