import { KiClassOfBusiness, KiQuote } from "./KiQuote"

export interface Overlining {
  mainQuote: KiQuote
  computedCapacity: number
  selectedQuotes: KiQuote[]
  overlined: boolean
}

export interface OverliningContext {
  capacities: Record<KiClassOfBusiness, number>
}

export const getOverliningContextUrl = (pbqaId: string): string =>
  `/api/pbqa/${pbqaId}/overlining`
