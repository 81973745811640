import { FC } from "react"
import { QuoteComparisonMatchModalData } from "src/screens/ReviewPBQA/state"
import { CheckFilledCircleIcon } from "@appia/ui-components"
import { KiAnswer, KiDateAnswer } from "@appia/api"
import { prettyPrintLongDateString } from "src/utils/prettyPrinters"

const QuoteComparisonMatch: FC<{
  quoteData: QuoteComparisonMatchModalData[]
}> = ({ quoteData }) => {
  const renderQuoteValue = (
    quoteValue: KiAnswer | Partial<Record<string, KiAnswer>> | undefined,
  ): string | undefined => {
    const isKiDateAnswer = (
      answer: KiAnswer | Partial<Record<string, KiAnswer>> | undefined,
    ): answer is KiDateAnswer => {
      return typeof answer === "string" && /\d{4}-\d{2}-\d{2}/.test(answer)
    }

    if (quoteValue !== null && isKiDateAnswer(quoteValue)) {
      return prettyPrintLongDateString(quoteValue)
    }

    if (typeof quoteValue === "string") {
      return quoteValue
    }

    if (Array.isArray(quoteValue)) {
      return quoteValue.join(", ")
    }

    if (
      quoteValue !== null &&
      typeof quoteValue === "object" &&
      "answer" in quoteValue &&
      "unit" in quoteValue
    ) {
      const roundedAnswer =
        typeof quoteValue.answer === "number"
          ? quoteValue.answer.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : quoteValue.answer
      return `${roundedAnswer} ${quoteValue.unit}`
    } else if (
      quoteValue !== null &&
      typeof quoteValue === "object" &&
      "id" in quoteValue &&
      "alias" in quoteValue &&
      "name" in quoteValue &&
      "code" in quoteValue
    ) {
      const brokerName =
        typeof quoteValue === "object" && typeof quoteValue.name === "string"
          ? quoteValue.name
          : "---"
      return brokerName
    }

    return "---"
  }

  return (
    <div className="mx-auto mt-4 w-full max-w-xl bg-white">
      {quoteData.map(({ label, quoteValue, isMatch }) => (
        <div
          key={label}
          className="border-gray-300 flex items-center gap-4 border-b-2"
        >
          <div className="basis-[10%]">
            {isMatch && (
              <CheckFilledCircleIcon className="w-5 text-otto-green-800" />
            )}
          </div>
          <div className="basis-[45%] p-1 text-otto-grey-700">{label}</div>
          <div className="bg-blue-400 basis-[45%] p-1">
            {renderQuoteValue(quoteValue)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default QuoteComparisonMatch
