import InputWithError from "src/components/InputWithError"
import { Input, InputLabel, SelectMultiple } from "@appia/ui-components"
import { FC } from "react"
import { isEmpty } from "src/utils/typeRefinements"
import { FormField } from "@appia/form-data"

const SharedInputs: FC<{
  callbackURI: FormField<string>
  scope: FormField<string[], string[]>
  setFormFields: React.Dispatch<
    React.SetStateAction<{
      callbackURI: FormField<string>
      scope: FormField<string[], string[]>
    }>
  >
  showFormFieldErrors: boolean
  emptyCallbackURI: string
  emptyScope: string
}> = ({
  callbackURI,
  scope,
  setFormFields,
  showFormFieldErrors,
  emptyCallbackURI,
  emptyScope,
}) => {
  return (
    <>
      <InputWithError
        formField={callbackURI}
        showFormFieldErrors={showFormFieldErrors}
        errorTestId="callbackURI-error"
        input={errorId => (
          <InputLabel label="Callback URI">
            <Input
              placeholder="https://"
              aria-describedby={errorId}
              hasError={!!errorId}
              type="url"
              value={callbackURI.raw}
              onChange={value =>
                setFormFields(fields => ({
                  ...fields,
                  callbackURI: {
                    raw: value,
                    validated: isEmpty(value)
                      ? { valid: false, error: emptyCallbackURI }
                      : { valid: true, data: value },
                  },
                }))
              }
            />
          </InputLabel>
        )}
      />

      <InputWithError
        formField={scope}
        showFormFieldErrors={showFormFieldErrors}
        errorTestId="scope-error"
        input={errorId => (
          <InputLabel label="Scope">
            <SelectMultiple
              placeholder="Select one or more"
              aria-describedby={errorId}
              options={[
                { value: "sov.completed", label: "sov.completed" },
                { value: "pbqa.completed", label: "pbqa.completed" },
              ]}
              selectedValues={scope.raw}
              onSelect={value => {
                setFormFields(fields => ({
                  ...fields,
                  scope: {
                    raw: value,
                    validated: isEmpty(value)
                      ? { valid: false, error: emptyScope }
                      : { valid: true, data: value },
                  },
                }))
              }}
            />
          </InputLabel>
        )}
      />
    </>
  )
}

export default SharedInputs
