import { PBQAInfo, Syndicate } from "@appia/api"
import * as RD from "@appia/remote-data"
import { useGetPBQAInfo, useGetSyndicatePartners } from "src/swr"

import { isNotNull } from "src/utils/typeRefinements"

export type SyndicatesRequest = RD.RemoteData<Error, Syndicate[]>

const useGetPBQASyndicates = (pbqaId: PBQAInfo["id"]): SyndicatesRequest => {
  const { request: pbqaInfoReq } = useGetPBQAInfo(pbqaId)

  // TODO [OE-428] This should fetch all syndicates, not only partners, or else
  // this will cause backwards-compatibility issues in future if we remove a
  // partner
  const { request: syndicatesReq } = useGetSyndicatePartners()

  return RD.matchAll(
    [pbqaInfoReq, syndicatesReq],

    RD.NotAsked as SyndicatesRequest,
    RD.Loading,

    ({ syndicateIds }, syndicates) =>
      RD.Success(
        syndicateIds
          .map(id => syndicates.find(s => s.id === id) ?? null)
          .filter(isNotNull),
      ),

    (pbqaInfoErr, syndicatesErr) => {
      const err = new Error("Failed to load syndicates for PBQA")

      if (pbqaInfoErr && syndicatesErr) {
        err.cause = [pbqaInfoErr, syndicatesErr]
      } else if (pbqaInfoErr) {
        err.cause = pbqaInfoErr
      } else if (syndicatesErr) {
        err.cause = syndicatesErr
      }

      return RD.Failure(err)
    },
  )
}

export default useGetPBQASyndicates
