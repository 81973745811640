import React, { FC, ReactNode, useState } from "react"
import {
  Card,
  Checkbox,
  ModalOverlay,
  SpinnerIcon,
  Toast,
} from "@appia/ui-components"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import { PBQASearchResult, archivePBQA } from "@appia/api"
import { getPBQALinkDetails } from "../../screens/PBQA/GetPBQALinkDetails"
import { logButtonClick, logPBQAArchive } from "src/amplitude"
import PBQAEntryFormActionMenu from "./PBQAFormActionMenu"
import useApiClient from "../../contexts/ApiClientContext"
import doPBQAAction from "../../utils/doPBQAAction"

interface ContractPBQAEntryProps {
  formId: string
  onCheckboxChange: (pbqaId: PBQASearchResult["id"]) => void
  onSelectAllCheckboxes: (checked: boolean) => void
  selectedPBQAIds: Set<PBQASearchResult["id"]>
  completedPBQAs: PBQASearchResult[]
  notCompletedPBQAs: PBQASearchResult[]
  triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
  updatePBQAs: () => void
}
const COMPLETED = "Completed"
const NOT_COMPLETED = "Not Completed"

const PBQAEntryForm: FC<ContractPBQAEntryProps> = ({
  formId,
  onCheckboxChange,
  onSelectAllCheckboxes,
  selectedPBQAIds,
  completedPBQAs,
  notCompletedPBQAs,
  triggerToast,
  updatePBQAs,
}) => {
  const apiClient = useApiClient()
  const [loadingOverlay, setLoadingOverlay] = useState<string | null>(null)

  const renderFieldset = (
    fieldType: string,
    pbqaEntries: PBQASearchResult[],
    showCheckboxes: boolean,
  ): JSX.Element => (
    <fieldset>
      <legend className="mb-1 font-bold">{fieldType}</legend>
      <hr />
      <div className="mt-2 grid grid-cols-8 items-start pl-2 font-bold">
        <label className="col-span-1">
          <span className="sr-only">Select All</span>
          {showCheckboxes && (
            <Checkbox
              checked={selectedPBQAIds.size === pbqaEntries.length}
              onChange={onSelectAllCheckboxes}
            />
          )}
        </label>
        <span className="col-span-2">Quote Line ID</span>
        <span className="col-span-2">Risk Codes</span>
        <span className="col-span-2">Upload Date</span>
        <span className="col-span-1"></span>
      </div>

      {pbqaEntries.map(pbqaEntry => {
        const linkDetails = getPBQALinkDetails(pbqaEntry)
        if (!linkDetails) {
          return null
        }
        return (
          <Card padding={2} className="mt-2 shadow-none" key={pbqaEntry.id}>
            <table className="w-full">
              <tbody>
                <tr className="grid grid-cols-8 items-start gap-1">
                  <td className="col-span-1">
                    <span className="sr-only">{`Quote Line ID: ${
                      pbqaEntry.quoteLineId
                    } - Risk Codes: ${
                      pbqaEntry?.riskCodes
                        ? pbqaEntry.riskCodes
                        : "Not Selected"
                    }`}</span>
                    {showCheckboxes && (
                      <Checkbox
                        checked={selectedPBQAIds.has(pbqaEntry.id)}
                        onChange={() => {
                          onCheckboxChange(pbqaEntry.id)
                        }}
                      />
                    )}
                  </td>
                  <td className="col-span-2">
                    <a
                      href={linkDetails.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                      aria-label={`Open ${pbqaEntry.quoteLineId} in a new tab`}
                    >
                      {pbqaEntry.quoteLineId}
                    </a>
                  </td>
                  <td className="col-span-2">
                    {pbqaEntry.riskCodes?.join(", ")}
                  </td>
                  <td className="col-span-2">
                    {prettyPrintDateString(pbqaEntry.createdAt)}
                  </td>
                  <td className="col-span-1 justify-self-end">
                    <div className="flex items-center justify-between gap-1">
                      <PBQAEntryFormActionMenu
                        pbqa={pbqaEntry}
                        onArchive={() => {
                          logButtonClick({
                            buttonName: "Archive",
                            containerName: "PBQA action menu",
                          })
                          logPBQAArchive({ pbqaId: pbqaEntry.id })
                          doPBQAAction(
                            () => archivePBQA(apiClient, pbqaEntry.id),
                            "Archiving PBQA",
                            () => "Successfully archived PBQA",
                            "Failed to archive PBQA",
                            setLoadingOverlay,
                            triggerToast,
                            updatePBQAs,
                          )
                        }}
                        onReview={() => {
                          window.open(
                            linkDetails.href,
                            "_blank",
                            "noopener,noreferrer",
                          )
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        )
      })}

      {loadingOverlay && (
        <ModalOverlay
          bgColor="bg-white"
          isOpen
          onClose={() => {
            setLoadingOverlay(null)
          }}
        >
          <div className="isolate inline-block text-center">
            <p className="mb-4 text-5xl font-bold">{loadingOverlay}</p>
            <SpinnerIcon className="mx-auto w-12 text-otto-pop" />
          </div>
        </ModalOverlay>
      )}
    </fieldset>
  )

  return (
    <div>
      <form
        id={formId}
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        noValidate
      >
        {renderFieldset(COMPLETED, completedPBQAs, true)}
        <hr className="mb-4" />
        {notCompletedPBQAs.length > 0 &&
          renderFieldset(NOT_COMPLETED, notCompletedPBQAs, false)}
      </form>
    </div>
  )
}

export default PBQAEntryForm
