import { FC, useId, useState } from "react"

import { PBQAQuestion } from "@appia/api"

import { Button } from "@appia/ui-components"

import ModalTemplate from "src/components/ModalTemplate"
import Group from "../components/Group"
import ReasonSelection, { NoteAndReasons } from "../components/ReasonSelection"

import {
  logButtonClick,
  logPBQANoteAdd,
  logPBQANoteChange,
  logPBQANoteRemove,
} from "src/amplitude"

import usePageName from "src/contexts/PageNameContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

const PBQAModal: FC<{
  isOpen: boolean
  onClose: () => void
  onSave: (n: NoteAndReasons) => void
  noteAndReasons: NoteAndReasons
  questionId: PBQAQuestion["id"]
}> = ({
  isOpen,
  onClose,
  onSave,
  noteAndReasons: originalNoteAndReasons,
  questionId,
}) => {
  const pageName = usePageName()
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  const legendId = useId()

  const [noteAndReasons, setNoteAndReasons] = useState<NoteAndReasons>(
    originalNoteAndReasons,
  )

  const [showNoteError, setShowNoteError] = useState<boolean>(false)

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Adding note"
      allowOverflow
      content={
        <Group aria-labelledby={legendId}>
          <p className="mb-2" id={legendId}>
            Please select the <span aria-hidden>reason(s)</span>
            <span className="sr-only">reason or reasons</span> for conflicting
            data, then submit to proceed.
          </p>

          <ReasonSelection
            label={
              <span className="sr-only">Reasons for conflicting data</span>
            }
            noteAndReasons={noteAndReasons}
            onChange={setNoteAndReasons}
            selectPlaceholder="Select reasons for conflicting data"
            showNoteError={showNoteError}
          />
        </Group>
      }
      actions={[
        <Button
          key="cancel"
          aria-label="Cancel and close dialog"
          label="Cancel"
          style="outlined"
          theme="night"
          onClick={() => {
            logButtonClick({
              buttonName: "Cancel",
              containerName: "Note modal",
              pageName,
            })
            onClose()
          }}
        />,

        <Button
          key="submit"
          aria-label="Save note and close dialog"
          label="Submit"
          style="filled"
          theme="pop"
          onClick={() => {
            logButtonClick({
              buttonName: "Submit",
              containerName: "Note modal",
              pageName,
            })

            setShowNoteError(false)

            const { note } = noteAndReasons
            const reasons = noteAndReasons.reasons || []
            const otherSelected = reasons.includes("other")

            // If the user selects `Other` they must also write a note
            if (otherSelected && (note === null || note.length === 0)) {
              setShowNoteError(true)
              return
            }

            const hadNoteBefore =
              (originalNoteAndReasons.reasons || []).length > 0
            const hasNoteNow = reasons.length > 0

            // If the user opened an empty modal and immediately saved it, just
            // close and don't do anything
            if (!hadNoteBefore && !hasNoteNow) {
              onClose()
              return
            }

            if (hadNoteBefore && !hasNoteNow) {
              // There used to be a note/reason, but it's been deleted
              logPBQANoteRemove({ pbqaId, surveyId, questionId })
              onSave({ note: null, reasons: [] })
              return
            } else {
              if (!hadNoteBefore && hasNoteNow) {
                // There wasn't a note/reason before, so a new one has been added
                logPBQANoteAdd({ pbqaId, surveyId, questionId })
              } else {
                // The existing note/reason has been updated
                logPBQANoteChange({ pbqaId, surveyId, questionId })
              }

              if (otherSelected) {
                onSave({ note, reasons })
              } else {
                onSave({ note: null, reasons })
              }

              return
            }
          }}
        />,
      ]}
    />
  )
}

export default PBQAModal
