import { AxiosInstance, AxiosPromise } from "axios"

// See $MONOREPO/server/app/policies/__init__.py for what these mean
export type PolicyStatus =
  | "CREATED"
  | "PREBIND"
  | "BOUND"
  | "EXPIRED"
  | "CANCELLED"
  | "ARCHIVED"

export type PolicySource = "ebix" | "whitespace" | "ppl" | "ppl_ng" | "unknown"

// A full policy, combining data from the Policy and PolicyData API models
export interface Policy {
  assuredName: string | null
  inceptionDate: string | null
  isBritFollow: boolean
  isStub: boolean
  policyId: string
  policyReference: string | null
  status: PolicyStatus
  umr: string | null
}

export const searchPoliciesUrl = (query: string): string =>
  `/api/policies/search?search_term=${encodeURIComponent(query)}`

export const searchPolicies = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<Policy[]> => client.get(searchPoliciesUrl(query))
