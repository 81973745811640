import { User } from "@appia/api"

export const forenameOrEmail = (user: User): string =>
  user.forename || user.email

// The util function name is misleading as it returns
// the forename if it exists while the surname doesn't
export const fullNameOrEmail = (user: User): string =>
  user.forename && user.surname
    ? `${user.forename} ${user.surname}`
    : forenameOrEmail(user)

export const compareByName = (u1: User, u2: User): number =>
  fullNameOrEmail(u1) > fullNameOrEmail(u2) ? 1 : -1

const accountIdToUser = (
  accountId: string | null,
  users: User[],
): User | undefined => users.find(user => user.id === accountId)

export const accountIdToFullnameOrEmail = (
  accountId: string | null,
  users: User[],
): string | null => {
  const user = accountIdToUser(accountId, users)

  if (!user) return null

  const { forename, surname, email } = user

  return forename && surname ? `${forename} ${surname}` : email
}
