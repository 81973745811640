import { FC } from "react"

import { Button, CheckCircleIcon, ModalDescription } from "@appia/ui-components"
import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"
import ModalTemplate from "src/components/ModalTemplate"

const CONTAINER_NAME = "Contract Processed modal"

const ContractProcessedModal: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const pageName = usePageName()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      content={
        <ModalDescription as="div" className="gap-4 text-center">
          <CheckCircleIcon className="mx-auto w-8 text-otto-green-700" />
          <h1 className="text-2xl font-bold">Contract processed</h1>
          <p className="mt-4">
            You&apos;ve successfully processed the contract. Please take the
            time to review the details on this page.
          </p>
        </ModalDescription>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="Close"
          label="Close"
          theme="pop"
          style="filled"
          onClick={() => {
            logButtonClick({
              buttonName: "Close",
              containerName: CONTAINER_NAME,
              pageName,
            })
            onClose()
          }}
        />,
      ]}
    />
  )
}

export default ContractProcessedModal
