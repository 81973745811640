import type { SVGComponent } from "./IconWrapper"

const SortDescendingSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6V4H14V6H4ZM10 18H4V16H10V18ZM4 12H12V10H4V12ZM16 16V6H18V16H20.5L17 19.5L13.5 16H16Z"
      fill="currentColor"
    />
  </svg>
)

export default SortDescendingSVG
