import type { SVGComponent } from "./IconWrapper"

const FirstPageSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2049 16.59L13.6149 12L18.2049 7.41L16.7949 6L10.7949 12L16.7949 18L18.2049 16.59ZM5.79492 6H7.79492V18H5.79492V6Z"
      fill="currentColor"
    />
  </svg>
)

export default FirstPageSVG
