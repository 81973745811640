import { FC } from "react"

import AttachmentUpload from "./AttachmentUpload"
import AttachmentTable from "./AttachmentTable"
import type { UploadedDocument } from "./types"

import { PolicyCreationUploadFileLimits } from "@appia/api"

const AttachmentInner: FC<{
  containerName: string
  limits: PolicyCreationUploadFileLimits
  onFileChange: (d: UploadedDocument | null) => void
  uploadedDocument: UploadedDocument | null
}> = ({ containerName, limits, onFileChange, uploadedDocument }) => {
  return uploadedDocument ? (
    <AttachmentTable
      onDeleteAttachment={() => {
        onFileChange(null)
      }}
      attachment={uploadedDocument}
      containerName={containerName}
    />
  ) : (
    <AttachmentUpload uploadLimits={limits} onUploadFile={onFileChange} />
  )
}

export default AttachmentInner
