import React, { useState } from "react"
import { Toast } from "@appia/ui-components"

interface ToastHandler {
  toastType: Toast.ToastType
  toastMessage: React.ReactNode
  toastState: ReturnType<typeof Toast.useToastState>
  setAndTriggerToast: (
    toastType: Toast.ToastType,
    toastMessage: React.ReactNode,
  ) => void
}

export const useToastHandler = (): ToastHandler => {
  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<React.ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: React.ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  return { toastType, toastMessage, toastState, setAndTriggerToast }
}
