/**
 * Although this component looks visually much like the `SearchBar` from
 * `@appia/ui-components`, it has quite different interactions and semantics,
 * hence this separate implementation.
 *
 * Specifically, the `@appia/ui-components` version is a combobox where the user
 * navigates items via the up/down arrows and the `aria-activedescendent` is
 * tracked. This version is a search form accompanied by a table of results
 * (which just happen to be inside a dialog) where the user can navigate via the
 * tab key.
 */
import {
  FC,
  KeyboardEventHandler,
  ReactNode,
  Ref,
  useId,
  useRef,
  useState,
} from "react"
import classNames from "classnames"

import { KiQuote } from "@appia/api"

import { Checkbox, FilterIcon, SearchIcon } from "@appia/ui-components"

import {
  TBody,
  THead,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from "../ResponsiveTable"
import {
  orderedFields,
  prettyPrintKiQuoteField,
  renderKiQuoteField,
} from "../kiQuoteHelpers"

import { logPBQAOverliningQuoteSelect } from "src/amplitude"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

const popoverCss =
  "otto-focus absolute top-full left-0 z-20 mt-2 max-h-[30rem] w-full rounded-md border border-otto-grey-400 bg-white text-base shadow-xl"

const SearchResults: FC<{
  checkedQuoteLineIds: KiQuote["quoteLineId"][]
  containerId: string
  containerRef: Ref<HTMLDivElement>
  onChangeChecked: (q: KiQuote, checked: boolean) => void
  onKeyDown: KeyboardEventHandler<HTMLDivElement>
  searchResults: KiQuote[]
  resultsTableId: string
}> = ({
  checkedQuoteLineIds,
  containerId,
  containerRef,
  onChangeChecked,
  onKeyDown,
  searchResults,
  resultsTableId,
}) => {
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  return (
    // We need the `onKeyDown` listener to catch presses of the Escape key, or
    // else they'll cause the whole modal to close
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={classNames(popoverCss, "overflow-y-scroll")}
      id={containerId}
      onKeyDown={onKeyDown}
      ref={containerRef}
      role="dialog"
      tabIndex={-1}
    >
      {searchResults.length > 0 && (
        <TableWrapper>
          <Table aria-labelledby={resultsTableId}>
            <caption id={resultsTableId} className="sr-only">
              Search results
            </caption>

            <THead>
              <Tr>
                <Th className="!bg-white">
                  <span className="sr-only">Selected</span>
                </Th>

                {orderedFields.map(field => (
                  <Th key={field} className="!bg-white font-normal">
                    {prettyPrintKiQuoteField(field)}
                  </Th>
                ))}
              </Tr>
            </THead>

            <TBody>
              {searchResults.map(quote => (
                <Tr
                  key={quote.quoteLineId}
                  className="sm:grid-cols-[auto,repeat(2,1fr)] md:grid-cols-[auto,repeat(3,1fr)]"
                >
                  <Td className="sm:row-span-5 md:row-span-3">
                    <Checkbox
                      data-cy="overlining-search-result"
                      checked={checkedQuoteLineIds.includes(quote.quoteLineId)}
                      onChange={v => {
                        logPBQAOverliningQuoteSelect({
                          pbqaId,
                          surveyId,
                          quoteLineId: quote.quoteLineId,
                        })

                        onChangeChecked(quote, v)
                      }}
                    />
                  </Td>

                  {orderedFields.map(field => (
                    <Td key={field}>
                      <span className="block font-normal lg:hidden">
                        {prettyPrintKiQuoteField(field)}
                      </span>

                      {renderKiQuoteField(field, quote)}
                    </Td>
                  ))}
                </Tr>
              ))}
            </TBody>
          </Table>
        </TableWrapper>
      )}

      <div role="alert">
        {searchResults.length === 0 && (
          <p className="py-2 px-3 text-otto-grey-700">No results found</p>
        )}
      </div>
    </div>
  )
}

export interface SearchBarProps {
  checkedQuoteLineIds: KiQuote["quoteLineId"][]
  filters: (onSubmit: () => void) => ReactNode
  onChangeChecked: (q: KiQuote, checked: boolean) => void
  onSearch: (s: string) => void
  searchResults: KiQuote[]
  searchText?: string
}

const SearchBar: FC<SearchBarProps> = ({
  checkedQuoteLineIds,
  filters,
  onChangeChecked,
  onSearch,
  searchResults,
  searchText,
}) => {
  const baseId = useId()
  const inputId = `${baseId}-input`
  const labelId = `${baseId}-label`

  const filterButtonId = `${baseId}-filter-button`
  const filterPanelId = `${baseId}-filter-panel`

  const searchButtonId = `${baseId}-search-button`
  const resultsTableId = `${baseId}-results-table`
  const resultsPanelId = `${baseId}-results-panel`

  const containerRef = useRef<HTMLDivElement>(null)
  const filtersContainerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const resultsContainerRef = useRef<HTMLDivElement>(null)

  const [resultsOpen, setResultsOpen] = useState<boolean>(false)
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)

  const onInteract = (): void => {
    setResultsOpen(true)

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div
      ref={containerRef}
      onBlur={e => {
        const userClickedResults =
          resultsContainerRef.current &&
          resultsContainerRef.current.contains(e.relatedTarget)

        const userClickedInput =
          inputRef.current && inputRef.current.contains(e.relatedTarget)

        if (!(userClickedResults || userClickedInput)) {
          setResultsOpen(false)
        }

        const userClickedFilters =
          filtersContainerRef.current &&
          filtersContainerRef.current.contains(e.relatedTarget)

        if (!userClickedFilters) {
          setFiltersOpen(false)
        }
      }}
    >
      <label htmlFor={inputId} className="sr-only" id={labelId}>
        Search for a quote
      </label>

      <div className="otto-focus relative flex h-10 w-full items-center justify-between gap-4 bg-otto-mid px-4 forced-colors:border forced-colors:border-transparent">
        <button
          aria-controls={resultsPanelId}
          aria-expanded={resultsOpen}
          aria-haspopup="dialog"
          aria-labelledby={`${labelId} ${searchButtonId}`}
          className="flex items-center rounded-r-md focus:outline-none"
          id={searchButtonId}
          onClick={onInteract}
          tabIndex={-1}
          type="button"
          data-cy="overlining-search-btn"
        >
          <SearchIcon className="w-5 text-otto-pop" aria-hidden />
        </button>

        <form
          aria-labelledby={labelId}
          role="search"
          className="contents"
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <input
            aria-controls={resultsPanelId}
            aria-haspopup="dialog"
            className="w-full bg-otto-mid text-base text-otto-pop placeholder:text-otto-pop-800 focus:outline-none"
            id={inputId}
            onChange={e => {
              onSearch(e.target.value)
              setResultsOpen(true)
            }}
            onFocus={() => setResultsOpen(true)}
            onKeyDown={e => {
              e.stopPropagation()

              if (e.code === "Escape") {
                setResultsOpen(false)
              }
            }}
            placeholder="Search on assured name"
            ref={inputRef}
            // We use role=searchbox instead of type=search because we don't
            // want the browser's inbuilt search input styling
            role="searchbox"
            spellCheck={false}
            type="text"
            value={searchText}
            data-cy="overlining-search-input"
          />
        </form>

        {resultsOpen && (
          <SearchResults
            checkedQuoteLineIds={checkedQuoteLineIds}
            containerId={resultsPanelId}
            containerRef={resultsContainerRef}
            onChangeChecked={onChangeChecked}
            onKeyDown={e => {
              e.stopPropagation()

              if (e.code === "Escape") {
                setResultsOpen(false)
              }
            }}
            searchResults={searchResults}
            resultsTableId={resultsTableId}
          />
        )}

        <button
          aria-controls={filterPanelId}
          aria-expanded={filtersOpen}
          aria-haspopup="dialog"
          className="otto-focus h-5 rounded-sm text-otto-pop"
          id={filterButtonId}
          onClick={() => setFiltersOpen(!filtersOpen)}
          onKeyDown={e => {
            e.stopPropagation()

            if (e.code === "Escape" && filtersOpen) {
              setFiltersOpen(false)
            }
          }}
          type="button"
        >
          <span className="sr-only">Search filters</span>
          <FilterIcon className="w-5" />
        </button>

        {filtersOpen && (
          // We need the `onKeyDown` listener to catch presses of the Escape
          // key, or else they'll cause the whole modal to close
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div
            className={popoverCss}
            id={filterPanelId}
            ref={filtersContainerRef}
            role="dialog"
            tabIndex={-1}
            onKeyDown={e => {
              e.stopPropagation()

              if (e.code === "Escape") {
                setFiltersOpen(false)
              }
            }}
          >
            {filters(onInteract)}
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchBar
