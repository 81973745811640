import { FC } from "react"

import {
  ArrowRightIcon,
  Button,
  Callout,
  CheckCircleIcon,
  CheckIcon,
} from "@appia/ui-components"
import ErrorMessage from "src/components/ErrorMessage"

import * as RD from "@appia/remote-data"

import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"

const FormCTAs: FC<{
  confirmRequest: RD.RemoteData<Error, unknown>
  mode: "continue" | "complete"
  onSaveDraft: () => Promise<void>
  saveDraftRequest: RD.RemoteData<Error, unknown>
  showFieldErrors: boolean
}> = ({
  confirmRequest,
  mode,
  onSaveDraft,
  saveDraftRequest,
  showFieldErrors,
}) => {
  const pageName = usePageName()

  return (
    <>
      {mode === "complete" && showFieldErrors && (
        <div className="mt-4 px-4">
          <Callout type="error">
            <p className="font-bold">
              The PBQA can only be completed if the following conditions are
              met:
            </p>

            <ul className="list-disc">
              <li>
                All the statuses have been filled. Please don&rsquo;t leave any
                blank statuses.
              </li>
              <li>
                All failed statuses have a note added for why they are OK to
                proceed.
              </li>
            </ul>
          </Callout>
        </div>
      )}

      {RD.isFailure(saveDraftRequest) && (
        <div className="mt-4 px-4">
          <ErrorMessage
            error={saveDraftRequest.error}
            message="Error saving as draft"
          />
        </div>
      )}

      <div className="grid grid-cols-1 items-center justify-between gap-4 p-4 lg:grid-cols-2">
        {RD.isSuccess(saveDraftRequest) ? (
          <div className="self-center justify-self-center">
            <span>Saved</span>
            <span className="ml-2 inline-block w-7 align-middle text-otto-bright-green">
              <CheckCircleIcon />
            </span>
          </div>
        ) : (
          <Button
            label="Save as draft"
            stretch="space-around"
            style="outlined"
            theme="night"
            isLoading={RD.isLoading(saveDraftRequest)}
            onClick={async () => {
              logButtonClick({
                buttonName: "Save as draft",
                containerName: "Main",
                pageName,
              })

              await onSaveDraft()
            }}
          />
        )}

        {mode === "complete" ? (
          <Button
            label="Complete PBQA"
            stretch="space-around"
            style="filled"
            theme="pop"
            isLoading={RD.isLoading(confirmRequest)}
            icon={{ position: "left", icon: <CheckIcon /> }}
          />
        ) : (
          <Button
            label="Continue"
            stretch="space-around"
            style="filled"
            theme="pop"
            isLoading={RD.isLoading(confirmRequest)}
            icon={{ position: "right", icon: <ArrowRightIcon /> }}
          />
        )}
      </div>
    </>
  )
}

export default FormCTAs
