import { FC, ReactNode, useState } from "react"
import { Navigate } from "react-router-dom"

import {
  ArchiveIcon,
  Card,
  ClipboardListIcon,
  Link,
  Tabs,
  Toast,
  UploadIcon,
} from "@appia/ui-components"
import ToastViewport from "src/components/ToastViewport"

import ScreenTemplate from "src/templates/ScreenTemplate"
import * as ActivePBQATable from "./ActivePBQATable"
import * as ArchivePBQATable from "./ArchivePBQATable"

import * as RD from "@appia/remote-data"
import { useGetCurrentUser } from "src/swr"

import { PageNameContext } from "src/contexts/PageNameContext"

import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import useLocalStorageState from "src/hooks/useLocalStorageState"
import { useTableFilters } from "src/components/DashboardTable"

const PAGE_NAME = "PBQA Checker"

type TabValue = "activePBQAs" | "archivePBQAs"

const activePBQAsValue: TabValue = "activePBQAs"
const archivePBQAsValue: TabValue = "archivePBQAs"

const tabsConfig: {
  value: TabValue
  text: string
  icon: typeof ArchiveIcon
}[] = [
  { value: activePBQAsValue, text: "Active", icon: ClipboardListIcon },
  { value: archivePBQAsValue, text: "Archive", icon: ArchiveIcon },
]

const PBQAScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const { request: userRequest } = useGetCurrentUser()
  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  const [activeTableSettings, setActiveTableSettings] =
    useLocalStorageState<ActivePBQATable.TableSettings>(
      ActivePBQATable.localStorageSettingsKey,
      ActivePBQATable.defaultTableSettings,
    )

  const [activeTableFilters, setActiveTableFilters] =
    useTableFilters<ActivePBQATable.TableFilters>(
      ActivePBQATable.localStorageFiltersKey,
      ActivePBQATable.defaultTableFilters,
    )

  const [archiveTableSettings, setArchiveTableSettings] =
    useLocalStorageState<ArchivePBQATable.TableSettings>(
      ArchivePBQATable.localStorageSettingsKey,
      ArchivePBQATable.defaultTableSettings,
    )

  const [archiveTableFilters, setArchiveTableFilters] =
    useTableFilters<ArchivePBQATable.TableFilters>(
      ArchivePBQATable.localStorageFiltersKey,
      ArchivePBQATable.defaultTableFilters,
    )

  const [activeTab, setActiveTab] = useState<TabValue>("activePBQAs")

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  if (!user) {
    return <Navigate to="/" />
  }

  return (
    <ScreenTemplate
      pageTitle={PAGE_NAME}
      layout={{
        type: "regular",
        actionButton: (
          <Link
            href="/pbqa/upload"
            label="Upload slips"
            theme="pop"
            style="filled"
            icon={{
              position: "left",
              icon: <UploadIcon />,
            }}
          />
        ),
      }}
    >
      <PageNameContext.Provider value={PAGE_NAME}>
        <div className="flex min-h-full flex-col pb-4">
          {user.teamId ? (
            <div className="flex grow flex-col">
              {activeTab === "activePBQAs" ? (
                <ActivePBQATable.TableControls
                  tableFilters={activeTableFilters}
                  setTableFilters={setActiveTableFilters}
                  setTableSettings={setActiveTableSettings}
                />
              ) : (
                <ArchivePBQATable.TableControls
                  tableFilters={archiveTableFilters}
                  setTableFilters={setArchiveTableFilters}
                  setTableSettings={setArchiveTableSettings}
                />
              )}

              <Tabs.Root
                asChild
                value={activeTab}
                onValueChange={s => {
                  if (s === "activePBQAs" || s === "archivePBQAs") {
                    setActiveTab(s)
                  }
                }}
              >
                <>
                  <div className="overflow-hidden rounded-lg rounded-b-none border border-b-0 border-otto-grey-300 bg-white">
                    <Tabs.List aria-label="Tables" className="max-w-min">
                      {tabsConfig.map(({ value, text, icon: Icon }) => (
                        <Tabs.Trigger
                          key={value}
                          value={value}
                          className="flex items-center justify-center gap-2 py-3 px-7"
                        >
                          <Icon className="w-5" />
                          <span>{text}</span>
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                  </div>

                  <Tabs.Content asChild value={activePBQAsValue}>
                    <ActivePBQATable.Table
                      tableFilters={activeTableFilters}
                      tableSettings={activeTableSettings}
                      setTableSettings={setActiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>

                  <Tabs.Content asChild value={archivePBQAsValue}>
                    <ArchivePBQATable.Table
                      tableFilters={archiveTableFilters}
                      tableSettings={archiveTableSettings}
                      setTableSettings={setArchiveTableSettings}
                      triggerToast={setAndTriggerToast}
                    />
                  </Tabs.Content>
                </>
              </Tabs.Root>
            </div>
          ) : (
            <Card className="m-auto w-max">
              <p className="mb-4 text-center">
                Looks like you haven&lsquo;t joined a team yet. You need to join
                a team to view PBQA slips.
              </p>

              <div className="mx-auto">
                <Link
                  href="/teams"
                  label="Join a team"
                  theme="night"
                  style="filled"
                  className="mx-auto"
                  onClick={() => {
                    logButtonClick({
                      buttonName: "Join a team",
                      linkHref: "/teams",
                      containerName: "Main",
                      pageName: PAGE_NAME,
                    })
                  }}
                />
              </div>
            </Card>
          )}
        </div>

        <Toast.Toast
          open={toastState.open}
          onOpenChange={toastState.onOpenChange}
          type={toastType}
          message={toastMessage}
        />

        <ToastViewport />
      </PageNameContext.Provider>
    </ScreenTemplate>
  )
}

export default PBQAScreen
