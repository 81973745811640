import { FC, Fragment, useId, useState } from "react"

import { CheckIcon, ErrorMessage, XIcon } from "@appia/ui-components"

import NoteModal from "./NoteModal"
import type { NoteAndReasons } from "../components/ReasonSelection"

import { PBQAAnswer, PBQAQuestion } from "@appia/api"

import { logPBQAAnswerAccept } from "src/amplitude"

import usePageName from "src/contexts/PageNameContext"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import classNames from "classnames"
import AddNoteButton from "../components/AddNoteButton"
import { NoteState, requiresNote } from "ReviewPBQA/state"

const AcceptedRadioButtons: FC<{
  accepted: PBQAAnswer["accepted"]
  noteAndReasons: NoteAndReasons
  onChangeAcceptance: (b: boolean | null) => void
  onChangeNoteAndReasons: (n: NoteAndReasons) => void
  paddingTop: string
  questionId: PBQAQuestion["id"]
  questionText: PBQAQuestion["text"]
  showFieldErrors: boolean
}> = ({
  accepted,
  noteAndReasons,
  onChangeAcceptance,
  onChangeNoteAndReasons,
  paddingTop,
  questionId,
  questionText,
  showFieldErrors,
}) => {
  const pageName = usePageName()
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()

  const [showModal, setShowModal] = useState<boolean>(false)

  const radioGroupName = useId()
  const errorId = useId()
  const descriptionId = useId()

  const showNoteButton = accepted === false
  const errorState: NoteState = requiresNote(accepted, noteAndReasons?.reasons)

  return (
    <div
      className={classNames(
        "w-full flex-shrink-0 border-t border-otto-grey-300 bg-white p-2 lg:w-32 lg:border-l lg:border-t-0",
        paddingTop,
      )}
    >
      <div className="flex w-full items-center justify-center gap-1 pb-[1px]">
        {(["No", "Yes"] as const).map(value => {
          let checked: boolean
          let checkedCss: string
          let Icon: typeof XIcon

          switch (value) {
            case "Yes":
              checked = accepted === true
              checkedCss =
                "bg-otto-green-50 text-otto-deep-green border-otto-deep-green"
              Icon = CheckIcon
              break
            case "No":
              checked = accepted === false
              checkedCss =
                "bg-otto-red-50 text-otto-bright-red border-otto-bright-red"
              Icon = XIcon
              break
          }

          return (
            <Fragment key={value}>
              <label
                className={classNames(
                  "otto-focus-within h-8 w-8 flex-shrink-0 cursor-pointer rounded-full border p-1",
                  {
                    [checkedCss]: checked,
                    "forced-colors:bg-SystemHighlight": checked,
                    "bg-otto-grey-200 text-otto-grey-700": !checked,
                  },
                )}
              >
                <Icon />
                <span className="sr-only">{value}</span>
                <input
                  type="radio"
                  name={radioGroupName}
                  required
                  aria-describedby={`${checked ? descriptionId : ""} ${
                    showFieldErrors && errorState !== "noError" ? errorId : ""
                  }`}
                  value={value}
                  checked={checked}
                  onClick={() => {
                    // If the user re-selects this button, return to the null
                    // state
                    if (checked) {
                      onChangeAcceptance(null)
                    }
                  }}
                  onChange={e => {
                    const accepted: boolean = e.target.value === "Yes"

                    onChangeAcceptance(accepted)

                    logPBQAAnswerAccept({
                      pbqaId,
                      surveyId,
                      questionId,
                      accepted,
                    })

                    if (!accepted) {
                      setShowModal(true)
                    }
                  }}
                  className="sr-only"
                />
              </label>

              {checked && (
                <p className="sr-only" id={descriptionId}>
                  Select this option again to de-select.
                </p>
              )}
            </Fragment>
          )
        })}
      </div>

      {showNoteButton && (
        <>
          <AddNoteButton
            pageName={pageName}
            questionId={questionId}
            reasons={noteAndReasons.reasons ?? []}
            label={questionText}
            onClick={() => setShowModal(true)}
          />

          {showModal && (
            <NoteModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              onSave={updatedNoteAndReasons => {
                onChangeNoteAndReasons(updatedNoteAndReasons)
                setShowModal(false)
              }}
              noteAndReasons={noteAndReasons}
              questionId={questionId}
            />
          )}
        </>
      )}

      {showFieldErrors &&
        (errorState === "notAccepted" ? (
          <ErrorMessage
            message="Select a state"
            id={errorId}
            className="mt-1"
          />
        ) : errorState === "missingNote" ? (
          <ErrorMessage message="Add a note" id={errorId} className="mt-1" />
        ) : null)}
    </div>
  )
}

export default AcceptedRadioButtons
