import { FC } from "react"
import { IconButton, TrashIcon } from "@appia/ui-components"
import type { UploadedDocument } from "./types"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"
import StaticTable from "src/components/StaticTable"

const AttachmentTable: FC<{
  attachment: UploadedDocument
  containerName: string
  onDeleteAttachment: () => void
}> = ({ attachment, containerName, onDeleteAttachment }) => {
  const pageName = usePageName()

  const headers = [
    "Attachment",
    <span key="header-delete" className="sr-only">
      Delete Attachment Action
    </span>,
  ]

  const data = [
    [
      <span key="data-attachment" className="block truncate">
        {attachment.name}
      </span>,
      <IconButton
        key="data-delete"
        color="text-otto-grey-600"
        label={`Delete file ${attachment.name}`}
        size={6}
        icon={<TrashIcon />}
        onClick={() => {
          logButtonClick({
            buttonName: "Cancel",
            containerName,
            pageName,
          })

          onDeleteAttachment()
        }}
      />,
    ],
  ]

  return <StaticTable caption="Attachment" headers={headers} data={data} />
}

export default AttachmentTable
