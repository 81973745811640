import { FC } from "react"

import * as RD from "@appia/remote-data"
import { useGetPolicyCreationUploadFileLimits } from "src/swr"

import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"

import AttachmentInner from "./AttachmentInner"
import type { UploadedDocument } from "./types"

const Attachment: FC<{
  containerName: string
  onFileChange: (d: UploadedDocument | null) => void
  uploadedDocument: UploadedDocument | null
}> = ({ containerName, onFileChange, uploadedDocument }) => {
  const { request: limitsReq } = useGetPolicyCreationUploadFileLimits()

  return RD.match(
    limitsReq,
    <Loading />,
    <Loading />,

    limits => (
      <AttachmentInner
        containerName={containerName}
        uploadedDocument={uploadedDocument}
        onFileChange={onFileChange}
        limits={limits}
      />
    ),

    limitsErr => (
      <ErrorMessage error={limitsErr} message="Failed to fetch upload limits" />
    ),
  )
}

export default Attachment
