import { FC, useId, useState } from "react"

import { Endorsement, Recipient, ReferDetails, referToPu } from "@appia/api"
import { useGetEndorsement, useGetPURecipients } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import {
  Button,
  Callout,
  Input,
  InputLabel,
  InsertLinkIcon,
  SelectMultiple,
  SendIcon,
  TextArea,
} from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"
import InputWithError from "src/components/InputWithError"

import { isEmpty, isNotNull } from "src/utils/typeRefinements"
import { FormField, mkInvalidFormField } from "@appia/form-data"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const EMPTY_BODY_ERROR = "Please add your message"
const EMPTY_SUBJECT_ERROR = "Please add a subject line"
const EMPTY_RECIPIENTS_ERROR = "Please select a recipient"

const ReferPUModal: FC<{
  endorsementId: Endorsement["id"]
  isOpen: boolean
  onClose: () => void
  onBack: () => void
  onSuccess: () => void
}> = ({ endorsementId, isOpen, onClose, onBack, onSuccess }) => {
  const pageName = usePageName()
  const apiClient = useApiClient()

  const { request: recipientRequest, update: updateRecipient } =
    useGetPURecipients()
  const { update: updateEndorsement } = useGetEndorsement(endorsementId)

  const recipientOptions: Recipient[] = RD.isSuccess(recipientRequest)
    ? recipientRequest.data
    : []

  const [showFormFieldErrors, setShowFormFieldErrors] = useState<boolean>(false)

  const [formFields, setFormFields] = useState<{
    emails: FormField<Recipient["email"][], Recipient[]>
    subject: FormField<string>
    body: FormField<string>
  }>({
    emails: mkInvalidFormField([], EMPTY_RECIPIENTS_ERROR),
    subject: mkInvalidFormField("", EMPTY_SUBJECT_ERROR),
    body: mkInvalidFormField("", EMPTY_BODY_ERROR),
  })

  const [sendEmailRequest, setSendEmailRequest] = useState<
    RD.RemoteData<Error, null>
  >(RD.NotAsked)

  const onSubmit = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Send",
      containerName: "Refer to PU Modal",
      pageName,
    })

    setShowFormFieldErrors(false)
    setSendEmailRequest(RD.NotAsked)

    const { emails, body, subject } = formFields

    if (
      emails.validated.valid === false ||
      body.validated.valid === false ||
      subject.validated.valid === false
    ) {
      setShowFormFieldErrors(true)
      return
    }

    const referDetails: ReferDetails = {
      emails: emails.validated.data,
      body: body.validated.data,
      subject: subject.validated.data,
    }

    try {
      setSendEmailRequest(RD.Loading)
      await referToPu(apiClient, endorsementId, referDetails)
      setSendEmailRequest(RD.Success(null))
      updateEndorsement()
      onSuccess()
    } catch (e) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        setSendEmailRequest(RD.Failure(e))
      }
    }
  }

  const formId = useId()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Refer to PU"
      actionsReflowBreakpoint="sm"
      content={
        <form
          id={formId}
          noValidate
          onSubmit={e => {
            e.preventDefault()

            onSubmit()
          }}
          className="grid gap-4"
        >
          <InputWithError
            errorTestId="to-error-message"
            formField={formFields.emails}
            showFormFieldErrors={showFormFieldErrors}
            input={errorId => (
              <>
                <InputLabel label="To:">
                  <SelectMultiple
                    aria-label="Select recipient(s)"
                    disabled={RD.isFailure(recipientRequest)}
                    errorMessageId={errorId}
                    options={recipientOptions.map(r => ({
                      label: r.name,
                      value: r.id,
                    }))}
                    onSelect={opts => {
                      const recipients: Recipient[] = opts
                        .map(
                          opt =>
                            recipientOptions.find(
                              recipient => recipient.id === opt,
                            ) ?? null,
                        )
                        .filter(isNotNull)

                      setFormFields(fields => ({
                        ...fields,
                        emails: {
                          raw: recipients,
                          validated:
                            recipients.length === 0
                              ? { valid: false, error: EMPTY_RECIPIENTS_ERROR }
                              : {
                                  valid: true,
                                  data: recipients.map(r => r.email),
                                },
                        },
                      }))
                    }}
                    selectedValues={formFields.emails.raw.map(r => r.id)}
                    placeholder="Select recipient(s)"
                    required
                  />
                </InputLabel>

                {RD.isFailure(recipientRequest) && (
                  <Callout className="mt-2" type="error">
                    <div className="grid gap-2">
                      <p className="font-bold">No email recipients available</p>
                      <p>Refresh or try again later.</p>
                      <Button
                        className="justify-self-end"
                        label="Refresh"
                        style="text"
                        theme="night"
                        onClick={() => updateRecipient()}
                      />
                    </div>
                  </Callout>
                )}
              </>
            )}
          />

          <InputWithError
            errorTestId="subject-error-message"
            formField={formFields.subject}
            showFormFieldErrors={showFormFieldErrors}
            input={errorId => (
              <InputLabel label="Subject:">
                <Input
                  aria-describedby={errorId}
                  hasError={!!errorId}
                  placeholder="Add subject line"
                  onChange={value =>
                    setFormFields(fields => ({
                      ...fields,
                      subject: {
                        raw: value,
                        validated: isEmpty(value)
                          ? { valid: false, error: EMPTY_SUBJECT_ERROR }
                          : { valid: true, data: value },
                      },
                    }))
                  }
                  required
                />
              </InputLabel>
            )}
          />

          <InputWithError
            errorTestId="body-error-message"
            formField={formFields.body}
            showFormFieldErrors={showFormFieldErrors}
            input={errorId => (
              <TextArea
                aria-describedby={errorId}
                hasError={!!errorId}
                label="Email body"
                visuallyHideLabel
                placeholder="Add your message"
                onChange={value =>
                  setFormFields(fields => ({
                    ...fields,
                    body: {
                      raw: value,
                      validated: isEmpty(value)
                        ? { valid: false, error: EMPTY_BODY_ERROR }
                        : { valid: true, data: value },
                    },
                  }))
                }
                value={formFields.body.raw}
                required
              />
            )}
          />

          <div className="flex items-center">
            <InsertLinkIcon className="mr-1.5 w-6" />
            <p>
              The email will include a one-time link to the endorsement in Otto
            </p>
          </div>

          {RD.isFailure(sendEmailRequest) && (
            <Callout type="error">Failed to send, please try again.</Callout>
          )}
        </form>
      }
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => {
            onClose()
            logButtonClick({
              buttonName: "Cancel",
              containerName: "Refer to PU Modal",
              pageName,
            })
          }}
        />,
        <Button
          form={formId}
          key="send"
          label="Send"
          icon={{ position: "right", icon: <SendIcon /> }}
          theme="pop"
          style="filled"
          isLoading={RD.isLoading(sendEmailRequest)}
        />,
      ]}
      onBack={onBack}
    />
  )
}

export default ReferPUModal
