import { FC, ReactNode } from "react"

import { ChevronDownIcon, ChevronUpIcon } from "@appia/ui-components"
import { Disclosure } from "@headlessui/react"

import classNames from "classnames"

const CollapsibleSectionPanel: FC<{
  children: ReactNode
  title: string
}> = ({ children, title }) => (
  <div className="rounded-md border border-otto-grey-300 bg-white">
    <Disclosure defaultOpen>
      {({ open }) => (
        <section>
          <Disclosure.Button
            className={classNames(
              "otto-focus-inset flex w-full items-center gap-4 py-3 px-2 xl:gap-8",
              { "border-b border-otto-grey-200": open },
            )}
          >
            <span className="ml-2 font-bold">{title}</span>

            <span className="ml-auto block w-8 shrink-0">
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </span>
          </Disclosure.Button>

          <Disclosure.Panel>{children}</Disclosure.Panel>
        </section>
      )}
    </Disclosure>
  </div>
)

export default CollapsibleSectionPanel
