import { FC } from "react"
import "./ProgressBar.css"

const ProgressBar: FC<{ id: string; progress: number }> = ({
  id,
  progress,
}) => (
  <label>
    <span className="mb-4 block text-3xl font-bold">Uploading slip</span>

    <progress id={id} className="h-3 w-full" max={100} value={progress}>
      {`${progress}%`}
    </progress>
  </label>
)

export default ProgressBar
