import { FC, useId, useState } from "react"

import { Document, PolicyCreationData, createPolicy } from "@appia/api"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"

import {
  Button,
  Input,
  InputLabel,
  ModalDescription,
  UploadIcon,
} from "@appia/ui-components"
import ErrorMessage from "src/components/ErrorMessage"
import ModalTemplate from "src/components/ModalTemplate"

import InputWithError from "src/components/InputWithError"
import Attachment from "./Attachment"
import type { UploadedDocument } from "./Attachment/types"

import usePageName from "src/contexts/PageNameContext"
import useApiClient from "src/contexts/ApiClientContext"

import { FormField, mkInvalidFormField } from "@appia/form-data"
import { logButtonClick } from "src/amplitude"
import { isEmpty } from "src/utils/typeRefinements"

const EMPTY_UMR = "Please fill in the UMR"
const EMPTY_ATTACHMENT = "Please upload a file"

const CONTAINER_NAME = "Policy creation modal"

const PolicyCreationModal: FC<{
  isOpen: boolean
  onClose: () => void
  onSuccess: (umr: string) => void
}> = ({ isOpen, onClose, onSuccess }) => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const [showFormFieldErrors, setShowFormFieldErrors] = useState<boolean>(false)

  const [formFields, setFormFields] = useState<{
    UMR: FormField<string>
    InsuredName: string
    PolicyReference: string
    InceptionDate: string
    Attachment: FormField<Document["id"], UploadedDocument | null>
  }>({
    UMR: mkInvalidFormField("", EMPTY_UMR),
    InsuredName: "",
    PolicyReference: "",
    InceptionDate: "",
    Attachment: mkInvalidFormField(null, EMPTY_ATTACHMENT),
  })

  const [uploadPolicy, setUploadPolicy] = useState<RD.RemoteData<Error, null>>(
    RD.NotAsked,
  )

  const onSubmit = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Upload",
      containerName: CONTAINER_NAME,
      pageName,
    })

    setShowFormFieldErrors(false)
    setUploadPolicy(RD.NotAsked)

    const { UMR, InsuredName, PolicyReference, InceptionDate, Attachment } =
      formFields

    if (UMR.validated.valid === false || Attachment.validated.valid === false) {
      setShowFormFieldErrors(true)
      return
    }

    const creationData: PolicyCreationData = {
      umr: UMR.validated.data,
      insuredName: InsuredName,
      policyReference: PolicyReference,
      // The API expects `inceptionDate` to be either a formatted date string or
      // null
      inceptionDate:
        InceptionDate === "" ? null : new Date(InceptionDate).toISOString(),
      documentId: Attachment.validated.data,
    }

    try {
      setUploadPolicy(RD.Loading)
      await createPolicy(apiClient, creationData)
      setUploadPolicy(RD.Success(null))
      onSuccess(UMR.validated.data)
    } catch (e) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        setUploadPolicy(RD.Failure(e))
      }
    }
  }

  const formId = useId()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Upload final slip"
      content={
        <ModalDescription as="div">
          <form
            id={formId}
            className="grid gap-4"
            noValidate
            onSubmit={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <InputWithError
              formField={formFields.UMR}
              showFormFieldErrors={showFormFieldErrors}
              errorTestId="umr-error"
              input={errorId => (
                <InputLabel label="UMR">
                  <Input
                    placeholder="Add UMR"
                    aria-describedby={errorId}
                    hasError={!!errorId}
                    onChange={value =>
                      setFormFields(fields => ({
                        ...fields,
                        UMR: {
                          raw: value,
                          validated: isEmpty(value)
                            ? { valid: false, error: EMPTY_UMR }
                            : { valid: true, data: value },
                        },
                      }))
                    }
                    required
                  />
                </InputLabel>
              )}
            />

            <InputLabel label="Insured name">
              <Input
                placeholder="Add Insured Name"
                onChange={value =>
                  setFormFields(fields => ({
                    ...fields,
                    InsuredName: value,
                  }))
                }
              />
            </InputLabel>

            <InputLabel label="Policy reference">
              <Input
                placeholder="Add Policy Reference"
                onChange={value =>
                  setFormFields(fields => ({
                    ...fields,
                    PolicyReference: value,
                  }))
                }
              />
            </InputLabel>

            <InputLabel label="Inception Date">
              <Input
                type="date"
                placeholder="Add Inception Date"
                onChange={value =>
                  setFormFields(fields => ({
                    ...fields,
                    InceptionDate: value,
                  }))
                }
              />
            </InputLabel>

            <InputWithError
              formField={formFields.Attachment}
              showFormFieldErrors={showFormFieldErrors}
              errorTestId="attachment-error"
              input={() => (
                <Attachment
                  containerName={CONTAINER_NAME}
                  uploadedDocument={formFields.Attachment.raw}
                  onFileChange={file => {
                    setFormFields(fields => ({
                      ...fields,
                      Attachment: {
                        raw: file,
                        validated:
                          file === null
                            ? { valid: false, error: EMPTY_ATTACHMENT }
                            : { valid: true, data: file.id },
                      },
                    }))
                  }}
                />
              )}
            />

            {RD.isFailure(uploadPolicy) && (
              <ErrorMessage
                message="Failed to upload, please try again."
                error={uploadPolicy.error}
              />
            )}
          </form>
        </ModalDescription>
      }
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => {
            onClose()
            logButtonClick({
              buttonName: "Cancel",
              containerName: CONTAINER_NAME,
              pageName,
            })
          }}
        />,
        <Button
          form={formId}
          key="upload"
          label="Upload"
          icon={{ position: "right", icon: <UploadIcon /> }}
          theme="pop"
          style="filled"
          isLoading={RD.isLoading(uploadPolicy)}
        />,
      ]}
    />
  )
}
export default PolicyCreationModal
