export interface ChecklistItem {
  endorsementType: string
  toTPU: boolean
  toPU: boolean
}

export const checklist: ChecklistItem[] = [
  {
    endorsementType: "Period Extension",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "(Re)Insured name change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "(Re)Insured address change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Additional Assured Names",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Automatic period extension",
    toTPU: false,
    toPU: true,
  },
  {
    endorsementType: "Policy/Endorsement Cancellation",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Syndicate/Leader Change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Rate Matrix",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Deductible change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Profit Commission",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Adding/Removing Locations",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Premium change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Net down",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Minimum Premium change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "BoR/UMR Change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Surplus Lines Broker or Service of Suit change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Overseas Broker Change",
    toTPU: false,
    toPU: true,
  },
  {
    endorsementType: "Limits of Liability/Sum Insured change",
    toTPU: true,
    toPU: true,
  },
  {
    endorsementType: "Adjustment to TIV/Total Sum Insured (no limit change)",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Adjustment to TIV (limit change)",
    toTPU: true,
    toPU: true,
  },
  {
    endorsementType: "Sublimit change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Order Change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Basis of Written Lines (Increasing Ki line)",
    toTPU: true,
    toPU: true,
  },
  {
    endorsementType: "Basis of Written Lines (Decreasing Ki line)",
    toTPU: true,
    toPU: true,
  },
  {
    endorsementType: "Risk Code Change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Adding / Removing LMA / NMA Clause",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "War and Terrorism Exclusion",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Wording change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Settlement Due Date (SDD)",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Tax Schedule / Tax Split amendment",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Premium Payment terms/conditions adjustment",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Subjectivities satisfied",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "RI Cert",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Adding Service of Suit / Surplus Lines Broker",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Adding retrocedent",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Additional mortgagees/loss payees",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Changing Reinsurer",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Changing locations within a sub-limit",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Adding Vessels",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Allocation of premium to coding",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Canadian AIF (Interlocking) - Clause",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Claims Agreement Parties",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Contract Clause Change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Retention Change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Excess Change",
    toTPU: true,
    toPU: true,
  },
  {
    endorsementType: "Vendors change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Brokerage",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "NAICs code change",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Adding wells of energy related values",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Extra Shows (no premium change)",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Extra Shows (premium change)",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Location change of premises in Limit(s)",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Addition of loss history",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Revising Conditions",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Revised Insured Event Dates",
    toTPU: false,
    toPU: false,
  },
  {
    endorsementType: "Inception date change",
    toTPU: true,
    toPU: false,
  },
  {
    endorsementType: "Course of Construction project being added",
    toTPU: true,
    toPU: false,
  },
]
