/**
 * Note that, at the request of the product and design teams, this component
 * should look and feel exactly like the `ToggleTabs` component even though it
 * serves a different purpose. Thus, much of the implementation is duplicated.
 */
import { FC, useEffect, useId, useRef, useState } from "react"
import classNames from "classnames"

interface Option {
  label: string
  value: string
}

const ToggleRadioButtons: FC<{
  legend: string
  onSelect: (v: Option["value"]) => void
  options: Option[]
  selectedValue?: Option["value"]
  visuallyHideLegend?: boolean
}> = ({
  legend,
  onSelect,
  options,
  selectedValue,
  visuallyHideLegend = false,
}) => {
  const selectedLabelRef = useRef<HTMLLabelElement>(null)

  const [highlightPos, setHighlightPos] = useState<{
    left: number
    width: number
  } | null>(null)

  useEffect(() => {
    if (selectedLabelRef.current) {
      setHighlightPos({
        left: selectedLabelRef.current.offsetLeft,
        width: selectedLabelRef.current.offsetWidth,
      })
    }
    // removing selectedLabelRef.current causes 1st render hiccups
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabelRef.current, selectedValue])

  const groupId = useId()

  return (
    <fieldset className="block">
      <legend
        className={classNames("mb-1 block text-base font-normal", {
          "sr-only": visuallyHideLegend,
        })}
      >
        {legend}
      </legend>

      <div className="relative isolate grid w-max auto-cols-auto grid-flow-col grid-rows-1 items-center rounded-full border-2 border-otto-mist bg-otto-mist py-2">
        <div
          role="presentation"
          className={classNames(
            "absolute left-0 top-0 bottom-0 z-0 transform self-stretch rounded-full bg-white p-2 forced-colors:bg-SystemHighlight motion-safe:transition-[width,transform] motion-safe:duration-200 motion-safe:ease-in-out",
            // Hide the element until it already has a position set, otherwise it
            // will animate from 0 to its initial position when the page loads
            { hidden: highlightPos === null },
          )}
          style={{
            width: `${highlightPos?.width || 0}px`,
            transform: `translateX(${highlightPos?.left || 0}px)`,
          }}
        />

        {options.map(option => {
          const isSelected = option.value === selectedValue

          return (
            <label
              key={option.value}
              className="otto-focus-within relative z-10 min-w-[3rem] cursor-pointer rounded-full bg-transparent px-2 text-center text-base"
              ref={isSelected ? selectedLabelRef : undefined}
            >
              {/* When this tab is selected, the label switches to a bold font,
              which causes the tabs to jump slightly as the width changes. To
              avoid this we render a hidden `span` that's always bold, so that
              the element is already the correct width. The 'real' `span` that
              the user sees is positioned over the top of the hidden one.
          */}
              <span aria-hidden className="select-none font-bold opacity-0">
                {option.label}
              </span>

              <span
                className={classNames("absolute inset-0", {
                  "font-bold": isSelected,
                })}
              >
                {option.label}
              </span>

              <input
                type="radio"
                className="sr-only"
                name={groupId}
                value={option.value}
                checked={isSelected}
                onChange={() => onSelect(option.value)}
              />
            </label>
          )
        })}
      </div>
    </fieldset>
  )
}

export default ToggleRadioButtons
