import React, { FC, useState } from "react"
import { Button, InputLabel, Select } from "@appia/ui-components"
import ModalTemplate from "../../../components/ModalTemplate"
import { DocumentsUploadArea } from "./DocumentsUploadArea"
import { ContractFileSource, ContractFileType } from "@appia/api"

interface DocumentsUploadModalProps {
  isOpen: boolean
  onClose: () => void
  contractVersionId: number
  umr: string
}

const DocumentsUploadModal: FC<DocumentsUploadModalProps> = ({
  isOpen,
  onClose,
  contractVersionId,
  umr,
}) => {
  const [type, setType] = useState<string>("")
  const [source, setSource] = useState<string>("")

  const handleCancel = (): void => {
    setType("")
    setSource("")

    onClose()
  }

  const isUploadDisabled = [type, source].some(value => value === "")

  const typeOptions = Object.keys(ContractFileType).map(key => ({
    value: key,
    label: ContractFileType[key as keyof typeof ContractFileType],
  }))

  const sourceOptions = Object.keys(ContractFileSource).map(key => ({
    value: key,
    label: ContractFileSource[key as keyof typeof ContractFileSource],
  }))

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={handleCancel}
      title="Upload Document"
      content={
        <div className="px-4" style={{ minHeight: "300px" }}>
          <div className="mb-4">
            <InputLabel label="Type">
              <Select
                options={typeOptions}
                selectedValue={type}
                onSelect={setType}
                placeholder="Select Type"
                required
              />
            </InputLabel>
          </div>
          <div className="mb-4">
            <InputLabel label="Source">
              <Select
                options={sourceOptions}
                selectedValue={source}
                onSelect={setSource}
                placeholder="Select Source"
                required
              />
            </InputLabel>
          </div>
          <div className="mb-4">
            <InputLabel label="Document">
              <p className="mb-2" style={{ color: "#71717A" }}>
                Fill in the required fields before uploading the file.
              </p>
            </InputLabel>
            <DocumentsUploadArea
              disabled={isUploadDisabled}
              type={type as keyof typeof ContractFileType}
              source={source as keyof typeof ContractFileSource}
              contractVersionId={contractVersionId}
              umr={umr}
            />
          </div>
        </div>
      }
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={handleCancel}
        />,
      ]}
    />
  )
}

export default DocumentsUploadModal
