import type { SVGComponent } from "./IconWrapper"

const GlobeSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM10.925 20.475V18.425C10.3417 18.425 9.85 18.2083 9.45 17.775C9.05 17.3417 8.85 16.8333 8.85 16.25V15.15L3.725 10.025C3.64167 10.3583 3.58333 10.6875 3.55 11.0125C3.51667 11.3375 3.5 11.6667 3.5 12C3.5 14.1667 4.20417 16.0583 5.6125 17.675C7.02083 19.2917 8.79167 20.225 10.925 20.475ZM18.275 17.775C18.6417 17.375 18.9625 16.95 19.2375 16.5C19.5125 16.05 19.7458 15.5792 19.9375 15.0875C20.1292 14.5958 20.2708 14.0917 20.3625 13.575C20.4542 13.0583 20.5 12.5333 20.5 12C20.5 10.2333 20.0167 8.62917 19.05 7.1875C18.0833 5.74583 16.7917 4.69167 15.175 4.025V4.475C15.175 5.05833 14.975 5.56667 14.575 6C14.175 6.43333 13.6833 6.65 13.1 6.65H10.925V8.825C10.925 9.10833 10.8125 9.34167 10.5875 9.525C10.3625 9.70833 10.1083 9.8 9.825 9.8H7.75V12H14.2C14.4833 12 14.7167 12.1083 14.9 12.325C15.0833 12.5417 15.175 12.7917 15.175 13.075V16.25H16.25C16.7333 16.25 17.1583 16.3917 17.525 16.675C17.8917 16.9583 18.1417 17.325 18.275 17.775Z"
      fill="currentColor"
    />
  </svg>
)

export default GlobeSVG
