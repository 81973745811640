import { FC } from "react"

import {
  Button,
  Callout,
  Link,
  ModalDescription,
  UploadIcon,
} from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const Modal: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const pageName = usePageName()

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Uploaded"
      content={
        <Callout type="success">
          <ModalDescription>File uploaded successfully.</ModalDescription>
        </Callout>
      }
      actions={[
        <Button
          key="upload-another"
          label="Upload another file"
          icon={{
            position: "right",
            icon: <UploadIcon />,
          }}
          style="outlined"
          theme="night"
          onClick={() => {
            logButtonClick({
              buttonName: "Upload another file",
              containerName: "Success modal",
              pageName,
            })

            onClose()
          }}
        />,

        <Link
          key="dashboard"
          href="/pbqa"
          label="Go to dashboard"
          style="filled"
          theme="pop"
          onClick={() => {
            logButtonClick({
              buttonName: "Go to dashboard",
              linkHref: "/pbqa",
              containerName: "Success modal",
              pageName,
            })
          }}
        />,
      ]}
    />
  )
}

export default Modal
