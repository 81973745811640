import { jsPDF } from "jspdf"
import autoTable, { Color } from "jspdf-autotable"
import {
  ContractResponse,
  ContractSectionItem,
  EnergyReferenceSplit,
  MAJOR_CLASS_CODE_DESCRIPTION_MAPPING,
  MINOR_CLASS_CODE_DESCRIPTION_MAPPING,
  ReferenceSplit,
  SUB_CLASS_CODE_DESCRIPTION_MAPPING,
  SyndicateLine,
} from "@appia/api"
import {
  calculateAdjustedValue,
  formatDecimal2DP,
  formatPercentage2DP,
  prettyPrintSnakeCase,
} from "../../../utils/prettyPrinters"
import kilogo from "./kilogo.png"

import { generateDocumentName } from "../../../utils/documents"
import { formatDateToDDMMYYYY } from "../../../utils/dates"

const headerFontSize = 11
const bodyFontSize = 8
const tableCellPadding = 2
const tableCellWidth = 160
const tableTextColor: Color = [0, 0, 0]
const tableFillColor: Color = [255, 255, 255]
const tableDividerSpacing = 10

declare module "jspdf" {
  interface jsPDF {
    lastAutoTable: {
      finalY?: number
    }
  }
}

export const generatePDF = (
  contractData: ContractResponse,
  version: number,
): void => {
  const doc = new jsPDF("p", "pt", "a4")
  doc.setFontSize(10)
  let currentY = 10
  contractData.sections.forEach((section: ContractSectionItem) => {
    section.lines.forEach((line: SyndicateLine) => {
      const isEnergy =
        line.syndicateData.syndicateData.groupClass?.includes("energy")

      if (line.syndicateData.syndicateData.referenceSplit) {
        line.syndicateData.syndicateData.referenceSplit.forEach(
          (refSplit: ReferenceSplit) => {
            // page header
            autoTable(doc, {
              startY: currentY,
              head: [["             ", "Contract Data"]],
              body: [],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: 20,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
              didDrawCell: data => {
                if (data.section === "head" && data.column.index === 0) {
                  doc.addImage(
                    kilogo,
                    "PNG",
                    data.cell.x + 5,
                    data.cell.y + 5,
                    30,
                    30,
                  )
                }
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + 20
              : currentY

            // reference split title
            autoTable(doc, {
              startY: currentY,
              head: [[refSplit.policyReference, ""]],
              body: [],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: 14,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
                lineColor: [0, 0, 0],
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
              didDrawCell: data => {
                const { cell, doc, section } = data
                doc.setLineWidth(1)
                doc.setDrawColor(0, 0, 0)
                if (section === "head") {
                  doc.line(
                    cell.x,
                    cell.y + cell.height,
                    cell.x + cell.width,
                    cell.y + cell.height,
                  )
                }
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Organisations", ""]],
              body: [["Client/Insured/Reinsured", contractData.header.insured]],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            const minorClassSelection = isEnergy
              ? (refSplit as EnergyReferenceSplit).minorClass
              : line.syndicateData.syndicateData.minorClass
            const subClassSelection = isEnergy
              ? (refSplit as EnergyReferenceSplit).subClass
              : line.syndicateData.syndicateData.subClass

            autoTable(doc, {
              startY: currentY,
              head: [["Stats Ref", ""]],
              body: [
                [
                  "Class Type",
                  line.syndicateData.syndicateData.classType
                    ? prettyPrintSnakeCase(
                        line.syndicateData.syndicateData.classType,
                      )
                    : "",
                ],
                [
                  "Major Class",
                  line.syndicateData.syndicateData.majorClass
                    ? MAJOR_CLASS_CODE_DESCRIPTION_MAPPING[
                        line.syndicateData.syndicateData.majorClass
                      ]
                    : "",
                ],
                [
                  "Minor Class",
                  minorClassSelection
                    ? MINOR_CLASS_CODE_DESCRIPTION_MAPPING[minorClassSelection]
                    : "",
                ],
                [
                  "Class",
                  subClassSelection
                    ? SUB_CLASS_CODE_DESCRIPTION_MAPPING[subClassSelection]
                    : "",
                ],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Brokers", ""]],
              body: [
                ["Pseudo", contractData.header.brokingHouse],
                ["Contact", contractData.header.brokerName],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["General", ""]],
              body: [
                // this is KI syndicate code hardcored
                ["Syndicate", "1618"],
                ["Market", contractData.header.conductRating],
                ["Broker Settled Indicator", section.brokerSettledIndicator],
                ["Agg Risk Data", section.aggregateRiskData],
                ["UMR", contractData.contract.uniqueMarketReference],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            const limitCurrencySelection = isEnergy
              ? (refSplit as EnergyReferenceSplit).limitAllocationCurrency
              : section.limitAmountCurrency
            const limitAmountSelection = isEnergy
              ? (refSplit as EnergyReferenceSplit).limitAllocation
              : section.limitAmount

            autoTable(doc, {
              startY: currentY,
              head: [["Limits", ""]],
              body: [
                ["Limit CCY", limitCurrencySelection],
                [
                  "Limit",
                  limitAmountSelection
                    ? formatDecimal2DP(limitAmountSelection)
                    : null,
                ],
                ["Cyber Exposure", section.cyberExposure],
                ["Cyber War Exposure", section.cyberWarExposure],
                [
                  "Deductible",
                  section.deductible
                    ? formatDecimal2DP(section.deductible)
                    : null,
                ],
                [
                  "Excess",
                  section.excess ? formatDecimal2DP(section.excess) : null,
                ],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Premiums", ""]],
              body: [
                [
                  "Written Premium",
                  refSplit.premiumAllocation
                    ? formatDecimal2DP(refSplit.premiumAllocation)
                    : null,
                ],
                [
                  "Allocated Percentage",
                  refSplit.premiumAllocationPercent
                    ? formatDecimal2DP(refSplit.premiumAllocationPercent) + " %"
                    : null,
                ],
                ["Currency", refSplit.premiumAllocationCurrency],
                [
                  "Model Price",
                  refSplit.ggModelPrice
                    ? formatDecimal2DP(refSplit.ggModelPrice)
                    : null,
                ],
                [
                  "Technical Price",
                  refSplit.ggTechnicalPrice
                    ? formatDecimal2DP(refSplit.ggTechnicalPrice)
                    : null,
                ],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Premium Rating Index", ""]],
              body: [
                [
                  "Change in Limit/Attachment/Deductible",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.changeInLad : null,
                  ),
                ],
                [
                  "Change in Coverage",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.changeInCoverage : null,
                  ),
                ],
                [
                  "Risk Adjusted Rate Change",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.rarc : null,
                  ),
                ],
                [
                  "Change in Social Inflation",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.changeInSocialInflation : null,
                  ),
                ],
                [
                  "Change in Exposure Inflation",
                  calculateAdjustedValue(
                    section.rarc
                      ? section.rarc.changeInExposureInflation
                      : null,
                  ),
                ],
                [
                  "Change in Excess Inflation",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.changeInExcessInflation : null,
                  ),
                ],
                [
                  "Change in Economic Claims Inflation",
                  calculateAdjustedValue(
                    section.rarc
                      ? section.rarc.changeInEconomicInflation
                      : null,
                  ),
                ],
                [
                  "Change in Other",
                  calculateAdjustedValue(
                    section.rarc ? section.rarc.changeInOther : null,
                  ),
                ],
                [
                  "PLR %",
                  refSplit.gnTechnicalPlr
                    ? formatPercentage2DP(refSplit.gnTechnicalPlr)
                    : null,
                ],
              ],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Additional Details", ""]],
              body: [["Occupation", contractData.header.customerType]],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY

            autoTable(doc, {
              startY: currentY,
              head: [["Notes", ""]],
              body: [["Quote Line ID", contractData.sections[0].quoteLineId]],
              theme: "grid",
              tableWidth: "auto",
              headStyles: {
                fillColor: tableFillColor,
                textColor: tableTextColor,
                fontSize: headerFontSize,
                fontStyle: "bold",
              },
              styles: {
                cellPadding: tableCellPadding,
                fontSize: bodyFontSize,
                overflow: "linebreak",
              },
              columnStyles: {
                0: { cellWidth: tableCellWidth, fontStyle: "bold" },
              },
            })

            currentY = doc.lastAutoTable.finalY
              ? doc.lastAutoTable.finalY + tableDividerSpacing
              : currentY
          },
        )
      }
    })
  })

  currentY = doc.lastAutoTable.finalY
    ? doc.lastAutoTable.finalY + tableDividerSpacing
    : currentY

  // save PDF
  const contractVersion = contractData.contract.version
  const completedAtDate = contractVersion.completedAt
    ? formatDateToDDMMYYYY(contractVersion.completedAt)
    : "UNKNOWN_COMPLETED_DATE"
  const fileName = generateDocumentName(
    contractData.header.insured,
    completedAtDate,
    version,
  )
  doc.save(fileName)
}

export default generatePDF
