import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import applyCaseMiddleware from "axios-case-converter"

export type ApiClient = AxiosInstance

export type ApiResponse<T> = AxiosResponse<T>

export interface ApiErrorCode {
  errorCode: string | null
  message: string
}

export interface ApiErrorDetail {
  loc: string[]
  msg: string
  type: string
}

export interface ApiErrorFieldErrors {
  // Top-level form errors
  errors: string[]
  // Mapping from individual field ID to error array
  fields: Record<string, string[]>
}

export type ApiErrorBody =
  | string
  | ApiErrorCode
  | ApiErrorDetail[]
  | ApiErrorFieldErrors
  | { response: string }
  | { response: ApiErrorDetail[] }

export type ApiError =
  | AxiosError<{ detail: ApiErrorBody }>
  | AxiosError<{ error: string }>
  | AxiosError<string>

export const isApiError = axios.isAxiosError

export const Client = (
  domain: string,
  options?: AxiosRequestConfig,
  useCaseConversion = true,
): ApiClient => {
  const axiosInstance = axios.create({
    ...options,
    baseURL: domain,
    withCredentials: true,
  })

  if (useCaseConversion) {
    return applyCaseMiddleware(axiosInstance, {
      ignoreHeaders: true,
      preservedKeys: key => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
        return uuidRegex.test(key)
      },
    })
  }

  return axiosInstance
}
