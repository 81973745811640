import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Callout, Link, ModalDescription } from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"
import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const SuccessModal: FC<{
  isOpen: boolean
  onClose: () => void
  endorsementId: string | null
}> = ({ isOpen, onClose, endorsementId }) => {
  const pageName = usePageName()
  const navigate = useNavigate()

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Uploaded"
      content={
        <Callout type="success">
          <ModalDescription>File uploaded successfully.</ModalDescription>
        </Callout>
      }
      actions={[
        <Link
          key="dashboard"
          href="/endorsements"
          label="Go to dashboard"
          style="outlined"
          theme="night"
          onClick={() => {
            logButtonClick({
              buttonName: "Go to dashboard",
              linkHref: "/endorsement",
              containerName: "Success modal",
              pageName,
            })
          }}
        />,
        <Button
          key="endorsement"
          label="Go to Endorsement"
          style="filled"
          theme="pop"
          onClick={() => {
            logButtonClick({
              buttonName: "Go to Endorsement",
              containerName: "Success modal",
              pageName,
            })
            navigate(`/endorsements/review/${endorsementId}`)
          }}
        />,
      ]}
    />
  )
}

export default SuccessModal
