import { useCallback, useMemo, useState } from "react"
import { FormField, ValidatedData } from "@appia/form-data"

/**
 * A React hook for a stateful `FormField` with parsing and a "show error" flag.
 * Whenever the form field changes, the error flag is reset.
 *
 * @template Data The type of the validated data used in the stateful `FormField`.
 * @template Raw The type of the raw input data used in the stateful `FormField`.
 *
 * @param initialValue - The initial, raw value of the form field.
 * @param parse - A function that takes the raw input value and returns a validated data object.
 *
 * @returns {Array} An array containing:
 * - `field` - The form field object, containing the raw input value and the validated data.
 * - `parseAndSetField` - A function to update the form field. It takes the new raw input value, parses it using the `parse` function, and updates the form field state.
 * - `showError` - A state variable indicating whether an error should be shown when the form field is invalid.
 * - `setShowError` - A function to update the `showError` state.
 *
 * @example
 * const [field, setField, showError, setShowError] = useFormFieldWithAutoResetError(initialValue, parseFunction);
 */
const useFormFieldWithAutoResetError = <Data, Raw>(
  initialValue: Raw,
  parse: (value: Raw) => ValidatedData<Data>,
): [
  FormField<Data, Raw>,
  (value: Raw) => void,
  boolean,
  (showError: boolean) => void,
] => {
  // Memoised to evaluate the initial form field once on mount, hence empty dependency array.
  const initialFormField = useMemo(
    () => ({
      raw: initialValue,
      validated: parse(initialValue),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [field, setField] = useState<FormField<Data, Raw>>(initialFormField)
  const [showError, setShowError] = useState(false)

  const parseAndSetField = useCallback(
    (value: Raw): void => {
      // Reset showError whenever the field is set.
      setShowError(false)
      setField({ raw: value, validated: parse(value) })
    },
    [parse],
  )

  return [field, parseAndSetField, showError, setShowError]
}

export default useFormFieldWithAutoResetError
