import { FC } from "react"
import { NavLink, useParams } from "react-router-dom"

import {
  ArrowLeftIcon,
  ExclamationFilledCircleIcon,
  Link,
  UnitsInputValue,
} from "@appia/ui-components"

import { PBQASurvey } from "@appia/api"

import { logButtonClick } from "src/amplitude"

import { surveySectionToUrl } from "ReviewPBQA/surveyUtils"
import {
  PBQAReviewState,
  QuestionAnswersState,
  requiresNote,
} from "ReviewPBQA/state"

import { toArray } from "src/utils/arrays"
import { isEmpty } from "src/utils/typeRefinements"

import classNames from "classnames"

// TODO It's not great for this logic to live here, especially since similar
// checks exist in the AcceptedRadioButtons and QuestionInput components. The
// best way to unify these checks would be to implement the ValidatedData
// structure, per OE-37
const questionAnswerStateHasError = (state: QuestionAnswersState): boolean => {
  const someAanswersRequireNote = toArray(state.answer).some(
    a => requiresNote(a.accepted, a.reasons) !== "noError",
  )

  const isRequired = state.question.required

  let someAnswerValuesEmpty: boolean
  switch (state.type) {
    case "boolean":
    case "overlining": {
      return someAanswersRequireNote
    }
    case "integer":
    case "decimal": {
      someAnswerValuesEmpty = toArray(state.answer).some(a => {
        const value: UnitsInputValue = {
          amount: a.answer,
          unit: a.unit,
        }
        return isEmpty(value)
      })
      break
    }
    default: {
      someAnswerValuesEmpty = toArray(state.answer).some(a => isEmpty(a.answer))
      break
    }
  }

  return (isRequired && someAnswerValuesEmpty) || someAanswersRequireNote
}

const SectionNav: FC<{
  pageTitle: string
  showFieldErrors: boolean
  state: PBQAReviewState
  survey: PBQASurvey
}> = ({ pageTitle, showFieldErrors, state, survey }) => {
  const params = useParams()

  return (
    <div className="w-full border-b border-otto-grey-300 bg-white shadow">
      <nav
        className="mx-auto grid w-full max-w-screen grid-cols-[auto,auto] items-center justify-between gap-4 pr-4 sm:pr-6 sm:pl-2 md:grid-cols-[1fr,auto,1fr] lg:pr-8 lg:pl-4"
        aria-label="PBQA survey sections"
      >
        <Link
          href="/pbqa"
          onClick={() => {
            logButtonClick({
              buttonName: "Go back",
              linkHref: "/pbqa",
              containerName: "Main",
              pageName: pageTitle,
            })
          }}
          icon={{ position: "left", icon: <ArrowLeftIcon /> }}
          label="Go back"
          style="text"
          theme="night"
          size="small"
          className="my-1"
        />

        <div className="mx-auto flex max-w-full items-stretch gap-4 self-stretch overflow-x-auto py-1">
          {survey.groupingHeaders.map((section, idx) => {
            const someSectionAnswersAreInvalid: boolean = survey.questions
              .filter(q => q.grouping === idx)
              .map(({ id }) => state[id])
              .some(questionAnswerStateHasError)

            return (
              <NavLink
                key={section}
                to={`/pbqa/review/${params.id}/${surveySectionToUrl(section)}`}
                className={({ isActive }) =>
                  classNames(
                    "otto-focus-inset relative flex grow items-center gap-2 whitespace-nowrap rounded-md px-2 text-base font-bold hover:bg-otto-grey-900 hover:bg-opacity-10",
                    "after:absolute after:bottom-[-4px] after:left-0 after:w-full after:border-b-2",
                    isActive
                      ? "after:border-otto-pop-700"
                      : "after:content-none",
                  )
                }
              >
                {showFieldErrors && someSectionAnswersAreInvalid && (
                  <ExclamationFilledCircleIcon
                    label="Section has errors:"
                    className="w-5 shrink-0 text-otto-bright-red"
                  />
                )}

                {section}
              </NavLink>
            )
          })}
        </div>
      </nav>
    </div>
  )
}

export default SectionNav
