import { FC } from "react"
import { ottoPop } from "@appia/ui-palette"

const fillColor = ottoPop[700]

const EmptyTableIcon: FC = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="48" cy="48" r="48" fill={fillColor} />
    <g clipPath="url(#clip0_169_41886)">
      <path
        d="M73.6703 50.2198C73.6244 50.0962 73.5529 49.9837 73.4603 49.8898L63.4603 39.8898C63.3677 39.7953 63.2572 39.7202 63.1353 39.6687C63.0135 39.6172 62.8826 39.5904 62.7503 39.5898H34.0003C33.868 39.5904 33.7371 39.6172 33.6152 39.6687C33.4933 39.7202 33.3829 39.7953 33.2903 39.8898L23.2903 49.8898C23.1977 49.9837 23.1261 50.0962 23.0803 50.2198C23.0243 50.3385 22.9969 50.4686 23.0003 50.5998V62.8598C23.0003 63.5808 23.1423 64.2947 23.4182 64.9608C23.6941 65.6268 24.0985 66.2321 24.6082 66.7419C25.118 67.2516 25.7233 67.656 26.3893 67.9319C27.0554 68.2078 27.7693 68.3498 28.4903 68.3498H68.2503C68.9721 68.3511 69.687 68.2101 70.3543 67.9348C71.0215 67.6595 71.6279 67.2553 72.1388 66.7454C72.6496 66.2355 73.0549 65.6298 73.3314 64.9631C73.6079 64.2963 73.7503 63.5816 73.7503 62.8598V50.6398C73.7542 50.4957 73.7269 50.3524 73.6703 50.2198ZM34.4103 41.6398H62.3403L70.3403 49.6398H57.8803C57.6151 49.6398 57.3607 49.7452 57.1732 49.9327C56.9856 50.1203 56.8803 50.3746 56.8803 50.6398V52.6398C56.8803 53.3877 56.5832 54.105 56.0543 54.6339C55.5255 55.1627 54.8082 55.4598 54.0603 55.4598H42.6803C41.9341 55.4572 41.2194 55.1589 40.6927 54.6303C40.166 54.1018 39.8703 53.386 39.8703 52.6398V50.6398C39.8703 50.3746 39.7649 50.1203 39.5774 49.9327C39.3898 49.7452 39.1355 49.6398 38.8703 49.6398H26.4203L34.4103 41.6398ZM71.7503 62.8798C71.7476 63.8063 71.3777 64.694 70.7216 65.3482C70.0655 66.0025 69.1768 66.3698 68.2503 66.3698H28.4903C27.5655 66.3672 26.6793 65.9987 26.0254 65.3447C25.3715 64.6908 25.0029 63.8046 25.0003 62.8798V51.6398H37.8703V52.6398C37.8729 53.9156 38.3802 55.1386 39.2814 56.0417C40.1826 56.9447 41.4045 57.4546 42.6803 57.4598H54.0603C55.3378 57.4572 56.5623 56.9485 57.4656 56.0452C58.369 55.1418 58.8776 53.9174 58.8803 52.6398V51.6398H71.7503V62.8798Z"
        fill={fillColor}
      />
      <path
        d="M40.1206 32.5498C40.2137 32.6772 40.3356 32.7807 40.4764 32.852C40.6172 32.9233 40.7728 32.9602 40.9306 32.9598C41.139 32.9584 41.3418 32.892 41.5106 32.7698C41.7246 32.6153 41.8689 32.3825 41.912 32.1222C41.955 31.8618 41.8934 31.5949 41.7406 31.3798L37.7906 25.9198C37.7133 25.8132 37.6157 25.7229 37.5034 25.6541C37.3911 25.5853 37.2664 25.5394 37.1363 25.519C37.0062 25.4985 36.8734 25.504 36.7454 25.535C36.6175 25.566 36.4969 25.622 36.3906 25.6998C36.1782 25.8557 36.0359 26.0892 35.9947 26.3495C35.9535 26.6098 36.0167 26.8759 36.1706 27.0898L40.1206 32.5498Z"
        fill={fillColor}
      />
      <path
        d="M48.3691 32.96C48.6344 32.96 48.8887 32.8546 49.0762 32.6671C49.2638 32.4795 49.3691 32.2252 49.3691 31.96V24.96C49.3691 24.6947 49.2638 24.4404 49.0762 24.2528C48.8887 24.0653 48.6344 23.96 48.3691 23.96C48.1039 23.96 47.8496 24.0653 47.662 24.2528C47.4745 24.4404 47.3691 24.6947 47.3691 24.96V31.96C47.3691 32.2252 47.4745 32.4795 47.662 32.6671C47.8496 32.8546 48.1039 32.96 48.3691 32.96Z"
        fill={fillColor}
      />
      <path
        d="M55.8001 32.9598C55.9579 32.9602 56.1135 32.9233 56.2543 32.852C56.3951 32.7807 56.517 32.6772 56.6101 32.5498L60.5601 27.0898C60.714 26.8759 60.7772 26.6098 60.736 26.3495C60.6948 26.0892 60.5525 25.8557 60.3401 25.6998C60.2338 25.622 60.1132 25.566 59.9853 25.535C59.8573 25.504 59.7245 25.4985 59.5944 25.519C59.4643 25.5394 59.3396 25.5853 59.2273 25.6541C59.115 25.7229 59.0174 25.8132 58.9401 25.9198L54.9901 31.3798C54.8838 31.529 54.8206 31.7046 54.8073 31.8873C54.794 32.0701 54.8312 32.2529 54.9148 32.416C54.9984 32.579 55.1252 32.716 55.2813 32.8119C55.4374 32.9078 55.6169 32.9589 55.8001 32.9598Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath>
        <rect
          width="50.75"
          height="44.35"
          fill="white"
          transform="translate(23 24)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default EmptyTableIcon
