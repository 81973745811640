import { FC, useId } from "react"

import { KiQuote } from "@appia/api"

import {
  orderedFields,
  prettyPrintKiQuoteField,
  renderKiQuoteField,
} from "./kiQuoteHelpers"

import {
  TBody,
  THead,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from "./ResponsiveTable"

const MainQuote: FC<{ quote: KiQuote }> = ({ quote }) => {
  const headingId = useId()

  return (
    <section
      aria-labelledby={headingId}
      className="overflow-hidden rounded-lg border border-otto-grey-300"
    >
      <h3
        id={headingId}
        className="border-b border-otto-grey-300 py-2 px-4 font-bold"
      >
        Main selected quote:
      </h3>

      <TableWrapper className="bg-ki-light">
        <Table aria-labelledby={headingId}>
          <THead>
            <tr>
              {orderedFields.map(field => (
                <Th key={field}>{prettyPrintKiQuoteField(field)}</Th>
              ))}
            </tr>
          </THead>

          <TBody>
            <Tr className="sm:grid-cols-2 md:grid-cols-3">
              {orderedFields.map(field => (
                <Td key={field} className="bg-ki-light">
                  <span className="block font-normal lg:hidden">
                    {prettyPrintKiQuoteField(field)}
                  </span>

                  {renderKiQuoteField(field, quote)}
                </Td>
              ))}
            </Tr>
          </TBody>
        </Table>
      </TableWrapper>
    </section>
  )
}

export default MainQuote
