import { FC, ReactNode, useEffect, useState } from "react"
import { logButtonClick, useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import ScreenTemplate from "src/templates/ScreenTemplate"
import { Card, GlobeIcon, Link, PlusIcon, Toast } from "@appia/ui-components"
import * as RD from "@appia/remote-data"
import { useGetWebhooks } from "src/swr"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import ToastViewport from "src/components/ToastViewport"

const PAGE_NAME = "Webhooks"

const WebhooksScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const { request: webhooksRequest } = useGetWebhooks()

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  /*
  In Dev mode, React runs all hooks twice. As a result, the toast is not displayed
  because the session storage value was removed during the second render cycle.

  To test the actual toast behaviour, build the application and run the following commands outside of Dev mode:
  1. yarn build
  2. yarn preview
  */

  useEffect(() => {
    const toastMessage = sessionStorage.getItem("toastMessage")

    if (toastMessage !== null) {
      setToastType("success")
      setToastMessage(toastMessage)
      toastState.triggerToast()

      sessionStorage.removeItem("toastMessage")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScreenTemplate
      pageTitle={PAGE_NAME}
      layout={{
        type: "regular",
        backPath: "/settings",
        actionButton: (
          <Link
            data-cy="create-webhook"
            href="/settings/webhooks/create"
            label="Create webhook"
            theme="pop"
            style="filled"
            icon={{
              position: "left",
              icon: <PlusIcon />,
            }}
            onClick={() => {
              logButtonClick({
                buttonName: "Create webhook",
                linkHref: "/settings/webhooks/create",
                containerName: "Main",
                pageName: PAGE_NAME,
              })
            }}
          />
        ),
      }}
    >
      {RD.match(
        webhooksRequest,
        <Loading />,
        <Loading />,

        ({ webhooks }) => (
          <>
            <Card padding={0}>
              {webhooks.length === 0 ? (
                <div className="flex flex-col justify-center py-10 text-center">
                  <div className="mx-auto h-8 w-8 text-otto-grey-500">
                    <GlobeIcon />
                  </div>
                  <h2 className="mt-2 font-medium text-otto-grey-900">
                    No webhooks configured
                  </h2>
                  <p className="mt-1 text-otto-grey-600">
                    Get started by creating a new webhook.
                  </p>
                </div>
              ) : (
                <ul className="divide-y divide-otto-grey-300">
                  {webhooks.map(wh => (
                    <li key={wh.id}>
                      <div className="flex items-center justify-between px-4 py-4">
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-base font-medium">
                            {wh.callbackUri}
                          </p>
                        </div>
                        <div>
                          <Link
                            label="Edit"
                            theme="night"
                            style="outlined"
                            href={`/settings/webhooks/${wh.id}`}
                            aria-label={`Edit webhook for ${wh.callbackUri}`}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </Card>
          </>
        ),

        error => (
          <Card>
            <ErrorMessage message="Failed to load webhooks" error={error} />
          </Card>
        ),
      )}

      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />
      <ToastViewport />
    </ScreenTemplate>
  )
}

export default WebhooksScreen
