import type { Broker, KiJourneyType, KiRiskType, Syndicate } from "@appia/api"

export const prettyPrintDateString = (s: string): string =>
  new Intl.DateTimeFormat("en-GB").format(new Date(s))

export const prettyPrintLongDateString = (s: string): string => {
  const date = new Date(s)
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  } as Intl.DateTimeFormatOptions
  return new Intl.DateTimeFormat("en-GB", options).format(date)
}

export const prettyPrintBroker = (b: Broker): string => `${b.name} - ${b.alias}`

export const prettyPrintSyndicate = (b: Syndicate): string =>
  `${b.name} - ${b.code}`

export const formatDecimal2DP = (n: number | null): string | null => {
  if (n === null) {
    return "N/A"
  }

  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n)
}

export const formatPercentage = (n: number): string =>
  new Intl.NumberFormat("en-GB", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(n)

export const formatPercentage2DP = (n: number): string =>
  new Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n)

export const formatPercentage4DP = (n: number): string =>
  new Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(n)

export const calculateAdjustedValue = (
  value: number | null | undefined,
): string => {
  if (value === null || value === undefined) {
    return "N/A"
  } else if (value >= 0) {
    // If the value is positive or zero, add it to 100 and multiply by 100 to convert to a percentage
    return `${(100 + value * 100).toFixed(2)}`
  } else {
    // If the value is negative, subtract its absolute value from 100, also converting to a percentage
    return `${(100 - Math.abs(value * 100)).toFixed(2)}`
  }
}

export const formatCurrency = (n: number, c: string): string => {
  const formatted = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: c,
    currencyDisplay: "code",
  }).format(n)

  // Move the currency code after the decimal (which isn't an option built into
  // `Intl.NumberFormat`)
  const withoutCode = formatted.replace(c, "").trim()
  return `${withoutCode} ${c}`
}

export const prettyPrintKiRiskType = (rt: KiRiskType): string =>
  rt
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

export const prettyPrintKiJourneyType = (jt: KiJourneyType): string =>
  jt
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

export const prettyPrintSnakeCase = (jt: string): string =>
  jt
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

export const prettyPrintList = (items: string[] | null): string => {
  if (!items) {
    return ""
  }

  return items.join(", ")
}
