import { PBQAOverview } from "@appia/api"
import {
  prettyPrintList,
  prettyPrintLongDateString,
} from "src/utils/prettyPrinters"

export interface ValidatedHeaderData {
  assuredName: string
  inceptionDate: string
  riskCodes: string
}

export const validateHeaderData = (
  overviewRequestData: PBQAOverview,
): ValidatedHeaderData => {
  return {
    assuredName: overviewRequestData.assuredName
      ? overviewRequestData.assuredName
      : "-",
    inceptionDate: overviewRequestData.inceptionDate
      ? prettyPrintLongDateString(overviewRequestData.inceptionDate)
      : "-",
    riskCodes: overviewRequestData.riskCodes
      ? prettyPrintList(overviewRequestData.riskCodes)
      : "-",
  }
}
