import { KiClassOfBusiness } from "@appia/api"
import { buildAdvancedSearchQuery } from "src/utils/advancedSearch"

// All the valid keys to search on, and their types
export interface KiQuoteSearchFilters {
  assuredName: string
  groupClass: KiClassOfBusiness
  quoteId: string
  quoteLineId: string
}

const kiSearchKeyMapping: Record<keyof KiQuoteSearchFilters, string> = {
  assuredName: "assured_name",
  groupClass: "group_class",
  quoteId: "quote_id",
  quoteLineId: "quote_line_id",
}

export const buildKiQuoteSearchQuery = (
  filters: Partial<KiQuoteSearchFilters>,
): string => buildAdvancedSearchQuery(filters, kiSearchKeyMapping)
