import { FC, ReactNode, useId, useState } from "react"

import { PBQAAnswer, PBQAAnswerReason } from "@appia/api"
import {
  Checkbox,
  ErrorMessage,
  SelectMultiple,
  SelectOption,
  TextArea,
} from "@appia/ui-components"

import { isNotNull } from "src/utils/typeRefinements"

const MAX_NOTE_LENGTH = 250 // characters

export interface NoteAndReasons {
  note: PBQAAnswer["note"]
  reasons: PBQAAnswer["reasons"]
}

// IMPORTANT: the order of entries here determines the order in which the user
// will see them
const answerReasons: Record<Exclude<PBQAAnswerReason, "other">, string> = {
  country_of_insured_changed: "Country of insured changed - okay",
  limit_lower_than_quoted: "Limit lower than quoted",
  limit_over: "Limit over - authorised by Ki Underwriter",
  premium_higher: "Premium higher in slip",
  premium_netted_down: "Premium netted down with brokerage",
  premium_pro_rata: "Premium pro rata",
  minimum_deposit_premium: "Minimum and deposit premium",
  premium_within_5_threshold: "Premium lower - within 5% threshold",
  premium_over_5_threshold:
    "Premium over 5% threshold - authorised by Ki Underwriter",
  brokerage_lower: "Brokerage lower",
  brokerage_higher: "Brokerage higher - authorised by Ki Underwriter",
  other_deductions_lower: "Other deductions lower",
  inception_date_changed: "Inception date changed - okay",
  expiry_date_changed: "Expiry date changed - okay",
  slip_risk_code: "Slip risk code okay - permitted class in SBF",
  ki_one_of_two_lloyds_syndicates: "One of two Lloyd's syndicates on slip",
  no_surplus_lines_info: "No surplus lines info - broker to endorse",
  no_service_of_suit: "No service of suit in slip - broker to endorse",
  ki_line_less_quote: "Syndicate line less than quote",
  ki_line_high_quote:
    "Syndicate line higher than quote - authorised by Ki Underwriter",
  leader_line_higher: "Leader line higher than quote",
  leader_line_lower:
    "Leader line lower than quote - authorised by Ki Underwriter",
  sov_not_required: "SOV not required",
  ai_conversion_to_usd: "Assisted journey quote - conversion to USD",
  ai_quote: "Assisted journey quote",
  nominated_syndicate_for_qbe_facility:
    "Nominated syndicate for QBE facility (Marsh and Aon)",
  confirmation_of_knorl: "Confirmation of KNORL",
  nominated_follow_unable_to_confirm_line_size:
    "Nominated follow - unable to confirm line size",
  not_applicable: "N/A",
}

const answerOptions: SelectOption[] = Object.entries(answerReasons).map(
  ([k, v]) => ({ label: v, value: k }),
)

const ReasonSelection: FC<{
  label: ReactNode
  noteAndReasons: NoteAndReasons
  onChange: (nr: NoteAndReasons) => void
  selectPlaceholder: string
  showNoteError: boolean
  showReasonError?: boolean
}> = ({
  label,
  noteAndReasons,
  onChange,
  selectPlaceholder,
  showNoteError,
  showReasonError = false,
}) => {
  const noteErrorId = useId()
  const reasonErrorId = useId()

  const { note } = noteAndReasons

  const reasons = noteAndReasons.reasons || []
  const [otherSelected, setOtherSelected] = useState<boolean>(
    reasons.includes("other"),
  )

  const reasonsWithoutOther = reasons.filter(r => r !== "other")

  return (
    <div>
      <label className="mb-4 block">
        {label}

        <SelectMultiple
          errorMessageId={showReasonError ? reasonErrorId : undefined}
          options={answerOptions}
          onSelect={opts => {
            const updated = opts.filter(isNotNull) as PBQAAnswerReason[]
            onChange({
              note,
              reasons: otherSelected ? [...updated, "other"] : updated,
            })
          }}
          selectedValues={reasonsWithoutOther}
          placeholder={selectPlaceholder}
          required
        />

        <div role="alert" id={reasonErrorId}>
          {showReasonError && (
            <ErrorMessage
              id={`${reasonErrorId}-error`}
              message="You must select a reason"
              className="mt-1"
            />
          )}
        </div>
      </label>

      <label className="flex items-center gap-2">
        <Checkbox
          checked={otherSelected}
          onChange={v => {
            setOtherSelected(v)
            if (v) {
              onChange({ note, reasons: [...reasonsWithoutOther, "other"] })
            } else {
              onChange({ note, reasons: reasonsWithoutOther })
            }
          }}
        />
        <span>Other</span>
      </label>

      {otherSelected && (
        <div className="mt-2">
          <TextArea
            aria-describedby={noteErrorId}
            label="Note for reason Other"
            maxLengthChars={MAX_NOTE_LENGTH}
            onChange={updated => {
              onChange({ reasons, note: updated })
            }}
            placeholder="Please add a note"
            required
            value={note}
            visuallyHideLabel
          />

          <div role="alert" id={noteErrorId} data-testid="note-error-alert">
            {showNoteError && (
              <ErrorMessage
                className="mt-1"
                id={`${noteErrorId}-error`}
                message={`You must enter a note if "Other" is selected.`}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ReasonSelection
