import { FC } from "react"
import { Card } from "@appia/ui-components"
import DefinitionList from "src/components/DefinitionList"

interface ContractHeaderDetailsCardProps {
  insured: string
  brokingHouse: string
  quoteIds: number[]
  umr: string
  programmeRef: string
  assignedTo: string | null
  onPlatform: boolean
}

const ContractHeaderDetailsCard: FC<ContractHeaderDetailsCardProps> = ({
  insured,
  brokingHouse,
  quoteIds,
  umr,
  programmeRef,
  assignedTo,
  onPlatform,
}): JSX.Element => {
  return (
    <Card className="w-96">
      <DefinitionList
        className="!gap-4"
        itemClassName="flex w-full flex-row justify-between"
        items={[
          { label: "Insured", value: insured },
          { label: "Broking house", value: brokingHouse },
          { label: "Quote ID", value: `${quoteIds}` },
          { label: "UMR", value: umr },
          { label: "Eclipse Programme Reference", value: programmeRef },
          { label: "Assigned to", value: assignedTo },
          { label: "On platform", value: onPlatform ? "Yes" : "No" },
        ]}
      />
    </Card>
  )
}
export default ContractHeaderDetailsCard
