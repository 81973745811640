import type { SVGComponent } from "./IconWrapper"

const LastPageSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.79492 7.41L10.3849 12L5.79492 16.59L7.20492 18L13.2049 12L7.20492 6L5.79492 7.41ZM16.2049 6H18.2049V18H16.2049V6Z"
      fill="currentColor"
    />
  </svg>
)

export default LastPageSVG
