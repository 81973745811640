import { FC } from "react"
import { Pill, PillType } from "@appia/ui-components"

const MatchingFieldsPercentPill: FC<{ matchValue: number }> = ({
  matchValue,
}): JSX.Element => {
  if (matchValue < 0 || matchValue > 1) {
    throw new Error("Input must be a number between 0 and 1")
  }

  const highMatch = 0.6
  const lowMatch = 0.3

  let type: PillType

  if (matchValue >= highMatch) {
    type = "success"
  } else if (matchValue < lowMatch) {
    type = "neutral"
  } else {
    type = "warning"
  }

  const roundedMatchValue = Math.round(matchValue * 100)

  return <Pill type={type} label={`${roundedMatchValue}%`}></Pill>
}

export default MatchingFieldsPercentPill
