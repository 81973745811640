import { FC, RefObject } from "react"
import { checklist } from "./checklist"
import { CheckCircleIcon, ModalDescription } from "@appia/ui-components"

const EndorsementTypeCheckIcon: FC<{ checked: boolean }> = ({ checked }) =>
  checked ? (
    <CheckCircleIcon className="m-auto w-6 text-otto-green-900" />
  ) : (
    <span className="w-6" />
  )

const ModalContent: FC<{ contentRef: RefObject<HTMLDivElement> }> = ({
  contentRef,
}) => (
  <ModalDescription as="div" ref={contentRef} tabIndex={-1}>
    <p className="mb-6 text-lg">
      Please select if this endorsement needs to be sent to PU or Opus & TPU.
    </p>

    <div className="rounded-[1rem] border">
      <table>
        <caption className="border-b p-3 text-lg font-bold">
          Endorsement triage
        </caption>

        <thead className="h-12 border-b">
          <tr>
            <th>
              <span className="sr-only">Endorsement change</span>
            </th>

            <th className="pr-3 text-lg font-normal">To TPU</th>
            <th className="pr-3 text-lg font-normal">To PU</th>
          </tr>
        </thead>

        <tbody>
          {checklist.map(({ endorsementType, toPU, toTPU }, index) => (
            <tr key={index} className="border-b last:border-none">
              <td className="p-3 text-lg">{endorsementType}</td>
              <td className="p-3 pr-6">
                <EndorsementTypeCheckIcon checked={toPU} />
              </td>
              <td className="p-3 pr-6">
                <EndorsementTypeCheckIcon checked={toTPU} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </ModalDescription>
)

export default ModalContent
