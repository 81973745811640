import { FC } from "react"

// Visually render "ID" but ensure screenreaders announce it as an acronym
export const ID: FC = () => (
  <>
    <span aria-hidden>ID</span>
    <span className="sr-only">I.D.</span>
  </>
)

// End a sentence with a full stop and a space, for screenreaders only
export const SrEndOfSentence: FC = () => (
  <span className="sr-only">.&nbsp;</span>
)
