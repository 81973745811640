import { FC } from "react"

import { Input, InputLabel } from "@appia/ui-components"
import SearchFilterForm from "src/components/SearchFilterForm"

// All the valid keys to search on, and their types
export interface PolicySearchFilters {
  umr: string
  assuredName: string
}

export const searchKeyMapping: Record<keyof PolicySearchFilters, string> = {
  assuredName: "insured",
  umr: "umr",
}

export const Filters: FC<{
  filters: Partial<PolicySearchFilters>
  onSubmit: (f: Partial<PolicySearchFilters>) => void
  onReset: () => void
}> = ({ filters: initialFilters, onSubmit, onReset }) => {
  return (
    <SearchFilterForm
      label="I’m looking for"
      initialFilters={initialFilters}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {(filters, setFilters) => (
        <>
          <InputLabel label="UMR">
            <Input
              placeholder="Search on UMR"
              onChange={v => setFilters(f => ({ ...f, umr: v }))}
              value={filters.umr || ""}
            />
          </InputLabel>

          <InputLabel label="Assured name">
            <Input
              placeholder="Search on assured name"
              onChange={v => setFilters(f => ({ ...f, assuredName: v }))}
              value={filters.assuredName || ""}
            />
          </InputLabel>
        </>
      )}
    </SearchFilterForm>
  )
}
