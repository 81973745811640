import type { SVGComponent } from "./IconWrapper"

const SendSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.51 6.03L11.02 9.25L3.5 8.25L3.51 6.03V6.03ZM11.01 14.75L3.5 17.97V15.75L11.01 14.75V14.75ZM1.51 3L1.5 10L16.5 12L1.5 14L1.51 21L22.5 12L1.51 3Z"
      fill="currentColor"
    />
  </svg>
)

export default SendSVG
