import { PBQAStatus } from "./Search"
import { Syndicate } from "../Syndicate"

export interface PBQAInfo {
  originalPbqaId: string | null
  id: string
  status: PBQAStatus
  syndicateIds: Syndicate["id"][]
}

export const getPBQAInfoUrl = (pbqaId: string): string => `/api/pbqa/${pbqaId}`
