import { FC } from "react"
import { Outlet, useParams, useSearchParams } from "react-router-dom"
import ScreenTemplate from "src/templates/ScreenTemplate"
import { useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import { PageNameContext } from "src/contexts/PageNameContext"
import ContractSectionNav from "../../components/ContractSectionNav"

const PAGE_NAME = "Review Contract"

export const REVIEW_CONTRACT_DOCUMENTS_PATH = "documents"
export const REVIEW_CONTRACT_GENERAL_PATH = "general"
export const REVIEW_CONTRACT_SECTIONS_PATH = "sections"
export const REVIEW_CONTRACT_GENERAL_PATH_WITH_FLAG =
  REVIEW_CONTRACT_GENERAL_PATH + "?documents=true"
export const REVIEW_CONTRACT_DOCUMENTS_PATH_WITH_FLAG =
  REVIEW_CONTRACT_DOCUMENTS_PATH + "?documents=true"
export const REVIEW_CONTRACT_SECTIONS_PATH_WITH_FLAG =
  REVIEW_CONTRACT_SECTIONS_PATH + "?documents=true"

export type ReviewContractPath =
  | typeof REVIEW_CONTRACT_DOCUMENTS_PATH
  | typeof REVIEW_CONTRACT_GENERAL_PATH
  | typeof REVIEW_CONTRACT_SECTIONS_PATH
  | typeof REVIEW_CONTRACT_GENERAL_PATH_WITH_FLAG
  | typeof REVIEW_CONTRACT_DOCUMENTS_PATH_WITH_FLAG
  | typeof REVIEW_CONTRACT_SECTIONS_PATH_WITH_FLAG

const SECTIONS: { title: string; path: ReviewContractPath }[] = [
  { title: "General", path: REVIEW_CONTRACT_GENERAL_PATH },
  { title: "Sections", path: REVIEW_CONTRACT_SECTIONS_PATH },
]

const SECTIONS_WITH_DOCS: { title: string; path: ReviewContractPath }[] = [
  { title: "Documents", path: REVIEW_CONTRACT_DOCUMENTS_PATH_WITH_FLAG },
  { title: "General", path: REVIEW_CONTRACT_GENERAL_PATH_WITH_FLAG },
  { title: "Sections", path: REVIEW_CONTRACT_SECTIONS_PATH_WITH_FLAG },
]

export const ReviewContractScreen: FC = () => {
  const { contractId } = useParams()

  if (!contractId) {
    throw new Error("Missing contractId in ReviewContract screen")
  }

  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const [searchParams] = useSearchParams()
  const showDocumentsScreen: boolean = searchParams.get("documents") === "true"

  return (
    <ScreenTemplate
      pageTitle={PAGE_NAME}
      noMaxContentWidth
      noPadding
      layout={{ type: "simple" }}
    >
      <PageNameContext.Provider value={PAGE_NAME}>
        <div className="flex h-full flex-col items-center">
          <ContractSectionNav
            sections={showDocumentsScreen ? SECTIONS_WITH_DOCS : SECTIONS}
          />
          <Outlet />
        </div>
      </PageNameContext.Provider>
    </ScreenTemplate>
  )
}

export default ReviewContractScreen
