import { FC } from "react"

import {
  ChevronDownIcon,
  ChevronUpIcon,
  Initials,
  SelectOption,
  SelectOptionInner,
} from "@appia/ui-components"
import { Menu } from "@headlessui/react"
import Loading from "src/components/Loading"

import type { User } from "@appia/api"

import * as RD from "@appia/remote-data"
import { useGetCurrentUser, useGetUsers } from "src/swr"
import * as Sentry from "@sentry/react"

import { compareByName, fullNameOrEmail } from "src/utils/users"

const NO_ONE = "no-one"

export function useUserFullNameById(userId: User["id"] | null): string | null {
  const { request: currentUserRequest } = useGetCurrentUser()
  const { request: usersRequest } = useGetUsers()

  if (!RD.isSuccess(currentUserRequest)) {
    return null
  }
  const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

  const owner = users.find(u => userId === u.id)
  // Explicitly return null if owner is not found
  return owner ? fullNameOrEmail(owner) : null
}

const MenuInner: FC<{
  currentUser: User
  onAssign: (id: User["id"], name: string) => Promise<void>
  onUnassign: () => Promise<void>
  ownerId?: User["id"]
}> = ({ ownerId, currentUser, onAssign, onUnassign }) => {
  const { request: usersRequest } = useGetUsers(currentUser.teamId || null)

  if (!RD.isSuccess(usersRequest)) {
    return <Loading />
  }

  const { users } = usersRequest.data

  const options: SelectOption[] = [
    { label: "No one", value: NO_ONE },
    ...users.sort(compareByName).map(u => ({
      value: u.id,
      label: fullNameOrEmail(u),
    })),
  ]

  const onSelect = async (assigneeId: User["id"]): Promise<void> => {
    if (assigneeId === (ownerId || NO_ONE)) {
      return
    }

    const assignee = users.find(u => u.id === assigneeId)

    try {
      if (assigneeId && assignee) {
        await onAssign(assigneeId, fullNameOrEmail(assignee))
      } else {
        await onUnassign()
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  return (
    <Menu.Items
      className="otto-focus-inset absolute top-full right-0 z-10 mt-2 w-60 overflow-hidden rounded-md border border-otto-grey-400 bg-white shadow-xl"
      aria-orientation="vertical"
    >
      <ul role="group" aria-labelledby="assign-legend">
        <legend
          id="assign-legend"
          className="border-b border-otto-grey-400 p-2 font-bold"
        >
          Assign
        </legend>

        {options.map(({ label, value }) => {
          const selected = value === (ownerId || NO_ONE)
          return (
            <Menu.Item key={label}>
              {({ active }) => (
                // `Menu.Item` adds key events but eslint doesn't know that
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li role="menuitem" onClick={() => onSelect(value)}>
                  <SelectOptionInner
                    active={active}
                    selected={selected}
                    label={label}
                  />
                </li>
              )}
            </Menu.Item>
          )
        })}
      </ul>
    </Menu.Items>
  )
}

const OwnerSelection: FC<{
  buttonLabel: string
  onAssign: (id: User["id"], name: string) => Promise<void>
  onUnassign: () => Promise<void>
  ownerId?: User["id"]
}> = ({ buttonLabel, ownerId, onAssign, onUnassign }) => {
  const { request: userRequest } = useGetCurrentUser()
  const { request: usersRequest } = useGetUsers()

  if (!RD.isSuccess(userRequest)) {
    return null
  }

  const currentUser = userRequest.data.user
  const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

  const owner = users.find(u => ownerId === u.id)
  const ownerName = owner && fullNameOrEmail(owner)

  return (
    <div className="relative flex items-center justify-center gap-2">
      <span>
        {ownerName ? (
          <Initials
            initials={ownerName
              .split(" ")
              .map(s => s[0])
              .join("")}
            label={ownerName}
          />
        ) : (
          <div
            className="h-6 w-6 rounded-full border border-dashed border-otto-grey-600"
            title="Unassigned"
          >
            <span className="sr-only">Unassigned</span>
          </div>
        )}
      </span>

      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="otto-focus rounded">
              <span className="sr-only">{buttonLabel}</span>
              <span className="inline-block w-6 align-middle">
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </span>
            </Menu.Button>

            <MenuInner
              ownerId={ownerId}
              currentUser={currentUser}
              onAssign={onAssign}
              onUnassign={onUnassign}
            />
          </>
        )}
      </Menu>
    </div>
  )
}

export default OwnerSelection
