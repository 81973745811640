import { Broker } from "../Broker"
import { PolicyReferencesForSyndicate } from "../Policies"

export interface PBQAOverview {
  assuredName: string
  broker: Broker
  completedSurveyId: string
  completedSurveySlug: string
  inceptionDate: string | null
  quoteLineId?: number
  originalPbqaId?: string
  pbqaId: string
  policyId: string | null
  policyReferences?: PolicyReferencesForSyndicate[]
  riskCodes: string[] | null
}

export const getPBQAOverviewUrl = (pbqaId: string): string =>
  `/api/pbqa/${pbqaId}/overview`
