import { FC, useId, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useLogPageView } from "src/amplitude"
import { PageNameContext } from "src/contexts/PageNameContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import ScreenTemplate from "src/templates/ScreenTemplate"
import EndorsementUploadDetails from "./EndorsementUploadDetails"
import EndorsementUploadFooter from "./EndorsementUploadFooter"
import { FormField, mkInvalidData } from "@appia/form-data"
import { EndorsementUploadType, SourceUploadType } from "@appia/api"
import { validateField } from "./utils"
import { INVALID_FIELD_ERROR_MESSAGE } from "./parsers"

const PAGE_NAME = "Upload Endorsement"

export type EndorsementUploadFormField = {
  umr: FormField<string, string> & { showError: boolean }
  insuredName: FormField<string, string> & { showError: boolean }
  endorsementType: FormField<EndorsementUploadType, string> & {
    showError: boolean
  }
  endorsementNumber: FormField<string, string> & { showError: boolean }
  source: FormField<SourceUploadType, string> & { showError: boolean }
}

const UploadEndorsementScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const navigate = useNavigate()
  const location = useLocation()

  const createInitialField = <Data, Raw = string>(
    raw: Raw,
  ): FormField<Data, Raw> & { showError: boolean } => ({
    raw,
    validated: mkInvalidData<Data>(INVALID_FIELD_ERROR_MESSAGE),
    showError: false,
  })

  const [stateAnswers, setStateAnswers] = useState<EndorsementUploadFormField>(
    location.state || {
      umr: createInitialField<string>(""),
      insuredName: createInitialField<string>(""),
      endorsementType: createInitialField<EndorsementUploadType>(""),
      endorsementNumber: createInitialField<string>(""),
      source: createInitialField<SourceUploadType>(""),
    },
  )

  const handleChange = (
    field: keyof EndorsementUploadFormField,
    value: string,
  ): void => {
    const validatedData = validateField(field, value)

    setStateAnswers(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        raw: value,
        validated: validatedData,
      },
    }))
  }

  const validateFields = (): boolean => {
    let hasErrors = false
    const updatedStateAnswers = { ...stateAnswers }

    Object.keys(stateAnswers).forEach(key => {
      const field = key as keyof EndorsementUploadFormField
      const validatedData = validateField(field, stateAnswers[field].raw)

      updatedStateAnswers[field] = {
        ...updatedStateAnswers[field],
        // @ts-expect-error - ignoring type
        validated: validatedData,
        showError: !validatedData.valid,
      }

      if (!validatedData.valid) {
        hasErrors = true
      }
    })

    setStateAnswers(updatedStateAnswers)
    return !hasErrors
  }

  const handleNext = (): void => {
    if (validateFields()) {
      navigate("/endorsements/upload-area", {
        state: { ...stateAnswers },
      })
    }
  }

  const headingId = useId()

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        layout={{
          type: "regular",
          backPath: "/endorsements",
        }}
      >
        <section aria-labelledby={headingId} className="mx-auto max-w-5xl">
          <h2 className="sr-only" id={headingId}>
            Upload Endorsement
          </h2>
        </section>
        <div role="alert" className="mx-auto w-full max-w-lg bg-white">
          <EndorsementUploadDetails
            umr={stateAnswers.umr}
            insuredName={stateAnswers.insuredName}
            endorsementType={stateAnswers.endorsementType}
            endorsementNumber={stateAnswers.endorsementNumber}
            source={stateAnswers.source}
            onChange={handleChange}
          />
          <EndorsementUploadFooter
            onCancel={() => navigate("/endorsements")}
            onNext={handleNext}
          />
        </div>
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default UploadEndorsementScreen
