// The user's entry is either valid, in which case we store the parsed value; or
// it's invalid, in which case we store an error explaining why
export type ValidatedData<Data> =
  | { valid: true; data: Data }
  | { valid: false; error: string }

export const mkValidData = <Data>(data: Data): ValidatedData<Data> => ({
  valid: true,
  data,
})

export const mkInvalidData = <Data>(error: string): ValidatedData<Data> => ({
  valid: false,
  error,
})

export interface FormField<Data, Raw = string> {
  // The raw value the user entered
  raw: Raw
  // The validated version of the raw data
  validated: ValidatedData<Data>
}

export const mkInvalidFormField = <Raw, Data = Raw>(
  raw: Raw,
  error: string,
): FormField<Data, Raw> => ({ raw, validated: { valid: false, error } })

export const mkValidFormField = <Raw, Data = Raw>(
  raw: Raw,
  data: Data,
): FormField<Data, Raw> => ({ raw, validated: { valid: true, data: data } })
