import { AxiosInstance, AxiosPromise } from "axios"

export interface Syndicate {
  id: string
  code: number
  name: string
}

const searchSyndicatesUrl = (query: string): string =>
  query ? `/api/syndicates/search?query=${query}` : `/api/syndicates/search`

export const searchSyndicates = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<Syndicate[]> => client.get(searchSyndicatesUrl(query))

export const getSyndicatePartnersUrl = `/api/syndicates/partners`

export const updateSyndicatePartners = (
  client: AxiosInstance,
  pbqa_id: string,
  syndicate_ids: Syndicate["id"][],
): AxiosPromise<Syndicate[]> =>
  client.put(`/api/pbqa/${pbqa_id}/syndicates`, {
    syndicate_ids,
  })
