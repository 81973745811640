import { AxiosInstance } from "axios"

export interface Recipient {
  id: string
  name: string
  email: string
}

export interface RecipientAPI {
  id: string
  name: string
  email: string
  teamId: string
  active: boolean
}

const toRecipients = (recipients: RecipientAPI[]): Recipient[] =>
  recipients.map(r => ({
    id: r.id,
    name: r.name,
    email: r.email,
  }))

export const getPURecipients = (client: AxiosInstance): Promise<Recipient[]> =>
  client
    .get("/api/endorsements/pu/recipients")
    .then(res => toRecipients(res.data))

export const getPURecipientsUrl = "/api/endorsements/pu/recipients"
