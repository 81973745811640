import type { SVGComponent } from "./IconWrapper"

const InformationFilledCircleSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM11 7L13 7L13 9L11 9L11 7ZM11 11L13 11L13 17L11 17L11 11Z"
      fill="currentColor"
    />
  </svg>
)

export default InformationFilledCircleSVG
