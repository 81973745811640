import type { SVGComponent } from "./IconWrapper"

const SortAscendingSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6V4H14V6H4ZM10 18H4V16H10V18ZM4 12H12V10H4V12ZM18 9.5L18 19.5H16L16 9.5H13.5L17 6L20.5 9.5H18Z"
      fill="currentColor"
    />
  </svg>
)

export default SortAscendingSVG
