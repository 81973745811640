import { AxiosInstance, AxiosPromise } from "axios"
import { User } from "./Users"

export interface Team {
  companyId: string
  createdAt: string
  domain: string
  slug: string
  id: string
  name: string
  updatedAt: string
}

export const getTeamsUrl = "/api/teams"

export const getTeamUrl = (id: string): string => `/api/teams/${id}`

export const createTeam = (
  client: AxiosInstance,
  name: Team["name"],
): AxiosPromise<Team> =>
  client.post(`/api/teams`, {
    name,
  })

export const joinTeam = (
  client: AxiosInstance,
  team_id: Team["id"],
  user_id: User["id"],
  company_id: Team["companyId"],
): AxiosPromise<Team> =>
  client.post(`/api/teams/${team_id}/add`, {
    user_id,
    company_id,
  })

export const leaveTeam = (
  client: AxiosInstance,
  team_id: Team["id"],
  user_id: User["id"],
  company_id: Team["companyId"],
): AxiosPromise<Team> =>
  client.post(`/api/teams/${team_id}/remove`, {
    user_id,
    company_id,
  })

export const massJoinTeam = (
  client: AxiosInstance,
  team_id: Team["id"],
  user_ids: User["id"][],
  company_id: Team["companyId"],
): AxiosPromise<Team> =>
  client.post(`/api/teams/${team_id}/add-user-list`, {
    user_ids,
    company_id,
  })
