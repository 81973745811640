import { FC } from "react"

import { Button, Link, ModalDescription } from "@appia/ui-components"

import ErrorMessage from "src/components/ErrorMessage"
import ModalTemplate from "src/components/ModalTemplate"
import PolicyReferencesTable from "src/components/PolicyReferencesTable"

import { Endorsement } from "@appia/api"
import * as RD from "@appia/remote-data"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const CONTAINER_NAME = "Completion modal"

const CompletionModal: FC<{
  completionReq: RD.RemoteData<Error, Endorsement>
  isOpen: boolean
  onClose: () => void
}> = ({ completionReq, isOpen, onClose }) => {
  const pageName = usePageName()

  return RD.match(
    completionReq,
    null,
    null,

    endorsement => (
      <ModalTemplate
        isOpen={isOpen}
        onClose={onClose}
        title="Confirmed"
        content={
          <ModalDescription as="div">
            <p>
              Endorsement has been saved. Now upload the doc to DMS in Opus and
              create TPU workflow if applicable.
            </p>

            {endorsement.policyReferences.length > 0 && (
              <div className="mt-4 overflow-auto">
                <PolicyReferencesTable
                  policyReferences={endorsement.policyReferences}
                />
              </div>
            )}
          </ModalDescription>
        }
        actions={[
          <Link
            key="summary"
            href={`/endorsements/view/${endorsement.id}`}
            label="Go to summary"
            style="outlined"
            theme="night"
            onClick={() => {
              logButtonClick({
                buttonName: "Go to summary",
                linkHref: `/endorsements/view/${endorsement.id}`,
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />,

          <Link
            key="dashboard"
            label="Go to dashboard"
            href="/endorsements"
            theme="pop"
            style="filled"
            onClick={() => {
              logButtonClick({
                buttonName: "Go to dashboard",
                linkHref: `/endorsements`,
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />,
        ]}
      />
    ),

    error => (
      <ModalTemplate
        isOpen={isOpen}
        onClose={onClose}
        title="Not confirmed"
        content={
          <ModalDescription as="div">
            <ErrorMessage
              message="Sorry, there was an error while saving your changes. Please try again."
              error={error}
            />
          </ModalDescription>
        }
        actions={[
          <Button
            key="return"
            label="Return to endorsement"
            style="outlined"
            theme="night"
            onClick={() => {
              onClose()
              logButtonClick({
                buttonName: "Return to endorsement",
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />,
        ]}
      />
    ),
  )
}

export default CompletionModal
