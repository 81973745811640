import { FC, useEffect, useState } from "react"
import "./UnitsInput.css"

import { Input } from "./Input"
import { Select } from "./Select"

import classNames from "classnames"

export const parseAmount = (s: string): number | null => {
  const stripped = s.replace(/[^\d.]/g, "")

  if (stripped === "") {
    return null
  }

  const parsed = parseFloat(stripped)
  return isNaN(parsed) ? null : parsed
}

export const formatAmount = (n: number | null, precision = 2): string => {
  if (n === null) {
    return ""
  }

  const s = Number(n).toFixed(precision)

  const [integerPart, fractionPart] = s.split(".")
  const formattedIntegerPart = integerPart
    .split("")
    .reverse()
    .reduce((acc, digit, i) => {
      const sep = i > 0 && i % 3 === 0 ? "," : ""
      return `${digit}${sep}${acc}`
    }, "")

  return `${formattedIntegerPart}.${fractionPart}`
}

export interface UnitsInputValue {
  amount: number | null
  unit: string | null
}

export interface UnitsInputProps {
  id: string
  labelId: string
  onChange: (v: UnitsInputValue) => void
  units: string | string[]
  value: UnitsInputValue

  disabled?: boolean
  errorMessageId?: string
  placeholder?: string
  precision?: number
  required?: boolean
  amountTestId?: string
  unitsTestId?: string
}

export const UnitsInput: FC<UnitsInputProps> = ({
  id,
  labelId,
  onChange,
  precision = 2,
  units,
  value,

  disabled,
  errorMessageId,
  placeholder,
  required = false,
  amountTestId,
  unitsTestId,
}) => {
  const amountId = `${id}-amount`
  const unitsId = `${id}-units`

  const { amount, unit } = value

  const [workingAmount, setWorkingAmount] = useState<string>(
    formatAmount(amount, precision),
  )

  // The value could be changed programmatically, which would bypass the
  // `onBlur` listener, so we also need to listen here
  useEffect(
    () => setWorkingAmount(formatAmount(amount, precision)),
    [amount, precision],
  )

  return (
    <div className="units-input relative flex w-full items-stretch">
      <div className="z-10 mr-[-1px] flex-grow">
        <span id={amountId} className="sr-only">
          Amount
        </span>

        <Input
          aria-labelledby={`${labelId} ${amountId} ${
            Array.isArray(units) ? "" : unitsId
          }`}
          testId={amountTestId}
          aria-describedby={errorMessageId}
          disabled={disabled}
          hasError={!!errorMessageId}
          id={id}
          inputMode="decimal"
          placeholder={placeholder}
          required={required}
          type="text"
          onChange={setWorkingAmount}
          onBlur={() => {
            const parsed = parseAmount(workingAmount)
            onChange({
              // If there's only one choice for `units`, always return that
              unit: unit === null && typeof units === "string" ? units : unit,
              amount: parsed,
            })
            setWorkingAmount(formatAmount(parsed, precision))
          }}
          value={workingAmount}
        />
      </div>

      {Array.isArray(units) ? (
        <>
          <span id={unitsId} className="sr-only">
            Unit
          </span>

          <div
            className="select-wrapper flex flex-shrink-0 items-stretch focus-within:z-20"
            data-cy="unit-select"
            data-testid={unitsTestId}
          >
            <Select
              aria-labelledby={`${labelId} ${unitsId}`}
              disabled={disabled}
              errorMessageId={errorMessageId}
              onSelect={opt => onChange({ amount, unit: opt })}
              options={units.map(code => ({ label: code, value: code }))}
              placeholder="XXX"
              required={required}
              selectedValue={unit}
              truncateItems={false}
            />
          </div>
        </>
      ) : (
        <div
          id={unitsId}
          data-testid={unitsTestId}
          className={classNames(
            "border-l-none flex flex-shrink-0 items-center rounded rounded-l-none border px-2 text-base",
            disabled ? "bg-otto-grey-200" : "bg-white",
            !errorMessageId ? "border-otto-grey-400" : "border-otto-bright-red",
          )}
        >
          {units}
        </div>
      )}
    </div>
  )
}
