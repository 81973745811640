import { Dispatch, FC, ReactElement, SetStateAction, useEffect } from "react"

import {
  IconButton,
  Select,
  SelectAsync,
  SelectAsyncProps,
  SelectOption,
  XIcon,
} from "@appia/ui-components"

import { ApiClient } from "@appia/api"
import { AxiosPromise } from "axios"

import { logTableFilter } from "src/amplitude"
import useLocalStorageState from "src/hooks/useLocalStorageState"

export const useTableFilters = <Filters extends object>(
  localStorageFiltersKey: string,
  defaultTableFilters: Filters,
): [Filters, Dispatch<SetStateAction<Filters>>] => {
  const [tableFilters, setTableFilters] = useLocalStorageState<Filters>(
    localStorageFiltersKey,
    defaultTableFilters,
  )

  // In https://github.com/Ki-Insurance/otto-core/pull/1983 we changed the
  // filters format across all tables from a series of (string | undefined)
  // values to a series of arrays. This `useEffect` acts as a migration.
  //
  // TODO: We should be able to remove this once enough time has passed and
  // we're confident all users have been migrated to the new format.
  const keys = Object.keys(defaultTableFilters) as (keyof Filters)[]
  const needsMigrating = keys.some(k => !Array.isArray(tableFilters[k]))

  useEffect(() => {
    if (needsMigrating) {
      setTableFilters(defaultTableFilters)
    }
  }, [tableFilters, defaultTableFilters, setTableFilters, needsMigrating])

  return [needsMigrating ? defaultTableFilters : tableFilters, setTableFilters]
}

type FilterDefinitionBase<Data> = {
  uniqueKey: string
  label: string
  mapResultToLabel: (d: Data) => string
  onChange: (vs: Data[]) => void
}

type FilterDefinitionSyncAsync<Data> =
  | { type: "sync"; options: SelectOption[]; values: string[] }
  | {
      type: "async"
      loader: (apiClient: ApiClient, query: string) => AxiosPromise<Data[]>
      mapResultToValue: (d: Data) => string
      values: Data[]
    }

export type FilterDefinition<Data> = FilterDefinitionBase<Data> &
  FilterDefinitionSyncAsync<Data>

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const configureFilter = <D extends unknown>(
  def: FilterDefinition<D>,
): FilterDefinition<unknown> => def as FilterDefinition<unknown>

export const ActiveFilter: FC<{
  label: string
  value: string
  onClear: () => void
}> = ({ label, value, onClear }) => (
  <li
    className={`flex items-center gap-1 whitespace-nowrap rounded-full bg-otto-grey-200 px-4 py-1 forced-colors:border forced-colors:border-transparent`}
  >
    <span>{label}:</span>
    <span className="font-bold">{value}</span>
    <IconButton
      label={`Clear ${value} filter for ${label}`}
      icon={<XIcon />}
      onClick={onClear}
      size={5}
    />
  </li>
)

// Wrap the `Select` component to add special behaviour such as an "All" option
// and Amplitude logging
export const FilterSync: FC<{
  filterKey: string
  onValueChange: (v: string | null) => void
  options: SelectOption[]
  placeholder: string
  tableId: string
  selectedValues: string[]
}> = ({
  filterKey,
  onValueChange,
  options,
  placeholder,
  tableId,
  selectedValues,
}) => {
  const filterAllKey = `filter-all-${filterKey}`

  const selectedValuesSet = new Set(selectedValues)

  const unselectedOptions = options.filter(
    opt => !selectedValuesSet.has(opt.value),
  )

  return (
    <div data-cy={`filter-${filterKey}`}>
      <Select
        // This component only handles the choosing of new values; unlike a
        // normal <select>, it doesn't show the already selected values. Those
        // are rendered elsewhere on the page by the `ActiveFilter` component
        selectedValue={null}
        onSelect={val => {
          if (val === filterAllKey) {
            onValueChange(null)
          } else {
            onValueChange(val)

            if (val) {
              logTableFilter({ field: filterKey, value: val, tableId })
            }
          }
        }}
        options={[{ label: "All", value: filterAllKey }, ...unselectedOptions]}
        placeholder={placeholder}
      />
    </div>
  )
}

// Wrap the `SelectAsync` component to add special behaviour such as Amplitude
// logging
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const FilterAsync = <T extends unknown>({
  filterKey,
  loadResults,
  mapResultToLabel,
  mapResultToValue,
  onValueChange,
  placeholder,
  tableId,
}: {
  filterKey: string
  loadResults: SelectAsyncProps<T>["loadResults"]
  mapResultToLabel: SelectAsyncProps<T>["mapResultToLabel"]
  mapResultToValue: SelectAsyncProps<T>["mapResultToValue"]
  onValueChange: (v: T) => void
  placeholder: string
  tableId: string
}): ReactElement => (
  <div data-cy={`filter-${filterKey}`}>
    <SelectAsync
      // This component only handles the choosing of new values; unlike a
      // normal <select>, it doesn't show the already selected values. Those
      // are rendered elsewhere on the page by the `ActiveFilter` component
      selectedValue={null}
      onSelect={val => {
        onValueChange(val)
        logTableFilter({
          field: filterKey,
          value: JSON.stringify(val),
          tableId,
        })
      }}
      loadResults={loadResults}
      mapResultToLabel={mapResultToLabel}
      mapResultToValue={mapResultToValue}
      placeholder={placeholder}
    />
  </div>
)
