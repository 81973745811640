import { AxiosInstance, AxiosPromise } from "axios"

export interface Broker {
  id: string
  name: string
  alias: string
}

const searchBrokersUrl = (query: string): string =>
  query ? `/api/brokers/search?query=${query}` : `/api/brokers/search`

export const searchBrokers = (
  client: AxiosInstance,
  query: string,
): AxiosPromise<Broker[]> => client.get(searchBrokersUrl(query))
