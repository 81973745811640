export const buildAdvancedSearchQuery = <
  Filters extends Record<string, string>,
  Key extends keyof Filters = keyof Filters,
>(
  filters: Partial<Filters>,
  searchKeyMapping: Record<Key, string>,
): string =>
  Object.entries(filters)
    .reduce<string[]>((acc, [k, v]) => {
      const key = k as Key
      const val: Filters[Key] | undefined = v?.trim()

      if (val === undefined || val.length === 0) {
        return acc
      }

      return [...acc, `${searchKeyMapping[key]}: "${val}"`]
    }, [])
    .join(" AND ")
