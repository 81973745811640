import { FC } from "react"

import ReasonSelection, {
  NoteAndReasons,
} from "../../../components/ReasonSelection"

const Reasons: FC<{
  noteAndReasons: NoteAndReasons
  onChange: (nr: NoteAndReasons) => void
  showNoteErrors: boolean
}> = ({ noteAndReasons, onChange, showNoteErrors }) => (
  <section
    aria-label="Reasons for overlining"
    className="mx-[-1rem] w-[calc(100%+2rem)] border-t border-otto-grey-300 px-4 pt-4"
  >
    <ReasonSelection
      label={
        <span className="mb-2 block">
          Please select the <span aria-hidden>reason(s)</span>
          <span className="sr-only">reason or reasons</span> for overlining:
        </span>
      }
      noteAndReasons={noteAndReasons}
      onChange={onChange}
      selectPlaceholder="Select reasons for overlining"
      showNoteError={showNoteErrors}
      showReasonError={showNoteErrors && noteAndReasons.reasons?.length === 0}
    />
  </section>
)

export default Reasons
