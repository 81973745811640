import { ReactElement, useEffect, useMemo, useRef } from "react"
import "./index.css"

import { HotTable } from "@handsontable/react"

import { Card, Tabs } from "@appia/ui-components"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import Spreadsheet, { CellHighlight } from "src/components/Spreadsheet"
import ExcelToolbar from "./Toolbar"

import { Document } from "@appia/api"
import { useGetSpreadsheetData } from "src/swr"
import * as RD from "@appia/remote-data"

import { columnToIndex } from "src/utils/spreadsheets"

import classNames from "classnames"
import { dequal } from "dequal"

const EXCEL_ELEMENT_ID = "excel-document-view"

// A 1-indexed reference to a cell in an Excel sheet, plus the identifier of
// the related field
export interface ExcelCellWithIdentifier<T> {
  fieldIdentifier: T
  sheetName: string
  column: string
  row: number
}

export interface ExcelViewerProps<T> {
  activeField: T | null
  activeSheetName: string | null
  cellsToHighlight: ExcelCellWithIdentifier<T>[]
  documentMimeType: Document["mimetype"]
  documentName: Document["name"]
  documentUrl: Document["url"]
  documentUuid: Document["id"]
  setActiveSheetName: (s: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ExcelViewer = <T extends unknown>({
  activeField,
  activeSheetName,
  cellsToHighlight,
  documentMimeType,
  documentName,
  documentUrl,
  documentUuid,
  setActiveSheetName,
}: ExcelViewerProps<T>): ReactElement => {
  const { request: spreadsheetDataRequest } =
    useGetSpreadsheetData(documentUuid)

  const hotRef = useRef<HotTable>(null)

  useEffect(() => {
    if (
      RD.isSuccess(spreadsheetDataRequest) &&
      spreadsheetDataRequest.data.length > 0 &&
      activeSheetName === null
    ) {
      setActiveSheetName(spreadsheetDataRequest.data[0].sheetName)
    }
  }, [setActiveSheetName, spreadsheetDataRequest, activeSheetName])

  // Compute the highlights for each worksheet and cache them. Here we use
  // `useMemo` because we will need to recompute them if the `highlights` or
  // active field change
  const cellsToHighlightPerSheet = useMemo<
    Record<string, CellHighlight[]>
  >(() => {
    if (!RD.isSuccess(spreadsheetDataRequest)) {
      return {}
    }

    return spreadsheetDataRequest.data.reduce(
      (acc, { sheetName }) => ({
        ...acc,
        [sheetName]: cellsToHighlight
          .filter(cell => cell.sheetName === sheetName)
          .map(({ row, column, fieldIdentifier }) => ({
            // The API stores 1-indexed data, Excel-style, but HOT is 0-indexed
            row: row - 1,
            col: columnToIndex(column),
            className: classNames("cell-highlight", {
              "cell-highlight-active": dequal(activeField, fieldIdentifier),
            }),
          })),
      }),
      {},
    )
  }, [spreadsheetDataRequest, cellsToHighlight, activeField])

  useEffect(() => {
    if (activeField) {
      const cellsForField: { row: number; col: number }[] = cellsToHighlight
        .filter(cell => dequal(cell.fieldIdentifier, activeField))
        .map(cell => ({ row: cell.row, col: columnToIndex(cell.column) }))

      if (cellsForField.length === 0) {
        return
      }

      // The row data we get is 1-indexed but HOT is 0-indexed, so subtract 1
      const topMostRow = Math.min(...cellsForField.map(({ row }) => row)) - 1
      // `columnToIndex` has already converted the column to be 0-indexed
      const leftMostCol = Math.min(...cellsForField.map(({ col }) => col))

      if (hotRef && hotRef.current && hotRef.current.hotInstance) {
        hotRef.current.hotInstance.scrollViewportTo(
          // Leave a gap of 1 row and 1 column around the highlight if possible,
          // for nicer UX
          Math.max(topMostRow - 1, 0),
          Math.max(leftMostCol - 1, 0),
        )
      }
    }
  }, [activeField, cellsToHighlight])

  return RD.match(
    spreadsheetDataRequest,
    <Loading className="mt-4" />,
    <Loading className="mt-4" />,

    sheets => (
      <Card
        className="ExcelViewer relative flex-grow overflow-hidden"
        padding={0}
      >
        <ExcelToolbar
          excelElementId={EXCEL_ELEMENT_ID}
          documentName={documentName}
          documentMimeType={documentMimeType}
          documentUrl={documentUrl}
        />

        <Tabs.Root
          value={activeSheetName || ""}
          onValueChange={name => {
            setActiveSheetName(name)
          }}
        >
          {sheets.map(({ sheetName, data }, i) => (
            <Tabs.Content
              key={i}
              value={sheetName}
              id={EXCEL_ELEMENT_ID}
              className="absolute top-12 bottom-8 left-0 right-0 rounded-b-md bg-otto-grey-300"
            >
              <Spreadsheet
                hotTableRef={hotRef}
                data={data}
                cellHighlights={cellsToHighlightPerSheet[sheetName]}
              />
            </Tabs.Content>
          ))}

          <div className="absolute bottom-0 left-0 right-0 h-8 overflow-hidden rounded-sm forced-colors:border-t">
            <Tabs.List
              className="h-full max-w-min divide-x divide-otto-grey-300"
              aria-label="Workbook sheet"
            >
              {sheets.map(({ sheetName }, i) => (
                <Tabs.Trigger
                  key={i}
                  value={sheetName}
                  className="px-4 text-sm"
                >
                  {sheetName}
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </div>
        </Tabs.Root>
      </Card>
    ),

    error => (
      <ErrorMessage error={error} message="Failed to load spreadsheet data" />
    ),
  )
}

export default ExcelViewer
