import { useState } from "react"

import {
  PBQASurvey,
  PBQASurveyDetails,
  getPBQASurvey,
  getPBQASurveysAvailable,
} from "@appia/api"

import useApiClient from "src/contexts/ApiClientContext"

import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

export type PBQASurveyWithDetails = PBQASurvey & PBQASurveyDetails

export type SurveysRequest = RD.RemoteData<Error, PBQASurveyWithDetails[]>

const useGetSurveys = (pbqaId: string): SurveysRequest => {
  const apiClient = useApiClient()

  const [request, setRequest] = useState<SurveysRequest>(RD.NotAsked)

  const go = async (): Promise<void> => {
    if (!RD.isNotAsked(request)) {
      return
    }

    setRequest(RD.Loading)

    try {
      const { data: availableSurveys } = await getPBQASurveysAvailable(
        apiClient,
        pbqaId,
      )

      const surveys = await Promise.all(
        availableSurveys.map(async details => {
          const { data: survey } = await getPBQASurvey(
            apiClient,
            pbqaId,
            details.id,
          )

          return { ...details, ...survey }
        }),
      )

      setRequest(RD.Success(surveys))
    } catch (e) {
      if (e instanceof Error) {
        Sentry.captureException(e)
        setRequest(RD.Failure(e))
      }
    }
  }
  go()

  return request
}

export default useGetSurveys
