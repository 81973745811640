import { FC, ReactNode } from "react"

import { Syndicate } from "@appia/api"

import { ChevronDownIcon, ChevronUpIcon } from "@appia/ui-components"
import { Disclosure } from "@headlessui/react"

import classNames from "classnames"

const CollapsibleSyndicatePanel: FC<{
  syndicate: Syndicate
  children: ReactNode
}> = ({ syndicate, children }) => (
  <Disclosure defaultOpen>
    {({ open }) => (
      <>
        <Disclosure.Button
          className={classNames(
            "otto-focus-inset flex w-full items-center gap-4 border-b border-otto-grey-300 bg-white py-3 px-2 xl:gap-8",
          )}
        >
          <span className="text-left font-bold">
            {syndicate.name} ({syndicate.code})
          </span>

          <span className="ml-auto block w-8 shrink-0">
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </span>
        </Disclosure.Button>

        <Disclosure.Panel>{children}</Disclosure.Panel>
      </>
    )}
  </Disclosure>
)

export default CollapsibleSyndicatePanel
