import { Dispatch, ReactElement, SetStateAction, useState } from "react"

import { Button } from "@appia/ui-components"

interface Props<Filters> {
  label: string
  initialFilters: Filters
  onSubmit: (f: Filters) => void
  onReset: () => void
  children: (
    filters: Filters,
    setFilters: SetStateAction<Dispatch<Filters>>,
  ) => ReactElement
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const SearchFilterForm = <Filters extends unknown>({
  label,
  initialFilters,
  onSubmit,
  onReset,
  children,
}: Props<Filters>): ReactElement => {
  const [filters, setFilters] = useState<Filters>(initialFilters)

  return (
    <form
      aria-label="Search filters"
      onSubmit={e => {
        e.preventDefault()
        onSubmit(filters)
      }}
    >
      <p className="border-b border-b-otto-grey-300 px-4 py-2 text-otto-grey-700">
        {label}
      </p>

      <div className="grid gap-2 border-b border-b-otto-grey-300 px-4 pt-2 pb-4">
        {children(filters, setFilters)}
      </div>

      <div className="flex flex-col items-center justify-end gap-2 px-4 py-2 sm:flex-row">
        <Button
          label="Reset"
          theme="night"
          style="outlined"
          size="small"
          onClick={onReset}
        />

        <Button label="Search" theme="pop" style="filled" size="small" />
      </div>
    </form>
  )
}

export default SearchFilterForm
