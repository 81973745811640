import { FC } from "react"
import * as Toolbar from "@radix-ui/react-toolbar"

import {
  DownloadIcon,
  IconButton,
  ZoomInIcon,
  ZoomOutIcon,
} from "@appia/ui-components"

import usePageName from "src/contexts/PageNameContext"

import { downloadFile } from "src/utils/download"
import { logButtonClick, logFileDownload } from "src/amplitude"

import { Document } from "@appia/api"

export const ZOOM_MIN = 0.25
export const ZOOM_MAX = 3
const ZOOM_INCREMENT = 0.25

const CONTAINER_NAME = "PDF toolbar"

const PDFToolbar: FC<{
  pdfElementId: string
  documentName: Document["name"]
  documentUrl: Document["url"]
  documentMimeType: Document["mimetype"]
  setZoom: (n: number) => void
  zoom: number
}> = ({
  pdfElementId,
  documentName,
  documentUrl,
  documentMimeType,
  zoom,
  setZoom,
}) => {
  const pageName = usePageName()

  return (
    <>
      <Toolbar.Root
        aria-controls={pdfElementId}
        aria-label="PDF controls"
        className="grid h-12 w-full grid-cols-3 items-center bg-black px-4 forced-colors:border-b"
      >
        <div className="col-start-2 flex items-center justify-center gap-4 text-white">
          <Toolbar.Button asChild>
            <IconButton
              label="Zoom out"
              icon={<ZoomOutIcon />}
              color="text-white"
              size={6}
              disabled={zoom <= ZOOM_MIN}
              onClick={() => {
                if (zoom - ZOOM_INCREMENT >= ZOOM_MIN) {
                  setZoom(zoom - ZOOM_INCREMENT)
                }
                logButtonClick({
                  buttonName: "Zoom out",
                  containerName: CONTAINER_NAME,
                  pageName,
                })
              }}
            />
          </Toolbar.Button>

          <p>
            Zoom:{" "}
            {new Intl.NumberFormat("en-GB", {
              style: "percent",
              maximumFractionDigits: 0,
            }).format(zoom)}
          </p>

          <Toolbar.Button asChild>
            <IconButton
              label="Zoom in"
              icon={<ZoomInIcon />}
              color="text-white"
              size={6}
              disabled={zoom >= ZOOM_MAX}
              onClick={() => {
                if (zoom + ZOOM_INCREMENT <= ZOOM_MAX) {
                  setZoom(zoom + ZOOM_INCREMENT)
                }
                logButtonClick({
                  buttonName: "Zoom in",
                  containerName: CONTAINER_NAME,
                  pageName,
                })
              }}
            />
          </Toolbar.Button>
        </div>

        <div className="col-start-3 flex items-center justify-end gap-4">
          <Toolbar.Button asChild>
            <IconButton
              label="Download document"
              icon={<DownloadIcon />}
              color="text-white"
              size={6}
              onClick={async () => {
                downloadFile(documentUrl, documentName, documentMimeType)

                logFileDownload({ fileName: documentName })

                logButtonClick({
                  buttonName: "Download",
                  containerName: CONTAINER_NAME,
                  pageName,
                })
              }}
            />
          </Toolbar.Button>
        </div>
      </Toolbar.Root>
    </>
  )
}

export default PDFToolbar
