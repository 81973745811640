import { ReactNode } from "react"

import { KiClassOfBusiness, KiQuote, KiStatus } from "@appia/api"

import {
  formatCurrency,
  formatDecimal2DP,
  formatPercentage,
} from "src/utils/prettyPrinters"

import { ID } from "src/components/A11y"
import EmptyData from "src/components/EmptyData"

// This determines the order of the quote fields in the tables and search
// results
export const orderedFields = [
  "assured_name",
  "status",
  "policy_ref",
  "class_of_business",
  "quote_line_id",
  "ki_line",
  "limit_original_currency",
  "limit_usd",
  "ki_capacity_usd",
] as const

type KiQuoteField = (typeof orderedFields)[number]

export const prettyPrintKiQuoteField = (field: KiQuoteField): ReactNode => {
  switch (field) {
    case "assured_name":
      return "Assured name"
    case "status":
      return "Status"
    case "policy_ref":
      return "Policy ref"
    case "class_of_business":
      return "Class of business"
    case "quote_line_id":
      return (
        <>
          Quote line <ID />
        </>
      )
    case "ki_line":
      return "Ki line"
    case "limit_original_currency":
      return (
        <>
          <span aria-hidden>Limit (orig. ccy)</span>
          <span className="sr-only">Limit (original currency)</span>
        </>
      )
    case "limit_usd":
      return "Limit (USD)"
    case "ki_capacity_usd":
      return "Ki capacity (USD)"
  }
}

export const prettyPrintKiStatus = (s: KiStatus): string =>
  s.charAt(0).toUpperCase() + s.slice(1)

export const prettyPrintKiClassOfBusiness = (cob: KiClassOfBusiness): string =>
  cob.toUpperCase().replaceAll("_", " ")

export const renderKiQuoteField = (
  field: KiQuoteField,
  quote: KiQuote,
): ReactNode => {
  switch (field) {
    case "assured_name":
      return (
        <span className="inline-block lg:max-w-xxs lg:truncate">
          {quote.assuredName}
        </span>
      )
    case "status":
      return prettyPrintKiStatus(quote.status)
    case "policy_ref":
      return quote.policyReference ? (
        <span className="inline-block lg:max-w-xxs lg:truncate">
          {quote.policyReference}
        </span>
      ) : (
        <EmptyData />
      )
    case "class_of_business":
      return quote.groupClass === null ? (
        <EmptyData />
      ) : (
        <span
          className="inline-block whitespace-nowrap rounded bg-otto-grey-200 py-1 px-2 text-sm"
          data-cy="overlining-class-of-business"
        >
          {prettyPrintKiClassOfBusiness(quote.groupClass)}
        </span>
      )
    case "quote_line_id":
      return quote.quoteLineId
    case "ki_line":
      return quote.lineSize === null ? (
        <EmptyData />
      ) : (
        formatPercentage(quote.lineSize)
      )
    case "limit_original_currency":
      return quote.limitCurrency === null
        ? formatDecimal2DP(quote.limit)
        : formatCurrency(quote.limit, quote.limitCurrency)
    case "limit_usd":
      return quote.limitUsd === null ? (
        <EmptyData />
      ) : (
        formatDecimal2DP(quote.limitUsd)
      )
    case "ki_capacity_usd":
      return quote.capacity === null ? (
        <EmptyData />
      ) : (
        formatDecimal2DP(quote.capacity)
      )
  }
}
