import { createContext, useContext } from "react"
import { ApiClient, Client } from "@appia/api"

const apiClientWithCaseConversion = Client(window.HOST)
const apiClientWithoutCaseConversion = Client(window.HOST, {}, false)

export const ApiClientContext = createContext<ApiClient | null>(
  apiClientWithCaseConversion,
)

const useApiClient = (useCaseConversion = true): ApiClient => {
  const apiClient = useContext(ApiClientContext)
  if (apiClient === null || apiClient === undefined) {
    throw new Error("Tried to access uninitialised API client")
  }

  return useCaseConversion ? apiClient : apiClientWithoutCaseConversion
}

export default useApiClient
