import React, { useId } from "react"
import { ContractFile, Document } from "@appia/api"
import { Card, DownloadIcon, IconButton } from "@appia/ui-components"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import { downloadFile } from "src/utils/download"
import { logButtonClick, logFileDownload } from "src/amplitude"
import Loading from "src/components/Loading"
import * as RD from "@appia/remote-data"
import ErrorMessage from "../../components/ErrorMessage"

interface ContractDocumentsMergedProps {
  documentsRequest: RD.RemoteData<Error, Document[]>
  pageName: string
  contractFiles: ContractFile[] | null | undefined
}

enum FileType {
  Document = "document",
  ContractFile = "contract file",
}

interface DocumentType {
  id: string
  name: string
  updatedAt: string
  type: FileType.Document
  url: string
  mimetype: string
}

interface ContractFileType {
  id: string
  name: string
  updatedAt: string
  type: FileType.ContractFile
}

type DocumentMerged = DocumentType | ContractFileType

const isDocumentType = (doc: DocumentMerged): doc is DocumentType => {
  return doc.type === FileType.Document
}

const handleDownloadClick = async (
  doc: DocumentMerged,
  pageName: string,
): Promise<void> => {
  if (isDocumentType(doc)) {
    await downloadFile(doc.url, doc.name, doc.mimetype)
  } else {
    // Handle contract file download here
  }

  logFileDownload({ fileName: doc.name })
  logButtonClick({
    buttonName: "Download",
    containerName: "Main",
    pageName: pageName,
  })
}

const ContractDocumentsMerged: React.FC<ContractDocumentsMergedProps> = ({
  documentsRequest,
  pageName,
  contractFiles,
}) => {
  const documentsLabelId = useId()

  return RD.match(
    documentsRequest,
    <Loading />,
    <Loading />,
    (documents: Document[]) => {
      const allDocuments: DocumentMerged[] = [
        ...documents.map(
          (doc): DocumentType => ({
            id: doc.id,
            name: doc.name,
            updatedAt: doc.updatedAt,
            type: FileType.Document,
            url: doc.url,
            mimetype: doc.mimetype,
          }),
        ),

        ...(contractFiles ?? []).map(
          (file): ContractFileType => ({
            id: file.id,
            name: file.name,
            updatedAt: file.updatedAt.toString(),
            type: FileType.ContractFile,
          }),
        ),
      ]

      return (
        <section className="pt-4 pb-8" aria-labelledby="Previous Contracts">
          <h3 id={documentsLabelId} className="mb-2 text-xl">
            Contract Documents
          </h3>
          <Card className="mb-4">
            <div className="grid grid-cols-3 items-center">
              <div className="font-bold">Name</div>
              <div className="flex items-center justify-center pl-3 font-bold">
                Uploaded on
              </div>
              <div className="flex items-center justify-center pl-3 font-bold"></div>
            </div>

            {allDocuments.length > 0 ? (
              allDocuments.map(doc => (
                <div
                  key={doc.id}
                  className="mt-3 grid grid-cols-3 items-center"
                >
                  <div>{doc.name}</div>
                  <div className="flex justify-center">
                    {prettyPrintDateString(doc.updatedAt)}
                  </div>
                  <div className="flex justify-end">
                    <IconButton
                      icon={<DownloadIcon />}
                      size={6}
                      label={`Download ${doc.type} ${doc.name}`}
                      onClick={() => handleDownloadClick(doc, pageName)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No documents available.</p>
            )}
          </Card>
        </section>
      )
    },
    documentError => (
      <ErrorMessage message="Failed to load documents" error={documentError} />
    ),
  )
}

export default ContractDocumentsMerged
