import { FC } from "react"

import { Callout, Link } from "@appia/ui-components"

import ModalTemplate from "src/components/ModalTemplate"

const SuccessModal: FC<{
  isOpen: boolean
  message: string
  onClose: () => void
  showDashboardCta?: boolean
}> = ({ isOpen, message, onClose, showDashboardCta }) => (
  <ModalTemplate
    isOpen={isOpen}
    onClose={onClose}
    title="Confirmed"
    content={<Callout type="success">{message}</Callout>}
    actions={
      showDashboardCta
        ? [
            <Link
              key="dashboard"
              label="Go to dashboard"
              href="/endorsements"
              theme="pop"
              style="filled"
            />,
          ]
        : []
    }
  />
)

export default SuccessModal
