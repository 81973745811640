import { AxiosInstance, AxiosPromise } from "axios"
import { Document, PolicyDocuments } from "../../"
import { Endorsement } from "../Endorsement"

export interface SignedDetails {
  endorsement: Endorsement
  endorsementDocuments: Document[]
  policyDocuments: PolicyDocuments
  teamName: string
}

export const getSignedReferralDetails = (
  client: AxiosInstance,
  id: string,
  token: string,
): AxiosPromise<SignedDetails> =>
  client.get(`/api/endorsements/${id}/pu`, { params: { signing_token: token } })

export const acceptReferredEndorsement = (
  client: AxiosInstance,
  id: string,
  token: string,
): AxiosPromise =>
  client.post(
    `/api/endorsements/${id}/pu/accept`,
    {},
    { params: { signing_token: token } },
  )

export const rejectReferredEndorsement = (
  client: AxiosInstance,
  id: string,
  token: string,
  reason: string,
): AxiosPromise =>
  client.post(
    `/api/endorsements/${id}/pu/reject`,
    { reason },
    { params: { signing_token: token } },
  )
