export type OneOrMany<T> = T | T[]

export const mapOneOrMany = <A>(
  f: (a: A) => A,
  val: OneOrMany<A>,
): OneOrMany<A> => (Array.isArray(val) ? val.map(f) : f(val))

export const findInOneOrMany = <A>(
  f: (a: A) => boolean,
  val: OneOrMany<A>,
): A | null => (Array.isArray(val) ? val.find(f) ?? null : f(val) ? val : null)
