import { FC, ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"

import * as Sentry from "@sentry/react"
import * as Amplitude from "./amplitude"

import * as RD from "@appia/remote-data"
import { useGetCurrentUser } from "src/swr"

export const RequiresAuth: FC<{
  children: ReactNode
}> = ({ children }) => {
  const location = useLocation()

  const { request: userRequest } = useGetCurrentUser()

  return RD.match(
    userRequest,
    null,
    null,
    me => {
      Amplitude.setUser({ userId: me.user.id, teamId: me.user.teamId })
      Sentry.setUser({ id: me.user.id })
      return <>{children}</>
    },
    () => <Navigate to="/login" state={location.pathname} />,
  )
}
