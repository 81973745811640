import type { SVGComponent } from "./IconWrapper"

const RotateRightSVG: SVGComponent = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5849 7.08497L11.0349 2.53497V5.60497C7.09491 6.09497 4.03491 9.45497 4.03491 13.535C4.03491 17.615 7.08491 20.975 11.0349 21.465V19.445C8.19491 18.965 6.03491 16.505 6.03491 13.535C6.03491 10.565 8.19491 8.10497 11.0349 7.62497V11.535L15.5849 7.08497ZM19.9649 12.535C19.7949 11.145 19.2449 9.80497 18.3449 8.64497L16.9249 10.065C17.4649 10.815 17.8049 11.665 17.9449 12.535H19.9649ZM13.0349 19.435V21.455C14.4249 21.285 15.7749 20.745 16.9349 19.845L15.4949 18.405C14.7449 18.945 13.9049 19.295 13.0349 19.435ZM16.9249 17.015L18.3449 18.425C19.2449 17.265 19.7949 15.925 19.9649 14.535H17.9449C17.8049 15.405 17.4649 16.255 16.9249 17.015Z"
      fill="currentColor"
    />
  </svg>
)

export default RotateRightSVG
