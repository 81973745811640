import { AxiosInstance, AxiosPromise } from "axios"

export interface Webhook {
  id: string
  key: string
  callbackUri: string
  enabled: boolean
  companyId: string
  scope: string[]
}

export interface WebhookList {
  webhooks: Webhook[]
}

export interface WebhookCreate {
  callbackUri: string
  scope: string[]
}

export interface WebhookUpdate {
  callbackUri: string
  enabled: boolean
  scope: string[]
}

export const listWebhooksUrl = "/api/webhooks"

export const getWebhookUrl = (id: string): string => `/api/webhooks/${id}`

export const createWebhook = (
  client: AxiosInstance,
  data: WebhookCreate,
): AxiosPromise<Webhook> => {
  return client.post(`/api/webhooks`, data)
}

export const updateWebhook = (
  client: AxiosInstance,
  id: string,
  data: WebhookUpdate,
): AxiosPromise<Webhook> => {
  return client.put(`/api/webhooks/${id}`, data)
}
