import type { User } from "../Users"
import type { EndorsementStatus } from "./Status"
import type { KiClassOfBusiness } from "../PBQA/KiQuote"
import type { KiJourneyType } from "../PBQA/Survey"

export type EndorsementType = "request" | "notice"

export type EndorsementSortDirection = "asc" | "desc"

export type EndorsementSortKey =
  | "archived_by"
  | "assured_name"
  | "broker"
  | "type"
  | "group_class"
  | "journey"
  | "owned_by"
  | "submission_date" // AKA "Upload date"
  | "umr"
  | "updated_at" // AKA "Archive date"
  | "updated_by" // AKA "Archived by"

export interface EndorsementsSearchQuery {
  // Search parameters
  assuredName: string[] | null
  ownerId: (User["id"] | "unassigned")[] | null
  status: EndorsementStatus[]
  type: EndorsementType[] | null
  umr: string[] | null
  updatedBy: User["id"][] | null
  groupClass: KiClassOfBusiness[] | null
  // Search and pagination settings
  order: EndorsementSortKey | null
  orderBy: EndorsementSortDirection | null
  page: number | null
  pageSize: number | null
}

export interface EndorsementsSearch {
  items: EndorsementsSearchResult[]
  hits: number
  query: EndorsementsSearchQuery
}

export interface EndorsementsSearchResult {
  archivedStatus?: EndorsementStatus
  assuredName?: string
  brokerAlias?: string
  brokerId?: string
  brokerName?: string
  createdAt: string
  type?: EndorsementType
  id: string
  inceptionDate?: string
  ownerId?: User["id"]
  ownerName?: string
  status: EndorsementStatus
  umr?: string
  updatedAt: string
  updatedBy?: User["id"]
  groupClass?: KiClassOfBusiness
  journey?: KiJourneyType
}

export const searchEndorsementsUrl = (params: URLSearchParams): string =>
  `/api/endorsements/search?${params.toString()}`
