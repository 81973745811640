import { FC } from "react"
import classNames from "classnames"
import { SpinnerIcon } from "@appia/ui-components"

const Loading: FC<{ className?: string; id?: string }> = ({
  className,
  id,
}) => (
  <div id={id} className={classNames("mx-auto w-8", className)}>
    <SpinnerIcon label="Loading" />
  </div>
)

export default Loading
