import { FC } from "react"
import {
  ApiError,
  ApiErrorCode,
  ApiErrorDetail,
  ApiErrorFieldErrors,
  isApiError,
} from "@appia/api"
import { Callout } from "@appia/ui-components"

const joinWithNewlines = (s: string[]): string =>
  s.join(`
`)

const printLoc = (loc: string[]): string => loc.join(" -> ")

const printErrorCode = (details: ApiErrorCode): string => {
  const codeStr = details.errorCode ? `Error code: ${details.errorCode}` : null
  return codeStr
    ? joinWithNewlines([codeStr, "", details.message])
    : details.message
}

const printDetails = (details: ApiErrorDetail[]): string => {
  const strs = details.map(({ loc, msg }) => `At ${printLoc(loc)}: ${msg}`)
  return joinWithNewlines(strs)
}

const printFieldErrors = ({ errors, fields }: ApiErrorFieldErrors): string => {
  const fieldStrs = Object.entries(fields).map(
    ([id, msgs]) => `At field ${id}: ${msgs.join(" ")}`,
  )
  return `${joinWithNewlines(errors)}

${joinWithNewlines(fieldStrs)}`
}

export const printErrorDetails = (err: ApiError): string => {
  if (!err.response) {
    return ""
  }

  if (typeof err.response.data === "string") {
    return err.response.data
  }

  if ("error" in err.response.data) {
    return err.response.data.error
  }

  const { detail } = err.response.data

  if (typeof detail === "string") {
    return detail
  } else if (Array.isArray(detail)) {
    return printDetails(detail)
  } else if ("errorCode" in detail) {
    return printErrorCode(detail)
  } else if ("fields" in detail) {
    return printFieldErrors(detail)
  } else if (Array.isArray(detail.response)) {
    return printDetails(detail.response)
  } else {
    return detail.response
  }
}

const ErrorMessage: FC<{
  message: string
  error?: ApiError | Error
}> = ({ message, error }) => (
  <Callout type="error">
    <p>{message}</p>

    {error && (
      <>
        <code className="block overflow-x-auto text-sm">{error.message}</code>

        {isApiError(error) && (
          <code className="block overflow-x-auto text-sm">
            <pre>{printErrorDetails(error)}</pre>
          </code>
        )}
      </>
    )}
  </Callout>
)

export default ErrorMessage
