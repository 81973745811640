import { FC, ReactNode } from "react"
import { ContractsPipelineSearchResult } from "@appia/api"
import {
  ArchiveIcon,
  DotsVerticalIcon,
  IconMenu,
  MenuItem,
  RotateRightIcon,
} from "@appia/ui-components"

const Menu: FC<{ children: ReactNode }> = ({ children }) => (
  <IconMenu
    buttonTextAccessible="Action menu"
    icon={() => <DotsVerticalIcon />}
  >
    {children}
  </IconMenu>
)

const ContractActionMenu: FC<{
  contract: ContractsPipelineSearchResult
  onRedo: (id: string) => void
  onArchive: (id: string) => void
}> = ({ onArchive, contract, onRedo }) => {
  const archiveMenuItem = (
    <MenuItem
      onClick={() => onArchive(contract.id)}
      className="flex items-center justify-start gap-2"
    >
      <ArchiveIcon className="w-5 text-otto-grey-700" />
      <span>Archive</span>
    </MenuItem>
  )

  const redoMenuItem = (
    <MenuItem
      onClick={() => onRedo(contract.externalId)}
      className="flex items-center justify-start gap-2"
    >
      <RotateRightIcon className="w-5 text-otto-grey-700" />
      <span>Redo</span>
    </MenuItem>
  )

  switch (contract.versionStatus) {
    case "archived":
      return null
    case "created":
    case "in_progress":
      return <Menu>{archiveMenuItem}</Menu>
    case "completed":
      return (
        <Menu>
          {archiveMenuItem}
          {redoMenuItem}
        </Menu>
      )
    default:
      return null
  }
}

export default ContractActionMenu
