import { FC, useId } from "react"

import { KiQuote, PBQAAnswer, PBQAQuestion } from "@appia/api"

import { InputLabel } from "@appia/ui-components"

import BooleanQuestion from "./BooleanQuestion"
import NonBooleanQuestion from "./NonBooleanQuestion"
import OverliningQuestion from "./OverliningQuestion"

import ReadOnlyKiData from "../components/ReadOnlyKiData"
import QuestionInput from "../components/QuestionInput"

import {
  QuestionAnswerState,
  QuestionAnswerStateBoolean,
  QuestionAnswerStateEEA,
  QuestionAnswerStateOverlining,
} from "ReviewPBQA/state"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"

import { isEmpty } from "src/utils/typeRefinements"
import EEAQuestionWrapper from "./EEAQuestionWrapper"

const ReadOnlyQuestion: FC<{
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  questionAnswerState: Exclude<
    QuestionAnswerState,
    | QuestionAnswerStateBoolean
    | QuestionAnswerStateOverlining
    | QuestionAnswerStateEEA
  >
  showFieldErrors: boolean
  onCopySyndicateAnswer?: () => void
}> = ({
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  questionAnswerState,
  showFieldErrors,
  onCopySyndicateAnswer,
}) => {
  return (
    <NonBooleanQuestion
      isActive={isActive}
      onChangeAnswer={onChangeAnswer}
      onJumpToBoundingBox={onJumpToBoundingBox}
      questionAnswerState={questionAnswerState}
      questionComponent={
        <ReadOnlyKiData
          questionAnswerState={questionAnswerState}
          onCopySyndicateAnswer={onCopySyndicateAnswer}
        />
      }
      showFieldErrors={showFieldErrors}
    />
  )
}

const NonBooleanQuestionWrapper: FC<{
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  questionAnswerState: Exclude<
    QuestionAnswerState,
    | QuestionAnswerStateBoolean
    | QuestionAnswerStateOverlining
    | QuestionAnswerStateEEA
  >
  showFieldErrors: boolean
}> = ({
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  questionAnswerState,
  showFieldErrors,
}) => {
  const { question } = questionAnswerState

  const inputId = useId()
  const inputLabelId = useId()

  return (
    <NonBooleanQuestion
      isActive={isActive}
      onChangeAnswer={onChangeAnswer}
      onJumpToBoundingBox={onJumpToBoundingBox}
      questionAnswerState={questionAnswerState}
      questionComponent={
        <InputLabel htmlFor={inputId} id={inputLabelId} label={question.label}>
          <QuestionInput
            inputId={inputId}
            inputLabelId={inputLabelId}
            onChangeAnswer={onChangeAnswer}
            questionAnswerState={questionAnswerState}
            showChangedValuesWarning={false}
            showFieldErrors={showFieldErrors}
          />
        </InputLabel>
      }
      showFieldErrors={showFieldErrors}
    />
  )
}

const NonComparisonGroup: FC<{
  onChangeAnswer: (id: PBQAQuestion["id"], a: PBQAAnswer) => void
  onJumpToBoundingBox: (id: PBQAQuestion["id"]) => void
  questionStates: QuestionAnswerState[]
  quote: KiQuote | null
  showFieldErrors: boolean
}> = ({
  onChangeAnswer,
  onJumpToBoundingBox,
  questionStates,
  quote,
  showFieldErrors,
}) => {
  const { activeQuestionId } = usePBQASurvey()

  const handleCopySyndicateAnswer = (): void => {
    const [readOnlyQuestionState, syndicateQuestionState] = questionStates

    const readOnlyQuestionAnswer = readOnlyQuestionState.answer
    const syndicateQuestionId = syndicateQuestionState.question.id
    const syndicateQuestionAnswer = syndicateQuestionState.answer
    const syndicateAccepted = syndicateQuestionAnswer.accepted

    const newSyndicateAnswer: PBQAAnswer = {
      ...syndicateQuestionAnswer,
      accepted:
        readOnlyQuestionAnswer.answer !== syndicateQuestionAnswer.answer &&
        syndicateAccepted === true
          ? false
          : syndicateQuestionAnswer.accepted,
      answer: readOnlyQuestionAnswer.answer,
      // @ts-expect-error - ignoring type for unit
      unit: readOnlyQuestionAnswer.unit,
    }

    onChangeAnswer(syndicateQuestionId, newSyndicateAnswer)
  }

  return (
    <>
      {questionStates.map(state => {
        const questionId = state.question.id

        if (state.type === "boolean") {
          return (
            <BooleanQuestion
              key={state.question.id}
              isActive={activeQuestionId === questionId}
              onChangeAnswer={a => onChangeAnswer(questionId, a)}
              onJumpToBoundingBox={() => onJumpToBoundingBox(questionId)}
              question={state.question}
              answer={state.answer}
              showFieldErrors={showFieldErrors}
            />
          )
        } else if (state.type === "overlining") {
          return (
            <OverliningQuestion
              key={state.question.id}
              answer={state.answer}
              isActive={activeQuestionId === questionId}
              onChangeAnswer={a => onChangeAnswer(questionId, a)}
              question={state.question}
              quote={quote}
              showFieldErrors={showFieldErrors}
            />
          )
        } else if (state.type === "eea") {
          return (
            <EEAQuestionWrapper
              key={state.question.id}
              isActive={activeQuestionId === questionId}
              onChangeAnswer={a => onChangeAnswer(questionId, a)}
              onJumpToBoundingBox={() => onJumpToBoundingBox(questionId)}
              questionAnswerState={state}
              showFieldErrors={showFieldErrors}
            />
          )
        }

        let kiAnswerValue: Parameters<typeof isEmpty>[0]
        switch (state.type) {
          case "decimal":
          case "integer":
            kiAnswerValue = {
              amount: state.kiAnswer?.answer || null,
              unit: state.kiAnswer?.unit || null,
            }
            break
          default:
            kiAnswerValue = state.kiAnswer
            break
        }

        const { canOverride } = state.question

        return isEmpty(kiAnswerValue) || canOverride ? (
          <NonBooleanQuestionWrapper
            key={state.question.id}
            isActive={activeQuestionId === questionId}
            onChangeAnswer={a => onChangeAnswer(questionId, a)}
            onJumpToBoundingBox={() => onJumpToBoundingBox(questionId)}
            questionAnswerState={state}
            showFieldErrors={showFieldErrors}
          />
        ) : (
          <ReadOnlyQuestion
            key={state.question.id}
            isActive={activeQuestionId === questionId}
            onChangeAnswer={a => onChangeAnswer(questionId, a)}
            onJumpToBoundingBox={() => onJumpToBoundingBox(questionId)}
            questionAnswerState={state}
            showFieldErrors={showFieldErrors}
            onCopySyndicateAnswer={handleCopySyndicateAnswer}
          />
        )
      })}
    </>
  )
}

export default NonComparisonGroup
