import type { SVGComponent } from "./IconWrapper"

const SelectorSVG: SVGComponent = props => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.78"
      y="0.78"
      width="54.45"
      height="54.45"
      rx="27.225"
      fill="currentColor"
      style={{ opacity: 0.1 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5595 24.4897L32.3761 23.5254L28.0053 13.2285L23.6346 23.5409L12.4512 24.4897L20.9437 31.8469L18.3929 42.7814L28.0053 36.9797L37.6178 42.7814L35.0825 31.8469L43.5595 24.4897ZM28.0053 34.0711L22.157 37.6019L23.7124 30.9447L18.5484 26.4651L25.3611 25.8741L28.0053 19.6057L30.6651 25.8896L37.4778 26.4807L32.3138 30.9603L33.8693 37.6175L28.0053 34.0711Z"
      fill="currentColor"
    />
    <rect
      x="0.78"
      y="0.78"
      width="54.45"
      height="54.45"
      rx="27.225"
      stroke="currentColor"
      strokeWidth="1.56"
    />
  </svg>
)

export default SelectorSVG
