import type { Broker, Syndicate } from "@appia/api"
import type { UnitsInputValue } from "@appia/ui-components"

export const isArray = <T, U>(x: T | U[]): x is U[] => Array.isArray(x)

export const isNotNull = <T>(x: T | null): x is T => x !== null

export const isEmpty = (
  val:
    | string
    | string[]
    | number
    | number[]
    | Broker
    | Syndicate
    | UnitsInputValue
    | null
    | undefined,
): boolean => {
  if (val === null || val === undefined) {
    return true
  } else if (Array.isArray(val)) {
    return val.length === 0
  } else if (typeof val === "string") {
    return val.trim().length === 0
  } else if (typeof val === "object" && "amount" in val && "unit" in val) {
    return val.unit === null || val.amount === null
  } else {
    return false
  }
}
