import { FC } from "react"

import {
  ArchiveIcon,
  BoltIcon,
  CheckIcon,
  ClipboardListIcon,
  EditNoteIcon,
  Pill,
  SpinnerIcon,
  XIcon,
} from "@appia/ui-components"
import { ContractStatus } from "@appia/api"

export type Status =
  | "archived"
  | "created"
  | "pending"
  | "processing"
  | "review_required"
  | "review_in_progress"
  | "review_complete"
  | "failed"
  | "failed_extraction"

export const prettyPrintStatus = (status: Status | ContractStatus): string => {
  switch (status) {
    case "archived":
      return "Archived"
    case "created":
      return "Created"
    case "pending":
      return "Pending"
    case "processing":
      return "Processing"
    case "review_required":
      return "Review required"
    case "review_in_progress":
      return "Review in progress"
    case "review_complete":
      return "Completed"
    case "failed":
      return "Failed"
    case "completed":
      return "Completed"
    case "in_progress":
      return "In progress"
    case "failed_extraction":
      return "Failed extraction"
  }
}

const StatusPill: FC<{
  status: Status | ContractStatus
}> = ({ status }) => {
  const label = prettyPrintStatus(status)

  switch (status) {
    case "review_required":
      return <Pill type="pop" icon={<ClipboardListIcon />} label={label} />
    case "review_in_progress":
    case "in_progress":
      return <Pill type="warning" icon={<EditNoteIcon />} label={label} />
    case "review_complete":
    case "completed":
      return <Pill type="success" icon={<CheckIcon />} label={label} />
    case "archived":
      return <Pill type="disabled" icon={<ArchiveIcon />} label={label} />
    case "created":
      return <Pill type="disabled" icon={<BoltIcon />} label={label} />
    case "pending":
    case "processing":
      return <Pill type="neutral" icon={<SpinnerIcon />} label={label} />
    case "failed":
    case "failed_extraction":
      return <Pill type="error" icon={<XIcon />} label={label} />
  }
}

export default StatusPill
