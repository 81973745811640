import { FC, ReactNode, RefObject } from "react"

import {
  ArrowLeftIcon,
  IconButton,
  Modal,
  ModalTitle,
  XIcon,
} from "@appia/ui-components"
import classNames from "classnames"

type Breakpoint = "sm" | "lg"

const ModalTemplate: FC<{
  actions: ReactNode[]
  actionsReflowBreakpoint?: Breakpoint
  allowOverflow?: boolean
  className?: string
  content: ReactNode
  initialFocus?: RefObject<HTMLElement>
  isOpen: boolean
  onBack?: () => void
  onClose: () => void
  title?: string
}> = ({
  actions,
  actionsReflowBreakpoint = "lg",
  allowOverflow,
  className,
  content,
  initialFocus,
  isOpen,
  onBack,
  onClose,
  title,
}) => (
  <Modal
    initialFocus={initialFocus}
    isOpen={isOpen}
    onClose={onClose}
    allowOverflow={allowOverflow}
    className={className}
  >
    <div className="relative">
      {/* The close button is placed last in the source order because it should
          be the last interactive element the user tabs to. However, we want it
          to display visually in the top-right corner, so we use a grid layout
          here with an empty cell to 'reserve' some space for it.
          */}
      <ModalTitle
        className={classNames(
          "grid grid-cols-[1fr,2rem] gap-1 border-b border-otto-grey-300 p-4 text-2xl font-bold",
          { "grid-cols-[2rem,1fr,2rem] gap-3": onBack },
        )}
      >
        {onBack && (
          <IconButton
            icon={<ArrowLeftIcon />}
            label="Go back"
            onClick={onBack}
          />
        )}
        {title || <span>&nbsp;</span>}
      </ModalTitle>

      <div className="p-4">{content}</div>

      <div
        className={classNames(
          "flex flex-col items-center justify-end gap-4 border-t border-otto-grey-300 p-4",
          { "sm:flex-row": actionsReflowBreakpoint === "sm" },
          { "lg:flex-row": actionsReflowBreakpoint === "lg" },
        )}
      >
        {actions}
      </div>

      <div className="absolute right-4 top-4">
        <IconButton label="Close dialog" icon={<XIcon />} onClick={onClose} />
      </div>
    </div>
  </Modal>
)

export default ModalTemplate
