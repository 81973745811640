import { FC, ReactNode } from "react"

const Group: FC<{
  "aria-labelledby": string
  className?: string
  children: ReactNode
}> = ({ "aria-labelledby": ariaLabelledBy, className, children }) => (
  // We use a `div` with `role=group` and `aria-labelledby` here instead of a
  // fieldset because it's hard to override the browser's default fieldset CSS
  <div role="group" aria-labelledby={ariaLabelledBy} className={className}>
    {children}
  </div>
)

export default Group
