import { createContext, useContext } from "react"

export const PageNameContext = createContext<string | null>(null)

const usePageName = (): string => {
  const pageName = useContext(PageNameContext)
  if (pageName === null) {
    throw new Error("Tried to access uninitialised page name")
  }

  return pageName
}

export default usePageName
