import { AxiosInstance, AxiosPromise } from "axios"
import { Endorsement } from "./Endorsement"
import { Policy } from "../Policies"

export interface EndorsementUpdate {
  policyId?: Policy["policyId"] | null
  umr?: string | null
  insured?: string | null
  policyReference?: string | null
}

export const updateEndorsementPolicy = (
  client: AxiosInstance,
  endorsement_id: Endorsement["id"],
  update: EndorsementUpdate,
): AxiosPromise => client.put(`/api/endorsements/${endorsement_id}`, update)
