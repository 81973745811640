import { FC } from "react"

import { Input, InputLabel } from "@appia/ui-components"
import { ID } from "src/components/A11y"

import { KiQuoteSearchFilters } from "ReviewPBQA/kiQuoteSearch"
import SearchFilterForm from "src/components/SearchFilterForm"

const Filters: FC<{
  filters: Partial<KiQuoteSearchFilters>
  onSubmit: (f: Partial<KiQuoteSearchFilters>) => void
  onReset: () => void
}> = ({ filters: initialFilters, onSubmit, onReset }) => {
  return (
    <SearchFilterForm
      label="I’m looking for..."
      initialFilters={initialFilters}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {(filters, setFilters) => (
        <>
          <InputLabel label="Assured name">
            <Input
              id="search-assured-name"
              placeholder="Search on assured name"
              onChange={v => setFilters(f => ({ ...f, assuredName: v }))}
              value={filters.assuredName || ""}
            />
          </InputLabel>

          <InputLabel
            label={
              <>
                Quote <ID />
              </>
            }
          >
            <Input
              id="search-quote-id"
              placeholder="Search on quote ID"
              onChange={v => setFilters(f => ({ ...f, quoteId: v }))}
              value={filters.quoteId || ""}
            />
          </InputLabel>

          <InputLabel
            label={
              <>
                Quote line <ID />
              </>
            }
          >
            <Input
              id="search-quote-line-id"
              placeholder="Search on quote line ID"
              onChange={v => setFilters(f => ({ ...f, quoteLineId: v }))}
              value={filters.quoteLineId || ""}
            />
          </InputLabel>
        </>
      )}
    </SearchFilterForm>
  )
}

export default Filters
