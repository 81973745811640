import { FC, ReactNode, useEffect, useState } from "react"
import {
  Button,
  Card,
  Input,
  InputLabel,
  Select,
  SelectOption,
} from "@appia/ui-components"
import {
  CONDUCT_RATING_HIGH,
  CONDUCT_RATING_LOW,
  CONDUCT_RATING_MEDIUM,
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_INSURANCE_CARRIER,
  CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE,
  CUSTOMER_TYPE_MICRO_ENTERPRISE,
  CUSTOMER_TYPE_SMALL_CORPORATE,
  ConductRating,
  CustomerType,
  UpdateHeaderData,
} from "@appia/api"
import classNames from "classnames"
import InputWithError from "src/components/InputWithError"
import useFormFieldWithAutoResetError from "src/hooks/useFormFieldWithAutoResetError"
import {
  parseBrokerContactName,
  parseConductRating,
  parseCustomerType,
} from "./parsers"
import UnsavedChangesModal from "../UnsavedChangesModal"
import { useNavigate } from "react-router-dom"

const customerTypeSelectOptions: SelectOption[] = [
  { value: CUSTOMER_TYPE_INDIVIDUAL, label: "Individual" },
  { value: CUSTOMER_TYPE_MICRO_ENTERPRISE, label: "Micro Enterprise" },
  { value: CUSTOMER_TYPE_SMALL_CORPORATE, label: "Small Corporate" },
  {
    value: CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE,
    label: "Medium or Large corporate",
  },
  { value: CUSTOMER_TYPE_INSURANCE_CARRIER, label: "Insurance Carrier" },
]

const conductRatingSelectOptions: SelectOption[] = [
  { value: CONDUCT_RATING_HIGH, label: "High Conduct Risk" },
  { value: CONDUCT_RATING_MEDIUM, label: "Medium Conduct Risk" },
  { value: CONDUCT_RATING_LOW, label: "Low Conduct Risk" },
]

const InputFieldContainer: FC<{
  children: ReactNode
  className?: string
}> = ({ children, className }): JSX.Element => {
  return (
    <div className={classNames("bg-[#f9f8f4] p-4", className)}>
      <div className="w-4/5">{children}</div>
    </div>
  )
}

interface ContractHeaderDetailsFormProps {
  initialFormFields: {
    brokerName: string
    conductRating: ConductRating | null
    customerType: CustomerType | null
  }
  onSave: (fields: UpdateHeaderData) => void
  onCancel: () => void
  onSubmit: (fields: UpdateHeaderData) => void
}

const ContractHeaderDetailsForm: FC<ContractHeaderDetailsFormProps> = ({
  onSave,
  onCancel,
  onSubmit,
  initialFormFields,
}): JSX.Element => {
  const [
    brokerContactName,
    setBrokerContactName,
    showBrokerContactNameError,
    setShowBrokerContactNameError,
  ] = useFormFieldWithAutoResetError<string, string>(
    initialFormFields.brokerName,
    parseBrokerContactName,
  )

  const [
    customerType,
    setCustomerType,
    showCustomerTypeError,
    setShowCustomerTypeError,
  ] = useFormFieldWithAutoResetError<CustomerType, string | null>(
    initialFormFields.customerType,
    parseCustomerType,
  )

  const [
    conductRating,
    setConductRating,
    showConductRatingError,
    setShowConductRatingError,
  ] = useFormFieldWithAutoResetError<ConductRating, string | null>(
    initialFormFields.conductRating,
    parseConductRating,
  )

  useEffect(() => {
    if (initialFormFields) {
      setBrokerContactName(initialFormFields.brokerName)
      setConductRating(initialFormFields.conductRating)
      setCustomerType(initialFormFields.customerType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBrokerContactName, setConductRating, setCustomerType])

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const validateAndGetData = (): UpdateHeaderData | null => {
    if (!brokerContactName.validated.valid) {
      setShowBrokerContactNameError(true)
    }

    if (!customerType.validated.valid) {
      setShowCustomerTypeError(true)
    }

    if (!conductRating.validated.valid) {
      setShowConductRatingError(true)
    }

    if (
      brokerContactName.validated.valid &&
      customerType.validated.valid &&
      conductRating.validated.valid
    ) {
      return {
        brokerName: brokerContactName.validated.data,
        customerType: customerType.validated.data,
        conductRating: conductRating.validated.data,
      }
    }

    return null
  }
  return (
    <>
      <form
        className="flex w-[592px] flex-col gap-2"
        onSubmit={e => {
          e.preventDefault()
          const data = validateAndGetData()
          if (data) {
            onSubmit(data)
          }
        }}
      >
        <Card padding={0}>
          <InputLabel
            label="Broker contact name"
            className="!mb-0 border-b px-4 py-2 !font-bold"
          >
            <InputFieldContainer>
              <InputWithError
                errorTestId="broker-contact-name-error-message"
                formField={brokerContactName}
                showFormFieldErrors={showBrokerContactNameError}
                input={errorId => (
                  <Input
                    type="text"
                    inputMode="text"
                    hasError={!!errorId}
                    onChange={setBrokerContactName}
                    value={brokerContactName.raw}
                  />
                )}
              />
            </InputFieldContainer>
          </InputLabel>

          <InputLabel
            label="Customer type"
            className="!mb-0 border-t border-b px-4 py-2 !font-bold"
          >
            <InputFieldContainer>
              <InputWithError
                errorTestId="customer-type-error-message"
                formField={customerType}
                showFormFieldErrors={showCustomerTypeError}
                input={errorId => (
                  <Select
                    errorMessageId={errorId}
                    options={customerTypeSelectOptions}
                    selectedValue={customerType.raw}
                    onSelect={setCustomerType}
                    placeholder=""
                  />
                )}
              />
            </InputFieldContainer>
          </InputLabel>

          <InputLabel
            label="Conduct rating"
            className="!mb-0 border-t border-b px-4 py-2 !font-bold"
          >
            <InputFieldContainer className="rounded-b-md">
              <InputWithError
                errorTestId="conduct-rating-error-message"
                formField={conductRating}
                showFormFieldErrors={showConductRatingError}
                input={errorId => (
                  <Select
                    errorMessageId={errorId}
                    options={conductRatingSelectOptions}
                    selectedValue={conductRating.raw}
                    onSelect={setConductRating}
                    placeholder=""
                  />
                )}
              />
            </InputFieldContainer>
          </InputLabel>
        </Card>

        <Card className="flex flex-row gap-2">
          <Button
            key="save"
            label="Save"
            theme="night"
            style="text"
            className="!inline-block"
            onClick={() => {
              const data = validateAndGetData()
              if (data) {
                onSave(data)
              }
            }}
          />
          <Button
            key="cancel"
            label="Cancel"
            theme="night"
            style="outlined"
            stretch="space-between"
            className="!inline-block"
            onClick={() => {
              const fieldBrokerContactName = brokerContactName.validated.valid
                ? brokerContactName.validated.data
                : brokerContactName.raw

              const fieldConductRating = conductRating.validated.valid
                ? conductRating.validated.data
                : conductRating.raw

              const fieldCustomerType = customerType.validated.valid
                ? customerType.validated.data
                : customerType.raw

              if (
                fieldBrokerContactName !== initialFormFields.brokerName ||
                fieldConductRating !== initialFormFields.conductRating ||
                fieldCustomerType !== initialFormFields.customerType
              ) {
                setModalOpen(true)
              } else {
                onCancel()
              }
            }}
          />
          <Button
            key="next"
            label="Next"
            theme="pop"
            style="filled"
            stretch="space-between"
            className="!inline-block"
          />
        </Card>
      </form>
      {modalOpen && (
        <UnsavedChangesModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onLeave={() => {
            navigate("/contract")
          }}
        />
      )}
    </>
  )
}

export default ContractHeaderDetailsForm
