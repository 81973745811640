import { createContext, useContext } from "react"
import { PBQAQuestion } from "@appia/api"
import { PBQASurveyWithDetails } from "./useGetSurveys"

export interface PBQASurveyContextValue {
  pbqaId: string
  activeSurvey: PBQASurveyWithDetails
  activeSectionIdx: number
  activeQuestionId: PBQAQuestion["id"] | null
}

export const PBQASurveyContext = createContext<PBQASurveyContextValue | null>(
  null,
)

const usePBQASurvey = (): PBQASurveyContextValue => {
  const data = useContext(PBQASurveyContext)
  if (data === null) {
    throw new Error("Tried to access uninitialised PBQA survey context")
  }

  return data
}

export default usePBQASurvey
