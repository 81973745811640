import React from "react"
import { Button, DownloadIcon, TrashIcon } from "@appia/ui-components"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import { ContractFile, ContractFileSource, ContractFileType } from "@appia/api"

const fileHeaders = [
  "Name",
  "Type",
  "Source",
  "Uploaded on",
  "Sent to partners?",
]

interface FilesTableProps {
  files?: ContractFile[] | null
}

const FilesTable: React.FC<FilesTableProps> = ({ files }): JSX.Element => {
  return (
    <>
      <div className="m-4 grid grid-cols-6 items-center pt-2">
        {fileHeaders.map((header, index) => (
          <div key={index}>
            <b>{header}</b>
          </div>
        ))}
      </div>
      {files && files.length > 0 ? (
        files.map((file, index) => (
          <div className="m-4 grid grid-cols-6 items-center" key={index}>
            <div className="pr-3">{file.name}</div>
            <div>{ContractFileType[file.type]}</div>
            <div>{ContractFileSource[file.source]}</div>
            <div>{prettyPrintDateString(file.updatedAt.toString())}</div>
            <div data-testid="sent-to-partners-value">
              {file.uploadedToPartnerIngestionDate ? "Yes" : "No"}
            </div>
            <div className="flex justify-end pr-1">
              <Button
                data-testid="file-download-button"
                label=""
                style="text"
                theme="night"
                /* eslint-disable @typescript-eslint/no-empty-function */
                onClick={() => {}}
                icon={{
                  position: "left",
                  icon: <DownloadIcon className="h-5 w-5 justify-end" />,
                }}
              />
              <Button
                data-testid="file-delete-button"
                label=""
                style="text"
                theme="night"
                /* eslint-disable @typescript-eslint/no-empty-function */
                onClick={() => {}}
                icon={{
                  position: "left",
                  icon: <TrashIcon className="h-5 w-5 justify-end" />,
                }}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="p-4 text-center">No documents in this contract.</div>
      )}
    </>
  )
}

export default FilesTable
