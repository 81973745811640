import { FC } from "react"

import { AddNoteIcon, ChatAltIcon } from "@appia/ui-components"

import { PBQAAnswerReason } from "@appia/api"

import { logButtonClick } from "src/amplitude"

export interface AddNoteButtonProps {
  pageName: string
  questionId: string
  reasons: PBQAAnswerReason[]
  label: string
  onClick: () => void
}

const AddNoteButton: FC<AddNoteButtonProps> = ({
  pageName,
  questionId,
  reasons,
  label,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={() => {
        onClick()

        logButtonClick({
          buttonName: "Add note",
          containerName: `pbqa_question_${questionId}`,
          pageName,
        })
      }}
      className="otto-focus mt-1 flex items-center justify-center gap-1 rounded-sm text-sm"
    >
      {reasons.length === 0 ? (
        <>
          <AddNoteIcon className="w-4 text-otto-deep-red" />
          <span aria-hidden className="text-otto-deep-red">
            Add note
          </span>
          <span className="sr-only">Add note for question: {label}</span>
        </>
      ) : (
        <>
          <ChatAltIcon className="w-4 text-otto-deep-green" />
          <span aria-hidden className="text-otto-deep-green">
            Note added
          </span>
          <span className="sr-only">Change note for question {label}</span>
        </>
      )}
    </button>
  )
}

export default AddNoteButton
