import { useEffect, useState } from "react"

import { KiQuote, PBQAInfo, getKiQuote, isApiError } from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"

export type PBQAQuoteRequest = RD.RemoteData<Error, KiQuote | null>

const useGetPBQAQuote = (pbqaId: PBQAInfo["id"]): PBQAQuoteRequest => {
  const apiClient = useApiClient()

  const [quoteReq, setQuoteReq] = useState<PBQAQuoteRequest>(RD.NotAsked)

  useEffect(() => {
    const go = async (): Promise<void> => {
      setQuoteReq(RD.Loading)

      try {
        const { data: existingQuote } = await getKiQuote(apiClient, pbqaId)
        setQuoteReq(RD.Success(existingQuote))
      } catch (e) {
        // A 404 is an acceptable response here. It means there's no associated
        // quote for this PBQA yet
        if (isApiError(e) && e.response?.status === 404) {
          setQuoteReq(RD.Success(null))
        } else {
          Sentry.captureException(e)

          if (e instanceof Error) {
            setQuoteReq(RD.Failure(e))
          }
        }
      }
    }
    go()
  }, [apiClient, pbqaId])

  return quoteReq
}

export default useGetPBQAQuote
