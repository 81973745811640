import { PBQAAnswer, PBQAQuestion, PBQASurvey, Syndicate } from "@appia/api"
import * as Sentry from "@sentry/react"

import slugify from "slugify"
import { toArray } from "src/utils/arrays"

import { PBQAReviewState } from "ReviewPBQA/state"

const answerFullyAccepted = (answer: PBQAAnswer): boolean => {
  if (answer.accepted === false) {
    return (answer.reasons || []).length > 0
  }

  return answer.accepted !== null
}

export const surveyComplete = (
  survey: PBQASurvey,
  syndicateIds: Syndicate["id"][],
  state: PBQAReviewState,
): boolean => {
  if (syndicateIds.length === 0) {
    return false
  }

  const incompleteQuestionIds = survey.questions.reduce<PBQAQuestion["id"][]>(
    (acc, q) => {
      const questionAndAnswer = state[q.id]

      let answered: boolean
      switch (questionAndAnswer.type) {
        case "eea":
        case "decimal":
        case "integer": {
          const { answer } = questionAndAnswer
          answered = toArray(answer).every(
            a => a.answer !== null && a.unit !== null,
          )
          break
        }
        case "boolean":
        case "broker":
        case "country":
        case "date":
        case "inception_date":
        case "insured":
        case "option":
        case "policy_reference":
        case "string":
        case "umr":
        case "syndicate":
        case "overlining": {
          const { answer } = questionAndAnswer
          answered = toArray(answer).every(a => a.answer !== null)
          break
        }
        case "option_multi": {
          const { answer } = questionAndAnswer
          answered = toArray(answer).every(
            a => a.answer !== null && a.answer.length > 0,
          )
          break
        }
      }

      const { question, answer } = questionAndAnswer

      const answers = toArray(answer)

      const accepted = answers.every(answerFullyAccepted)

      const oneAnswerPerSyndicate = question.isSyndicateSpecific
        ? syndicateIds.every(syndicateId =>
            answers.find(a => a.syndicateId === syndicateId),
          )
        : true

      const complete =
        question.required === false
          ? accepted
          : answered && accepted && oneAnswerPerSyndicate

      return complete ? acc : [...acc, q.id]
    },
    [],
  )

  if (incompleteQuestionIds.length > 0) {
    const incompleteAnswers = incompleteQuestionIds.map(id =>
      JSON.stringify(state[id].answer),
    )

    Sentry.withScope(scope => {
      scope.setLevel("debug")
      Sentry.captureException(new Error("Survey incomplete"), {
        extra: {
          incompleteQuestionIds,
          incompleteAnswers,
          pbqaId: survey.answers[0]?.pbqaId,
          surveyId: survey.id,
          surveySlug: survey.slug,
        },
      })
    })

    return false
  }

  return true
}

export const surveySectionToUrl = (
  s: PBQASurvey["groupingHeaders"][number],
): string => slugify(s).toLowerCase()
