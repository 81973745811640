import { FC, ReactElement, useId } from "react"
import { PBQAAnswer } from "@appia/api"
import {
  QuestionAnswerState,
  QuestionAnswerStateBoolean,
  QuestionAnswerStateOverlining,
} from "ReviewPBQA/state"

import AcceptedRadioButtons from "../AcceptedRadioButtons"
import BoundingBoxCTA from "../components/BoundingBoxCTA"
import Group from "../components/Group"

import classNames from "classnames"

const NonBooleanQuestion: FC<{
  isActive: boolean
  onChangeAnswer: (a: PBQAAnswer) => void
  onJumpToBoundingBox: () => void
  questionAnswerState: Exclude<
    QuestionAnswerState,
    QuestionAnswerStateBoolean | QuestionAnswerStateOverlining
  >
  questionComponent: ReactElement
  showFieldErrors: boolean
}> = ({
  isActive,
  onChangeAnswer,
  onJumpToBoundingBox,
  questionAnswerState,
  questionComponent,
  showFieldErrors,
}) => {
  const { question, answer } = questionAnswerState

  const legendId = useId()

  return (
    <Group
      aria-labelledby={legendId}
      className={classNames(
        "border-b border-otto-grey-300",
        isActive ? "bg-ki-dark" : "bg-ki-light",
      )}
    >
      <div
        className={classNames(
          "grid w-full grid-cols-1 items-stretch justify-between lg:grid-cols-[1fr,auto]",
        )}
      >
        <div className="w-full p-2">
          <div className="mb-4 flex items-start gap-2">
            {answer.boundingBox && (
              <BoundingBoxCTA
                questionLabel={question.label}
                onClick={onJumpToBoundingBox}
              />
            )}

            <legend id={legendId}>{question.text}</legend>
          </div>

          {questionAnswerState.type === "eea" ? (
            <div className="lg:max-w-[32rem]">{questionComponent}</div>
          ) : (
            <div className="lg:max-w-[15rem]">{questionComponent}</div>
          )}
        </div>

        <AcceptedRadioButtons
          questionId={question.id}
          questionText={question.text}
          accepted={answer.accepted}
          onChangeAcceptance={accepted =>
            onChangeAnswer({ ...answer, accepted })
          }
          noteAndReasons={{ note: answer.note, reasons: answer.reasons }}
          onChangeNoteAndReasons={({ note, reasons }) =>
            onChangeAnswer({ ...answer, note, reasons })
          }
          showFieldErrors={showFieldErrors}
          paddingTop="lg:pt-8"
        />
      </div>
    </Group>
  )
}

export default NonBooleanQuestion
