import { FC, useState } from "react"
import { Navigate } from "react-router-dom"

import UserSelection from "./components/UserSelection"

import ScreenTemplate from "src/templates/ScreenTemplate"
import ErrorMessage from "src/components/ErrorMessage"
import { Button, Card, Input, InputLabel, Link } from "@appia/ui-components"

import useApiClient from "src/contexts/ApiClientContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"

import { Team, createTeam, massJoinTeam } from "@appia/api"
import { useGetCurrentUser, useGetTeams, useGetUsers } from "src/swr"
import * as RD from "@appia/remote-data"

import { logButtonClick, useLogPageView } from "src/amplitude"
import * as Sentry from "@sentry/react"

import slugify from "slugify"

const PAGE_NAME = "Create team"

const CreateTeamScreen: FC = () => {
  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const apiClient = useApiClient()

  const [teamName, setTeamName] = useState<string>("")
  const slug = slugify(teamName, { lower: true })

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  const { request: currentUserRequest, update: updateGetCurrentUser } =
    useGetCurrentUser()
  const { update: updateGetTeams } = useGetTeams()
  const { request: usersRequest, update: updateGetUsers } = useGetUsers()

  const [createRequest, setCreateRequest] = useState<
    RD.RemoteData<Error, Team>
  >(RD.NotAsked)

  if (!RD.isSuccess(currentUserRequest)) {
    return <Navigate to="/" />
  }

  if (RD.isSuccess(createRequest)) {
    return <Navigate to="/teams" />
  }

  const { companyId } = currentUserRequest.data.user

  const onSubmitForm = async (): Promise<void> => {
    if (!apiClient) {
      return
    }

    try {
      if (teamName.length < 2) {
        throw new Error("Team name must be longer than 2 characters")
      } else if (teamName.length > 100) {
        throw new Error("Team name must be shorter than 100 characters")
      }

      setCreateRequest(RD.Loading)

      const { data: team } = await createTeam(apiClient, teamName)

      await massJoinTeam(apiClient, team.id, selectedUserIds, companyId)

      setCreateRequest(RD.Success(team))

      updateGetCurrentUser()
      updateGetTeams()
      updateGetUsers()
    } catch (e) {
      if (e instanceof Error) {
        setCreateRequest(RD.Failure(e))
        Sentry.captureException(e)
      }
    }
  }

  return (
    <ScreenTemplate
      layout={{ type: "regular", backPath: "/teams" }}
      pageTitle="Create a new team"
    >
      <Card>
        <form
          aria-label="Create team"
          className="grid gap-6"
          onSubmit={async ev => {
            ev.preventDefault()
            onSubmitForm()
          }}
        >
          <InputLabel label="Team name">
            <Input value={teamName} onChange={setTeamName} />
          </InputLabel>

          <InputLabel label="Team email address">
            <Input
              type="email"
              disabled
              value={`${slug || "_"}@mail.appia.dev`}
              onChange={() => {
                return
              }}
            />
          </InputLabel>

          <div>
            <span className="mb-1 block">Team members</span>
            <UserSelection
              allUsers={
                RD.isSuccess(usersRequest) ? usersRequest.data.users : []
              }
              selectedUserIds={selectedUserIds}
              selectUser={id => setSelectedUserIds(ids => [...ids, id])}
              deselectUser={idToDeselect =>
                setSelectedUserIds(ids => ids.filter(id => id !== idToDeselect))
              }
            />
          </div>

          {RD.isFailure(createRequest) && (
            <ErrorMessage
              message="There was an error creating your team:"
              error={createRequest.error}
            />
          )}

          <div className="flex flex-col gap-4 sm:flex-row md:mx-auto md:w-1/2">
            <Link
              href="/teams"
              theme="night"
              style="outlined"
              label="Go back"
              stretch="space-around"
              onClick={() => {
                logButtonClick({
                  buttonName: "Go back",
                  linkHref: "/teams",
                  containerName: "Main",
                  pageName: PAGE_NAME,
                })
              }}
            />

            <Button
              theme="night"
              style="filled"
              stretch="space-around"
              label="Create team"
              isLoading={RD.isLoading(createRequest)}
            />
          </div>
        </form>
      </Card>
    </ScreenTemplate>
  )
}

export default CreateTeamScreen
