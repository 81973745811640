import { FC } from "react"

const EmptyData: FC = () => (
  <span>
    <span className="sr-only">No data</span>
    <span className="text-otto-grey-600" aria-hidden>
      &mdash;
    </span>
  </span>
)

export default EmptyData
