import type { User } from "../Users"

export type PBQAStatus =
  | "archived"
  | "created"
  | "failed"
  | "failed_extraction"
  | "processing"
  | "review_complete"
  | "review_in_progress"
  | "review_required"

export type PBQASortDirection = "asc" | "desc"

export type PBQASortKey =
  | "assured_name"
  | "broker"
  | "inception_date"
  | "owned_by"
  | "submission_date"
  | "umr"
  | "updated_at"
  | "updated_by"

export interface PBQAsSearchQuery {
  // Search parameters
  assuredName: string[] | null
  brokerId: string[] | null
  ownerId: (User["id"] | "unassigned")[] | null
  policyId: string[] | null
  status: PBQAStatus[] | null
  umr: string[] | null
  updatedBy: string[]
  // Search and pagination settings
  order: PBQASortKey | null
  orderBy: PBQASortDirection | null
  page: number | null
  pageSize: number | null
}

export interface PBQASearch {
  items: PBQASearchResult[]
  hits: number
}

export interface PBQASearchResult {
  archivedStatus?: PBQAStatus
  assuredName?: string
  brokerAlias?: string
  brokerId?: string
  brokerName?: string
  createdAt: string
  id: string
  inceptionDate?: string
  quoteLineId?: number
  ownerId?: User["id"]
  ownerName?: string
  riskCodes?: string[]
  status: PBQAStatus
  umr?: string
  updatedAt: string
  updatedBy?: string
}

export const searchPBQAsUrl = (params: URLSearchParams): string =>
  `/api/pbqa/search?${params.toString()}`
