import { FC, useId, useState } from "react"

import {
  KiAnswer,
  PBQAAnswer,
  searchBrokers,
  searchSyndicates,
} from "@appia/api"

import {
  ErrorMessage,
  Input,
  Select,
  SelectAsync,
  SelectMultiple,
  UnitsInput,
  UnitsInputValue,
  WarningMessage,
} from "@appia/ui-components"

import { answersMatch } from "ReviewPBQA/answerUtils"
import {
  QuestionAnswerState,
  QuestionAnswerStateBoolean,
  QuestionAnswerStateOverlining,
} from "ReviewPBQA/state"
import { isEmpty, isNotNull } from "src/utils/typeRefinements"
import {
  prettyPrintBroker,
  prettyPrintSyndicate,
} from "src/utils/prettyPrinters"

import { logPBQAAnswerChange } from "src/amplitude"
import { useDebouncedCallback } from "use-debounce"
import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"
import useApiClient from "src/contexts/ApiClientContext"

import { dequal } from "dequal"

const ChangedValuesWarning: FC<{ warningId: string }> = ({ warningId }) => (
  <WarningMessage
    message="Values have changed"
    className="mt-1"
    id={warningId}
  />
)

const RequiredError: FC<{ label: string; id: string }> = ({ label, id }) => (
  <ErrorMessage
    className="mt-1"
    id={id}
    message={`Please fill in the ${label}`}
  />
)

const QuestionInput: FC<{
  autoAcceptOnBlur?: boolean
  inputId: string
  inputLabelId: string
  onChangeAnswer: (a: PBQAAnswer) => void
  questionAnswerState: Exclude<
    QuestionAnswerState,
    QuestionAnswerStateBoolean | QuestionAnswerStateOverlining
  >
  showChangedValuesWarning: boolean
  showFieldErrors: boolean
}> = ({
  autoAcceptOnBlur = false,
  inputId,
  inputLabelId,
  onChangeAnswer,
  questionAnswerState,
  showChangedValuesWarning,
  showFieldErrors,
}) => {
  const {
    pbqaId,
    activeSurvey: { id: surveyId },
  } = usePBQASurvey()
  const questionId = questionAnswerState.question.id
  const apiClient = useApiClient()

  const logPBQAAnswerChangeDebounced = useDebouncedCallback(
    () => logPBQAAnswerChange({ pbqaId, surveyId, questionId }),
    1000,
  )

  const inputErrorId = useId()

  const inputWarningIdBase = useId()
  const inputWarningId = showChangedValuesWarning ? inputWarningIdBase : ""

  const changedValuesWarning = showChangedValuesWarning ? (
    <ChangedValuesWarning warningId={inputWarningId} />
  ) : null

  const handleChangeAndAccept = (
    a: PBQAAnswer,
    k: KiAnswer | undefined,
    hasBeenEdited: boolean,
  ): void => {
    if (autoAcceptOnBlur && hasBeenEdited) {
      const match = answersMatch(a, k)

      onChangeAnswer({
        ...a,
        accepted: match,
        note: match ? null : a.note,
      })
    } else {
      onChangeAnswer(a)
    }
  }

  const [userHasEdited, setUserHasEdited] = useState<boolean>(false)
  const isRequired = questionAnswerState.question.required
  const hasError =
    showFieldErrors && isRequired && isEmpty(questionAnswerState.answer.answer)

  switch (questionAnswerState.type) {
    case "broker": {
      const { question, answer, kiAnswer } = questionAnswerState

      return (
        <div>
          <SelectAsync
            id={inputId}
            placeholder="Type to search"
            loadResults={async (searchQuery: string) => {
              const { data: brokers } = await searchBrokers(
                apiClient,
                searchQuery,
              )
              return brokers
            }}
            mapResultToLabel={prettyPrintBroker}
            mapResultToValue={broker => broker.id}
            selectedValue={answer.answer}
            onSelect={value => {
              // If no change, return
              if (value?.id === answer.answer?.id) {
                return
              }

              handleChangeAndAccept(
                { ...answer, answer: value },
                kiAnswer,
                true,
              )

              logPBQAAnswerChangeDebounced()
            }}
            errorMessageId={hasError ? inputErrorId : undefined}
            aria-labelledby={`${inputLabelId} ${inputWarningId}`}
            required={isRequired}
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "syndicate": {
      const { question, answer, kiAnswer } = questionAnswerState

      return (
        <div>
          <SelectAsync
            id={inputId}
            placeholder="Type to search"
            loadResults={async (searchQuery: string) => {
              const { data: syndicates } = await searchSyndicates(
                apiClient,
                searchQuery,
              )
              return syndicates
            }}
            mapResultToLabel={prettyPrintSyndicate}
            mapResultToValue={syndicate => syndicate.id}
            selectedValue={answer.answer}
            onSelect={value => {
              // If no change, return
              if (value?.id === answer.answer?.id) {
                return
              }

              handleChangeAndAccept(
                { ...answer, answer: value },
                kiAnswer,
                true,
              )

              logPBQAAnswerChangeDebounced()
            }}
            errorMessageId={hasError ? inputErrorId : undefined}
            aria-labelledby={`${inputLabelId} ${inputWarningId}`}
            required={isRequired}
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "date":
    case "inception_date": {
      const { answer, question, kiAnswer } = questionAnswerState

      return (
        <div>
          <Input
            id={inputId}
            type="date"
            onChange={val => {
              onChangeAnswer({ ...answer, answer: val })
              setUserHasEdited(true)
              logPBQAAnswerChangeDebounced()
            }}
            onBlur={() => {
              handleChangeAndAccept(answer, kiAnswer, userHasEdited)
              setUserHasEdited(false)
            }}
            value={answer.answer || ""}
            required={isRequired}
            hasError={hasError}
            aria-describedby={`${
              hasError ? inputErrorId : ""
            } ${inputWarningId}`}
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "insured":
    case "umr":
    case "policy_reference":
    case "string": {
      const { answer, question, kiAnswer } = questionAnswerState

      return (
        <div>
          <Input
            id={inputId}
            type="text"
            onChange={val => {
              onChangeAnswer({ ...answer, answer: val === "" ? null : val })
              setUserHasEdited(true)
              logPBQAAnswerChangeDebounced()
            }}
            onBlur={() => {
              handleChangeAndAccept(answer, kiAnswer, userHasEdited)
              setUserHasEdited(false)
            }}
            value={answer.answer || ""}
            title={answer.answer || undefined}
            required={isRequired}
            hasError={hasError}
            aria-describedby={`${
              hasError ? inputErrorId : ""
            } ${inputWarningId}`}
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "eea":
    case "integer":
    case "decimal": {
      const { question, answer, kiAnswer } = questionAnswerState

      const { acceptableUnits } = question
      const precision = "precision" in question ? question.precision : undefined
      const value: UnitsInputValue = {
        amount: answer.answer,
        unit: answer.unit,
      }

      const hasUnitError = showFieldErrors && isRequired && isEmpty(value)

      return (
        <div>
          <UnitsInput
            id={inputId}
            labelId={inputLabelId}
            precision={precision ?? 2}
            units={
              acceptableUnits.length === 1
                ? acceptableUnits[0]
                : acceptableUnits
            }
            required={isRequired}
            value={value}
            errorMessageId={hasUnitError ? inputErrorId : undefined}
            onChange={({ amount, unit }) => {
              // If no change, return
              if (amount === answer.answer && unit === answer.unit) {
                return
              }

              handleChangeAndAccept(
                { ...answer, answer: amount, unit },
                kiAnswer,
                true,
              )
              logPBQAAnswerChangeDebounced()
            }}
          />

          {changedValuesWarning}

          {hasUnitError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "country":
    case "option": {
      const { question, answer, kiAnswer } = questionAnswerState

      return (
        <div>
          <Select
            truncateItems={false}
            id={inputId}
            options={question.choices.map((choice: string) => ({
              label: choice,
              value: choice,
            }))}
            placeholder="Choose one"
            aria-labelledby={`${inputLabelId} ${inputWarningId}`}
            errorMessageId={hasError ? inputErrorId : undefined}
            onSelect={val => {
              // If no change, return
              if (val === answer.answer) {
                return
              }

              handleChangeAndAccept({ ...answer, answer: val }, kiAnswer, true)
              logPBQAAnswerChangeDebounced()
            }}
            selectedValue={answer.answer}
            required={isRequired}
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }

    case "option_multi": {
      const { question, answer, kiAnswer } = questionAnswerState

      return (
        <div>
          <SelectMultiple
            id={inputId}
            options={question.choices.map((choice: string) => ({
              label: choice,
              value: choice,
            }))}
            placeholder="Choose one or more"
            aria-labelledby={`${inputLabelId} ${inputWarningId}`}
            errorMessageId={hasError ? inputErrorId : undefined}
            onSelect={val => {
              const vals = val.filter(isNotNull)

              // If no change, return
              if (dequal(vals.sort(), answer.answer?.sort())) {
                return
              }

              handleChangeAndAccept({ ...answer, answer: vals }, kiAnswer, true)
              logPBQAAnswerChangeDebounced()
            }}
            selectedValues={answer.answer || []}
            required
          />

          {changedValuesWarning}

          {hasError && (
            <RequiredError label={question.label} id={inputErrorId} />
          )}
        </div>
      )
    }
  }
}

export default QuestionInput
