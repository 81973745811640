/**
 * From Tailwind UI: https://tailwindui.com/components/application-ui/application-shells/stacked#component-3dc007ccdc462a3767d28be1119c96a7
 */
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { Disclosure } from "@headlessui/react"
import { Logo, LogoBackgroundPattern } from "./Logo"

const SimpleHeader: FC = () => (
  <div>
    <Disclosure
      as="nav"
      aria-label="Site"
      className="relative bg-otto-night forced-colors:border-b"
    >
      <div className="relative z-10 mx-auto flex h-[3.25rem] max-w-8xl flex-shrink-0 items-center justify-between px-4 text-otto-pop sm:px-6 lg:px-8">
        <NavLink to="/" className="otto-focus block rounded">
          <Logo />
        </NavLink>
      </div>

      <div className="absolute top-0 bottom-0 z-0 hidden w-full overflow-hidden md:block">
        <div className="absolute top-0 h-[200px] w-full translate-x-[130px] translate-y-[30px] scale-[1.8]">
          <LogoBackgroundPattern />
        </div>
      </div>
    </Disclosure>
  </div>
)

export default SimpleHeader
